import jsonSchemaTraverse from 'json-schema-traverse';
import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';

import CompensationTemplate from '../templates/compensation-template.vue';
import { WIDGET_TYPE } from '../vjsf';
import { traverseUISchema } from './traverse-ui-schema';

export const VacancyRequestHelpers = {
  getDeadlineTitle,
  getFilterFields,
  removeDeadline,
  removeDelimiters,
  removePosition
};

// получение названия deadline-поля из json-схемы
function getDeadlineTitle(vacancyRequestSchema) {
  const schema = vacancyRequestSchema.schema;
  let result = '';
  const pre = (...args) => {
    const [schema, , , , parentKeyword, , property] = args;
    if (isDeadlineField({ property, schema, parentKeyword }, vacancyRequestSchema)) {
      result = schema.title;
    }
  };
  jsonSchemaTraverse(schema, { cb: { pre } });
  return result;
}

// удаление deadline-поля из схемы для кейса, когда deadline рисуется отдельно
// NOTE: скорее всего можно нарисовать со всей формой, если зарегистрировать виджет для отрисовки,
// но пока так чтобы всё не разнести
function removeDeadline(vacancyRequestSchema) {
  const schema = vacancyRequestSchema.schema;
  const result = cloneDeep(schema);
  const pre = (...args) => {
    const [schema, , , , parentKeyword, parentSchema, property] = args;
    if (isDeadlineField({ property, schema, parentKeyword }, vacancyRequestSchema)) {
      parentSchema.properties = omit(parentSchema.properties, property);
    }
  };
  jsonSchemaTraverse(result, { cb: { pre } });
  return result;
}

// удаление position-поля из схемы для кейса, когда position рисуется отдельно
// (на просмотре заявки position пишется в заголовке)
function removePosition(vacancyRequestSchema) {
  const schema = vacancyRequestSchema.schema;
  const result = cloneDeep(schema);
  const pre = (...args) => {
    const [schema, , , , parentKeyword, parentSchema, property] = args;
    if (isPositionField({ property, schema, parentKeyword })) {
      parentSchema.properties = omit(parentSchema.properties, property);
    }
  };
  jsonSchemaTraverse(result, { cb: { pre } });
  return result;
}

function removeDelimiters(vacancyRequestSchema) {
  const uiSchema = vacancyRequestSchema.ui_schema;
  const result = cloneDeep(uiSchema);
  traverseUISchema(result, (f) => {
    if (f['ui:options']?.breakAfter) {
      f['ui:options'].breakAfter = false;
    }
  });
  return result;
}

// получение списка полей для их отображения-скрытия в интерфейсе
function getFilterFields(vacancyRequestSchema) {
  const uiFields = [];
  traverseUISchema(vacancyRequestSchema.ui_schema, (field, pointer, parent) => {
    // не выводим отдельно дочки для поля "уровень компенсации"
    if (
      parent?.['ui:ObjectFieldTemplate'] === CompensationTemplate.name
      || parent?.['ui:ObjectFieldTemplate'] === CompensationTemplate
    ) {
      return;
    }
    if (
      field['ui:order']
      && field['ui:ObjectFieldTemplate'] !== CompensationTemplate.name
      && field['ui:ObjectFieldTemplate'] !== CompensationTemplate
    ) {
      // если поле комплексное, то его не выводим
      // исключение - "уровень компенсации"
      return;
    }
    // также в фильтр не попадают скрытые поля
    if (field['ui:widget'] === WIDGET_TYPE.HIDDEN || field['ui:widget'] === WIDGET_TYPE.SUBMIT) {
      return;
    }
    uiFields.push({ pointer, field });
  });

  // названия полей хранятся в схеме, поэтому нужно обойти и её
  const schemaFieldsMap = new Map();
  /*
   * нормализация нужна для преобразования такого
   * '/properties/_reason/allOf/0/then/properties/reason_replacement'
   * в такое
   * '/_reason/reason_replacement'
   * для сопоставления со структурой из ui_schema
   * (там обычная вложенность объектов, без properties, allOf и прочего)
   */
  const normalizeSchemaPointer = (str) =>
    str.replaceAll(/\/allOf\/\d+\/(if|then)/g, '').replaceAll('/properties', '');

  const traverseCb = (...args) => {
    const [schema, pointer, , , parentKeyword, , property] = args;

    // иначе обходить будет всякое лишнее (allOf, if, then)
    if (parentKeyword !== 'properties') {
      return;
    }

    if (isDeadlineField({ property, schema, parentKeyword }, vacancyRequestSchema)) {
      return;
    }

    const normalizedPointer = normalizeSchemaPointer(pointer);
    if (!schemaFieldsMap.has(normalizedPointer)) {
      schemaFieldsMap.set(normalizedPointer, schema);
    }
  };
  jsonSchemaTraverse(vacancyRequestSchema.schema, { cb: { pre: traverseCb } });

  return uiFields
    .filter(({ pointer }) => schemaFieldsMap.has(pointer))
    .map(({ pointer }) => ({
      id: pointer,
      title: schemaFieldsMap.get(pointer).title
    }));
}

function isDeadlineField({ property, schema, parentKeyword }, vacancyRequestSchema) {
  const DEADLINE_KEY = vacancyRequestSchema.deadline_field;
  return property === DEADLINE_KEY && schema.type !== 'object' && parentKeyword === 'properties';
}

function isPositionField({ property, schema, parentKeyword }) {
  const POSITION_KEY = 'position';
  return property === POSITION_KEY && schema.type !== 'object' && parentKeyword === 'properties';
}
