<template>
  <vacancy-workdays-progress
    :workdays-in-work="inWork"
    :workdays-before-deadline="beforeDeadline"
    :workdays-after-deadline="afterDeadline"
  />
</template>

<script>
import VacancyWorkdaysProgress from '@/components/deadline-progress/vacancy-workdays-progress.vue';

export default {
  name: 'RequestListItemDeadlineProgress',
  components: {
    VacancyWorkdaysProgress
  },
  props: {
    inWork: {
      type: Number,
      required: true
    },
    beforeDeadline: {
      type: Number,
      required: true
    },
    afterDeadline: {
      type: Number,
      required: true
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
