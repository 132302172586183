<template>
  <transition-group
    ref="wrapper"
    tag="div"
    :class="classes"
    :enter-from-class="$style['fade-enter']"
    :enter-active-class="$style['fade-enter-active']"
    :leave-active-class="$style['fade-leave-active']"
    :leave-to-class="$style['fade-leave-to']"
    :css="isAnimationEnabled"
    data-qa="skeleton"
    @before-enter="beforeEnter"
    @enter="enter"
    @after-enter="afterEnter"
    @before-leave="beforeLeave"
    @leave="leave"
    @after-leave="afterLeave"
  >
    <div
      v-show="isShowSkeleton && !isLoaded"
      ref="loader"
      key="loader"
      :class="$style.loader"
      :style="styles"
      data-qa="loader"
    >
      <slot name="grid" :uuid="uuid" />
    </div>
    <div v-show="isLoaded" ref="content" key="content" data-qa="content">
      <slot />
    </div>
  </transition-group>
</template>

<script>
import { nanoid } from 'nanoid';

import { SkeletonEvents, SkeletonHelper } from '@/shared/lib/util/skeleton-helper';

const ANIMATION_DURATION = 200;
const MIN_ANIMATION_DELAY = 400;
const LOADER_HIDDEN_CLASS = 'hidden';

export default {
  name: 'Skeleton',
  props: {
    zone: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '0'
    },
    height: {
      type: String,
      default: '0'
    },
    relative: {
      type: Boolean,
      default: true
    },
    // Поскольку используется <transition-group>, то
    // при повторном отображении лоадера он появится плавно,
    // но например для списка вакансий это не нужно,
    // плюс могут появляться разные ненужные сайд-эффекты
    instantLoaderShowing: {
      type: Boolean
    },
    loading: {
      type: Boolean,
      default: undefined
    },
    animationDisabled: {
      type: Boolean
    }
  },
  emits: ['loader:appearing', 'content:appeared', 'content:appearing'],
  data() {
    return {
      uuid: nanoid(),
      isShowSkeleton: false,
      isLoaded: this.animationDisabled,
      isReadyToShowContent: false,
      isAnimationDelayed: false,
      animationDurationEnter: ANIMATION_DURATION,
      animationDurationLeave: ANIMATION_DURATION
    };
  },
  computed: {
    classes() {
      return {
        [this.$style.wrapper]: true,
        [this.$style.relative]: this.relative
      };
    },
    isCustomLoader() {
      // Альтернативный способ управления скелетоном в случае,
      // когда контент уже загружен, но нам нужно извне
      // снова показать лоадер
      return typeof this.loading !== 'undefined';
    },
    isAnimationEnabled() {
      return Boolean(this.zone);
    },
    styles() {
      return {
        width: this.width,
        height: this.height
      };
    },
    clipPath() {
      return `url(#skeleton-${this.zone})`;
    }
  },
  watch: {
    loading: {
      handler(loading) {
        if (!this.isCustomLoader) {
          return;
        }
        if (loading) {
          this.isLoaded = false;
          return;
        }
        this.onContentReadyToShow();
      },
      immediate: true
    }
  },
  mounted() {
    // Если скелетон выключен
    if (!this.zone) {
      this.$emit('content:appearing');
      this.showContent();
      return;
    }

    SkeletonHelper.init(() => {
      this.isShowSkeleton = true;
      this.loader = document.querySelector(`.skeleton.${this.zone}`);
      this.startTime = Date.now();
      if (!this.isCustomLoader) {
        SkeletonHelper.subscribe(this.zone, this.onContentReadyToShow);
      }
      this.$emit('loader:appearing');
    });
  },
  methods: {
    onContentReadyToShow() {
      if (this.isReadyToShowContent) {
        this.showContent();
        return;
      }
      this.isAnimationDelayed = true;
    },
    showContent() {
      this.isLoaded = true;
      SkeletonHelper.emit(SkeletonEvents.CONTENT_APPEARING, this.zone);
    },
    setMinHeight(el) {
      if (!this.$refs.wrapper) {
        return;
      }
      const currentMinHeight = Number.parseInt(this.$refs.wrapper.$el.style.minHeight) || 0;
      this.$refs.wrapper.$el.style.minHeight = el
        ? `${Math.max(el.clientHeight, currentMinHeight)}px`
        : null;
    },
    beforeEnter(el) {
      this.setMinHeight(el);
      if (this.instantLoaderShowing) {
        this.animationDurationEnter = el === this.$refs.loader ? 0 : ANIMATION_DURATION;
      }
      if (this.relative) {
        el.classList.add(this.$style.flying);
      }
      if (el === this.$refs.content) {
        this.$emit('content:appearing');
      }
    },
    enter(el) {
      this.setMinHeight(el);
      if (el === this.$refs.loader) {
        this.loader?.classList.remove(LOADER_HIDDEN_CLASS);
      }
    },
    afterEnter(el) {
      el.classList.remove(this.$style.flying);
      this.setMinHeight();

      switch (el) {
        case this.$refs.content:
          this.$emit('content:appeared');
          break;

        case this.$refs.loader:
          setTimeout(
            () => {
              this.isReadyToShowContent = true;
              this.runDelayedAnimation();
            },
            this.isCustomLoader
              ? MIN_ANIMATION_DELAY
              : this.startTime + MIN_ANIMATION_DELAY - Date.now()
          );
          break;
      }
    },
    beforeLeave(el) {
      this.setMinHeight(el);
      if (this.instantLoaderShowing) {
        this.animationDurationLeave = el === this.$refs.content ? 0 : ANIMATION_DURATION;
      }
    },
    leave(el) {
      if (el === this.$refs.loader) {
        this.loader?.classList.add(LOADER_HIDDEN_CLASS);
      }
    },
    afterLeave(el) {
      if (el === this.$refs.loader) {
        this.isReadyToShowContent = false;
      }
    },
    runDelayedAnimation() {
      if (this.isAnimationDelayed) {
        this.showContent();
        this.isAnimationDelayed = false;
      }
    }
  }
};
</script>

<style module>
.wrapper {
  flex: 1;

  &.relative {
    position: relative;
  }
}

.rect {
  /*
   Используется, чтобы градиент "проезжал" по всем
   элементам равномерно, потому что иначе поскольку
   все скелетоны имеют разную ширину,
   а ширина градиента равна ширине <rect>,
   то градиент не синхронизируется между скелетонами
   */
  width: var(--skeleton-shadow-max-width);
}

.loader {
  /* Remove whitespaces */
  font-size: 0;
  line-height: 0;
}

.flying {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-timing-function: ease-out;
}

.fade-enter-active {
  transition-duration: calc(v-bind(animationDurationEnter) * 1ms);
}

.fade-leave-active {
  transition-duration: calc(v-bind(animationDurationLeave) * 1ms);
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<i18n lang="json">{}</i18n>
