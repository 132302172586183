import type { Response } from '../poller/poller';

import { nanoid } from 'nanoid';

export enum BroadcastMessageType {
  REGISTER_TAB = 'register_tab',
  UNREGISTER_TAB = 'unregister_tab',
  SYNC_TABS = 'sync_tabs',
  EVENT = 'event',
  HEARTBEAT = 'heartbeat'
}

export type PostData = {
  type: BroadcastMessageType;
  data: any;
};

const MASTER_TIMEOUT = 1200 + 300 * Math.random();
const MASTER_HEARTBEAT_TIMEOUT = MASTER_TIMEOUT * 2;

type BroadcastTab = {
  id: string;
  timestamp: number;
  isMaster: boolean;
};

type OnBecomeMasterCallback = () => void;
type OnEventHandlerCallback = (data: Response) => void;

type BroadcastTabsMap = Map<string, BroadcastTab>;

export default class TabsBroadcastChannel {
  isMasterTab: boolean;
  #currentTab: BroadcastTab;
  #registeredTabsMap: BroadcastTabsMap = new Map();
  #broadcastChannel: BroadcastChannel;
  #onBecomeMasterCallbacks: Set<OnBecomeMasterCallback> = new Set();
  #onEventCallbacks: Set<OnEventHandlerCallback> = new Set();
  #masterZombieCounter = 0;

  constructor() {
    this.isMasterTab = false;

    this.#currentTab = { id: nanoid(), timestamp: Date.now(), isMaster: false };
    this.#registeredTabsMap.set(this.#currentTab.id, this.#currentTab);

    this.#broadcastChannel = new BroadcastChannel('huntflow-broadcast');
    this.#broadcastChannel.postMessage({
      type: BroadcastMessageType.REGISTER_TAB,
      tab: this.#currentTab
    });

    this.#initListeners();

    setTimeout(() => {
      this.#syncTabs();
      this.#tryToBecomeMasterTab();
    }, MASTER_TIMEOUT);

    let lastInterval = Date.now();
    setInterval(() => {
      const lastHeartbeatTime = Number(localStorage.getItem('master_tab_beat') || '0');
      if (Date.now() - lastInterval > MASTER_HEARTBEAT_TIMEOUT) {
        lastInterval = Date.now();
        return;
      }
      if (Date.now() - lastHeartbeatTime > MASTER_HEARTBEAT_TIMEOUT) {
        if (this.#masterZombieCounter > 4) {
          this.#hadleZombieMaster();
        } else {
          this.#masterZombieCounter += 1;
        }
      } else {
        this.#masterZombieCounter = 0;
      }
      lastInterval = Date.now();
    }, 2000);

    window.addEventListener('storage', (ev) => {
      if (ev.key === 'master_tab_id' && ev.newValue) {
        console.log('got new master', ev.newValue);
        const tab = this.#registeredTabsMap.get(ev.newValue);
        if (tab) {
          tab.isMaster = true;
        }
      }
    });
  }

  #hadleZombieMaster() {
    this.#registeredTabsMap.forEach((tab) => {
      if (this.#currentTab.id !== tab.id && tab.isMaster) {
        console.log('slashing zombie master');
        this.#registeredTabsMap.delete(tab.id);
        this.#syncTabs();
        this.#tryToBecomeMasterTab();
      }
    });
  }

  public onEvent(callback: OnEventHandlerCallback): void {
    this.#onEventCallbacks.add(callback);
  }

  public onBecomeMaster(callback: OnBecomeMasterCallback): void {
    this.#onBecomeMasterCallbacks.add(callback);
  }

  public postMessage(postData: PostData): void {
    if (this.isMasterTab) {
      this.#beat();
    }
    try {
      this.#broadcastChannel.postMessage(postData);
    } catch {
      console.log('failed to broadcast', postData);
    }
  }

  #notifyEvent(data: Response): void {
    this.#onEventCallbacks.forEach((callback) => callback(data));
  }

  #notifyBecomeMaster(): void {
    this.#onBecomeMasterCallbacks.forEach((callback) => callback());
  }

  #initListeners(): void {
    this.#broadcastChannel.addEventListener('message', (message) => {
      const { type } = message.data;

      if (type === BroadcastMessageType.REGISTER_TAB) {
        this.#registerTab(message.data.tab);
      }

      if (type === BroadcastMessageType.UNREGISTER_TAB) {
        this.#unregisterTab(message.data.tab);
      }

      if (type === BroadcastMessageType.SYNC_TABS) {
        this.#setTabsMap(message.data.map);
      }

      if (type === BroadcastMessageType.EVENT) {
        this.#notifyEvent(message.data.data);
      }
    });

    window.addEventListener('beforeunload', () => {
      console.log('beforeunload');
      this.#unregisterTab(this.#currentTab);
      this.#broadcastChannel.close();
    });
  }

  #syncTabs(): void {
    this.#broadcastChannel.postMessage({
      type: BroadcastMessageType.SYNC_TABS,
      map: this.#registeredTabsMap
    });
  }

  #setTabsMap(map: BroadcastTabsMap) {
    this.#registeredTabsMap = new Map(map);
    this.#tryToBecomeMasterTab();
  }

  #registerTab(tab: BroadcastTab): void {
    this.#registeredTabsMap.set(tab.id, tab);
    this.#syncTabs();
  }

  #unregisterTab(tab: BroadcastTab): void {
    this.#registeredTabsMap.delete(tab.id);
    this.#syncTabs();
  }

  #beat() {
    localStorage.setItem('master_tab_beat', Date.now().toString());
  }

  #tryToBecomeMasterTab(): void {
    if (this.isMasterTab) {
      return;
    }

    const tabs = Array.from(this.#registeredTabsMap.values());
    const tabsWithoutCurrent = tabs.filter((tab) => tab.id !== this.#currentTab.id);

    const hasMaster = tabs.some(({ isMaster }) => isMaster);

    if (hasMaster) {
      return;
    }

    const canBecomeMaster = tabsWithoutCurrent.every((tab) => {
      return tab.timestamp > this.#currentTab.timestamp;
    });

    if (canBecomeMaster) {
      this.isMasterTab = true;
      console.log('becoming master');
      this.#notifyBecomeMaster();
      localStorage.setItem('master_tab_id', this.#currentTab.id);
      // Можно оставить здесь, т.к. нужно только на случай смерти вкладки
      setInterval(() => {
        this.postMessage({ type: BroadcastMessageType.HEARTBEAT, data: this.#currentTab.id });
        this.#beat();
      }, MASTER_TIMEOUT);
    }
  }
}
