import cloneDeep from 'lodash/cloneDeep';
// если у последнего поля есть разделитель - нужно его убирать
import mapValues from 'lodash/mapValues';

const isMissingValue = (value) => {
  return [undefined, null, ''].includes(value) || (Array.isArray(value) && !value.length);
};

export function ajustUISchema(uiSchema, { formData }) {
  const order = uiSchema['ui:order'];
  let lastItem = order[order.length - 1];

  if (formData) {
    for (let i = order.length - 1; i >= 0; i--) {
      const field = order[i];
      if (!isMissingValue(formData[field])) {
        lastItem = field;
        break;
      }
    }
  }

  return mapValues(uiSchema, (fieldSchema, key) => {
    const resultFieldSchema = cloneDeep(fieldSchema);
    if (key === lastItem && resultFieldSchema['ui:options']?.breakAfter) {
      resultFieldSchema['ui:options'].breakAfter = false;
    }
    return resultFieldSchema;
  });
}
