<template>
  <div>
    <base-error v-for="(error, index) in errors" :key="index">
      {{ translateError(error) }}
    </base-error>
  </div>
</template>

<script>
import BaseError from '@/components/error/error.vue';

import { trlMessage } from '../../../shared/lib/i18n';

export default {
  name: 'FormFieldError',
  components: {
    BaseError
  },
  props: {
    errorSchema: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    errors() {
      if (!this.errorSchema) {
        return undefined;
      }

      const errMap = (errorInfo) => {
        // кейс для отображения ошибок от бэкенда
        if (typeof errorInfo === 'string') {
          return errorInfo;
        }

        const { name, params } = errorInfo;
        const messageCode = `survey.validation.${name}`;
        // eslint-disable-next-line no-restricted-syntax
        return trlMessage(messageCode, params);
      };

      const requiredErr = this.errorSchema.find((err) => err.name === 'required');
      if (requiredErr) {
        return [errMap(requiredErr)];
      }
      const isNotEmptyErr = this.errorSchema.find((err) => err.name === 'isNotEmpty');
      if (isNotEmptyErr) {
        return [errMap(isNotEmptyErr)];
      }

      const isFormatError = (format) =>
        this.errorSchema.find((err) => err.name === 'format' && err.params.format === format);

      if (isFormatError('snils')) {
        return [trlMessage('This value is not a SNILS')];
      }
      if (isFormatError('email')) {
        return [trlMessage('error.not-correct-email')];
      }
      if (isFormatError('uri')) {
        return [trlMessage('survey.validation.uri')];
      }

      return this.errorSchema.map(errMap);
    }
  },
  methods: {
    // TODO: залипуха, нужно вместе с бэком проточить формат ошибки
    translateError(error) {
      if (error === 'error.unique_fail') {
        return this.$trlMessage('vacancy.not-unique.error');
      }
      return error;
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
