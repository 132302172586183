<template>
  <div :class="$style.root" @click="handleClick">
    <label :class="$style.check">
      <input
        :id="idPrefix + item.id"
        ref="input"
        type="radio"
        :name="`${idPrefix}recent_request`"
        :checked="checked"
        :class="$style.radio"
        @change="handleChange"
      >
      <base-icon v-if="checked" type="checked-item-mark" />
    </label>

    <request-list-item-status :quota="item" :class="$style.status" />

    <request-list-item-caption
      :hired="item.already_hired"
      :to-hire="item.applicants_to_hire"
      :created-at="item.created"
      :class="$style.caption"
    />

    <request-list-item-body
      :schema="schema"
      :ui-schema="uiSchema"
      :form-data="item.values"
      :additional-fields="additionalFields"
      :class="$style.body"
    />
    <request-list-item-deadline-progress
      v-if="showDeadlineProgress"
      :in-work="item.work_days_in_work"
      :before-deadline="item.work_days_before_deadline"
      :class="$style.deadline"
    />
    <request-list-item-deadline
      v-else-if="deadlineDate"
      :date="deadlineDate"
      :vacancy-request-schema="vacancyRequestSchema"
      :class="$style.deadline"
    />
  </div>
</template>

<script>
import { nanoid } from 'nanoid';

import { AdditionalSchemaKeys } from '@/components/hf/request-list-item/additional-schema-keys';
import RequestListItemBody from '@/components/hf/request-list-item/body.vue';
import RequestListItemCaption from '@/components/hf/request-list-item/caption.vue';
import RequestListItemDeadlineProgress from '@/components/hf/request-list-item/deadline-progress.vue';
import RequestListItemDeadline from '@/components/hf/request-list-item/deadline.vue';
import { includesAdditionalKey } from '@/components/hf/request-list-item/helpers/includes-additional-key';
import RequestListItemStatus from '@/components/hf/request-list-item/status.vue';
import RequestListItemCompensationTemplate from '@/components/hf/request-list-item/widgets/compensation-template.vue';
import BaseIcon from '@/components/icon.vue';
import { setObjectTemplateComponents } from '@/components/vjsf/helpers/set-object-template-components';
import { VacancyRequestHelpers } from '@/components/vjsf/helpers/vacancy-request-helpers';

import { DateTimeHelper } from '@/shared/lib/util/date-time-helper';
import { VacancyRequestStatus } from '@/shared/types/vacancy-request-status';

export default {
  name: 'RecentRequestItem',
  components: {
    BaseIcon,
    RequestListItemBody,
    RequestListItemCaption,
    RequestListItemDeadline,
    RequestListItemDeadlineProgress,
    RequestListItemStatus
  },
  props: {
    checked: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    },
    vacancyRequestSchema: {
      type: Object,
      required: true
    },
    fields: {
      type: Array,
      required: true
    }
  },
  emits: ['check'],
  computed: {
    schema() {
      return VacancyRequestHelpers.removeDeadline(this.vacancyRequestSchema);
    },
    uiSchema() {
      const uiSchema = VacancyRequestHelpers.removeDelimiters(this.vacancyRequestSchema);
      return setObjectTemplateComponents(uiSchema, {
        [RequestListItemCompensationTemplate.name]: RequestListItemCompensationTemplate
      });
    },
    isRejected() {
      return this.item.status === VacancyRequestStatus.REJECTED;
    },
    deadlineDate() {
      if (this.isRejected) {
        return undefined;
      }
      return this.item.deadline;
    },
    showDeadlineProgress() {
      if (this.isRejected) {
        return false;
      }
      return (
        typeof this.item.work_days_before_deadline === 'number'
        && typeof this.item.work_days_in_work === 'number'
      );
    },
    additionalFields() {
      const additionalFields = [];
      if (this.isRejected) {
        const reason = this.item.states.find(
          (state) =>
            state.status?.toLocaleLowerCase?.() === VacancyRequestStatus.REJECTED && state.reason
        )?.reason;
        if (reason) {
          additionalFields.push({
            title: this.$trlMessage('vacancy.request.reason'),
            value: reason
          });
        }
      }
      if (includesAdditionalKey(AdditionalSchemaKeys.watcher, this.fields)) {
        const value = this.item.account_info.name;
        additionalFields.push({
          key: AdditionalSchemaKeys.watcher,
          value
        });
      }
      if (includesAdditionalKey(AdditionalSchemaKeys.takenBy, this.fields)) {
        let value = this.item.taken_by;
        // что характерно, taken_by будет только у взятых в работу заявок, да
        value
          = value
            && `${value.name || value.email}, ${DateTimeHelper.toShortFormat({
              date: this.item.changed
            })}`;
        additionalFields.push({
          key: AdditionalSchemaKeys.takenBy,
          value
        });
      }
      return additionalFields;
    }
  },
  watch: {
    checked(value) {
      if (value) {
        this.focus();
      }
    }
  },
  created() {
    this.idPrefix = nanoid();
  },
  mounted() {
    if (this.checked) {
      this.focus();
    }
  },
  methods: {
    handleClick() {
      this.focus();
      if (!this.checked) {
        this.$emit('check');
      }
    },
    handleChange(event) {
      if (event.target.checked && !this.checked) {
        this.focus();
        this.$emit('check');
      }
    },
    focus() {
      this.$refs.input?.focus?.();
    }
  }
};
</script>

<style lang="less" module>
@import '~@less/common/variables.less';

.root {
  position: relative;
  padding: 20px 0 20px 35px;
  cursor: default;
  &:hover {
    background-color: #f9f9f9;
  }
}

.radio {
  composes: visually-hidden from global;
}

.check {
  position: absolute;
  top: 50px;
  left: 10px;
}

.root + .root {
  border-top: 1px solid @borderColor;
}

.status + .caption {
  margin-top: 6px;
}

.caption + .body {
  margin-top: 4px;
}

.body + .deadline {
  margin-top: 2px;
}
</style>

<i18n lang="json">{}</i18n>
