import type { AxiosClient } from '@/shared/api/utils/client/axios';
import type { PaginatedList } from '@/shared/api/utils/types';
import type { QuotaState } from '@/shared/types/quota-state';
import type { VacancyQuota } from '@/shared/types/vacancy-quota';

import { ApiLayer } from '@/shared/api/utils/api-layer';
import axios from '@/shared/api/utils/client/axios';

type QuotaOptions = {
  status?: QuotaState;
  page?: number;
  include_all_frames?: boolean;
  with_hired?: boolean;
  count_hired_events?: boolean;
  skip_pagination?: boolean;
  count?: number;
};

class VacancyQuotaLayer extends ApiLayer<AxiosClient> {
  getListByVacancy(vacancyId: number, params: QuotaOptions = {}) {
    return this.methods.get<Record<number, PaginatedList<VacancyQuota>>>(
      `/vacancy/${vacancyId}/quotas`,
      {
        params
      }
    );
  }

  getList(vacancyId: number, params: QuotaOptions = {}) {
    return this.getListByVacancy(vacancyId, params).then((map) => map[vacancyId]);
  }
}

export const VacancyQuotaAPI = new VacancyQuotaLayer(axios);
