<template>
  <applicants-export
    :title="title"
    :statuses="realStatuses"
    :value="initialValue"
    :loading="isLoading"
    @submit="handleSubmit"
    @cancel="handleCancel"
  />
</template>

<script setup lang="ts">
import type { AxiosError } from 'axios';

import type { Pipeline } from '@/shared/types/pipeline';
import type { Status } from '@/shared/types/status';

import { computed, defineProps, defineEmits, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import { HttpErrorStatus } from '@/shared/api/http-error';
import { VacancyAPI } from '@/shared/api/vacancy';
import { useI18N } from '@/shared/lib/use-i18n';

import ApplicantsExport from '../../applicants-export/applicants-export.vue';
import { ExportType } from './export-type';

const { accountVacancyStatusGroup, applicantId, vacancyId, type } = defineProps<IProps>();

const emit = defineEmits(['submit', 'cancel', 'empty']);

const i18n = useI18N();

type ValueOf<T> = T[keyof T];

type IProps = {
  type: ValueOf<typeof ExportType>;
  applicantId?: number;
  vacancyId: number;
  accountVacancyStatusGroup?: number;
};

const isLoading = ref(false);
const store = useStore();
const pipelineById = computed(() => store.getters['pipelines/pipelineById']);
const statuses = computed(() => store.getters['vacancyStats/statuses']);
const pipelineStatusIds = computed(() =>
  accountVacancyStatusGroup
    ? getPipelineStatusIds(pipelineById.value(accountVacancyStatusGroup))
    : null
);

const realStatuses = computed(() => {
  if (applicantId) {
    return [];
  }

  return filterStatuses(
    statuses.value.filter(({ virtual }) => !virtual),
    pipelineStatusIds.value
  );
});

const title = computed(() => {
  if (type === ExportType.LIST) {
    return i18n('title.list');
  } else if (type === ExportType.SURVEY_TYPE_A) {
    return i18n('title.survey_type_a');
  } else if (type === ExportType.SURVEY_TYPE_Q) {
    return i18n('title.survey_type_q');
  }
  return null;
});

const initialValue = {
  field: [],
  status: realStatuses.value.map(({ id }) => id)
};

const handleSubmit = (values: { field: Array<string>; status: Array<number> }) => {
  isLoading.value = true;

  if (type === ExportType.LIST) {
    return VacancyAPI.fetchStopList(vacancyId, values)
      .then(() => {
        emit('submit');
      })
      .finally(() => {
        isLoading.value = false;
      });
  }

  let payload;
  if (applicantId) {
    payload = {
      vacancy_id: vacancyId,
      field: values.field,
      applicant_id: applicantId
    };
  } else {
    payload = {
      vacancy_id: vacancyId,
      field: values.field,
      status: values.status
    };
  }

  const handleError = (err: AxiosError) => {
    type ErrorResponseData = {
      errors?: {
        common?: Array<string>;
      };
    };
    const data = err.response?.data as ErrorResponseData;
    const reason = data?.errors?.common?.[0];
    const statusCode = err.response?.status;

    const isEmptyReport
      = statusCode === HttpErrorStatus.BAD_REQUEST
        && (reason === 'error.reports.survey_type_a.empty_report'
          || reason === 'error.reports.survey_type_q.empty_report');

    if (isEmptyReport) {
      emit('empty');
    } else {
      throw err;
    }
  };

  if (type === ExportType.SURVEY_TYPE_A) {
    return VacancyAPI.generateSurveyTypeAReport(payload)
      .then(() => {
        emit('submit');
      })
      .catch(handleError)
      .finally(() => {
        isLoading.value = false;
      });
  }

  if (type === ExportType.SURVEY_TYPE_Q) {
    return VacancyAPI.generateSurveyTypeQReport(payload)
      .then(() => {
        emit('submit');
      })
      .catch(handleError)
      .finally(() => {
        isLoading.value = false;
      });
  }
};

const handleCancel = () => emit('cancel');

defineComponent({
  name: 'VApplicantsExport'
});

function getPipelineStatusIds(pipeline: Pipeline) {
  return pipeline?.statuses.map((status) => status.account_vacancy_status);
}

function filterStatuses(statuses: Array<Status>, pipelineStatusIds: Array<Status>) {
  return pipelineStatusIds ? statuses.filter((s) => pipelineStatusIds.includes(s.id)) : statuses;
}
</script>

<i18n lang="json">
{
  "title.list": {
    "ru_RU": "Лист кандидатов",
    "en_US": "Applicants list"
  },
  "title.survey_type_a": {
    "ru_RU": "Обратная связь по кандидатам на вакансии",
    "en_US": "Feedback on candidates for vacancy"
  },
  "title.survey_type_q": {
    "ru_RU": "Анкеты кандидатов на вакансии",
    "en_US": "Candidate questionnaires for vacancy"
  }
}
</i18n>
