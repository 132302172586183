<template>
  <button class="elixir-button" @click="toggleElixir">
    <regular-state-bg class="elixit-bg--regular" />
    <hover-state-bg class="elixit-bg--hover" />
    <active-state-bg class="elixit-bg--active" />
    <span class="counter">
      <span v-if="totalCounter" :title="total || 0">
        {{ totalCounter }}
      </span>
      <dots v-else />
    </span>
    <span v-if="mineCounter" :title="mine || 0" class="badge">
      {{ mineCounter }}
    </span>
  </button>
</template>

<script>
import ActiveStateBg from './elixir-huntflow-bubble-active.svg?vue';
import Dots from './elixir-huntflow-bubble-dots.svg?vue';
import HoverStateBg from './elixir-huntflow-bubble-hover.svg?vue';
import RegularStateBg from './elixir-huntflow-bubble.svg?vue';
import getOrCreateElixirInstance from './helpers/getOrCreateElixirInstance';

const EVENTS = {
  unreadMessagesChange: 'UNREAD_COUNTER_MESSAGES_CHANGE',
  unreadRepliesChange: 'UNREAD_COUNTER_REPLIES_CHANGE'
};

export default {
  name: 'ElixirButton',
  components: {
    RegularStateBg,
    HoverStateBg,
    ActiveStateBg,
    Dots
  },
  data() {
    return {
      total: undefined,
      mine: undefined
    };
  },
  computed: {
    totalCounter() {
      return this.total && getApproximateCounterRepresentation(this.total, 2);
    },
    mineCounter() {
      return this.mine && getApproximateCounterRepresentation(this.mine, 1);
    }
  },
  mounted() {
    this.elixir = getOrCreateElixirInstance();
    this.total = this.elixir.unreadMessagesCount;
    this.mine = this.elixir.unreadRepliesCount;

    this.elixir.on(EVENTS.unreadMessagesChange, (unreadMessagesCount) => {
      this.total = unreadMessagesCount;
    });

    this.elixir.on(EVENTS.unreadRepliesChange, (unreadRepliesCount) => {
      this.mine = unreadRepliesCount;
    });
  },
  methods: {
    toggleElixir() {
      this.elixir.widgetIsPopupOpen ? this.elixir.closePopup() : this.elixir.openPopup();
    }
  }
};

function getApproximateCounterRepresentation(value, maxDigitCount) {
  const nearestOverflowValue = 10 ** maxDigitCount;
  return value >= nearestOverflowValue ? `${nearestOverflowValue - 1}+` : String(value);
}
</script>

<style>
$highlight-bg-color: #ff0035;
$highlight-text-color: #ffffff;

.elixir-button {
  @mixin reset-button-styles;
  position: relative;
  bottom: 0;
  vertical-align: top;
  font-size: 0;
  line-height: 0;
  z-index: 999999;
}

.elixit-bg--regular,
.elixit-bg--hover,
.elixit-bg--active {
  display: none;
}

.elixir-button .elixit-bg--regular {
  display: inline-block;
}

.elixir-button:hover .elixit-bg--regular,
.elixir-button:hover .elixit-bg--active {
  display: none;
}
.elixir-button:hover .elixit-bg--hover {
  display: inline-block;
}

.elixir-button:active .elixit-bg--regular,
.elixir-button:active .elixit-bg--hover {
  display: none;
}
.elixir-button:active .elixit-bg--active {
  display: inline-block;
}

.elixir-button .counter {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 10px;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
}

.elixir-button:active .counter > * {
  margin-top: 1px;
}

.elixir-button .badge {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -13px;
  right: -13px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  padding: 1px 0 0 1px;
  font-size: 12px;
  line-height: 14px;
  background-color: $highlight-bg-color;
  color: $highlight-text-color;
  font-weight: 500;
}

/*
   TODO: поддержать возможность кастомизации стилей фрейма на стороне Эликсира
   и переместить в ./helpers/getOrCreateElixirInstance.js
 */
.elixirchat-widget-iframe.elixirchat-widget-iframe--visible {
  bottom: 67px;
  right: 17px;
}
</style>

<i18n lang="json">{}</i18n>
