<template>
  <div :class="className" data-qa="popup-layout-footer">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'VFooter',
  computed: {
    className() {
      return {
        [this.$style.footer]: true
      };
    }
  }
};
</script>

<style module>
.footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 20px 24px;
  border-top: 1px solid var(--border-neutral-S, rgba(0, 0, 0, 0.08));
  background-color: $white;
  border-radius: 0 0 var(--Radius-x4, 16px) var(--Radius-x4, 16px);
}
</style>

<i18n lang="json">{}</i18n>
