type IUiSchemaEntry = {
  'ui:options'?: { breakAfter: boolean };
};

type ISchema = {
  properties: {
    [key: string]: {
      metadata?: { is_system: boolean };
    };
  };
};

// такое себе решение оказалось -
// без проблем поставит разделитель между обычными полями (если одно из них системное, а другое - нет),
// но не отработает если соседствуют поля с условным отображением,
// т.к. они хранятся отдельно от schema.properties
export function separateSystemFields(
  schema: ISchema,
  uiSchema: Record<string, IUiSchemaEntry> & { 'ui:order': Array<string> },
  zones: Record<string, Set<string>>
): void {
  const setBreakAfter = (fieldUiSchema: IUiSchemaEntry, breakAfter = true) => {
    if (fieldUiSchema['ui:options']) {
      fieldUiSchema['ui:options'].breakAfter = breakAfter;
    } else {
      fieldUiSchema['ui:options'] = { breakAfter };
    }
  };

  Object.keys(zones).forEach((zoneName) => {
    let fields = Array.from(zones[zoneName]);
    fields = uiSchema['ui:order'].filter((name) => fields.includes(name));

    // add a delimiter between fields
    fields.forEach((name, index, arr) => {
      if (
        index > 0
        && schema.properties[arr[index - 1]]
        && schema.properties[name]
        && schema.properties[arr[index - 1]].metadata?.is_system
        !== schema.properties[name].metadata?.is_system
      ) {
        setBreakAfter(uiSchema[arr[index - 1]]);
      }

      if (
        index < arr.length - 1
        && schema.properties[arr[index + 1]]
        && schema.properties[name]
        && schema.properties[arr[index + 1]].metadata?.is_system
        !== schema.properties[name].metadata?.is_system
      ) {
        setBreakAfter(uiSchema[name]);
      }
    });

    // remove the trailing delimiter
    const lastFieldName = fields.at(-1);
    if (lastFieldName) {
      setBreakAfter(uiSchema[lastFieldName], false);
    }
  });
}
