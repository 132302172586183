<template>
  <modal-wrapper :open="showModal" @change="showModal = $event">
    <template #default="{ hide }">
      <popup-layout :title="$trlMessage('documents_request.document_type_modal.title')">
        <template #main>
          <base-label :class="$style.field">
            <div :class="$style.labelText">
              {{ $trlMessage('documents_request.document_type_modal.step.1') }}
            </div>
            <base-select :value="documentType" @input="handleTypeChange">
              <base-option v-for="type in $options.types" :key="type" :value="type">
                <!-- eslint-disable-next-line vue/no-restricted-syntax -->
                {{ $trlMessage(`documents_request.document_type_modal.type.${type}`) }}
              </base-option>
            </base-select>
          </base-label>

          <base-select
            v-if="showClosingDocumentType"
            :value="closingDocumentType"
            :class="$style.field"
            @input="handleTypeChange($event, $options.CLOSING_DOCUMENTS_KEY)"
          >
            <base-option
              v-for="subtype in $options.closingDocumentsTypes"
              :key="subtype"
              :value="subtype"
            >
              {{
                // eslint-disable-next-line vue/no-restricted-syntax
                $trlMessage(
                  `documents_request.document_type_modal.type.${$options.CLOSING_DOCUMENTS_KEY}.${subtype}`
                )
              }}
            </base-option>
          </base-select>

          <div v-if="shouldShowWarning" :class="$style.warning" v-html="warningMessage" />
        </template>

        <template #footer>
          <base-button data-qa="continue" :disabled="shouldShowWarning" @click="nextStep">
            {{ $trlMessage('documents_request.document_type_modal.button.next') }}
          </base-button>
          <base-button data-qa="close" @click="hide">
            {{ $trlMessage('button.cancel') }}
          </base-button>
        </template>
      </popup-layout>
    </template>
  </modal-wrapper>
</template>

<script>
import BaseButton from '@/components/button/button.vue';
import BaseLabel from '@/components/label/label.vue';
import ModalWrapper from '@/components/modal-next/modal-wrapper.vue';
import PopupLayout from '@/components/popup-layout/popup-layout.vue';
import BaseOption from '@/components/select/base-option.vue';
import BaseSelect from '@/components/select/select.vue';

import { userPermissions } from '@/shared/lib/config/user-permissions';
import { StorageHelper } from '@/shared/lib/util/storage-helper';

import { DocumentType, ClosingDocumentType } from './document-type';

const STORAGE_KEY = `accounting_documents_document_${userPermissions.get(
  'nick'
)}_${userPermissions.get('account_member_id')}`;

const TYPES = [DocumentType.RECONCILIATION, DocumentType.CLOSING, DocumentType.OTHER];
const CLOSING_DOCUMENT_TYPES = [
  ClosingDocumentType.LICENSE_AGREEMENT,
  ClosingDocumentType.CONTRACT,
  ClosingDocumentType.INVOICE
];

export default {
  name: 'DocumentsModal',
  components: {
    BaseOption,
    ModalWrapper,
    PopupLayout,
    BaseLabel,
    BaseSelect,
    BaseButton
  },
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change', 'next-step'],
  CLOSING_DOCUMENTS_KEY: DocumentType.CLOSING,
  types: TYPES,
  closingDocumentsTypes: CLOSING_DOCUMENT_TYPES,
  data() {
    return {
      ...loadFormData(STORAGE_KEY)
    };
  },
  computed: {
    showModal: {
      get() {
        return this.open;
      },
      set(flag) {
        this.$emit('change', flag);
      }
    },
    shouldShowWarning() {
      return (
        this.documentType === DocumentType.CLOSING
        && this.closingDocumentType === ClosingDocumentType.INVOICE
      );
    },
    warningMessage() {
      if (!this.shouldShowWarning) {
        return undefined;
      }
      const msgKey = `documents_request.document_type_modal.type.closing_documents.${ClosingDocumentType.INVOICE}.warning`;
      // eslint-disable-next-line no-restricted-syntax
      return this.$trlMessage(msgKey, {
        b: (chunks) => `<b>${chunks}</b>`
      });
    },
    showClosingDocumentType() {
      return this.documentType === DocumentType.CLOSING;
    }
  },
  methods: {
    handleTypeChange({ target }, type = '') {
      if (type === DocumentType.CLOSING) {
        this.closingDocumentType = target.value;
        return;
      }

      this.documentType = target.value;
    },
    nextStep() {
      saveFormData(
        {
          documentType: this.documentType,
          closingDocumentType: this.closingDocumentType
        },
        STORAGE_KEY
      );

      this.$emit(
        'next-step',
        this.documentType === DocumentType.CLOSING ? this.closingDocumentType : this.documentType
      );
    }
  }
};

function saveFormData(values, storageKey) {
  return StorageHelper.setJSON(storageKey, values);
}

function loadFormData(storageKey) {
  const result = StorageHelper.getJSON(storageKey) || {};
  if (!result.documentType) {
    result.documentType = TYPES[0];
  }
  if (!result.closingDocumentType) {
    result.closingDocumentType = CLOSING_DOCUMENT_TYPES[0];
  }
  return result;
}
</script>

<style lang="less" module>
.field {
  margin-bottom: 10px;
}

.labelText {
  font-weight: 500;
  margin-bottom: 10px;
}

.warning {
  color: #ec7e00;
  margin: 5px 0 10px;
}
</style>

<i18n lang="json">{}</i18n>
