import type { DateInterval } from '../date-interval';

import { ALL_DAY_ROW_HEIGHT } from '../../config';
import {
  isTwoDaysEvent,
  isEventTakesAllDay,
  isEventEndsAfterDay,
  isEventStartsBeforeDay
} from '../event';
import { time2y } from '../time2y';

const isEventStartBeforeDayStart = (event: DateInterval, dayStart: Date): boolean =>
  event.start.getDate() < dayStart.getDate();

const isEventEndOnDayStart = (event: DateInterval, dayStart: Date): boolean =>
  event.end.getDate() === dayStart.getDate();

export const calculateEventTop = (
  event: DateInterval | null,
  hourHeight: number,
  dayStart: Date
): number | undefined => {
  if (!event) {
    return undefined;
  }

  if (
    isTwoDaysEvent(event)
    && (isEventStartBeforeDayStart(event, dayStart) || isEventEndOnDayStart(event, dayStart))
  ) {
    return ALL_DAY_ROW_HEIGHT;
  }

  return time2y(event.start, hourHeight);
};

export const calculateEventHeight = (
  event: DateInterval | null,
  hourHeight: number,
  selectedDay: DateInterval
): number | undefined => {
  if (!event) {
    return undefined;
  }

  const startY = time2y(event.start, hourHeight) - ALL_DAY_ROW_HEIGHT;
  const endY = time2y(event.end, hourHeight) - ALL_DAY_ROW_HEIGHT;

  if (isEventTakesAllDay(event, selectedDay)) {
    return 24 * hourHeight;
  }

  if (isEventStartsBeforeDay(event, selectedDay)) {
    return Math.min(endY, 24 * hourHeight);
  }

  if (isEventEndsAfterDay(event, selectedDay)) {
    return 24 * hourHeight - startY;
  }

  // EventOrSelectedDay is within this day
  return Math.max(endY - startY, Math.round(hourHeight / 3));
};
