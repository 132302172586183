<template>
  <div>
    <base-label v-if="title" :class="$style.title" :for="id">
      {{ title }}
    </base-label>
    <ul v-if="items && items.length > 0" :class="$style.list">
      <li v-for="(item, index) in items" :key="item.id || item.email" :class="$style.item">
        <slot name="itemPrepend" :item="item" :index="index" />
        <slot name="item" :item="item" :index="index" :invalid="isInvalid(index)">
          <email-list-item
            :item="item"
            :invalid="isInvalid(index)"
            @remove="$emit('remove', item)"
          />
        </slot>
        <slot name="itemAppend" :item="item" :index="index" />
      </li>
    </ul>
    <slot name="append" :emails="emails" />
  </div>
</template>

<script>
import BaseLabel from '@/components/label/label.vue';

import EmailListItem from './email-list-item.vue';

export default {
  name: 'EmailList',
  components: {
    EmailListItem,
    BaseLabel
  },
  props: {
    title: {
      type: String,
      default: undefined
    },
    id: {
      type: String,
      default: undefined
    },
    items: {
      type: Array,
      default: () => []
    },
    invalidValueIndices: {
      type: Array,
      default: () => []
    }
  },
  emits: ['remove'],
  computed: {
    emails() {
      return this.items.map((item) => item.email);
    }
  },
  methods: {
    isInvalid(index) {
      return this.invalidValueIndices.includes(index);
    }
  }
};
</script>

<style module>
.title {
  display: inline-block;
  color: #050505;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
}

.list {
  display: grid;
  gap: 8px;
  list-style: none inside none;
  margin: 0 0 8px 0;
  padding: 0;
}

.item {
  @mixin ellipsis;
}
</style>

<i18n lang="json">{}</i18n>
