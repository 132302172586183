import type { parentField } from '@/components/base-list/composables/constants';
import type { DictionarySettings } from '@/shared/types/dictionary-settings';

export type DictionaryStructure = {
  meta: Record<string, unknown>;
  items: {
    [id: number | string]: TreeId;
  };
};

export type ListOptions = {
  include_inactive?: boolean;
  next_page_cursor?: string;
  only_available?: boolean;
};

export type TreeOptions = ListOptions & {
  parent_id?: number;
};

export type SearchOptions = ListOptions & {
  query?: string;
};

export const TREE_ROOT_ID = Symbol('TREE_ROOT_ID').toString();
export type TreeId = number | typeof TREE_ROOT_ID;

export type DictionaryItem = {
  id: TreeId;
  name: string;
  foreign: string;
  active: boolean;
  parent_id: TreeId;
  available?: boolean;
  meta?: Record<string, unknown>;
  [parentField]?: TreeId;
};

export type SearchDictionaryItem = DictionaryItem & {
  name_highlight: string;
};

export type LogsOptions = {
  next_page_cursor?: string;
};

type LogUser = {
  id: number;
  name: string;
  email: string;
  phone: string;
};

type LogDictionaryItem = {
  id: number;
  name: string;
};

type LogDictionaryItemFull = LogDictionaryItem & {
  foreign: string;
  has_children: boolean;
  parents: LogDictionaryItem[];
};

export type LogElement = {
  id: number;
  user?: LogUser;
  created: string;
  log_type: 'DICTIONARIES_SETTINGS' | 'DICTIONARIES';
  data: {
    type?: 'add' | 'rename' | 'unite' | 'move' | 'archive' | 'restore' | 'remove' | 'api';
    total_items?: number;
    before: Record<string, any>;
    after: Record<string, any>;
  };
};

export type SettingsLog = LogElement & {
  log_type: 'DICTIONARIES_SETTINGS';
  data: {
    before: DictionarySettings;
    after: DictionarySettings;
  };
};

export type AddLog = LogElement & {
  log_type: 'DICTIONARIES';
  data: {
    type: 'add';
    total_items: number;
    before: Record<string, never>;
    after: LogDictionaryItemFull;
  };
};

export type RenameLog = LogElement & {
  log_type: 'DICTIONARIES';
  data: {
    type: 'rename';
    total_items: number;
    before: Omit<LogDictionaryItemFull, 'parents'>;
    after: LogDictionaryItemFull;
  };
};

export type UniteLog = LogElement & {
  log_type: 'DICTIONARIES';
  data: {
    type: 'unite';
    total_items: number;
    before: {
      items: LogDictionaryItemFull[];
    };
    after: LogDictionaryItemFull;
  };
};

export type MoveLog = LogElement & {
  log_type: 'DICTIONARIES';
  data: {
    type: 'move';
    total_items: number;
    before: {
      items: LogDictionaryItemFull[];
    };
    after: LogDictionaryItemFull | Record<string, never>;
  };
};

export type ArchiveLog = LogElement & {
  log_type: 'DICTIONARIES';
  data: {
    type: 'archive';
    total_items: number;
    before: {
      items: LogDictionaryItemFull[];
    };
    after: Record<string, never>;
  };
};

export type RestoreLog = LogElement & {
  log_type: 'DICTIONARIES';
  data: {
    type: 'archive';
    total_items: number;
    before: Record<string, never>;
    after: LogDictionaryItemFull;
  };
};

export type RemoveLog = LogElement & {
  log_type: 'DICTIONARIES';
  data: {
    type: 'remove';
    total_items: number;
    before: LogDictionaryItemFull;
    after: LogDictionaryItemFull;
  };
};

export type ApiLog = LogElement & {
  log_type: 'DICTIONARIES';
  data: {
    type: 'api';
    before: Record<string, never>;
    after: Record<string, never>;
  };
};

export type DictionaryLog =
  | SettingsLog
  | AddLog
  | RenameLog
  | UniteLog
  | RestoreLog
  | MoveLog
  | ArchiveLog
  | RemoveLog
  | ApiLog;
