import type { Ref, ComputedRef } from 'vue';

import type { HierarchyMap } from '@/components/hierarchy/hierarchy-map';

import difference from 'lodash/difference';
import { unref, ref, computed } from 'vue';

import { TREE_ROOT_ID } from '@/shared/api/dictionary-partial/types';

import { useListValue } from './use-list-value';

type Value = string | number;
type ValueVariant = Value | null | undefined | Array<Value>;

type Options = {
  multiple: Ref<boolean>;
  maxValues: Ref<number>;
  onlyLeaf: Ref<boolean>;
  disableAutoCompress: Ref<boolean>;
  onChange: (value: ValueVariant) => void;
  hierarchyMap: Ref<HierarchyMap>;
};
type Result<Item> = {
  toggle: (item: Item) => void;
  reset: () => void;
  selection: ComputedRef<ValueVariant>;
  selectionMap: ComputedRef<Record<Value, boolean>>;
};

export function useListSelection<Item extends Record<string, string | number | boolean>>(
  value: Ref<ValueVariant>,
  {
    multiple = ref(false),
    maxValues = ref(Infinity),
    onChange,
    hierarchyMap,
    onlyLeaf = ref(false),
    disableAutoCompress = ref(false)
  }: Options
): Result<Item> {
  const { value: normalizedValue, deepValue: allSelectedValue } = useListValue(value, {
    multiple,
    onlyLeaf,
    hierarchyMap
  });

  const selectionMap = computed(() => {
    const values = allSelectedValue.value;
    if (Array.isArray(values)) {
      return values.reduce<Record<Value, boolean>>((acc, value) => {
        acc[value] = true;
        return acc;
      }, {});
    }

    return {
      [String(values)]: true
    };
  });

  function availableId(id: Value) {
    if (!unref(onlyLeaf)) {
      return true;
    }

    return !hierarchyMap.value.directChildrenMap[id]?.length;
  }

  function isDeselectable(id: Value) {
    return hierarchyMap.value.deepChildrenMap[id]?.some((v) => selectionMap.value[v]);
  }

  function deselectBranches(id: Value, deselectLeaf = false) {
    const allSelected = allSelectedValue.value as Array<Value>;
    const deselected = deselectLeaf ? [id] : [];
    // Незачем проходиться по всем родителям, если выбран только пункт который мы и деселектим, ну или если пунктов вообще нет
    if (!allSelected.length) {
      return [];
    } else if (allSelected.length === 1 && allSelected[0] === id) {
      return deselectLeaf ? [] : [id];
    } else {
      let parentId = hierarchyMap.value.parentChildMap[id];
      while (parentId && parentId !== TREE_ROOT_ID) {
        if (allSelected.includes(parentId)) {
          deselected.push(parentId);
        }
        parentId = hierarchyMap.value.parentChildMap[parentId as Value];
      }
    }

    return difference(allSelected, deselected);
  }

  function deselect(id: Value) {
    if (!unref(multiple)) {
      return id;
    }
    if (unref(onlyLeaf) && selectionMap.value[id]) {
      return deselectBranches(id, true);
    }

    const allSelected = allSelectedValue.value as Array<Value>;
    const deselected = [...(hierarchyMap.value.deepChildrenMap[id] || []), id];
    let parentId = hierarchyMap.value.parentChildMap[id];
    while (parentId && allSelected.includes(parentId as Value)) {
      deselected.push(parentId as Value);
      parentId = hierarchyMap.value.parentChildMap[parentId as Value];
    }
    return difference(allSelected, deselected);
  }

  function select(id: Value) {
    if (!availableId(id)) {
      return normalizedValue.value;
    }
    if (!unref(multiple)) {
      return id;
    }

    if (unref(onlyLeaf)) {
      const result = deselectBranches(id).concat(id);
      return result.length < unref(maxValues) ? result : normalizedValue.value;
    }

    const selected = [...(normalizedValue.value as Array<Value>)];
    const allSelected = allSelectedValue.value as Array<Value>;

    let parentId = hierarchyMap.value.parentChildMap[id];
    if (!disableAutoCompress.value) {
      while (
        parentId
        && parentId !== TREE_ROOT_ID
        && !hierarchyMap.value.directChildrenMap[parentId as Value].some(
          (child) => id !== child && !allSelected.includes(child)
        )
      ) {
        id = parentId as Value;
        parentId = hierarchyMap.value.parentChildMap[parentId as Value];
      }
    }

    const deselected = hierarchyMap.value.deepChildrenMap[id];
    selected.push(id);
    const result = difference(selected, deselected);
    return allSelected.concat(deselected).length <= unref(maxValues)
      ? result
      : normalizedValue.value;
  }

  function itemToValue(item: Item) {
    return item.id as number | string;
  }

  function toggle(item: Item) {
    const value = itemToValue(item);

    if (isDeselectable(value) && !availableId(value)) {
      onChange(deselect(value));
      return;
    }

    if (item.disabled || item.available === false) {
      return;
    }

    if (selectionMap.value[value]) {
      onChange(deselect(value));
      return;
    }

    onChange(select(value));
  }

  function reset() {
    onChange(unref(multiple) ? [] : null);
  }

  return {
    toggle,
    reset,
    selection: allSelectedValue,
    selectionMap
  };
}
