import type { Middleware } from '@floating-ui/dom';

export const flipSizeFixMiddleware: Middleware = {
  name: 'flipSizeFix',
  fn: ({ middlewareData, rects }) => {
    if (middlewareData.flipSizeFix?.reset) {
      return {};
    }
    return {
      data: {
        reset: true
      },
      reset: {
        rects: {
          floating: {
            ...rects.floating,
            height: rects.floating.height + 1
          },
          reference: rects.reference
        }
      }
    };
  }
};
