<template>
  <vacancy-form v-if="formProps" v-bind="formProps" @saved="handleSaved" />
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

import VacancyForm from '@/modules/vacancy/components/vacancy-form/vacancy-form.vue';

import { EntityAdd } from '@/shared/lib/entity-add/entity-add';
import { waitForMessage } from '@/shared/lib/poller-instance';
import { MessageEvent } from '@/shared/types/poller-message';
import { VacancyState } from '@/shared/types/vacancy-state';

import { mergeVacancies, getChildVacancyByUniqueFields, getVacancyFields } from '../helpers';

export default {
  name: 'VacancyRequestVacancyWrapper',
  components: {
    VacancyForm
  },
  props: {
    vacancyId: {
      type: Number,
      default: undefined
    },
    vacancyRequest: {
      type: Object,
      required: true
    },
    vacancyRequestSchema: {
      type: Object,
      required: true
    }
  },
  emits: ['save'],
  setup(props, { emit }) {
    const store = useStore();

    const multivacancyFields = computed(() => store.getters['config/useMultivacancy']);

    const vacancyFields = computed(() =>
      getVacancyFields(props.vacancyRequest, props.vacancyRequestSchema)
    );

    const formProps = ref(
      props.vacancyId
        ? undefined
        : {
            // Добавляем заявку как новую
            vacancy: vacancyFields.value,
            vacancyRequest: vacancyFields.value
          }
    );

    if (props.vacancyId) {
      // Добавляем заявку к существующей
      store.dispatch('vacancies/loadVacancy', props.vacancyId).then((existingVacancy) => {
        if (!existingVacancy.multiple) {
          const mergedVacancy = mergeVacancies(existingVacancy, vacancyFields.value);
          formProps.value = {
            vacancy: mergedVacancy,
            vacancyRequest: vacancyFields.value
          };
          return;
        }

        store.dispatch('vacancies/loadChildren', props.vacancyId).then((children) => {
          const candidate = getChildVacancyByUniqueFields(
            children,
            vacancyFields.value,
            multivacancyFields.value
          );

          if (!candidate) {
            formProps.value = {
              vacancy: existingVacancy,
              vacancyRequest: vacancyFields.value,
              multiple: true
            };
            return;
          }

          store.dispatch('vacancies/loadVacancy', candidate.id).then((childVacancy) => {
            const mergedFields = mergeVacancies(childVacancy, vacancyFields.value);
            const multiVacancyModel = {
              ...existingVacancy,
              blocks: [...children.filter((m) => m.id !== childVacancy.id), mergedFields]
            };
            formProps.value = {
              vacancy: multiVacancyModel,
              childId: childVacancy.id,
              vacancyRequest: vacancyFields.value
            };
          });
        });
      });
    }

    function handleSaved(vacancy) {
      emit('save', { vacancyId: vacancy.id });

      if (vacancy.state === VacancyState.closed) {
        const editPromise = waitForMessage(
          (message) => message.event === MessageEvent.vacancyEdit && message.data.id === vacancy.id
        );
        Promise.all([EntityAdd.onVacancyAdd(vacancy), editPromise]).then(([url]) =>
          EntityAdd.navigate(url)
        );
      } else {
        EntityAdd.onVacancyAdd(vacancy).then(EntityAdd.navigate);
      }
    }

    return {
      formProps,
      handleSaved
    };
  }
};
</script>

<i18n lang="json">{}</i18n>
