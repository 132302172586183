import type {
  Router as RouterType,
  RouterOptions
} from 'vue-router';

import {
  isNavigationFailure,
  createRouter as createRouterBase,
  createWebHistory,
  createWebHashHistory
} from 'vue-router';

export { createWebHistory, createWebHashHistory };
export type CreateRouter = RouterType;

export const createRouter = (options: RouterOptions): CreateRouter => {
  const router = createRouterBase(options);
  const originalPush = router.push;
  router.push = (to) => {
    return originalPush.call(this, to).catch((err) => {
      if (isNavigationFailure(err)) {
        // resolve err
        return err as any;
      }
      // rethrow error
      return Promise.reject(err);
    });
  };

  const originalReplace = router.replace;
  router.replace = (to) => {
    return originalReplace.call(this, to).catch((err) => {
      if (isNavigationFailure(err)) {
        // resolve err
        return err as any;
      }
      // rethrow error
      return Promise.reject(err);
    });
  };

  return router;
};
