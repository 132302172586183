<template>
  <base-template
    :id="id"
    :ordered-properties="orderedProperties"
    :schema="schema"
    :ui-schema="uiSchema"
    :label="label"
  >
    <slot name="contract" />
    <div v-if="schema.properties.contract_from" :class="$style.range">
      <div :class="$style.field">
        <slot name="contract_from" :ui-schema="contractFromUiSchema" />
      </div>
      <div :class="$style.dash">
        <form-field label="&nbsp;" data-qa="contract_delimiter">
          &mdash;
        </form-field>
      </div>
      <div :class="$style.field">
        <slot name="contract_to" :ui-schema="contractToUiSchema" />
      </div>
    </div>
  </base-template>
</template>

<script>
import FormField from '../widgets/form-field.vue';
import BaseTemplate from './base-template.vue';

export default {
  name: 'ContractTemplate',
  components: { FormField, BaseTemplate },
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      default: () => ({})
    },
    schema: {
      type: Object,
      required: true
    },
    uiSchema: {
      type: Object,
      required: true
    },
    orderedProperties: {
      type: Array,
      required: true
    }
  },
  computed: {
    computedFormData() {
      return this.formData || {};
    },
    contractFromUiSchema() {
      const schema = this.uiSchema.contract_from;
      return {
        ...schema,
        'ui:options': {
          ...schema?.['ui:options'],
          max: this.computedFormData.contract_to || false
        }
      };
    },
    contractToUiSchema() {
      const schema = this.uiSchema.contract_to;
      return {
        ...schema,
        'ui:options': {
          ...schema?.['ui:options'],
          min: this.computedFormData.contract_from || false
        }
      };
    }
  }
};
</script>

<style module>
.range {
  display: flex;
  width: 100%;
}
.field {
  flex: 1 0 0;
}
.dash {
  padding: 0 10px;
  flex: 0 0 auto;
}
</style>

<i18n lang="json">{}</i18n>
