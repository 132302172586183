<template>
  <div :class="classes" :style="style" @click="$emit('click', $event)" @mousedown="handleMouseDown">
    <span :class="$style.additionalContent">
      <slot name="prepend" :value="value" :disabled="disabled" :readonly="readonly">
        <base-icon v-if="prependIcon" :type="prependIcon" />
      </slot>
    </span>
    <input
      ref="input"
      v-bind="$attrs"
      :aria-invalid="invalid ? 'true' : 'false'"
      :value="value"
      :readonly="readonly || disabled"
      :disabled="disabled"
      :class="$style.input"
      @input="handleInput"
    >
    <span :class="$style.additionalContent">
      <slot name="append" :value="value" :disabled="disabled" :readonly="readonly">
        <base-icon v-if="appendIcon" :type="appendIcon" />
      </slot>
    </span>
    <button
      v-if="clearable && !disabled && value"
      type="button"
      :class="$style.clear"
      data-qa="clear"
      @click.capture.stop="$emit('input', undefined)"
    >
      <base-icon type="remove" width="14px" />
    </button>
  </div>
</template>

<script>
import BaseIcon from '@/components/icon.vue';

export default {
  name: 'BaseInput',
  components: { BaseIcon },
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number],
      default: undefined
    },
    visuallyDisabled: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    invalid: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    big: Boolean,
    prependIcon: {
      type: String,
      default: null
    },
    appendIcon: {
      type: String,
      default: null
    },
    number: Boolean,
    class: {
      type: [String, Array, Object],
      default: undefined
    },
    style: {
      type: [String, Array, Object],
      default: undefined
    }
  },
  emits: ['click', 'input'],
  computed: {
    classes() {
      return [
        {
          [this.$style.wrapper]: true,
          [this.$style.invalid]: this.invalid,
          [this.$style.disabled]: this.disabled || this.visuallyDisabled,
          [this.$style.readonly]: this.readonly,
          [this.$style.big]: this.big
        },
        this.class
      ];
    }
  },
  mounted() {
    if (this.autofocus) {
      this.setFocus(true);
    }
  },
  methods: {
    parseValue(value) {
      if (!this.number) {
        return value;
      }
      if (value === '') {
        return undefined;
      }
      const parsedValue = Number.parseFloat(value);
      return Number.isNaN(parsedValue) ? value : parsedValue;
    },
    setFocus(preventScroll = false) {
      this.$nextTick(() => {
        this.$refs.input.focus({
          preventScroll
        });
      });
    },
    handleMouseDown(event) {
      if (event.target === this.$refs.input) {
        return;
      }

      this.setFocus();
      event.preventDefault();
    },
    handleInput(event) {
      this.$emit('input', this.parseValue(event.target.value));
    }
  }
};
</script>

<style module lang="less">
@import '~@less/common/variables';
@import '~@less/common/mixins/font.less';

.wrapper {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  color: var(--content-primary, #050505);
  height: 40px;
  padding: 0 var(--Space-400, 16px);
  margin: 0;
  border-radius: var(--Radius-x2, 8px);
  border: 1px solid var(--border-neutral-M, rgba(0, 0, 0, 0.16));
  background-color: @white;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: text;
  position: relative;
}
.wrapper:focus-within {
  border-color: var(--content-brand-blue, #25cfe8);
}
.wrapper.invalid {
  border-color: var(--content-danger, #f00);
}
.disabled {
  cursor: default;
  opacity: 0.3;
  input {
    pointer-events: none;
    -webkit-text-fill-color: var(--content-primary, #050505);
  }
}
.readonly,
.readonly .input {
  cursor: default;
}
.additionalContent {
  flex: none;
  display: inline-flex;
  align-items: center;
  align-self: stretch;
}
.input {
  align-self: stretch;
  flex: 1 0;
  padding: 0;
  margin: 0;
  outline: 0 none;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  background-color: transparent;
  background-image: none;
  border: none;
  width: 100%;
}
.additionalContent:nth-of-type(1):not(:empty) {
  margin-right: 3px;
}
.additionalContent:nth-of-type(2):not(:empty) {
  margin-left: 3px;
}

.big {
  padding-top: 9px;
  padding-bottom: 10px;
}

.clear {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #8f999c;
  border: none;
  padding: 0;
  background-color: transparent;
  width: 36px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.clear:hover,
.clear:focus {
  color: @textColor;
  outline: none;
}
</style>

<i18n lang="json">{}</i18n>
