// TODO: Remove direct import after babel-preset-env adoption
import filter from 'lodash/filter';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';

import { mapSchemaFields } from '@/shared/api/map-schema-fields';
import { TagsAPI } from '@/shared/api/tags';
import { compareByName } from '@/shared/lib/compators/compators';
import tariffs from '@/shared/lib/tariffs';
import { listToIndex, stringToIndex } from '@/shared/lib/util/search';

// initial state
const account_members = window.AccountMember || [];
const account_vacancy_fields = mapSchemaFields(window.AccountVacancyFields) || {};
const account_applicant_questionary = mapSchemaFields(window.AccountApplicantQuestionary) || null;
const account_tags = (window.Tags || []).map((accountTag) => ({
  ...accountTag,
  _index: stringToIndex(accountTag.name)
}));

const state = {
  account_members: account_members.map((v) => ({
    ...v,
    _index: listToIndex(v.name, v.email)
  })),
  account_vacancy_fields,
  account_applicant_questionary,
  account_tags
};

export const getters = {
  vacancy_fields: (state) => state.account_vacancy_fields,
  filterable_vacancy_fields: (state) =>
    sortBy(
      filter(Object.values(state.account_vacancy_fields), (f) => f.filterable),
      'order'
    ),
  applicant_questionary: (state) => state.account_applicant_questionary,
  isDemoPlan: () => tariffs.isDemo,
  questionaryExists: (state) =>
    state.account_applicant_questionary
    && Object.keys(state.account_applicant_questionary).length > 0,
  tags: (state) => state.account_tags.filter((tag) => !tag.removed).sort(compareByName)
};

// actions
const actions = {
  updateAccountTags({ commit }, { tags }) {
    const payload = tags.filter((tag) => tag.name).sort(compareByName);
    commit('setTags', { tags: payload });

    const groppedTags = groupBy(
      payload.filter((item) => item?.action),
      'action'
    );

    return TagsAPI.create(groppedTags).then((response) => {
      commit('setTags', { tags: response.items });
    });
  }
};

// mutations
const mutations = {
  setTags(state, { tags }) {
    if (!Array.isArray(tags)) {
      tags = [];
    }
    state.account_tags = tags.map((tag) => ({
      ...tag,
      _index: stringToIndex(tag.name)
    }));
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
