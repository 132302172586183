<template>
  <div :class="$style.promo">
    <div :class="$style.content">
      <div :class="$style.body">
        <tariff-badge
          v-if="unlimitedTariffGroup && !settingsHref"
          :tariff-group="unlimitedTariffGroup"
          :class="$style.badge"
        />
        <svg
          :class="$style.picture"
          xmlns="http://www.w3.org/2000/svg"
          width="103"
          height="128"
          fill="none"
        >
          <rect width="89.868" height="107.782" x="12.328" y="19.617" fill="#FCDE78" rx="11.043" />
          <path
            stroke="#1D1D1F"
            stroke-linecap="square"
            stroke-linejoin="round"
            stroke-width="3"
            d="m4.284 32.097-.306 73.913a9.202 9.202 0 0 0 9.104 9.239l60.73.65c5.162.056 9.36-4.146 9.3-9.308l-.879-75.58-10.494.265a5.521 5.521 0 0 1-5.658-5.343l-.36-11.27s-41.59-.21-57.416.277c-9.649.306-7.686 7.264 2.238 6.763 4.368-.22 6.058-4.064 6.581-8.298.722-5.839 5.02-11.309 10.901-11.207l58.395 1.01a9.202 9.202 0 0 1 9.043 9.146l.114 19.06m-7.414 71.757H96V58.208"
          />
          <path
            stroke="#050505"
            stroke-width="3"
            d="M31.795 46.736c0 4.751-3.812 8.603-8.513 8.603-4.701 0-8.512-3.852-8.512-8.603 0-4.752 3.81-8.603 8.512-8.603a8.41 8.41 0 0 1 4.811 1.505M31.795 74.267c0 4.752-3.812 8.603-8.513 8.603-4.701 0-8.512-3.852-8.512-8.603 0-4.751 3.81-8.603 8.512-8.603 1.786 0 3.443.556 4.811 1.505"
          />
          <path
            stroke="#1D1D1F"
            stroke-linejoin="round"
            stroke-width="3"
            d="m20.727 45.376 3.143 3.943 10.477-12.905M20.727 72.903l3.143 3.943 10.477-12.905M40.305 45.016H64.14M40.305 72.547H64.14"
          />
        </svg>
        <h2 :class="$style.title" v-html="$trlMessage('promo.survey_type_q.add.title')" />
        <p
          :class="$style.description"
          v-html="$trlMessage('promo.survey_type_q.add.description')"
        />
      </div>
      <buttons-line :class="$style.footer">
        <link-button v-if="settingsHref" :to="settingsHref" color="green">
          {{ $trlMessage('promo.survey_type_q.create_first_questionnaire') }}
        </link-button>
        <apply-button v-else />
        <base-button @click="$emit('close')">
          {{ $trlMessage('button.close') }}
        </base-button>
      </buttons-line>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/button/button.vue';
import LinkButton from '@/components/button/link-button.vue';
import ButtonsLine from '@/components/layout/buttons-line.vue';
import ApplyButton from '@/components/promo/apply-button.vue';
import TariffBadge from '@/components/tariff-badge/tariff-badge.vue';

import tariffs, { ServiceKindCodes } from '../../../shared/lib/tariffs';

export default {
  name: 'SurveyTypeQPromo',
  components: {
    ApplyButton,
    BaseButton,
    ButtonsLine,
    LinkButton,
    TariffBadge
  },
  props: {
    settingsHref: {
      type: String,
      default: undefined
    }
  },
  emits: ['close'],
  computed: {
    serviceKindCode() {
      return ServiceKindCodes.survey_type_q;
    },
    isTariffAvailable() {
      return tariffs.isTariffAvailable(this.serviceKindCode);
    },
    unlimitedTariffGroup() {
      return tariffs.getUnlimitedGroup(this.serviceKindCode);
    }
  }
};
</script>

<style lang="less" module>
.promo {
  width: 764px;
  padding: 30px 0;
  background-color: #fff;
  border-radius: var(--Radius-x4, 16px) var(--Radius-x4, 16px);
}

.content {
  margin: 0 auto;
  width: 475px;
}

.title,
.description {
  margin: 0;
  padding: 0;
}

.picture {
  display: block;
}

.title {
  font-size: 25px;
  line-height: 30px;
  font-weight: 500;
}

.description {
  font-size: 18px;
  line-height: 26px;
}

.footer {
  padding-top: 20px;
  border-top: 1px solid #cccccc;
}

.badge + .picture {
  margin-top: 40px;
}

.picture + .title {
  margin-top: 30px;
}

.title + .description {
  margin-top: 10px;
}

.body + .footer {
  margin-top: 30px;
}
</style>

<i18n lang="json">{}</i18n>
