<template>
  <base-file @change="onAttached">
    <template #default="props">
      <slot v-bind="props" :loading="uploading" />
    </template>
  </base-file>
</template>

<script>
import { notification } from '@/shared/lib/notification/notification';
import { FileHelper } from '@/shared/lib/util/file-helper';
import { FileState } from '@/shared/types/file-state';

import BaseFile from '../base-file/base-file.vue';
import { baseUploadEmitter } from './base-upload-emitter';

export default {
  name: 'BaseUpload',
  components: { BaseFile },
  props: {
    preset: {
      type: String,
      default: ''
    },
    parse: Boolean,
    baseUrl: String
  },
  emits: [
    'state',
    /**
     * Добавление новых файлов + изменение состояния старых
     */
    'files'
  ],
  data() {
    return {
      uploading: false,
      /**
       * Хранение всех файлов (включая новые и загруженные)
       * @type {Map<string, {info: ReturnType<typeof FileHelper.fileInfo>, status: string}>}
       */
      allFiles: new Map(),
      /**
       * Активные загрузки
       * @type {Map<string, {promise: import('@/shared/api/utils/cancelable-promise').CancelablePromise, info: ReturnType<typeof FileHelper.fileInfo>}>} ключ - temporalId
       */
      uploads: new Map()
    };
  },
  /**
   * Временно, чтобы оставить логику компонента рядом с ним и не заниматься перепрокидыванием методов.
   * Убрать во время рефакторинга на fsd
   * @see src/components/hf/applicant-comment-attachments/base-attachments.vue
   */
  mounted() {
    baseUploadEmitter.on('cancel-upload', this.cancelUpload);
    baseUploadEmitter.on('remove-file', this.removeFile);
  },
  beforeUnmount() {
    baseUploadEmitter.off('cancel-upload', this.cancelUpload);
    baseUploadEmitter.off('remove-file', this.removeFile);
  },
  methods: {
    updateFiles() {
      this.$emit(
        'files',
        Array.from(this.allFiles.values()).map(({ info }) => ({ ...info }))
      );
    },
    updateState(state, fileInfo) {
      const file = this.allFiles.get(fileInfo.temporalId);
      file.status = state;
      this.allFiles.set(fileInfo.temporalId, file);

      this.$emit('state', {
        state,
        file: { ...fileInfo }
      });
    },
    removeFile(temporalId) {
      this.allFiles.delete(temporalId);
    },
    getUpload(temporalId) {
      return this.uploads.get(temporalId);
    },
    onAttached(files) {
      this.uploading = true;

      const filesInfo = files.map(FileHelper.fileInfo);

      filesInfo.forEach((info) => {
        this.allFiles.set(info.temporalId, { info, status: FileState.uploading });
        this.updateState(FileState.uploading, info);
      });

      this.updateFiles();

      files.forEach((file, index) => {
        const info = filesInfo[index];
        const uploadPromise = FileHelper.upload(file, {
          preset: this.preset,
          parse: this.parse,
          baseURL: this.baseUrl
        })
          .then((file) => {
            Object.assign(filesInfo[index], file);
            this.updateState(FileState.uploaded, info);
            this.updateFiles();
          })
          .catch((error) => {
            const upload = this.getUpload(info.temporalId);
            if (upload && upload.promise.cancelled) {
              this.removeFile(info.temporalId);
              return;
            }
            Object.assign(filesInfo[index], { error: error.response.data });
            this.updateState(FileState.failed, info);
            this.updateFiles();
            notification.notify({
              content: this.$trlMessage('file.error.attach'),
              isError: true
            });
          })
          .finally(() => {
            this.uploads.delete(info.temporalId);
          });

        const newUpload = { promise: uploadPromise, info };
        this.uploads.set(info.temporalId, newUpload);
      });

      const promises = Array.from(this.uploads.values()).map((upload) => upload.promise);
      Promise.allSettled(promises).finally(() => {
        this.uploading = false;
      });
    },
    cancelUpload(temporalId) {
      const upload = this.uploads.get(temporalId);
      if (upload) {
        upload.promise.cancel();
        this.uploads.delete(temporalId);
        this.removeFile(temporalId);
        this.updateFiles();
      }
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
