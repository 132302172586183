export enum MessageEvent {
  job = 'job',
  calendars = 'calendars',
  calendarEvents = 'calendar_events',
  vacancyEdit = 'vacancy:edit',
  vacancyAdd = 'vacancy:add',
  vacancyRemove = 'vacancy:remove',
  vacancyRequestEdit = 'vacancy_request:edit',
  vacancyRequestAdd = 'vacancy_request:add',
  vacancyRequestRemove = 'vacancy_request:remove',
  applicantEdit = 'applicant:edit',
  applicantAdd = 'applicant:add',
  applicantRemove = 'applicant:remove',
  applicantLogRemoved = 'applicant:log_removed',
  applicantLogPin = 'applicant:log_pin',
  applicantLogUnPin = 'applicant:log_unpin',
  applicantComment = 'applicant:comment',
  vacancyApplicantAdd = 'vacancy_applicant:add',
  vacancyApplicantEdit = 'vacancy_applicant:edit',
  license_request_send = 'license_request:send',
  vacancyApplicantSplit = 'vacancy_applicant:split',
  vacancyApplicantSplitRemove = 'vacancy_applicant:child_split_remove',
  vacancyResponseStartSync = 'vacancy_response:start_sync',
  vacancyResponseFinishedSync = 'vacancy_response:finished_sync',
  notify = 'notify',
  accountFile = 'account_file',
  setting = 'setting',
  dictionaryUpdated = 'dictionary:updated',
  dictionaryUpdateEdit = 'dictionaries-update:edit',
  applicantResponseExternalRejected = 'applicant_response_external:rejected',
  vacancyExternalUnlink = 'vacancy_external_unlinked',
  vacancyExternalUnpublish = 'vacancy_external:unpublish',
  vacancyRecommendationRejected = 'vacancy_recommendation:rejected',
  vacancyRecommendationCreated = 'vacancy_recommendation:created',
  vacancyRecommendationUpdated = 'vacancy_recommendation:updated',
  cold_email = 'cold_email'
}
