<template>
  <form-field
    :label="label"
    :description="description"
    :delimiter="options.breakAfter"
    :data-qa="options.name || id"
    :error-schema="errorSchema"
    :files="files"
    :class="$props.class"
    :style="style"
  >
    <template #default="{ id: computedId, invalid }">
      <base-input
        :id="computedId"
        :value="computedModel"
        :name="computedId"
        :invalid="invalid"
        v-bind="fieldProps"
        @input-value="computedModel = $event"
      />
    </template>
  </form-field>
</template>

<script>
import BaseInput from '@/components/input/input.vue';

import { JSON_SCHEMA_TYPE } from '../vjsf';
import FormField from './form-field.vue';

export default {
  name: 'FormFieldInput',
  components: {
    BaseInput,
    FormField
  },
  inheritAttrs: false,
  props: {
    value: {
      type: [Number, String],
      default: undefined
    },
    label: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default: () => ({})
    },
    schema: {
      type: Object,
      required: true
    },
    errorSchema: {
      type: Array,
      default: () => []
    },
    id: {
      type: [String, Number],
      required: true
    },
    class: {
      type: [String, Array, Object],
      default: undefined
    },
    style: {
      type: [String, Array, Object],
      default: undefined
    }
  },
  emits: ['change'],
  computed: {
    files() {
      return this.schema.metadata?.files ?? [];
    },
    isNumber() {
      return this.schema.type === JSON_SCHEMA_TYPE.NUMBER;
    },
    computedModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('change', this.isNumber ? this.parseNumber(value) : value);
      }
    },
    fieldProps() {
      return {
        autocomplete: 'off',
        ...this.options,
        ...this.$attrs
      };
    }
  },
  methods: {
    parseNumber(value) {
      if (!value) {
        return null;
      }
      const intValue = Number(value);
      return Number.isNaN(intValue) ? value : intValue;
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
