<template>
  <div v-if="fullRules.length > 0" class="password-errors">
    <p class="password-errors__title">
      {{ title }}
    </p>
    <ul class="password-errors__list">
      <li
        v-for="rule in fullRules"
        :key="rule.id"
        class="password-errors__item"
        :class="{
          'password-errors__item_success': rule.correct,
          'password-errors__item_error': !rule.correct && dirty
        }"
      >
        <div v-if="rule.correct" class="password-errors__icon-container">
          <icon type="check" />
        </div>

        {{ rule.text }}
      </li>
    </ul>
  </div>
</template>

<script>
import Icon from '@/components/icon.vue';

export default {
  name: 'PasswordErrors',
  components: { Icon },
  props: {
    maxRepeatPassword: {
      type: Number,
      default: 0
    },
    errors: {
      type: Array,
      required: true
    },
    rules: {
      type: Array,
      required: true
    },
    dirty: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    errorByType() {
      const map = {};
      this.errors.forEach((error) => {
        map[error.type] = error;
      });
      return map;
    },
    fullRules() {
      return this.rules.map((rule) => {
        const error = this.errorByType[rule.id];
        return {
          ...rule,
          correct: !error,
          // По факту все переводы `password_policy.*`
          text: this.$i18n(rule.text, { count: rule.minCount })
        };
      });
    },
    title() {
      if (this.maxRepeatPassword > 0) {
        return this.$trlMessage('login.password-policy.title-with-repeat', {
          count: this.maxRepeatPassword
        });
      }
      return this.$trlMessage('login.password-policy.title');
    }
  }
};
</script>

<style lang="less" scoped>
@import '~@less/common/mixins/font.less';
@import '~@less/common/variables.less';

.password-errors {
  &__title {
    font-size: 16px;
    line-height: 22px;
    .font_bold();
    color: #8f999c;
    margin-bottom: 10px;
    margin-top: 0;
    @media (max-width: @screen-sm-max) {
      display: none;
    }
  }
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  &__item {
    color: @lightTextColor;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    position: relative;
    gap: 2px;
    margin: 0;
    padding-left: 26px;
    align-items: center;
    @media (max-width: @screen-sm-max) {
      font-size: 14px;
    }
    &:not(:last-child) {
      margin-bottom: 5px;
    }
    &_success {
      color: @btnGreen;
      padding-left: 0;
      .font_bold();
    }
    &_error {
      color: @redColor;
      .font_bold();
    }
  }
  &__icon-container {
    svg {
      display: block;
    }
  }
}
</style>

<i18n lang="json" base="../../auth.lang.json">
{}
</i18n>
