import debounce from 'lodash/debounce';
import { ref, watch } from 'vue';

export const useAutocompleteHook = ({ inputDebounce = 300, searchEngineInstance } = {}) => {
  const query = ref(undefined);
  const filterValue = ref(undefined);
  const { result, search } = searchEngineInstance;

  const debouncedSetQuery = debounce(
    (value) => {
      query.value = value;
    },
    inputDebounce,
    { leading: true }
  );

  const setFilterValue = (value) => {
    filterValue.value = value;
  };

  watch(filterValue, (currFilterValue) => {
    debouncedSetQuery(currFilterValue);
  });

  watch(query, (currQuery) => {
    search(currQuery);
  });

  return {
    filterValue,
    result,
    setFilterValue
  };
};
