import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';

export const mapSchemaFields = (obj) => {
  return (
    obj
    && mapValues(omit(obj, '__version'), (field) => {
      const isHidden = field.edit_via_api_only;
      return isHidden ? { ...field, type: 'hidden' } : field;
    })
  );
};

export const mapItemWithSchema = (obj) => {
  if (!obj.schema) {
    return obj;
  }
  return {
    ...obj,
    schema: mapSchemaFields(obj.schema)
  };
};
