<template>
  <base-row :class="$style.row">
    <div :class="$style.content">
      <slot name="default" :title-class="$style.primaryTitle" :title="title">
        <span :class="$style.primaryTitle" data-qa="title">{{ title }}</span>
      </slot>
      <div v-if="removeLayer && allowClose" :class="$style.closeButton" @click="removeLayer">
        <base-icon type="x" />
      </div>
    </div>
  </base-row>
</template>

<script setup lang="ts">
import { inject } from 'vue';

import BaseIcon from '@/components/icon.vue';

import { removeLayerSymbol } from '@/shared/ui/base-layer/constants';

import BaseRow from './row.vue';

defineOptions({
  name: 'LayoutHeader'
});

defineProps({
  title: {
    type: String,
    default: ''
  },
  allowClose: {
    type: Boolean,
    default: true
  }
});

const removeLayer: any = inject(removeLayerSymbol);
</script>

<style module>
.content {
  padding: 20px 60px 20px 24px !important;
  border-bottom: 1px solid var(--border-neutral-S, rgba(0, 0, 0, 0.08));
  color: #050505;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
}

.primaryTitle {
  font-size: 20px;
  line-height: 28px;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.closeButton {
  flex: 0 0 auto;
  cursor: pointer;
  color: #a5a5a5;
  border-radius: 8px;
  position: absolute;
  top: 20px;
  right: 24px;
  &:hover,
  &:focus {
    background-color: $black-7;
  }
  &:active {
    background-color: #ebebeb;
  }
}

.row {
  background-color: $white;
  border-radius: var(--Radius-x4, 16px) var(--Radius-x4, 16px) 0 0;
}
</style>

<i18n lang="json">{}</i18n>
