import { createWebHistory, createRouter as createRouterBase } from '@/app/entries/create-router';

import { getAvailableRoutes } from '../organization-settings-router/routes'; // Routes для settings/org/huntflow
import commonRoutes from './routes'; // Routes для settings

// Создаём основной роутер
export function createRouter(base, orgsPath) {
  const settingsRoutes = commonRoutes;

  const orgsRoutes = getAvailableRoutes();

  // Добавляем префикс ко всем маршрутам
  const updatedOrgsRoutes = orgsRoutes.map((route) => ({
    ...route,
    path: `${orgsPath}${route.path}`, // Добавляем префикс к основному маршруту
    children: route.children?.map((child) => ({
      ...child,
      path: `${orgsPath}${child.path}` // Добавляем префикс к дочерним маршрутам
    }))
  }));

  // Объединение маршрутов
  const routes = [
    ...settingsRoutes.map((route) => ({
      ...route,
      path: `${route.path ? `${route.path}` : ''}`
    })),
    ...updatedOrgsRoutes
  ];

  return createRouterBase({
    history: createWebHistory(base),
    routes
  });
}

// Получение списка страниц для меню
export function getPages() {
  return commonRoutes.map(({ name }) => ({
    name,
    to: { name }
  }));
}
