export function isCancel(error: unknown): boolean {
  return error instanceof CancelableError;
}

export function filterCanceledRequest<E = unknown>(exception: E): Promise<E> | undefined {
  if (!isCancel(exception)) {
    return Promise.reject(exception);
  }
}

const symbol = Symbol('innerOnCancel');

type InnerOnCancel = {
  (message?: string): void;
  [symbol]: boolean;
};

class CancelableError extends Error {
  readonly __CANCEL__ = true;
}

function isInnerOnCancel(
  onCancel?: (message?: string) => void | InnerOnCancel
): onCancel is InnerOnCancel {
  return !!onCancel?.hasOwnProperty(symbol);
}

export class CancelablePromise<T> {
  private readonly promise: Promise<T>;
  private readonly onCancel: InnerOnCancel;
  private _canceled = false;

  constructor(promise: Promise<T>, onCancel?: (message?: string) => void | InnerOnCancel) {
    if (isInnerOnCancel(onCancel)) {
      this.promise = promise;
      this.onCancel = onCancel;
    } else {
      let wrapReject: (reason?: any) => void;
      let wrapResolve: (value: T | PromiseLike<T>) => void;

      this.promise = new Promise<T>((resolve, reject) => {
        wrapResolve = resolve;
        wrapReject = reject;
      });
      promise.then(
        (res) => wrapResolve(res),
        (err) => wrapReject(err)
      );
      this.onCancel = ((message?: string) => {
        wrapReject(new CancelableError(message));
        if (onCancel) {
          onCancel(message);
        }
      }) as InnerOnCancel;
      this.onCancel[symbol] = true;
    }
    this.promise.catch((err) => {
      if (isCancel(err)) {
        this._canceled = true;
      }
    });
  }

  get [Symbol.toStringTag]() {
    return 'Promise';
  }

  catch<TResult = never>(
    onrejected?: ((reason: any) => TResult | PromiseLike<TResult>) | undefined | null
  ): CancelablePromise<T | TResult> {
    return new CancelablePromise(this.promise.catch(onrejected), this.onCancel);
  }

  then<TResult1 = T, TResult2 = never>(
    onfulfilled?: ((value: T) => PromiseLike<TResult1> | TResult1) | undefined | null,
    onrejected?: ((reason: any) => PromiseLike<TResult2> | TResult2) | undefined | null
  ): CancelablePromise<TResult1 | TResult2> {
    return new CancelablePromise(this.promise.then(onfulfilled, onrejected), this.onCancel);
  }

  finally(onfinally?: (() => void) | undefined | null): CancelablePromise<T> {
    return new CancelablePromise(this.promise.finally(onfinally), this.onCancel);
  }

  static resolve<T>(
    value: T | PromiseLike<T>,
    onCancel?: (message?: string) => void
  ): CancelablePromise<T> {
    return new CancelablePromise(Promise.resolve(value), onCancel);
  }

  // Используется стрелочная функция,
  // чтобы не терялся контекст, например в:
  // watchEffect((onCleanup) => const promise = ...; onCleanup(promise.cancel))
  cancel = (message?: string): void => {
    this._canceled = true;
    this.onCancel(message);
  };

  get canceled(): boolean {
    return this._canceled;
  }
}
