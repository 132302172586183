import type { ComputedRef, Ref } from 'vue';

import type { HierarchyMap } from '@/components/hierarchy/hierarchy-map';
import type { FullNames } from '@/shared/api/dictionary-partial';
import type {
  DictionaryUpdateInfo,
  DictionaryUpdateStructure
} from '@/shared/api/dictionary-partial/layer';
import type {
  TreeId,
  DictionaryItem,
  SearchDictionaryItem
} from '@/shared/api/dictionary-partial/types';
import type { CancelablePromise } from '@/shared/api/utils/cancelable-promise';

import { computed, inject, onBeforeUnmount, provide, ref } from 'vue';

import { useCursorTreePaginator } from '@/components/partial-dictionary-autocomplete/lib/useCursorTreePaginator';
import { useDictionaryResource } from '@/components/partial-dictionary-autocomplete/lib/useDictionaryResource';
import useInactiveManager from '@/components/partial-dictionary-autocomplete/lib/useInactiveManager';

import { getMethodsById } from '@/shared/api/dictionary-partial';
import { MessageEvent } from '@/shared/types/poller-message';

type DictionaryOptions = {
  dictionaryId: Ref<string>;
  multiple?: Ref<boolean>;
  currentValue?: Ref<TreeId[]>;
  autoUpdateOutdated?: Ref<boolean>;
  collapsible?: boolean;
  provideKey?: symbol;
  inactiveSelectable?: boolean;
  showRoot?: boolean;
};

type DictionaryState = {
  id: Ref<string>;
  structureChangeInfo: Ref<DictionaryUpdateInfo | null>;
  structureLoading: Ref<boolean>;

  items: Ref<DictionaryItem[]>;
  itemsLoading: Ref<boolean>;
  itemsByLevel: Ref<Record<TreeId, (DictionaryItem | SearchDictionaryItem)[]>>;

  canLoadMoreByLevel: ComputedRef<Record<TreeId, boolean>>;
  cursorByLevel: Ref<Record<TreeId, string | undefined>>;
  isLevelLoading: (level: TreeId) => boolean;

  // TODO: fix it
  // eslint-disable-next-line ts/no-unsafe-function-type
  pollerListeners: Record<string, Function>;

  isEmpty: ComputedRef<boolean>;
  showInModal: ComputedRef<boolean>;
  searchMode: Ref<boolean>;

  hierarchyMap: ComputedRef<HierarchyMap>;
  hierarchyMapWithInactive: ComputedRef<HierarchyMap>;

  query: Ref<string>;
  setQuery: (query: string) => void;

  showInactive: Ref<boolean>;
  hasInactive: Ref<boolean>;
  handleShowInactive: (showInactive: boolean) => void;

  next: (parentId?: TreeId) => void;
  clear: () => void;
  reFetch: (parentId?: TreeId) => void;
  getFullNames: (id: number[]) => CancelablePromise<FullNames>;
  updateStructure: () => Promise<DictionaryUpdateStructure>;
};

const USE_DICTIONARY_SYMBOL = Symbol('use-dictionary');

const useDictionary = ({
  dictionaryId,
  multiple = ref(false),
  currentValue = ref<TreeId[]>([]),
  autoUpdateOutdated = ref(true),
  collapsible = false,
  provideKey = USE_DICTIONARY_SYMBOL,
  inactiveSelectable = false,
  showRoot = false
}: DictionaryOptions): DictionaryState => {
  const query = ref<string>('');
  const searchMode = ref(false);

  const { showInactive, handleShowInactive } = useInactiveManager({
    dictionaryId
  });

  // API methods
  const apiMethods = getMethodsById(dictionaryId.value);

  const {
    parentChildMap,
    parentChildMapWithInactive,
    hierarchyMap,
    hierarchyMapWithInactive,

    structureChangeInfo,
    structureLoading,

    meta,
    getList,
    getSearch,
    getTree,
    updateStructure,
    handleDictionaryUpdated
  } = useDictionaryResource({
    query,
    dictionaryId,
    apiMethods,
    multiple,
    currentValue,
    inactiveSelectable,
    autoUpdateOutdated,
    showRoot
  });

  // Dictionary info
  const isEmpty = computed(() => Object.keys(parentChildMapWithInactive.value).length === 0);
  const showInModal = computed(() => !!(meta.value.levels && meta.value.levels >= 3));
  const hasInactive = computed(() => !!meta.value.has_inactive);

  // Request for dictionary items, depending on the mode
  const request = (data: { parentId?: TreeId; cursor?: string }) => {
    if (searchMode.value) {
      return getSearch(data);
    }
    if ((showInModal.value || collapsible) && !searchMode.value) {
      return getTree(data);
    }
    return getList(data);
  };

  // Cursor tree paginator
  const {
    items,
    canLoadMoreByLevel,
    cursorByLevel,
    itemsByLevel,
    itemsLoading,
    next,
    clear,
    reFetch,
    isLevelLoading
  } = useCursorTreePaginator<DictionaryItem | SearchDictionaryItem>({
    parentChildMap: showInactive ? parentChildMapWithInactive : parentChildMap,
    showRoot,
    request
  });

  // Listen for side dictionary updates
  const pollerListeners = {
    [MessageEvent.job]: handleDictionaryUpdated
  };

  // Query
  let searchTimeout: NodeJS.Timeout;

  const setQuery = (newQuery: string): void => {
    if (newQuery === query.value) {
      return;
    }
    query.value = newQuery;
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      searchMode.value = !!query.value;
      clear();
    }, 300);
  };

  onBeforeUnmount(() => {
    clearTimeout(searchTimeout);
  });

  const data = {
    // id,
    id: dictionaryId,

    // Structure
    structureChangeInfo,
    structureLoading,

    // Items
    items,
    itemsLoading,
    itemsByLevel,

    // Pagination
    canLoadMoreByLevel,
    cursorByLevel,
    isLevelLoading,

    pollerListeners,

    // UI
    isEmpty,
    showInModal,
    searchMode,

    // Hierarchy
    hierarchyMap,
    hierarchyMapWithInactive,

    // Search
    query,
    setQuery,

    // Inactive
    showInactive,
    hasInactive,
    handleShowInactive,

    // API
    next,
    clear,
    reFetch,
    apiMethods,
    getFullNames: apiMethods.getFullNames,
    updateStructure
  };

  provide(provideKey, data);
  return data;
};

export default useDictionary;

export const useInjectDictionary = (provideKey = USE_DICTIONARY_SYMBOL): DictionaryState => {
  const dictionary = inject<DictionaryState>(provideKey);
  if (dictionary) {
    return dictionary;
  } else {
    throw new Error(
      'No dictionary was provided, please make sure you are using "useDictionary" in the parent component'
    );
  }
};
