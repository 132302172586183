import type { AxiosClient } from '@/shared/api/utils/client/axios';

import { ApiLayer } from '@/shared/api/utils/api-layer';
import axios from '@/shared/api/utils/client/axios';

type ExceededInfo = {
  count: number;
};

class ExceededUsersLayer extends ApiLayer<AxiosClient> {
  get() {
    return this.methods.get<ExceededInfo>('/exceeded_users');
  }
}

export const ExceededUsersAPI = new ExceededUsersLayer(axios);
