<template>
  <select-list
    ref="list"
    :items="items"
    :loading="loading"
    :page-mode="pageMode"
    :multiple="multiple"
    :value="value"
    :max-height="maxHeight"
    :item-size="itemSize"
    :hierarchy-map="hierarchyMap"
    :show-inactive="showInactive"
    :only-leaf="onlyLeaf"
    :max-values="maxValues"
    :loop-pointer="loopPointer"
    :items-postprocessing="itemsPostprocessing"
    :caption-field="captionField"
    :disable-auto-compress="disableAutoCompress"
    @change="$emit('change', $event)"
    @reset="$emit('reset')"
    @left-key="$emit('left-key', $event)"
    @right-key="$emit('right-key', $event)"
  >
    <template #before="{ down, up, left, right, reset, enter }">
      <slot
        name="before"
        :focus="focus"
        :query="query"
        :placeholder="placeholder"
        :multiple="multiple"
        :clearable="clearable"
        :show-inactive="showInactive"
        :activity-toggle="activityToggle"
        :search="changeQuery"
        :down="down"
        :up="up"
        :reset="reset"
        :enter="enter"
        :left="left"
        :right="right"
        :toggle-activity-toggle="handleActivityToggle"
      >
        <before-list
          :id="id"
          :focus="focus"
          :query="query"
          :placeholder="placeholder"
          :activity-toggle="activityToggle"
          :multiple="multiple"
          :clearable="clearable"
          :show-inactive="showInactive"
          @search="changeQuery"
          @down="down"
          @up="up"
          @reset="reset"
          @enter="enter"
          @left="left"
          @right="right"
          @toggle-activity-toggle="handleActivityToggle"
        >
          <template #before-search="scopedProps">
            <slot name="before-search" v-bind="scopedProps" />
          </template>
          <template #search="scopedProps">
            <slot name="search" v-bind="scopedProps" />
          </template>
          <template #after-search="scopedProps">
            <slot name="after-search" v-bind="scopedProps" />
          </template>
          <template #reset="scopedProps">
            <slot name="reset" v-bind="scopedProps" />
          </template>
          <template #action="scopedProps">
            <slot name="action" v-bind="scopedProps" />
          </template>
        </before-list>
      </slot>
    </template>
    <template #not-found>
      <slot name="not-found" />
    </template>
    <template #loading>
      <slot name="loading" />
    </template>
    <template #item-header="props">
      <slot name="item-header" v-bind="props" />
    </template>
    <template #item-header-title="props">
      <slot name="item-header-title" v-bind="props" />
    </template>
    <template #item="props">
      <slot name="item" v-bind="props" />
    </template>
    <template #item-icon="props">
      <slot name="item-icon" v-bind="props" />
    </template>
    <template #item-before-content="props">
      <slot name="item-before-content" v-bind="props" />
    </template>
    <template #item-after-content="props">
      <slot name="item-after-content" v-bind="props" />
    </template>
    <template #item-title="props">
      <slot name="item-title" v-bind="props" />
    </template>
    <template #item-hint="props">
      <slot name="item-hint" v-bind="props" />
    </template>
    <template #item-subtitle="props">
      <slot name="item-subtitle" v-bind="props" />
    </template>
    <template #after="props">
      <slot name="after" v-bind="props" />
    </template>
    <template #after-list="props">
      <slot name="after-list" v-bind="props" />
    </template>
  </select-list>
</template>

<script>
import BeforeList from '@/components/base-combobox/before-list.vue';
import SelectList from '@/components/base-list/select-list.vue';

/**
 * Импорт этой константы с алиасом почему-то ломает страницу https://master.huntflow.dev/live
 */
import { DEFAULT_MAX_HEIGHT } from '../base-autocomplete/constants';

export default {
  name: 'BaseCombobox',
  components: { BeforeList, SelectList },
  props: {
    loading: SelectList.props.loading,
    pageMode: SelectList.props.pageMode,
    items: SelectList.props.items,
    itemSize: SelectList.props.itemSize,
    showInactive: SelectList.props.showInactive,
    hierarchyMap: SelectList.props.hierarchyMap,
    loopPointer: SelectList.props.loopPointer,
    multiple: SelectList.props.multiple,
    value: SelectList.props.value,
    itemsPostprocessing: SelectList.props.itemsPostprocessing,
    maxValues: SelectList.props.maxValues,
    onlyLeaf: SelectList.props.onlyLeaf,
    captionField: SelectList.props.captionField,

    maxHeight: {
      ...SelectList.props.maxHeight,
      default: () => DEFAULT_MAX_HEIGHT
    },
    disableAutoCompress: SelectList.props.disableAutoCompress,
    id: BeforeList.props.id,
    clearable: BeforeList.props.clearable,
    focus: BeforeList.props.focus,
    query: BeforeList.props.query,
    placeholder: BeforeList.props.placeholder,
    activityToggle: BeforeList.props.activityToggle
  },
  emits: ['change', 'reset', 'left-key', 'right-key', 'search', 'toggle-activity-toggle'],
  methods: {
    changeQuery(query) {
      this.$emit('search', query);
    },
    handleActivityToggle() {
      this.$emit('toggle-activity-toggle', !this.showInactive);
    },
    next() {
      this.$refs.list.next();
    },
    prev() {
      this.$refs.list.prev();
    },
    enter() {
      this.$refs.list.enter();
    },
    reset() {
      this.$refs.list.reset();
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
