<template>
  <div :class="[$style.tooltip, $props.class]" :style="style">
    <base-tooltip :distance="5">
      <template #content>
        <slot />
      </template>
      <template #default="{ trigger }">
        <button :ref="trigger" type="button" v-bind="$attrs" :class="className">
          <base-icon :type="type" width="20px" />
        </button>
      </template>
    </base-tooltip>
  </div>
</template>

<script>
import BaseIcon from '@/components/icon.vue';

import BaseTooltip from '@/shared/ui/base-tooltip/base-tooltip.vue';

export default {
  name: 'ItemButton',
  components: {
    BaseTooltip,
    BaseIcon
  },
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    },
    class: {
      type: [String, Array, Object],
      default: undefined
    },
    style: {
      type: [String, Array, Object],
      default: undefined
    }
  },
  computed: {
    className() {
      return {
        [this.$style.menubarButton]: true,
        [this.$style.menubarButtonActive]: this.active
      };
    }
  }
};
</script>

<style module>
.tooltip {
  flex: none;
  display: inline-flex;
}

.menubarButton {
  @mixin reset-button-styles;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  padding: 4px;
  border-radius: var(--radius-x-15, 6px);
  &:hover {
    background-color: var(--Background-white-white-hover, rgba(5, 5, 5, 0.04));
  }
}

.menubarButtonActive {
  background: var(--Background-white-white-active, rgba(5, 5, 5, 0.08));
  &:hover {
    background: var(--Background-white-white-active, rgba(5, 5, 5, 0.08));
  }
}
</style>

<i18n lang="json">{}</i18n>
