import { nanoid } from 'nanoid';

const GROUP_ITEM = Symbol('');
const EMPTY_ITEM = Symbol('');
const NEW_ITEM = Symbol('');

const makeGroupItem = (groupInfo = {}, { dataKey = 'id' } = {}) => ({
  ...groupInfo,
  [dataKey]: groupInfo[dataKey] || nanoid(),
  [GROUP_ITEM]: true
});

const makeEmptyItem = ({ dataKey = 'id' } = {}) => ({
  [dataKey]: nanoid(),
  [EMPTY_ITEM]: true
});

export const makeNewItem = (value, { dataKey = 'id' } = {}) => ({
  [dataKey]: nanoid(),
  value,
  [NEW_ITEM]: true
});

export const isGroupItem = (item) => item.hasOwnProperty(GROUP_ITEM);
export const isEmptyItem = (item) => item.hasOwnProperty(EMPTY_ITEM);
export const isNewItem = (item) => item.hasOwnProperty(NEW_ITEM);

export const toFlatList = (
  groups,
  { dataKey = 'id', includeEmptyGroup = true, includeGroupItem = true } = {}
) => {
  if (!groups) {
    return undefined;
  }
  const list = groups.reduce((acc, group) => {
    const { items, ...groupInfo } = group;
    const isEmptyGroup = items.length === 0;
    const groupItem
      = includeGroupItem && (!isEmptyGroup || includeEmptyGroup)
        ? makeGroupItem(groupInfo, { dataKey })
        : undefined;
    const emptyItem = includeEmptyGroup ? makeEmptyItem({ dataKey }) : undefined;

    return Array.prototype.concat.call(
      acc,
      groupItem ?? [],
      items.length ? items : (emptyItem ?? [])
    );
  }, []);

  return list.length ? list : [makeEmptyItem({ dataKey })];
};

export const getResultLength = (groups) => {
  if (!groups) {
    return undefined;
  }
  return groups.reduce((acc, group) => acc + group.items.length, 0);
};

export const getRoundRobinNextIndex = (index, result) => {
  const length = getResultLength(result);
  if (!length) {
    return undefined;
  }
  if (index === undefined) {
    return 0;
  }
  return index === length - 1 ? 0 : index + 1;
};

export const getRoundRobinPrevIndex = (index, result) => {
  const length = getResultLength(result);
  if (!length) {
    return undefined;
  }
  if (index === undefined) {
    return 0;
  }
  return index === 0 ? length - 1 : index - 1;
};
