<template>
  <div :class="className" :style="styles" data-qa="dropdown-autocomplete-content">
    <content-loader v-if="isLoaderVisible" />
    <template v-else>
      <h2 v-if="title" :class="[$style.heading, $style.title]">
        {{ title }}
      </h2>
      <div v-else :class="$style.heading">
        <slot name="heading" />
      </div>

      <slot name="prepend" />
      <div v-if="hasMain || message" :class="$style.main">
        <slot name="main">
          {{ message }}
        </slot>
      </div>
      <slot name="content" />
      <slot name="append" />

      <div v-if="hasFooter" :class="[$style.footer, { [$style.footerPadding]: footerWithPadding }]">
        <render-slot :slot-content="footerLayoutSlot">
          <error-wrapper>
            <slot name="error" />
          </error-wrapper>
          <layout-buttons-line>
            <render-slot :slot-content="footerSlot" />
          </layout-buttons-line>
        </render-slot>
      </div>
    </template>
  </div>
</template>

<script>
import { computed } from 'vue';

import LayoutButtonsLine from '@/components/layout/buttons-line.vue';
import ContentLoader from '@/components/loader/content-loader.vue';
import ErrorWrapper from '@/components/popup-layout/error-wrapper.vue';
import RenderSlot from '@/components/render-slot/render-slot.vue';

import { SlotChecker } from '@/shared/lib/util/slot-checker';

import widthStyles from '../../shared/ui/css/widths.module.css';

const MIN_LOADING_TIME_MS = 400;

export default {
  name: 'DropdownMenuLayout',
  components: { RenderSlot, ErrorWrapper, ContentLoader, LayoutButtonsLine },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: undefined
    },
    message: {
      type: String,
      default: ''
    },
    // TODO: придумать способ установки ширины равной триггеру
    size: {
      type: [String, Number],
      default: 'auto',
      validator(value) {
        return Boolean(widthStyles[value]) || value === 'auto' || Number.isInteger(value);
      }
    },
    footerWithPadding: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const footerSlot = SlotChecker.useSlotContent('footer');
    const footerLayoutSlot = SlotChecker.useSlotContent('footer-layout');
    const mainSlot = SlotChecker.useSlotContent('main');
    const hasFooter = computed(() => Boolean(footerSlot.value) || Boolean(footerLayoutSlot.value));
    const hasMain = computed(() => Boolean(mainSlot.value));
    return {
      footerSlot,
      footerLayoutSlot,
      hasFooter,
      hasMain
    };
  },
  data() {
    return {
      startTime: undefined,
      isLoaderVisible: this.loading
    };
  },
  computed: {
    styles() {
      if (typeof this.size === 'number') {
        return {
          width: `${this.size}px`
        };
      }
      return undefined;
    },
    className() {
      if (typeof this.size === 'number') {
        return this.$style.container;
      }
      const sizeClassName = widthStyles[this.size];
      return {
        [this.$style.container]: true,
        [sizeClassName]: Boolean(sizeClassName)
      };
    }
  },
  watch: {
    loading: {
      immediate: true,
      handler(loading) {
        clearTimeout(this.timeout);
        if (loading) {
          this.isLoaderVisible = true;
          this.startTime = Date.now();
          return;
        }
        const deltaTime = Date.now() - this.startTime;
        if (deltaTime < MIN_LOADING_TIME_MS) {
          this.timeout = setTimeout(() => {
            this.isLoaderVisible = false;
          }, MIN_LOADING_TIME_MS - deltaTime);
        } else {
          this.isLoaderVisible = false;
        }
      }
    }
  }
};
</script>

<style module>
.container {
  border-radius: 8px;
  &:has(.main) .heading {
    border-bottom: 1px solid var(--border-neutral-S, rgba(0, 0, 0, 0.08));
  }
}

.heading {
  border-radius: 8px 8px 0 0;
  padding: 16px;
  background-color: $white;
  position: sticky;
  top: 0;
  z-index: 1;
}

.title {
  margin: 0;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
}

.main {
  font-size: 15px;
  line-height: 20px;
  padding: 16px;
  margin: 0;

  strong {
    font-weight: 500;
  }
}
.footer {
  background-color: $white;
  position: sticky;
  bottom: 0;
  z-index: 1;
  border-top: 1px solid var(--border-neutral-S, rgba(0, 0, 0, 0.08));
  border-radius: 0 0 8px 8px;
}

.main:empty + .footer {
  border-top: 0;
}

.footerPadding {
  padding: 16px;
}

.heading,
.main,
.footer {
  &:empty {
    display: none;
  }
}
</style>

<i18n lang="json">{}</i18n>
