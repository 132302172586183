import type { AxiosClient } from '@/shared/api/utils/client/axios';

import { ApiLayer } from '@/shared/api/utils/api-layer';
import axios from '@/shared/api/utils/client/axios';

type InterviewType = {
  id: number;
  name: string;
  type: 'user' | 'interview';
};

type InterviewTypePayload = {
  id?: number;
  name: string;
};

class InterviewTypeLayer extends ApiLayer<AxiosClient> {
  update(types: InterviewTypePayload[]) {
    return this.methods
      .post<{ items: InterviewType[] }>('/settings/interview_types', types)
      .then(({ items }) => items);
  }
}

export const InterviewTypeAPI = new InterviewTypeLayer(axios);
