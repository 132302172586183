import type { AxiosClient } from '@/shared/api/utils/client/axios';
import type { CursorList, ToArray } from '@/shared/api/utils/types';
import type { MemberType } from '@/shared/types/member-type';
import type { FullUser, UserInvite, User } from '@/shared/types/user';

import { ApiLayer } from '@/shared/api/utils/api-layer';
import { axiosApp } from '@/shared/api/utils/client/axios';

type CountParams<MemberTypes extends MemberType[], IncludeInvites extends boolean> = {
  member_type?: MemberTypes;
  count_invites?: IncludeInvites;
};

type CountResult<MemberTypes extends MemberType[], IncludeInvites extends boolean> = {
  [Property in MemberTypes[number]]: IncludeInvites extends true
    ? {
        members: number;
        invites: number;
      }
    : {
        members: number;
      };
};

type ListParams = {
  member_type: MemberType | MemberType[];
  next_page_cursor?: string | null;
  count: number;
};

type SearchColleague = Omit<User, 'user_id'> & {
  type: 'member';
  name_highlight: string;
  email_highlight: string;
};

type SearchInvite = UserInvite & {
  type: 'invite';
  name_highlight: string;
  email_highlight: string;
};

type DownloadExcel = {
  query: string;
  member_type: MemberType | MemberType[];
};

// Те же коворкеры, но не легаси
class ColleagueLayer extends ApiLayer<AxiosClient> {
  count<
    MemberTypes extends MemberType | MemberType[] = [
      MemberType.OWNER,
      MemberType.MANAGER,
      MemberType.WATCHER
    ],
    IncludeInvites extends boolean = false
  >(
    params: CountParams<ToArray<MemberTypes>, IncludeInvites> = {}
  ) {
    return this.methods.get<CountResult<ToArray<MemberTypes>, IncludeInvites>>('/colleague/count', {
      params
    });
  }

  fetchList(params: ListParams) {
    return this.methods
      .get<CursorList<User>>('/colleague', {
        params
      })
      .then((res) => {
        res.items = res.items.map(enrichedColleague);
        return res;
      });
  }

  getById(id: number) {
    return this.methods.get<FullUser>(`/colleague/${id}`);
  }

  searchList(params: ListParams & { query: string }) {
    return this.methods
      .get<CursorList<SearchColleague | SearchInvite>>('/colleague/search', {
        params
      })
      .then((res) => {
        res.items = res.items.map(enrichedColleague);
        return res;
      });
  }

  downloadExcel(params: DownloadExcel) {
    return this.methods.post('/colleagues/xlsx', {}, { params });
  }
}

export function enrichedColleague<
  Colleague extends User | SearchColleague | UserInvite | SearchInvite
>(colleague: Colleague): Colleague & { id: number } {
  return {
    ...colleague,
    id: 'account_member_id' in colleague ? colleague.account_member_id : colleague.invite_id
  };
}
export const ColleagueAPI = new ColleagueLayer(axiosApp);
