import { WIDGET_TYPE } from '../types';
import AttachFileWidget from './attach-file.vue';
import RatingRadioWidget from './radio-rating.vue';
import RatingWidget from './rating.vue'; // используется райфайзеном

export const widgets = {
  [WIDGET_TYPE.RATING_RADIO]: RatingRadioWidget,
  [WIDGET_TYPE.RATING]: RatingWidget,
  [WIDGET_TYPE.ATTACH_FILE]: AttachFileWidget
};
