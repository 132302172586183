import type { Formatters } from 'intl-messageformat';

import { memoize } from '@formatjs/fast-memoize';
import IntlMessageFormat from 'intl-messageformat';

import commonTrl from '@/shared/assets/common.lang.json';

import { config } from '../config/config';

type CommonTrl = typeof commonTrl;

declare global {
  // eslint-disable-next-line ts/consistent-type-definitions
  interface Window {
    Translations?: Record<string, string>;
    TranslationsUI: Record<string, Record<string, string>>;
  }
}

const formatters: Formatters = {
  getNumberFormat: memoize((locale, opts) => new Intl.NumberFormat(locale, opts)),
  getDateTimeFormat: memoize((locale, opts) => new Intl.DateTimeFormat(locale, opts)),
  getPluralRules: memoize((locale, opts) => new Intl.PluralRules(locale, opts))
};

const messageParserCache = new Map();

export function extractLangMessages<
  Trl extends Record<string, string>,
  Lang extends (string & keyof Trl) | string
>(translations: Record<string, Trl>, lang: Lang): Record<string, string | undefined> {
  return Object.fromEntries(
    Object.entries(translations).map(([key, trls]) => [
      key,
      Object.entries(trls).find(([key]) => key.startsWith(lang))?.[1]
    ])
  );
}

function getSourceMessage(msg: string, defaultValue: string, translations: Record<string, string>) {
  if (messageParserCache.has(msg)) {
    return messageParserCache.get(msg);
  }
  const result = translations[msg] || (window.Translations || {})[msg];
  return result ?? defaultValue;
}

export function trlMessage(
  msg: string,
  payload = {},
  defaultValue = msg,
  lang = config.appConfig.get('lang'),
  userDefinedFormats = {},
  translations = {}
): string {
  try {
    const result = new IntlMessageFormat(
      getSourceMessage(msg, defaultValue, translations),
      lang.split('_')[0],
      {
        date: {
          shortWithNumericYear: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
          }
        },
        ...(userDefinedFormats || {})
      },
      { formatters }
    );

    messageParserCache.set(msg, result.getAst());
    return translate(result, payload);
  } catch (err) {
    if (err instanceof Error) {
      throw new TypeError(`translation is failed for message '${msg}'`, { cause: err });
    }
    throw err;
  }
}

export function supportLink(
  str: string[],
  href = `mailto:${config.appConfig.get('supportEmail')}`
): string {
  return `<a href="${href}">${str}</a>`;
}

const lang = config.appConfig.get('lang');

type Translation = Record<string, Record<string, string>>;

export function makeI18n<T extends Translation | undefined, B extends Translation | undefined>(
  translation?: T,
  baseTranslation?: B
): (
    key: keyof T | keyof B | keyof CommonTrl,
    payload?: Record<string, any>,
    defaultValue?: string
  ) => string {
  const prefixes = [translation, baseTranslation, commonTrl].filter(Boolean) as unknown as string[];
  return (
    message: keyof T | keyof B | keyof CommonTrl,
    payload = {},
    defaultValue: string = message as string
  ) => {
    const prefix = prefixes.find((prefix) => (message as string) in window.TranslationsUI[prefix]);
    if (prefix === undefined) {
      return defaultValue;
    }
    const fullMessage = window.TranslationsUI[prefix][message as string];

    const result = new IntlMessageFormat(
      messageParserCache.has(fullMessage) ? messageParserCache.get(fullMessage) : fullMessage,
      lang,
      {
        date: {
          shortWithNumericYear: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
          }
        }
      },
      { formatters }
    );

    messageParserCache.set(fullMessage, result.getAst());
    return translate(result, payload);
  };
}

function translate(messageFormat: IntlMessageFormat, payload: Record<string, unknown>): string {
  return messageFormat.format({
    'text-bold': (str) => `<b>${str}</b>`,
    'support-link': supportLink,
    ...payload
  }) as string;
}
