<template>
  <base-dropdown
    :flip="false"
    :auto-boundary-max-size="false"
    :content-class="$style.dropdownContent"
    prevent-overflow-on-cross-axis
    @change="handleDropdowStateChange"
  >
    <template #default="{ toggle }">
      <slot name="selection" :on-toggle="toggle" :selected-items="selectedItems">
        <div :class="$style.tags" data-qa="tags">
          <tag-button
            v-for="item in visibleItems"
            :key="item.id"
            :color="`#${item.color}`"
            :title="item.name"
            data-qa="tag-item"
            @click="canChange ? toggle() : null"
          >
            {{ item.name }}
          </tag-button>

          <tag-button
            v-if="hiddenItemsCount"
            :ellipsis="false"
            @click="canChange ? toggle() : null"
          >
            {{ $trlMessage('tag.more_tags', { count: hiddenItemsCount }) }}
          </tag-button>

          <tag-button
            v-if="canChange"
            :ellipsis="false"
            :class="$style.add"
            data-qa="tag-add-item"
            @click="canChange ? toggle() : null"
          >
            {{ $trlMessage('title.add') }}
          </tag-button>
        </div>
      </slot>
    </template>
    <template #content>
      <view-mode
        v-if="isViewMode"
        :items="items"
        :selected="selected"
        :can-edit="canEdit"
        :loading="loading"
        @edit="switchToEditMode"
        @change="handleChange"
      />
      <edit-mode
        v-else-if="isEditMode && canEdit"
        :initial-items="items"
        @cancel="switchToViewMode"
        @confirm="handleEditConfirm"
      />
    </template>
  </base-dropdown>
</template>

<script>
import { defineAsyncComponent } from 'vue';

import BaseDropdown from '@/shared/ui/base-dropdown/base-dropdown.vue';

import TagButton from './ui/tag-button.vue';
import ViewMode from './ui/view-mode/view-mode.vue';

const Modes = {
  VIEW: 0,
  EDIT: 1
};
const DEFAULT_MODE = Modes.VIEW;

export default {
  name: 'VTags',
  components: {
    BaseDropdown,
    TagButton,
    ViewMode,
    EditMode: defineAsyncComponent(() => import('./ui/edit-mode/edit-mode.vue'))
  },
  props: {
    canChange: {
      type: Boolean,
      default: true
    },
    canEdit: {
      type: Boolean,
      default: true
    },
    selected: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    block: Boolean,
    maxTagsToShow: {
      type: Number,
      default: Infinity
    }
  },
  emits: ['edit', 'change'],
  data() {
    return {
      mode: DEFAULT_MODE,
      loading: false // сейчас не будет использоваться, но идея здравая — оставлю до более подготовленных правок
    };
  },
  computed: {
    isViewMode() {
      return this.mode === Modes.VIEW;
    },
    isEditMode() {
      return this.mode === Modes.EDIT;
    },
    selectedItems() {
      return this.selected.map((id) => this.items.find((item) => item.id === id)).filter(Boolean);
    },
    visibleItems() {
      const diff = this.selectedItems.length - this.maxTagsToShow;
      if (diff > 1) {
        return this.selectedItems.slice(0, this.maxTagsToShow);
      }

      return this.selectedItems;
    },
    hiddenItemsCount() {
      return this.selectedItems.length - this.visibleItems.length;
    }
  },
  methods: {
    handleDropdowStateChange() {
      this.switchToViewMode();
    },
    switchToViewMode() {
      this.mode = Modes.VIEW;
    },
    switchToEditMode() {
      this.mode = Modes.EDIT;
    },
    handleEditConfirm(tags) {
      // вызывается при редактировании тегов аккаунта (не аппликанта!)
      this.$emit('edit', tags);
      this.switchToViewMode();
    },
    handleChange(items) {
      this.$emit('change', items);
    }
  }
};
</script>

<style module>
.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.dropdownContent {
  width: $largeWidth;
}

.add {
  cursor: pointer;
  color: $gray-700;
  background-color: $gray-100;
}
</style>

<i18n lang="json">{}</i18n>
