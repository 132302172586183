<template>
  <div>
    <content-loader v-if="loading && !hideLoader" transparent />
    <slot
      v-else
      name="default"
      :schema="schema"
      :ui-schema="uiSchema"
      :form-data="formData"
      :loading="loading"
    />
  </div>
</template>

<script>
import ContentLoader from '@/components/loader/content-loader.vue';

import { deriveFormData } from './schema-converter/derive-form-data';
import { deriveSchema } from './schema-converter/derive-schema';
import { deriveUISchema } from './schema-converter/derive-ui-schema';
import { preloadDictionaries } from './schema-converter/legacy-schema-utils';

export default {
  name: 'SchemaConverterWrapper',
  components: {
    ContentLoader
  },
  props: {
    legacySchema: {
      type: Object,
      default: null
    },
    initialFormData: {
      type: Object,
      default: () => ({})
    },
    valuesSource: {
      type: Object,
      default: () => ({})
    },
    hideLoader: Boolean,
    getObjectFieldTemplate: {
      type: Function
    },
    onlyData: Boolean
  },
  data() {
    return {
      loading: true,
      schema: null
    };
  },
  computed: {
    uiSchema() {
      if (this.onlyData || !this.legacySchema) {
        return {};
      }
      return deriveUISchema(this.legacySchema, this.getObjectFieldTemplate);
    },
    formData() {
      if (!this.legacySchema) {
        return undefined;
      }
      return deriveFormData(this.legacySchema, this.valuesSource, this.initialFormData || {});
    }
  },
  watch: {
    legacySchema: {
      handler(legacySchema) {
        if (this.onlyData) {
          this.loading = false;
          return;
        }
        this.loading = true;
        if (!legacySchema) {
          return;
        }
        preloadDictionaries(legacySchema).then((dictionaries) => {
          this.schema = deriveSchema(legacySchema, {
            dictionaries
          });
          this.loading = false;
        });
      },
      immediate: true
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
