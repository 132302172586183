import type { ComputedRef, Ref } from 'vue';

import type { TreeId } from '@/shared/api/dictionary-partial/types';
import type { CancelablePromise } from '@/shared/api/utils/cancelable-promise';
import type { CursorList } from '@/shared/api/utils/types';

import { ref, computed, watch } from 'vue';

import { deepField, isLabelField, parentField } from '@/components/base-list/composables/constants';
import { getItemDeep } from '@/components/base-list/composables/use-flat-list';

import { TREE_ROOT_ID } from '@/shared/api/dictionary-partial/types';
import { makeI18n } from '@/shared/lib/i18n';

import dictionaryTransitions from '../assets/dictionary-translations.lang.json';

type TreeItem = {
  id: TreeId;
  parent_id: TreeId;
  [deepField]?: number;
  [parentField]?: TreeId;
  [isLabelField]?: true;
};

type Options<T> = {
  parentChildMap: Ref<Record<TreeId, TreeId>>;
  manualReset?: Ref<boolean>;
  showRoot?: boolean;
  request: (data: { parentId?: TreeId; cursor?: string }) => CancelablePromise<CursorList<T>>;
};

export function useCursorTreePaginator<I extends TreeItem>({
  parentChildMap,
  manualReset = ref(false),
  request,
  showRoot = false
}: Options<I>) {
  const loadingByLevel = ref<Record<TreeId, boolean>>({});
  const cursorByLevel = ref<Record<TreeId, string | undefined>>({});
  const itemsByLevel = ref<Record<TreeId, I[]>>({});
  const promises = ref<Map<CancelablePromise<any>, CancelablePromise<any>>>(new Map());

  const itemsLoading = computed(() => loadingByLevel.value[TREE_ROOT_ID]);
  const isLevelLoading = (level: TreeId) => loadingByLevel.value[level];

  // Check if the item can be loaded more
  const canLoadMore = (id: TreeId): boolean => {
    return !(loadingByLevel.value[id] || (itemsByLevel.value[id] && !cursorByLevel.value[id]));
  };

  const canLoadMoreByLevel = computed(() => {
    return Object.keys(parentChildMap.value).reduce<Record<TreeId, boolean>>(
      (acc, rawId) => {
        const id = rawId === TREE_ROOT_ID.toString() ? TREE_ROOT_ID : Number(rawId);

        acc[id] = canLoadMore(id);
        return acc;
      },
      {
        [TREE_ROOT_ID]: canLoadMore(TREE_ROOT_ID)
      }
    );
  });

  //
  const getFlatItems = (levelItems: I[] = []): I[] => {
    const items: I[] = [];
    levelItems.forEach((item) => {
      const id = item.id;
      items.push(item);
      if (itemsByLevel.value[id]?.length) {
        items.push(...getFlatItems(itemsByLevel.value[id]));
      }
      if (loadingByLevel.value[id]) {
        items.push({
          [deepField]: getItemDeep(id, parentChildMap.value) + 1,
          id: `loading-${id.toString()}`,
          [parentField]: id,
          [isLabelField]: true
        } as I);
      }
    });
    return items;
  };

  const i18n = makeI18n(dictionaryTransitions);

  const items: ComputedRef<I[]> = computed(() => {
    const result: I[] = [];
    if (showRoot) {
      result.push({
        id: TREE_ROOT_ID,
        name: i18n('rootName'),
        available: true,
        active: true,
        foreign: i18n('rootName')
      } as unknown as I);
    }
    result.push(...getFlatItems(itemsByLevel.value[TREE_ROOT_ID]));
    if (loadingByLevel.value[TREE_ROOT_ID] && result.length) {
      result.push({
        [deepField]: getItemDeep(TREE_ROOT_ID, parentChildMap.value) + 1,
        id: `loading-${TREE_ROOT_ID.toString()}`,
        [parentField]: TREE_ROOT_ID,
        [isLabelField]: true
      } as I);
    }
    return result;
  });

  // Requests

  // - Re-fetches
  const reFetchLevel = async (
    parentId: TreeId,
    callback: (cursor?: string) => CancelablePromise<CursorList<I>>
  ): Promise<CursorList<I>> => {
    const items: I[] = [];
    let next_page_cursor: string | undefined;
    do {
      const result: CursorList<I> = await callback(next_page_cursor);
      items.push(...result.items);
      next_page_cursor = result.next_page_cursor;
    } while (next_page_cursor && itemsByLevel.value[parentId]!.length > items.length);

    return { items, next_page_cursor };
  };

  const reFetch = (parentId: TreeId = TREE_ROOT_ID): Promise<void> => {
    loadingByLevel.value[parentId] = true;

    const pId = parentId === TREE_ROOT_ID ? undefined : parentId;

    return reFetchLevel(parentId, (cursor) => request({ parentId: pId, cursor }))
      .then(({ next_page_cursor, items }) => {
        cursorByLevel.value[parentId] = next_page_cursor || undefined;
        loadingByLevel.value[parentId] = false;
        itemsByLevel.value[parentId] = items;
      })
      .finally(() => {
        loadingByLevel.value[parentId] = false;
      });
  };

  // - Fetches next page
  const fetchNext = (parentId: TreeId = TREE_ROOT_ID, reFetch = false): CancelablePromise<void> => {
    const cursor = cursorByLevel.value[parentId];
    if (!cursor && !itemsByLevel.value[parentId]?.length) {
      cursorByLevel.value[parentId] = undefined;
      loadingByLevel.value[parentId] = true;
      itemsByLevel.value[parentId] = [];
    }

    loadingByLevel.value[parentId] = true;

    const pId = parentId === TREE_ROOT_ID ? undefined : parentId;

    const promise = request({ parentId: pId, cursor })
      .then(({ next_page_cursor, items }) => {
        cursorByLevel.value[parentId] = next_page_cursor || undefined;

        if (!reFetch) {
          itemsByLevel.value[parentId].push(...items);
        } else {
          itemsByLevel.value[parentId] = items;
        }

        loadingByLevel.value[parentId] = false;
      })
      .finally(() => {
        promises.value.delete(promise);

        if (!promise.canceled) {
          loadingByLevel.value[parentId] = false;
        }
      }) as CancelablePromise<void>;

    promises.value.set(promise, promise);
    return promise;
  };

  const next = (parentId: TreeId = TREE_ROOT_ID): void => {
    if (canLoadMoreByLevel.value[parentId] || !itemsByLevel.value[parentId]) {
      fetchNext(parentId);
    }
  };

  // Clear
  const clear = () => {
    cursorByLevel.value = {};
    loadingByLevel.value = {};
    itemsByLevel.value = {};

    return fetchNext(TREE_ROOT_ID, true);
  };

  watch(parentChildMap, (_, oldHash: Record<TreeId, TreeId>) => {
    if (!Object.keys(oldHash).length || !manualReset.value) {
      fetchNext(TREE_ROOT_ID, true);
    }
  });

  return {
    items,
    canLoadMoreByLevel,
    cursorByLevel,
    itemsByLevel,

    itemsLoading,
    isLevelLoading,
    clear,
    next,
    reFetch
  };
}
