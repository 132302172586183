<script>
import { computed, toRefs } from 'vue';

import { useListValue } from '@/components/base-list/composables/use-list-value';

import { normalizeChildren } from '@/shared/lib/util/component-helpers';

export default {
  name: 'HierarchyValue',
  props: {
    value: {
      type: [Array, String, Number, Boolean],
      default: undefined
    },
    selectedItems: {
      type: [Array, Object],
      default: null
    },
    multiple: Boolean,
    hierarchyMap: {
      type: Object,
      default: null
    },
    onlyLeaf: Boolean
  },
  setup(props) {
    const { value, multiple, onlyLeaf, hierarchyMap, selectedItems } = toRefs(props);

    const { deepValue, value: normalizedValue } = useListValue(value, {
      multiple,
      onlyLeaf,
      hierarchyMap
    });

    const count = computed(() => {
      if (Array.isArray(deepValue.value)) {
        return deepValue.value.length;
      }

      return (selectedItems.value && selectedItems.value.id === deepValue.value)
        || ![null, undefined].includes(deepValue.value)
        ? 1
        : 0;
    });

    return {
      count,
      deepValue,
      normalizedValue
    };
  },
  render() {
    const children = normalizeChildren(this, {
      count: this.count,
      allValue: this.deepValue,
      normalizedValue: this.normalizedValue
    });
    return children[0];
  }
};
</script>

<i18n lang="json">{}</i18n>
