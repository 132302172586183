<template>
  <base-item :is-header="true">
    <span :class="$style.text">
      <slot />
    </span>
  </base-item>
</template>

<script>
import BaseItem from './base-item.vue';

export default {
  name: 'HeaderItem',
  components: { BaseItem }
};
</script>

<style module>
.text {
  width: 100%;
  font-size: 15px;
  line-height: 24px;
  color: $black;
  position: relative;
  opacity: 0.5;
  border-bottom: 1px solid $borderColor;
  margin-left: 8px;
  padding-top: 8px;
}
</style>

<i18n lang="json">{}</i18n>
