<script>
import { mapGetters } from 'vuex';

import { normalizeChildren } from '@/shared/lib/util/component-helpers';

export default {
  name: 'DropRouterLink',
  props: {
    item: {
      type: Object,
      required: true
    },
    isCommon: {
      type: Boolean,
      default: false
    },
    isOrgs: {
      type: Boolean,
      default: false
    }
  },
  emits: ['open'],
  computed: {
    ...mapGetters('config', ['organization'])
  },
  render() {
    const href = !this.isOrgs
      ? `/settings/${this.item.name}`
      : `/settings/orgs/${this.organization.nick}${this.item.path || `/${this.item.to.name}`}`;
    const children = normalizeChildren(this, {
      href,
      navigate: (event) => {
        if (this.isCommon || this.item.children.length <= 1) {
          event.preventDefault();
          window.open(href, '_blank');
        } else {
          event.preventDefault();
          this.$emit('open');
        }
      }
    });
    return children[0];
  }
};
</script>

<i18n lang="json">{}</i18n>
