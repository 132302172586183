import type { Ref } from 'vue';

import type { TreeId } from '@/shared/api/dictionary-partial/types';

import { computed, ref, watch } from 'vue';

import { StorageHelper } from '@/shared/lib/util/storage-helper';

const useInactiveManager = ({ dictionaryId }: { dictionaryId: Ref<TreeId> }) => {
  const storageKey = computed(() => `dictionary-${dictionaryId.value.toString()}-is-active`);
  const showInactive = ref(
    StorageHelper.getBool(storageKey.value, false, { namespaced: true }) as boolean
  );

  const handleShowInactive = (flag: boolean) => {
    showInactive.value = flag;
    StorageHelper.set(storageKey.value, flag, { namespaced: true });
  };

  watch(dictionaryId, () => {
    showInactive.value = Boolean(
      StorageHelper.getBool(storageKey.value, false, { namespaced: true })
    );
  });

  return {
    showInactive,
    handleShowInactive
  };
};

export default useInactiveManager;
