<template>
  <modal-wrapper :open="isOpened" @change="isOpened = $event">
    <template #default="{ hide }">
      <popup-layout data-qa="quota-modal" :title="title" single-column>
        <template #main>
          {{ message }}
        </template>
        <template #footer>
          <base-button data-qa="close" @click="hide">
            {{ $trlMessage('button.close') }}
          </base-button>
        </template>
      </popup-layout>
    </template>
  </modal-wrapper>
</template>

<script>
import BaseButton from '@/components/button/button.vue';
import ModalWrapper from '@/components/modal-next/modal-wrapper.vue';
import PopupLayout from '@/components/popup-layout/popup-layout.vue';

import { DateTimeHelper } from '@/shared/lib/util/date-time-helper';

export default {
  name: 'QuotaWithoutRequestModal',
  components: {
    BaseButton,
    ModalWrapper,
    PopupLayout
  },
  props: {
    quota: {
      type: Object,
      default: null
    }
  },
  emits: ['change'],
  computed: {
    isOpened: {
      get() {
        return Boolean(this.quota);
      },
      set(value) {
        if (!value) {
          this.$emit('change', null);
        }
      }
    },
    title() {
      if (!this.quota) {
        return;
      }

      return this.$trlMessage('vacancy.quota.title', {
        requestDate: DateTimeHelper.toShortFormat({ date: this.quota.created })
      });
    },
    message() {
      if (!this.quota) {
        return;
      }

      return this.$trlMessage('vacancy.quota.without-request.opened', {
        requestDate: DateTimeHelper.toShortFormat({ date: this.quota.created }),
        author: this.quota.account_info && this.quota.account_info.name
      });
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
