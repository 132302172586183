<template>
  <div>
    <component
      :is="component"
      :id="id"
      :items="items"
      :loading="itemsLoading"
      :page-mode="pageMode"
      :multiple="multiple"
      :value="value"
      :max-height="maxHeight"
      :hierarchy-map="hierarchyMap"
      :hierarchy-map-with-inactive="hierarchyMapWithInactive"
      :show-inactive="showInactive"
      :only-leaf="onlyLeaf"
      :max-values="maxValues"
      :activity-toggle="hasInactive"
      :query="query"
      :name="name"
      :placeholder="placeholder"
      :required="required"
      :clearable="!required"
      :disabled="disabled || resourceDisabled"
      :invalid="invalid"
      :collapsible="!searchMode"
      :title="modalTitle"
      :dropdown-size="dropdownSize"
      :show="visisbilityState"
      :disable-auto-compress="disableAutoCompress"
      @search="setQuery"
      @reset="emit('reset')"
      @toggle-activity-toggle="handleShowInactive"
      @change="emit('update:value', $event)"
      @visible-change="visisbilityState = $event"
    >
      <template #content>
        <slot name="content">
          <structure-updated
            v-if="structureChangeInfo"
            :info="structureChangeInfo"
            @update="forceUpdateStructure"
          />
          <div v-else-if="isEmpty" :class="$style.noItems">
            <div>{{ i18n('no-items') }}</div>
            <link-button :to="dictionarySettingsLink" target="_blank">
              {{ i18n('setup-dictionary') }}
            </link-button>
          </div>
        </slot>
      </template>
      <template #toggle="scopedProps">
        <toggle-text
          :text="scopedProps.text"
          :show-text="scopedProps.showText"
          :count="scopedProps.count"
          :value="scopedProps.value"
          :loading="structureLoading"
          :multiple="scopedProps.multiple"
          :multiple-text="multipleLabel"
          :dictionary-id="dictionaryId"
          :items="items"
          :hierarchy-map="hierarchyMapWithInactive"
        >
          <template #default="{ text, count }">
            <slot name="toggle" v-bind="scopedProps" :text="text" :count="count">
              <toggle-button
                :id="id || ''"
                :invalid="invalid || false"
                :disabled="disabled || structureLoading"
                :name="name || ''"
                :text="text"
                @click="scopedProps.toggle"
              >
                <template #icon>
                  <slot name="toggle-icon"></slot>
                </template>
                <select-button-text
                  :multiple="!structureLoading && scopedProps.multiple"
                  :default-unselected="multipleLabelDefaultUnselected || false"
                  :title="text"
                  :count="count"
                />
              </toggle-button>
            </slot>
          </template>
        </toggle-text>
      </template>
      <template #item-title="{ item }">
        <span :title="item.name" v-html="item.name_highlight || item.name" />
      </template>
      <template #item-subtitle="{ item }">
        <span v-if="item.meta" :title="item.meta.subTitle" v-html="item.meta.subTitle" />
      </template>
      <template #after-level="{ id }">
        <intersection-observer
          v-if="canLoadMoreByLevel[id]"
          :key="cursorByLevel[id]"
          @appear="next(id)"
        />
        <spinner-item v-if="(showInDropdown || !showInModal) && itemsLoading" :deep="0" />
      </template>
    </component>
    <notifier-message v-on="pollerListeners" />
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps, ref, toRefs } from 'vue';

import { DropdownAutocomplete, ModalAutocomplete } from '@/components/base-autocomplete';
import ToggleButton from '@/components/base-autocomplete/toggle-button.vue';
import LinkButton from '@/components/button/link-button.vue';
import SelectButtonText from '@/components/dictionary/select-button-text.vue';
import NotifierMessage from '@/components/hf/notifier-message/notifier-message.vue';
import IntersectionObserver from '@/components/intersection-observer/intersection-observer.vue';
import SpinnerItem from '@/components/list-item/spinner-item.vue';
import useDictionary from '@/components/partial-dictionary-autocomplete/lib/useDictionary';

import { DIVISION_DICTIONARY } from '@/shared/api/dictionary-partial';
import { userPermissions } from '@/shared/lib/config/user-permissions';
import { useI18N } from '@/shared/lib/use-i18n';

import StructureUpdated from './structure-updated.vue';
import ToggleText from './toggle-text.vue';

const {
  showInDropdown = false,
  dictionaryId,
  multiple = false,
  value,
  autoUpdateOutdated = false
} = defineProps<PartialDictionaryProps>();

const emit = defineEmits(['reset', 'update:value']);

const i18n = useI18N();

type PartialDictionaryProps = {
  showInDropdown?: boolean;
  dictionaryId: string;
  multiple?: boolean;
  value: any;
  autoUpdateOutdated?: boolean;
  multipleLabel: string;
  multipleLabelDefaultUnselected?: boolean;
  pageMode?: boolean;
  maxHeight?: number;
  onlyLeaf?: boolean;
  maxValues?: number;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  invalid?: boolean;
  dropdownSize?: string;
  name?: string;
  disableAutoCompress?: boolean;
  id?: string;
  modalTitle?: string;
};

const visisbilityState = ref(false);

const props = toRefs({
  dictionaryId,
  multiple,
  value,
  autoUpdateOutdated
});

const {
  structureChangeInfo,
  items,
  itemsLoading,
  structureLoading,
  disabled: resourceDisabled,
  query,
  setQuery,
  handleShowInactive,
  hasInactive,
  showInactive,
  hierarchyMap,
  hierarchyMapWithInactive,
  searchMode,
  canLoadMoreByLevel,
  cursorByLevel,
  pollerListeners,
  next,
  showInModal,
  isEmpty,
  updateStructure
} = useDictionary({
  dictionaryId: props.dictionaryId,
  multiple: props.multiple,
  currentValue: props.value,
  inactiveSelectable: true,
  autoUpdateOutdated: props.autoUpdateOutdated
});

const component = computed(() => {
  if (showInDropdown) {
    return DropdownAutocomplete;
  }
  return showInModal.value ? ModalAutocomplete : DropdownAutocomplete;
});

const dictionarySettingsLink = computed(() => {
  const type
    = dictionaryId === DIVISION_DICTIONARY ? 'dictionary-list' : 'additional-dictionary-list';
  return `/settings/orgs/${userPermissions.get('nick')}/${type}/${dictionaryId}`;
});

const forceUpdateStructure = () => {
  updateStructure();
};
</script>

<style module>
.noItems {
  display: grid;
  justify-items: center;
  gap: 10px;
  padding: 34px 0 38px;
  color: #999999;
  text-align: center;
}
</style>

<i18n lang="json">
{
  "no-items": {
    "ru_RU": "Нет пунктов в справочнике",
    "en_US": "No items in the directory"
  },
  "setup-dictionary": {
    "ru_RU": "Настроить справочник",
    "en_US": "Customise directory"
  }
}
</i18n>
