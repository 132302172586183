import keyBy from 'lodash/keyBy';

import { VacancyAPI } from '@/shared/api/vacancy';
import { userPermissions } from '@/shared/lib/config/user-permissions';
import { listToIndex } from '@/shared/lib/util/search';
import { VacancyNamingHelper } from '@/shared/lib/util/vacancy-naming';

function mapper(v) {
  const company = VacancyNamingHelper.computeCompany(v);

  return {
    ...v,
    company,
    _index: listToIndex(v.position, company)
  };
}

const initialState = {
  vacancies: [],
  ready: false
};

const gettersConfig = {
  ready: (state) => state.ready,
  all: (state) => state.vacancies,
  allHashedById: (state) => keyBy(state.vacancies, 'id'),
  byId: (state, getters) => (id) => getters.allHashedById[id],
  isMine: () => (id) => {
    const vacancyPermissions = userPermissions.vacancyPermissions;
    return !!vacancyPermissions[id];
  },
  hasDivisionPermission: (_, ___, __, rootGetters) => (vacancy) => {
    if (userPermissions.get('can_access_all_divisions')) {
      return true;
    }

    const permissions = rootGetters['config/divisionPermissions'];

    if (vacancy.multiple) {
      return vacancy.children_division.some((id) => permissions[id]);
    }

    const vacancyDivisionId = vacancy.account_division;
    if (vacancyDivisionId) {
      return !!permissions[vacancyDivisionId];
    }

    return true;
  }
};

function findIndexIn(state, vacancy) {
  return state.vacancies.findIndex((v) => v.id === vacancy.id);
}

const mutations = {
  ready(state) {
    state.ready = true;
  },
  add(state, { vacancy }) {
    const index = state.vacancies.findIndex(({ id }) => id === vacancy.id);
    if (index >= 0) {
      state.vacancies.splice(index, 1, mapper(vacancy));
    } else {
      state.vacancies.push(mapper(vacancy));
    }
  },
  remove(state, { vacancy }) {
    const index = findIndexIn(state, vacancy);

    if (index >= 0) {
      state.vacancies.splice(index, 1);
    }
  },
  reset(state, payload) {
    const vacancies = (payload && payload.vacancies) || [];

    state.vacancies = vacancies.map(mapper);
  },
  change(state, { vacancy }) {
    const index = findIndexIn(state, vacancy);

    if (index >= 0) {
      state.vacancies.splice(index, 1, mapper(vacancy));
    }
  }
};

const actions = {
  loadVacancy({ commit }, id) {
    return VacancyAPI.fetchById(id)
      .then((vacancy) => {
        commit('change', { vacancy });

        return vacancy;
      })
      .catch(() => {});
  },
  loadChildren({ commit, getters }, vacancyId) {
    // TODO: не уверен в корректности этой логики работы с кешем

    return VacancyAPI.fetchChildrenList(vacancyId)
      .then((children) => {
        children.forEach((child) => {
          // moved outside add-mutation to prevent redundant mutation handling (in plugins)
          const isAlreadyExists = Boolean(getters.allHashedById[child.id]);

          if (!isAlreadyExists) {
            commit('add', { vacancy: child });
          }
        });

        return children;
      })
      .catch(() => {});
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters: gettersConfig,
  actions,
  mutations
};
