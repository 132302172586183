declare global {
  // eslint-disable-next-line ts/consistent-type-definitions
  interface Window {
    ServerTimezone: string;
    UserTimezone: string;
  }
}

export const serverTimezone = window.ServerTimezone;
export const userTimezone = window.UserTimezone;
