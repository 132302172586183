import type { AxiosClient } from '@/shared/api/utils/client/axios';
import type { ItemList } from '@/shared/api/utils/types';

import { ApiLayer } from '@/shared/api/utils/api-layer';
import axios from '@/shared/api/utils/client/axios';

type Source = {
  account: number;
  active: boolean;
  id: number;
  name: string;
  removed: string | null;
  type: string;
};

type UpdatePayload = {
  sources: Source[];
  replacements: number[];
};

class SourceLayer extends ApiLayer<AxiosClient> {
  getList() {
    return this.methods.get<ItemList<Source>>('/source');
  }

  update(data: UpdatePayload) {
    return this.methods.post<ItemList<Source>>('/settings/sources', data);
  }
}
// TODO: переделать на именованный экспорт, в соответствии с README
export default new SourceLayer(axios);
