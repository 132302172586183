<template>
  <!-- eslint-disable vue/no-restricted-syntax -->
  <div :class="{ [$style.before]: true, [$style.border]: border }">
    <div :class="$style.search">
      <slot name="before-search" />
      <slot
        name="search"
        :focus="focus"
        :query="query"
        :placeholder="$trlMessage(placeholder)"
        :search="search"
        :down="down"
        :up="up"
        :enter="enter"
        :left="left"
        :right="right"
        :reset="reset"
      >
        <div :class="$style.searchWrapper" data-qa="query">
          <base-icon type="search-16" :class="$style.searchIcon" />
          <base-input
            :id="id"
            ref="input"
            :class="$style.input"
            :value="query"
            :focus="focus"
            autocomplete="off"
            :placeholder="$trlMessage(placeholder)"
            type="search"
            @input-value="search"
            @keydown.down.exact.prevent="down"
            @keydown.up.exact.prevent="up"
            @keydown.enter.exact.prevent="enter"
            @keydown.left.exact="left"
            @keydown.right.exact="right"
          />
          <button
            v-if="query"
            type="button"
            data-qa="clear"
            :class="$style.clear"
            @click="search('')"
          >
            <base-icon type="remove" width="14px" />
          </button>
        </div>
      </slot>
      <slot name="after-search" />
    </div>
    <div :class="$style.actions" @click.capture="setFocus">
      <div :class="$style.action">
        <slot name="reset" :reset="reset">
          <action-button v-if="multiple || clearable" data-qa="reset" @click="reset">
            {{ $trlMessage('autocomplete.reset') }}
          </action-button>
        </slot>
      </div>
      <div :class="$style.action">
        <slot name="action" :activity-toggle="handleToggleCallback">
          <action-button v-if="activityToggle" @click="handleToggleCallback">
            {{ $trlMessage('active.toggle', { showInactive }) }}
          </action-button>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/components/icon.vue';
import BaseInput from '@/components/input/input.vue';

import ActionButton from './action-button.vue';

export default {
  name: 'BeforeList',
  components: { ActionButton, BaseIcon, BaseInput },
  props: {
    id: {
      type: [String, Number],
      default: null
    },
    focus: Boolean,
    query: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'enter.search.keyword'
    },
    border: {
      type: Boolean,
      default: true
    },
    activityToggle: Boolean,
    clearable: Boolean,
    multiple: Boolean,
    showInactive: Boolean
  },
  emits: ['search', 'down', 'up', 'left', 'right', 'enter', 'reset', 'toggle-activity-toggle'],
  methods: {
    search(query) {
      this.$emit('search', query);
    },
    down() {
      this.$emit('down');
    },
    up() {
      this.$emit('up');
    },
    enter() {
      this.$emit('enter');
    },
    reset() {
      this.$emit('reset');
    },
    handleToggleCallback() {
      this.$emit('toggle-activity-toggle');
    },
    left(event) {
      this.handleEmptyQuery(event);
      this.$emit('left');
    },
    right(event) {
      this.handleEmptyQuery(event);
      this.$emit('right');
    },
    setFocus() {
      this.$refs.input.setFocus();
    },
    handleEmptyQuery($event) {
      if (this.query) {
        return;
      }
      $event.preventDefault();
    }
  }
};
</script>

<style module lang="less">
@import '~@less/common/variables';

.before:not(:empty) {
  padding: var(--combobox-before-gap, 16px 16px 0);
  line-height: normal;
  flex: 0 0 auto;
}
.border:not(:empty) {
  border-bottom: 1px solid @borderColor;
}
.search {
  padding-bottom: 16px;
}
.searchWrapper {
  position: relative;
  overflow: hidden;
}
.input.input {
  box-sizing: border-box;
  width: 100%;
  padding: 0 16px 0 32px;
}
.input:has(+ .clear) {
  padding-right: 35px;
}

.searchIcon {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.3;
  pointer-events: none;
}
.clear {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #d2d2d2;
  border: none;
  padding: 0;
  background-color: transparent;
  width: 36px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.clear:hover,
.clear:focus {
  color: @textColor;
  outline: none;
}
.actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.action:not(:empty) {
  margin: -4px 0 12px;
}
</style>

<i18n lang="json">{}</i18n>
