import type { AdditionalSchemaKeys } from '../additional-schema-keys';

export function includesAdditionalKey(
  name: AdditionalSchemaKeys,
  fields?: { id: string | AdditionalSchemaKeys }[]
): boolean {
  if (!fields) {
    return false;
  }
  return fields.some((field) => field.id === name);
}
