<template>
  <ul :class="$style.root">
    <li :class="$style.parent">
      <slot name="parent" />
    </li>
    <slot v-for="child in children" name="item" :item="child">
      <li :key="child.id">
        {{ child }}
      </li>
    </slot>
  </ul>
</template>

<script>
export default {
  name: 'VacancyItemGroupItems',
  props: {
    children: {
      type: Array,
      required: true
    }
  }
};
</script>

<style module>
.root {
  margin: 0;
  list-style: none;
  padding: 0 0 0 9px;
  position: relative;

  &::before {
    position: absolute;
    content: '';
    width: 1px;
    height: 100%;
    left: 5px;
    background-color: $sidebarTextColor;
    opacity: 0.16;
  }
}
</style>

<i18n lang="json">{}</i18n>
