import BaseTemplate from '../../templates/base-template.vue';
import CompensationTemplate from '../../templates/compensation-template.vue';
import ContractTemplate from '../../templates/contract-template.vue';
import IncomeTemplate from '../../templates/income-template.vue';
import { isContract, isIncome, isCompensation, isComplexLike } from '../legacy-schema-utils';
import { always, cond } from '../utils';

const mapping = [
  [isContract, always(ContractTemplate)],
  [isIncome, always(IncomeTemplate)],
  [isCompensation, always(CompensationTemplate)],
  [isComplexLike, always(BaseTemplate)],
  [always(true), always(undefined)]
];

export function getObjectFieldTemplate(field) {
  const ctor = cond(mapping)(field);
  return ctor();
}
