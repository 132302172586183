<template>
  <div>
    <block-title v-if="showTitle" :is-orgs="isOrgs" :is-dropdown="isDropdown" />
    <div v-if="isOrgs && isDropdown" :class="$style.delimiter" />
    <ul :class="listStyles">
      <base-tab
        v-for="item in pages"
        :key="item.name"
        :item="item"
        :is-orgs="isOrgs"
        :is-dropdown="isDropdown"
        :is-common="isCommon"
        @open="(name) => $emit('open', name)"
      />
    </ul>
  </div>
</template>

<script>
import BaseTab from './base-tab.vue';
import BlockTitle from './block-title.vue';

export default {
  name: 'BaseTabs',
  components: { BlockTitle, BaseTab },
  props: {
    pages: {
      type: Array,
      required: true
    },
    isOrgs: {
      type: Boolean,
      default: false
    },
    isDropdown: {
      type: Boolean,
      default: false
    },
    isCommon: {
      type: Boolean,
      default: false
    },
    showTitle: {
      type: Boolean,
      default: true
    }
  },
  emits: ['open'],
  computed: {
    listStyles() {
      return {
        [this.$style.tabs]: true,
        [this.$style.dropTabs]: this.isDropdown
      };
    }
  }
};
</script>

<style lang="less" module>
@import '~@less/common/variables.less';

.tabs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px 30px;
  padding: 16px 0 0 0;
  margin: 0;
}
.dropTabs {
  padding-top: 8px;
  gap: 16px 18px;
}
.delimiter {
  border-top: 1px solid @borderSetting;
  padding-bottom: 8px;
  margin: 0 -40px 0 -40px;
}
</style>

<i18n lang="json">{}</i18n>
