<template>
  <popup-layout single-column sticky-footer :loading="loading">
    <template #title>
      <request-list-header
        :schemas="actualSchemas"
        :fields="schemasFields"
        :selected-fields="selectedSchemasFields"
        @set:fields="$emit('set:fields', $event)"
      >
        <template #default>
          <slot name="caption" />
          <slot name="sub-caption" />
        </template>
      </request-list-header>
    </template>
    <template #prepend>
      <slot name="main" />
    </template>
    <template #footer>
      <div>
        <slot name="footer" />
        <base-button color="gray" size="s" data-qa="close" @click="$emit('close')">
          <slot name="close-text">
            {{ $trlMessage('button.close') }}
          </slot>
        </base-button>
      </div>
    </template>
  </popup-layout>
</template>

<script>
import BaseButton from '@/components/button/button.vue';
import RequestListHeader from '@/components/hf/request-list/header.vue';
import PopupLayout from '@/components/popup-layout/popup-layout.vue';

export default {
  name: 'QuotasModalLayout',
  components: { PopupLayout, BaseButton, RequestListHeader },
  props: {
    loading: Boolean,
    quotas: {
      type: Array,
      required: true
    },
    schemas: {
      type: Array,
      required: true
    },
    schemasFields: {
      type: Object,
      required: true
    },
    selectedSchemasFields: {
      type: Object,
      required: true
    }
  },
  emits: ['set:fields', 'close'],
  computed: {
    actualSchemaIds() {
      return [
        ...new Set(
          this.quotas.map((quota) => {
            if (quota.account_vacancy_request) {
              return quota.account_vacancy_request;
            }
            return quota.vacancy_request?.account_vacancy_request;
          })
        )
      ].filter(Boolean);
    },
    actualSchemas() {
      return this.schemas.filter(({ id }) => this.actualSchemaIds.includes(id));
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
