function format(n) {
  return (n < 10 ? '0' : '') + n;
}

function calculateReminder(sum) {
  if (sum < 100) {
    return format(sum);
  }

  if (sum === 100 || sum === 101) {
    return '00';
  }

  return calculateReminder(sum % 101);
}

function calculateSum(insuranceNumbers) {
  const sum = insuranceNumbers.split('').reduce((acc, n, i) => acc + Number.parseInt(n, 10) * (9 - i), 0);

  return calculateReminder(sum);
}

export function validateSnils(value) {
  if (typeof value !== 'string') {
    return false;
  }

  value = value.replace(/(\s|-)/g, '');

  if (value.length !== 11) {
    return false;
  }

  const insuranceNumbers = value.slice(0, 9);
  const controlNumbers = value.slice(9);
  const insuranceNumbersInt = Number.parseInt(insuranceNumbers, 10);

  if (Number.isNaN(insuranceNumbersInt)) {
    return false;
  }

  if (insuranceNumbersInt <= 1001998) {
    return true;
  }

  return calculateSum(insuranceNumbers) === controlNumbers;
}
