import type { Directive } from 'vue';

import { DomPurify } from '@/shared/lib/dom-purify/dom-purify';

export const purifyHtml: Directive<HTMLElement, string> = {
  mounted: (el, { value }) => {
    el.innerHTML = DomPurify.sanitize(value);
  },
  updated: (el, { value, oldValue }) => {
    if (oldValue === value) {
      return;
    }
    el.innerHTML = DomPurify.sanitize(value);
  }
};
