<template>
  <div class="layout__central">
    <div :class="$style.root">
      <div class="container">
        <div class="row">
          <div class="col-xs-offset-3 col-xs-7">
            <img :src="require('./image.svg')">

            <h2 :class="$style.title">
              {{ $trlMessage('page.404.title') }}
            </h2>
            <p :class="$style.text">
              {{ $trlMessage('page.404.text') }}
            </p>
            <link-button to="/" color="black">
              {{ $trlMessage('page.404.return') }}
            </link-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LinkButton from '@/components/button/link-button.vue';

export default {
  name: 'NotFound',
  components: { LinkButton }
};
</script>

<style lang="less" module>
.root {
  composes: global(error-block);
  background-image: none;
}

.title {
  composes: global(error-block__title);
  margin: 10px 0;
}

.text {
  margin: 10px 0;
}
</style>

<i18n lang="json">{}</i18n>
