import type { DateInterval } from '../date-interval';

import { computed } from 'vue';

import { getDisplayEvents } from './get-display-events';

type BaseAttenders = {
  email: string;
};

type EventProps<A extends BaseAttenders = BaseAttenders> = {
  start: Date;
  attenders: A[];
  busySlots: Record<string, DateInterval[]>;
};

export const useBusyEvents = (props: EventProps) => {
  const busyEvents = computed(() => getDisplayEvents(props));

  const busyEventsWithinDay = computed(() => {
    return busyEvents.value.filter((el) => !el.allDay);
  });

  const busyEventsAllDay = computed(() => {
    return busyEvents.value.filter((el) => el.allDay);
  });

  return {
    busyEventsWithinDay,
    busyEventsAllDay
  };
};
