import { isContract, isReason, isPositionStatus } from './legacy-schema-utils';

export function enrichLegacyField(field) {
  if (isContract(field)) {
    const { contract_from, contract_to } = field.fields;
    contract_from.availableOn = {
      type: 'field',
      operator: '==',
      field: 'contract',
      value: 'Срочный'
    };
    contract_to.availableOn = {
      type: 'field',
      operator: '==',
      field: 'contract',
      value: 'Срочный'
    };
  }
  if (isReason(field)) {
    const { reason_fio, reason_text, reason_date_dekret, reason_date_leave, reason_date_join }
      = field.fields;

    reason_fio.availableOn = {
      type: 'field',
      operator: '!=',
      field: 'reason',
      value: ['Новая штатная единица (в рамках бюджета)', 'Другое']
    };
    reason_text.availableOn = {
      type: 'field',
      operator: '==',
      field: 'reason',
      value: 'Другое'
    };
    reason_date_dekret.availableOn = {
      type: 'field',
      operator: '==',
      field: 'reason',
      value: 'Декретный отпуск'
    };
    reason_date_leave.availableOn = {
      type: 'field',
      operator: '==',
      field: 'reason',
      value: ['Увольнение по собственному желанию', 'Сотрудник не прошел испытательный срок']
    };
    reason_date_join.availableOn = {
      type: 'field',
      operator: '==',
      field: 'reason',
      value: ['Новая штатная единица (в рамках бюджета)', 'Другое']
    };
  }

  if (isPositionStatus(field)) {
    const { change_fio } = field.fields;

    change_fio.availableOn = {
      type: 'field',
      operator: '!=',
      field: 'position_status_value',
      value: ['Новая позиция']
    };
  }
  return field;
}
