import type { AxiosClient, AxiosRequestConfig } from './utils/client/axios';
import type { Delete } from './utils/default-responses';
import type { ItemList } from './utils/types';
import type { Pipeline } from '@/shared/types/pipeline';
import type { Status } from '@/shared/types/status';

import { ApiLayer } from './utils/api-layer';
import { axiosApp } from './utils/client/axios';

declare global {
  // eslint-disable-next-line ts/consistent-type-definitions
  interface Window {
    AccountPipelines: Pipeline[];
  }
}

class PipelinesLayer extends ApiLayer<AxiosClient> {
  getStatuses() {
    return Promise.resolve(window.Organization?.vacancy_status ?? []); // TODO: https://huntflow.atlassian.net/browse/DEV-5570
  }

  updateStatuses(statuses: Status[], config?: AxiosRequestConfig) {
    return this.methods
      .put<ItemList<Status>>('/settings/statuses', statuses, config)
      .then((res) => res.items);
  }

  getPipelines() {
    return Promise.resolve(window.AccountPipelines || []); // TODO: https://huntflow.atlassian.net/browse/DEV-5573
  }

  removePipeline(id: number, config?: AxiosRequestConfig) {
    return this.methods.delete<Delete>(`/pipelines/${id}`, config);
  }

  createPipeline(pipeline: Omit<Pipeline, 'id' | 'account'>, config?: AxiosRequestConfig) {
    return this.methods.post<Pipeline>('/pipelines', pipeline, config);
  }

  updatePipeline(pipeline: Pipeline, config?: AxiosRequestConfig) {
    return this.methods.put<Pipeline>(`/pipelines/${pipeline.id}`, pipeline, config);
  }

  updateTimeOnStatus(
    {
      pipeline,
      status,
      days,
      toAll
    }: {
      pipeline: number;
      status: number;
      days: number;
      toAll: boolean;
    },
    config?: AxiosRequestConfig
  ) {
    const updateStatuses = pipeline === -1;
    const path = updateStatuses
      ? `/settings/statuses/${status}`
      : `/pipelines/${pipeline}/statuses/${status}`;

    return this.methods.patch(
      path,
      {
        stay_duration: days || null
      },
      {
        ...config,
        params: toAll
          ? {
              apply_to_all: true
            }
          : null
      }
    ); // TODO: с возвращаемыми типами тут совсем беда, тут в трёх разных случаях приходит 3 разных ответа
  }
}
export const PipelinesAPI = new PipelinesLayer(axiosApp);
