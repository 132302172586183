<template>
  <button type="button" :disabled="isDisabled" :class="classes" @click="handleChange">
    <slot
      :date="date"
      :parsed-date="parsedDate"
      :disabled="isDisabled"
      :selected="isSelected"
      :is-today="isToday"
    />
  </button>
</template>

<script>
import { DateTimeHelper } from '@/shared/lib/util/date-time-helper';

import { DatePickerUtils } from './utils';

export default {
  name: 'BaseDay',
  props: {
    date: {
      type: String,
      required: true
    },
    today: {
      type: DateTimeHelper,
      default: null
    },
    selected: {
      type: DateTimeHelper,
      default: null
    },
    disabled: {
      type: Object,
      required: true
    },
    unit: {
      type: String,
      required: true
    },
    blur: Boolean
  },
  emits: ['change'],
  computed: {
    parsedDate() {
      return DateTimeHelper.parse({ date: this.date });
    },
    classes() {
      return {
        [this.$style.item]: true,
        [this.$style.blur]: this.blur,
        [this.$style.today]: this.isToday,
        [this.$style.selected]: this.isSelected
      };
    },
    isToday() {
      if (!this.today) {
        return false;
      }
      return this.today.hasSame(this.parsedDate, {
        unit: this.unit
      });
    },
    isSelected() {
      if (!this.selected) {
        return false;
      }
      return this.selected.hasSame(this.parsedDate, {
        unit: this.unit
      });
    },
    isDisabled() {
      return DatePickerUtils.isDisabled(this.parsedDate, this.disabled, {
        unit: this.unit
      });
    }
  },
  methods: {
    handleChange() {
      this.$emit('change', this.parsedDate);
    }
  }
};
</script>

<style module lang="less">
@import '~@less/common/variables';
.item {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: span 3;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 0;
}
.item:not(:disabled) {
  cursor: pointer;
}
.item:not(.selected):not(:disabled):hover {
  background-color: #f2f2f2;
}

.item.today {
  border-color: @blueColor;
}
.item:disabled {
  color: #ddd;
}

.blur {
  color: #bfbfbf;
}

.selected {
  background-color: @blueColor;
  color: @whiteColor;
}
</style>

<i18n lang="json">{}</i18n>
