type Index = {
  string: string;
  spaces: number[];
};

type SearchQueryFields = {
  [field: string]: string;
};

function getItemsIndex<T extends Record<string, unknown>>(
  items: T[],
  keys: SearchQueryFields
): Map<T, Record<string, Index>> {
  const map = new Map<T, Record<string, Index>>();
  return items.reduce((map, item) => {
    for (const key in keys) {
      const newKey: keyof T = keys[key];
      item[newKey] = item[key] as T[keyof T];
    }
    map.set(item, getItemIndex(item, Object.keys(keys)));
    return map;
  }, map);
}

function getItemIndex<T extends Record<string, unknown>, K extends keyof T>(item: T, keys: K[]) {
  return keys.reduce(
    (res, key) => {
      res[key] = stringToIndex(item[key]);
      return res;
    },
    {} as Record<K, Index>
  );
}

function getIndexWithSpaces(str: string, index: number, spaces: number[]) {
  const beforeIndex = str.substring(0, index);
  const spaceCount = beforeIndex.split(' ').length - 1;
  const additionalSpaces = spaces.slice(0, spaceCount).reduce((acc, num) => acc + num, 0);
  return index + additionalSpaces;
}

function addHighlights(str: string, positions: number[][]): string {
  let cursor = 0;
  return (
    positions.reduce((acc, [start, end]) => {
      const part = `${str.substring(cursor, start)}<b>${str.substring(start, end)}</b>`;
      cursor = end;
      return acc + part;
    }, '') + str.substring(cursor)
  );
}

function stringToIndex(value: unknown, trim = false): Index {
  const spaces: number[] = [];
  const str = String(value);
  return {
    string: str.toLowerCase().replaceAll(/[«»’‘"'−\\\-\s~()/_·]+/g, (match, offset: number) => {
      if (trim && (offset === 0 || offset + match.length === str.length)) {
        return '';
      }
      spaces.push(match.length - 1);
      return ' ';
    }),
    spaces
  };
}

type Options = {
  fields: SearchQueryFields;
  highlight?: boolean;
};

export class IndexSearch<Item extends Readonly<Record<string, unknown>>> {
  #options: Options;
  #index: Map<Item, Record<string, Index>>;

  constructor(items: Item[], { fields, highlight = true }: Options) {
    this.#options = { fields, highlight };
    this.#index = getItemsIndex(items, this.#options.fields);
  }

  // Подсвечиваем только символы, которые учавствуют в индексе. Символы, которые проглатываем не выделяем, это поведение согласовано с Екатерина Дробина (2022-10-12 :D)
  search(query: string): Item[] {
    if (!query) {
      return Array.from(this.#index.keys());
    }
    const queryIndex = stringToIndex(query, true);

    return Array.from(this.#index.entries()).reduce<Item[]>((acc, [item, itemIndex]) => {
      let found = false;
      const highlightedFields: Record<string, string> = {};
      const queryWords = queryIndex.string.split(' ');
      Object.entries<Index>(itemIndex).forEach(([fieldKey, { string, spaces }]) => {
        const index = string.indexOf(queryIndex.string);
        if (index === -1) {
          return;
        }
        found = true;

        if (this.#options.highlight) {
          const field = String(item[fieldKey]);
          const fieldInsensitive = field.toLowerCase();

          let startPosition = getIndexWithSpaces(string, index, spaces);

          const highlightPositions = queryWords.map((word) => {
            const start = fieldInsensitive.indexOf(word, startPosition);
            startPosition = start + word.length;
            return [start, startPosition];
          });

          const key = this.#options.fields[fieldKey];
          highlightedFields[`${key}_highlight`] = addHighlights(field, highlightPositions);
        }
      });

      if (found) {
        acc.push(Object.assign({}, item, highlightedFields));
      }

      return acc;
    }, []);
  }
}
