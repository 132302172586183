import type { AxiosClient } from './utils/client/axios';
import type { ItemList } from './utils/types';

import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';

import { hideApiFields } from '@/components/vjsf/helpers/hide-api-fields';
import { WIDGET_TYPE } from '@/components/vjsf/vjsf';

import { userPermissions } from '@/shared/lib/config/user-permissions';

import { ApiLayer } from './utils/api-layer';
import { axiosApp } from './utils/client/axios';

type IGetResponse = {
  schema: Record<string, unknown>;
  ui_schema: Record<string, unknown>;
  form_data: Record<string, unknown>;
};

type IVacancyRequestSchemaGetResponse = IGetResponse & {
  id: number;
  name: string;
  attendee_hint: string;
  attendee_required: boolean | null;
  archived: boolean;
  restricted_for: string[];
  version: number;
  schema: {
    properties: {
      position: Record<string, unknown>;
    };
  };
  ui_schema: {
    position: Record<string, unknown>;
  };
};

type IArchiveResponse = {
  status: boolean;
};

type IUpdateResponse = {
  is_updated: boolean;
  schema: IGetResponse;
};

class SchemaConverterLayer extends ApiLayer<AxiosClient> {
  getAdditionalInfoSchemaConstructor() {
    const accountId = userPermissions.id;
    const url = `/schema-translator/json_schema/${accountId}/questionary/constructor`;
    return this.methods.get<IGetResponse>(url).then(normalizeGetResponse);
  }

  getAdditionalInfoSchemaClient() {
    const accountId = userPermissions.id;
    const url = `/schema-translator/json_schema/${accountId}/questionary/client`;
    return this.methods.get<IGetResponse>(url).then(normalizeGetResponse);
  }

  updateAdditionalInfoSchema(payload: Record<string, any>) {
    const accountId = userPermissions.id;
    const url = `/schema-translator/json_schema/${accountId}/questionary/constructor`;
    return this.methods.put<IUpdateResponse>(url, payload).then((response) => ({
      ...response,
      schema: normalizeGetResponse(response.schema)
    }));
  }

  getVacancySchemaConstructor() {
    const accountId = userPermissions.id;
    const url = `/schema-translator/json_schema/${accountId}/vacancy/constructor`;
    return this.methods.get<IGetResponse>(url).then(normalizeGetResponse);
  }

  updateVacancySchema(payload: Record<string, any>) {
    const accountId = userPermissions.id;
    const url = `/schema-translator/json_schema/${accountId}/vacancy/constructor`;
    return this.methods.put<IUpdateResponse>(url, payload).then((response) => ({
      ...response,
      schema: normalizeGetResponse(response.schema)
    }));
  }

  getVacancyRequestSchemasClient({ onlyActive = true } = {}) {
    const accountId = userPermissions.id;
    const url = `/schema-translator/json_schema/${accountId}/vacancy_request/client`;
    return this.methods.get<ItemList<IVacancyRequestSchemaGetResponse>>(url).then(({ items }) => {
      return items
        .filter(({ archived }) => (onlyActive ? !archived : true))
        .map(normalizeVacancyRequestSchema);
    });
  }

  getVacancyRequestSchemasConstructor({ onlyActive = true } = {}) {
    const accountId = userPermissions.id;
    const url = `/schema-translator/json_schema/${accountId}/vacancy_request/constructor`;
    return this.methods.get<ItemList<IVacancyRequestSchemaGetResponse>>(url).then(({ items }) => {
      return items.filter(({ archived }) => (onlyActive ? !archived : true));
    });
  }

  createVacancyRequestSchemaConstructor(payload: Record<string, any>) {
    const accountId = userPermissions.id;
    const url = `/schema-translator/json_schema/${accountId}/vacancy_request/constructor`;
    return this.methods.post<IVacancyRequestSchemaGetResponse>(url, payload);
  }

  updateVacancyRequestSchemaConstructor(id: number, payload: Record<string, any>) {
    const accountId = userPermissions.id;
    const url = `/schema-translator/json_schema/${accountId}/vacancy_request/${id}/constructor`;
    return this.methods.put<IVacancyRequestSchemaGetResponse>(url, payload);
  }

  archiveVacancyRequestSchemaConstructor(id: number) {
    const accountId = userPermissions.id;
    const url = `/schema-translator/json_schema/${accountId}/vacancy_request/${id}/constructor/archive`;
    return this.methods.post<IArchiveResponse>(url);
  }

  unarchiveVacancyRequestSchemaConstructor(id: number) {
    const accountId = userPermissions.id;
    const url = `/schema-translator/json_schema/${accountId}/vacancy_request/${id}/constructor/unarchive`;
    return this.methods.post<IArchiveResponse>(url);
  }

  deleteVacancyRequestSchemaConstructor(id: number) {
    const accountId = userPermissions.id;
    const url = `/schema-translator/json_schema/${accountId}/vacancy_request/${id}/constructor`;
    return this.methods.delete(url);
  }

  getVacancyRequestSchemaClient(id: number) {
    const accountId = userPermissions.id;
    const url = `/schema-translator/json_schema/${accountId}/vacancy_request/${id}/client`;
    return this.methods
      .get<IVacancyRequestSchemaGetResponse>(url)
      .then(normalizeVacancyRequestSchema);
  }

  getVacancyRequestSchemaTemplate() {
    const accountId = userPermissions.id;
    const url = `/schema-translator/json_schema/${accountId}/vacancy_request/constructor/template`;
    return this.methods
      .get<Omit<IVacancyRequestSchemaGetResponse, 'id'>>(url)
      .then(normalizeGetResponse);
  }
}

function normalizeVacancyRequestSchema(response: IVacancyRequestSchemaGetResponse) {
  const positionType = response.schema.properties.position.type;
  if (positionType === 'number') {
    response.ui_schema.position['ui:widget'] = WIDGET_TYPE.PARTIAL_DICTIONARY;
  } else if (positionType === 'string') {
    response.ui_schema.position['ui:widget'] = WIDGET_TYPE.STRING;
  }
  response.ui_schema = hideApiFields(response.ui_schema);

  // XXX: https://huntflow.atlassian.net/browse/DEV-18319
  // Рабочий, но не самый элегантный вариант - добавлять default на бэкенде и удалять на фронте
  // к тому же не обрабатываются вложенные объекты
  // (но это не очень актуально - конструктор их всё равно не поддерживает)
  // по-хорошему бы ещё поковырять ajv/vjsf
  const properties = mapValues(response.schema.properties, (v) => {
    if (v.default) {
      return omit(v, ['default']);
    }
    return v;
  });

  return {
    ...response,
    schema: {
      ...response.schema,
      properties
    }
  };
}

function normalizeGetResponse(response: IGetResponse) {
  if (response.schema && !response.schema.properties) {
    response.schema.properties = {};
  }
  return response;
}

export const SchemaConverterAPI = new SchemaConverterLayer(axiosApp);
