<template>
  <base-item>
    {{ $trlMessage('Loading') }}
  </base-item>
</template>

<script>
import BaseItem from './base-item.vue';

export default {
  name: 'LoadingItem',
  components: { BaseItem }
};
</script>

<i18n lang="json">{}</i18n>
