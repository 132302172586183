import { createEmitter } from '@/shared/lib/emitter/emitter';

type Notification = {
  id?: string | number;
  content: string;
  isError?: boolean;
};

type Events = {
  notify: Notification;
};

const emitter = createEmitter<Events>();

export const notification = {
  notify(notification: Notification): void {
    emitter.emit('notify', notification);
  },

  subscribe(callback: (notification: Notification) => void): () => void {
    emitter.on('notify', callback);

    return () => emitter.off('notify', callback);
  }
};
