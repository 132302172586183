import type { Opts } from 'linkifyjs';

import linkifyStr from 'linkify-string';

type LinkifyOptions = {
  className?: string;
  target?: Opts['target'];
  maxLength?: number;
};

export const StringHelper = {
  format(string: string, ...replacements: string[]): string {
    return replacements.reduce((acc, replacement) => {
      return acc.replace('%s', replacement);
    }, string);
  },
  linkify(
    string: string,
    { className, target, maxLength = Infinity }: LinkifyOptions = {}
  ): string {
    const options: Opts = {
      attributes: {
        rel: 'noopener noreferrer'
      },
      className,
      target,
      truncate: maxLength
    };
    return linkifyStr(string, options);
  },
  capitalize(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  concat(items: string[], joiner = ', ', lastJoiner = ', '): string {
    if (items.length > 1) {
      return items.slice(0, items.length - 1).join(joiner) + lastJoiner + items.at(-1);
    }
    return items.join(', ');
  },
  withWhitespace(string: string | null): string | null {
    if (!string) {
      return string;
    }
    return ` ${string} `;
  }
};
