import { ApplicantAPI } from '@/shared/api/applicant';
import { userPermissions } from '@/shared/lib/config/user-permissions';
import { StatusType } from '@/shared/lib/status/status';
import { statusSortComparator } from '@/shared/lib/statusSortComparator';

import { trlMessage } from '../../../shared/lib/i18n';

const SpecialStatuses = {
  WORK_ON: 'workon',
  RESPONSE: 'response'
};

const state = {
  vacancy: null,
  statuses: (window.AccountVacancyStatusesData ?? []).sort(statusSortComparator),
  status: null,
  stats: [],
  loading: true,
  isVacancyResponseSync: false
};

const mutations = {
  setVacancy(state, vacancyId) {
    state.vacancy = vacancyId;
  },
  setStatus(state, status) {
    state.status = status;
  },
  setStats(state, stats) {
    state.stats = stats;
  },
  setLoading(state, isLoading) {
    state.loading = isLoading;
  },
  setIsVacancyResponseSync(state, isVacancyResponseSync) {
    state.isVacancyResponseSync = isVacancyResponseSync;
  }
};

const actions = {
  fetchStats({ commit, state }) {
    commit('setLoading', true);
    return ApplicantAPI.fetchStatsByFilter({ vacancy: state.vacancy })
      .then(({ items }) => {
        commit('setStats', items);
      })
      .finally(() => {
        commit('setLoading', false);
      });
  }
};

const getters = {
  allStatuses: (state) => state.statuses,
  loading: (state) => state.loading,
  statuses: (state) => state.statuses.filter((status) => !status.removed),
  trashStatus: (state) => state.statuses.find((status) => status.type === StatusType.TRASH),
  hiredStatus: (state) => state.statuses.find((status) => status.type === StatusType.HIRED),
  realStatuses: (state, getters) => getters.statuses.filter((status) => !status.virtual),
  statusById: (state, getters) => (id) =>
    getters.allStatuses.find((status) => status.id === Number.parseInt(id) || status.id === id),
  trashStatusId: (state, getters) => getters.trashStatus?.id,
  hiredStatusId: (state, getters) => getters.hiredStatus?.id,
  currentStatus: (state) => state.status,

  uiStats: (state, getters) => {
    const isWatcher = userPermissions.isWatcher;
    return getters.statuses
      .map((status) => {
        const id = status.id;

        const stat = state.stats.find((item) => item.status === id);
        if (Boolean(stat) === false) {
          return false;
        }

        // (!) во вьюхе "count" - это "current" из пришедших статов
        const count = getCount(stat);

        let name = status.name;

        if (isWatcher && isWorkon(id)) {
          name = trlMessage('filter.at-your-status');
        }
        return {
          ...stat,
          id,
          name,
          disabled: !isResponse(id) && !isWorkon(id) && Boolean(stat.count) === false,
          count
        };
      })
      .filter(Boolean);

    function getCount(stat) {
      return stat.current === undefined ? stat.count || 0 : stat.current;
    }
  },
  total: (state) => (statusId) => {
    const statusStats = state.stats.find((stat) => stat.status === statusId);
    return statusStats ? statusStats.count : 0;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

function isWorkon(id) {
  return id === SpecialStatuses.WORK_ON;
}

function isResponse(id) {
  return id === SpecialStatuses.RESPONSE;
}
