<template>
  <div class="autocomplete-list-item__link" :title="title" :data-qa="item.dataQa">
    {{ item[displayField] }}
    <div v-if="hasSubtitle" class="autocomplete-list-item__sub">
      {{ item[subDisplayField] }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'VItem',
  props: {
    item: { type: Object, required: true },
    displayField: { type: String, required: true },
    subDisplayField: { type: String, required: true }
  },
  computed: {
    hasSubtitle() {
      return Boolean(this.subDisplayField && this.item[this.subDisplayField]);
    },
    title() {
      let title = this.item[this.displayField];
      if (this.hasSubtitle) {
        title += ` (${this.item[this.subDisplayField]})`;
      }

      return title;
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
