import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, createBlock as _createBlock } from "vue"

import { computed, onBeforeUnmount, ref } from 'vue';

import BaseButton from '@/components/button/button.vue';

import SnowBg from './snow-bg.vue';

const SUGROB_STEP = 0.001;
const SUGROB_INTERVAL = 1000 / 30;
const SUGROB_MAX_PROGRESS = 0.29;


export default /*@__PURE__*/_defineComponent({
  __name: 'ny-button',
  setup(__props) {

_useCssVars(_ctx => ({
  "006c10f6": (snowProgress.value)
}))

const letItSnow = ref(false);
const snowProgress = ref(0);
let interval;
let stopTimeout;

const sugrobRemainingTime = computed(
  () => ((SUGROB_MAX_PROGRESS - snowProgress.value) / SUGROB_STEP) * SUGROB_INTERVAL
);

function stopSnow() {
  if (!letItSnow.value) {
    return;
  }
  stopTimeout = setTimeout(() => {
    letItSnow.value = false;
  }, sugrobRemainingTime.value + 3000);
}

function startSnow() {
  clearTimeout(stopTimeout);
  if (letItSnow.value) {
    return;
  }
  letItSnow.value = true;

  interval = setInterval(() => {
    snowProgress.value += SUGROB_STEP;
    if (snowProgress.value > SUGROB_MAX_PROGRESS) {
      clearInterval(interval);
    }
  }, SUGROB_INTERVAL);
}

onBeforeUnmount(() => {
  clearInterval(interval);
  clearTimeout(stopTimeout);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseButton, {
    class: _normalizeClass(_ctx.$style.nyButton),
    color: "violet",
    onMouseenter: startSnow,
    onMouseleave: stopSnow
  }, {
    default: _withCtx(() => [
      (_openBlock(), _createElementBlock("svg", {
        class: _normalizeClass(_ctx.$style.icon),
        width: "27",
        height: "40",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }, _cache[0] || (_cache[0] = [
        _createElementVNode("path", {
          d: "M16.768 26.355H10.26V39.95h6.507V26.356Z",
          fill: "#9E5D26"
        }, null, -1),
        _createTextVNode(),
        _createElementVNode("path", {
          d: "M16.768 30.488v-3.716l-4.924-.416H10.26v6.162l6.507-2.03Z",
          fill: "#642D10"
        }, null, -1),
        _createTextVNode(),
        _createElementVNode("path", {
          d: "m.568 30.387 12.946-18.63 12.946 18.63H.568Z",
          fill: "#007042"
        }, null, -1),
        _createTextVNode(),
        _createElementVNode("path", {
          d: "m3.433 26.252 16.558-5.177-1.515-2.183-9.362-.802-5.681 8.162Z",
          fill: "#015731"
        }, null, -1),
        _createTextVNode(),
        _createElementVNode("path", {
          d: "M2.54 21.064 13.515 5.277l10.974 15.787H2.54Z",
          fill: "#009542"
        }, null, -1),
        _createTextVNode(),
        _createElementVNode("path", {
          d: "m6.454 15.43 11.596-3.624L15.962 8.8l-4.613-.406-4.895 7.035Z",
          fill: "#007042"
        }, null, -1),
        _createTextVNode(),
        _createElementVNode("path", {
          d: "M5.347 11.756 13.514 0l8.168 11.756H5.347Z",
          fill: "#48B749"
        }, null, -1),
        _createTextVNode(),
        _createElementVNode("path", {
          d: "M17.079 5.125S15.039 8.8 7.678 8.394m12.004 5.758s-4.652 5.655-15.15 4.051m16.858 4.895s-7.944 6.63-19.229 5.005",
          stroke: "#FFE100",
          "stroke-width": ".5",
          "stroke-linecap": "round"
        }, null, -1),
        _createTextVNode(),
        _createElementVNode("path", {
          d: "M15.36 7.745c.509 0 .922-.431.922-.964 0-.533-.413-.965-.923-.965s-.922.432-.922.965c0 .533.413.964.922.964Zm-4.788 1.614c.51 0 .923-.432.923-.965 0-.533-.413-.964-.923-.964s-.923.431-.923.964c0 .533.414.965.923.965ZM7.377 19.452c.51 0 .922-.431.922-.964 0-.533-.413-.965-.922-.965-.51 0-.923.432-.923.965 0 .533.413.964.923.964ZM4.531 29.32c.51 0 .923-.432.923-.965 0-.533-.413-.964-.923-.964s-.923.431-.923.964c0 .533.413.965.923.965Zm5.391-.305c.51 0 .922-.432.922-.965 0-.532-.413-.964-.922-.964-.51 0-.923.432-.923.964 0 .533.413.965.923.965Zm5.205-1.36c.51 0 .922-.431.922-.964 0-.533-.413-.964-.922-.964-.51 0-.923.431-.923.964 0 .533.413.965.923.965Zm4.554-2.386c.51 0 .923-.432.923-.965 0-.532-.413-.964-.923-.964s-.922.432-.922.964c0 .533.413.965.922.965Zm-6.167-6.528c.51 0 .923-.431.923-.964 0-.533-.413-.965-.923-.965s-.922.432-.922.965c0 .533.413.964.922.964Zm4.536-2.343c.51 0 .922-.432.922-.965 0-.532-.413-.964-.922-.964-.51 0-.923.432-.923.964 0 .533.413.965.923.965Z",
          fill: "#ED1C24"
        }, null, -1)
      ]), 2)),
      _cache[1] || (_cache[1] = _createTextVNode()),
      _createVNode(SnowBg, { animated: letItSnow.value }, null, 8, ["animated"]),
      _cache[2] || (_cache[2] = _createTextVNode()),
      _renderSlot(_ctx.$slots, "default"),
      _cache[3] || (_cache[3] = _createTextVNode()),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.sugrob)
      }, null, 2)
    ], undefined, true),
    _: 3
  }, 8, ["class"]))
}
}

})