<template>
  <div :class="$style.wrapper">
    <div :class="$style.flex">
      <base-button :class="$style.button" @click="$emit('back')">
        <base-icon type="arrow-left" :class="$style.icon" />
      </base-button>
      <h1 :class="$style.title" v-html="title" />
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/button/button.vue';
import BaseIcon from '@/components/icon.vue';

export default {
  name: 'SettingTitle',
  components: {
    BaseIcon,
    BaseButton
  },
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: ''
    }
  },
  emits: ['back']
};
</script>

<style lang="less" module>
@import '~@less/common/variables.less';

.wrapper {
  padding: 12px 0 28px 6px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}
.button {
  position: relative;
  cursor: pointer;
  padding: 0 16px 0 0;
  margin: -15px 0 -15px -15px;
  width: 24px;
  height: 24px;
  box-shadow: none;
  border: none;
  background: transparent;
  &:focus-visible {
    outline: none;
  }
  &:before {
    content: '';
    border-left: 1px solid #dcdcdc;
    position: absolute;
    left: 40px;
    height: 32px;
    top: -4px;
    z-index: 1;
  }
  &:hover {
    .icon {
      color: #db525e;
    }
    background-color: transparent;
  }
}
.icon {
  width: 24px;
  height: 24px;
  color: #050505;
}
.title {
  font-size: 18px;
  line-height: 28px;
  margin: 0;
}
</style>

<i18n lang="json">{}</i18n>
