import { onMounted, onBeforeUnmount, nextTick } from 'vue';

import { useListHook } from './use-list-hook';

const Key = {
  ARROW_DOWN: 'ArrowDown',
  ARROW_UP: 'ArrowUp',
  ENTER: 'Enter',
  ESC: 'Escape'
};

export const useSuggestableHook = (
  { result, includeGroupItem, includeEmptyGroup, activeFirstItem, menu },
  { onCommit }
) => {
  const { items, activeItem, setNextActiveItem, setPrevActiveItem, setActiveItem } = useListHook(
    result,
    {
      includeGroupItem,
      includeEmptyGroup,
      activeFirstItem
    }
  );

  const handleUpDown = (event) => {
    const { key } = event;
    const isUp = key === Key.ARROW_UP;
    const isDown = key === Key.ARROW_DOWN;
    if (isUp || isDown) {
      if (menu.value) {
        // не перемещаем курсор в инпуте при нажатии на вверх-вниз
        // для меню - отключаем нативное скроллирование
        event.preventDefault();
      }
      if (isUp) {
        setPrevActiveItem();
      } else if (isDown) {
        setNextActiveItem();
      }

      // ожидаем пока activeItem обновит своё значение
      nextTick(() => {
        // и скроллим до него
        menu.value?.scrollToItem(activeItem.value);
      });
    }
  };

  const handleEnter = (event) => {
    const { key, ctrlKey, metaKey, shiftKey } = event;
    const isModificatorKeyPressed = ctrlKey || metaKey || shiftKey;
    const isEnter = key === Key.ENTER && !isModificatorKeyPressed;

    if (!isEnter) {
      return;
    }

    if (event.target.tagName === 'INPUT') {
      // на случай подобного
      // https://huntflow.atlassian.net/browse/SRV-8650
      event.preventDefault();
      event.stopPropagation();
    }
    onCommit();
  };

  const handleEsc = (event) => {
    // если установлен курсор, то нажатие на ESC снимает его
    const { key } = event;
    const isEsc = key === Key.ESC;
    if (isEsc && activeItem.value) {
      setActiveItem(undefined);
    }
  };

  const keyDownEventHandler = (event) => {
    handleUpDown(event);
    handleEnter(event);
    handleEsc(event);
  };

  const menuKeyDownEventHandler = (event) => {
    // реагируем на нажатия клавиш в меню только если фокус в нём
    if (!menu.value?.$el.contains(document.activeElement)) {
      return;
    }
    handleUpDown(event);
    handleEnter(event);
    handleEsc(event);
  };

  onMounted(() => {
    document.addEventListener('keydown', menuKeyDownEventHandler);
  });

  onBeforeUnmount(() => {
    document.removeEventListener('keydown', menuKeyDownEventHandler);
  });

  return {
    keyDownEventHandler,
    items,
    activeItem,
    setActiveItem
  };
};
