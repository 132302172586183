import { computed, onMounted, ref, toValue, useTemplateRef } from 'vue';

import { ROW_HEIGHT } from '../config';
import { time2y } from './time2y';

type ScrollSize = {
  vertical: number;
  horizontal: number;
};

/**
 * Вычисление ширины скролла,
 * чтобы скрыть вертикальную прокрутку (см. "right")
 * и отобразить горизонтальную прокрутку (см. "padding-bottom")
 */
const getScrollSize = (node: HTMLElement): ScrollSize => ({
  vertical: node.offsetWidth - node.clientWidth,
  horizontal: node.offsetHeight - node.clientHeight
});

export const useScroll = (intervalStart: Date) => {
  const scrollContainer = useTemplateRef<HTMLElement>('scrollContainer');

  const hScroll = ref(0);
  const vScroll = ref(0);

  onMounted(() => {
    if (!scrollContainer.value) {
      return;
    }

    const scrollSize = getScrollSize(scrollContainer.value);
    scrollContainer.value.scrollTop = time2y(toValue(intervalStart), ROW_HEIGHT) - 10;

    hScroll.value = scrollSize.horizontal;
    vScroll.value = scrollSize.vertical;
    /*
     * Кажется c "scrollSize.vertical" - это косяк легаси-имплементации.
     * Там сейчас так: есть горизонтальный скролл - нет вертикального, нет горизонтального скролла - есть вертикальный
     * Если эта логика должна сохраниться, то будет что-то вроде
     * vScroll.value = hScroll.value ? scrollSize.vertical : 0;
     */
  });

  const containerStyle = computed(() => `padding-bottom: ${hScroll.value}px`);
  const scrollContainerStyle = computed(() => `right: -${vScroll.value}px`);

  return {
    containerStyle,
    scrollContainerStyle
  };
};
