import identity from 'lodash/identity';
import noop from 'lodash/noop';

import calculateNodeHeight, { purgeCache } from './calculate-node-height';

const map = new Map();
let id = 0;
const defaultOptions = { useCacheForDOMMeasurements: false };

function assign(ta, initOptions = {}) {
  if (!ta || ta.nodeName !== 'TEXTAREA' || map.has(ta)) {
    return;
  }
  const uid = id++;
  let resizeLock = false;
  let updating = false;
  const { useCacheForDOMMeasurements, maxRows } = { ...initOptions, ...defaultOptions };
  let state = {
    height: (ta.style && ta.style.height) || 0,
    minHeight: -Infinity,
    maxHeight: Infinity
  };
  const minRows = ta.getAttribute('rows');

  function render(callback) {
    if (updating) {
      return;
    }
    updating = true;
    requestAnimationFrame(() => {
      let css = `${ta.style.cssText}; height: ${state.height}px`;

      const maxHeight = Math.max(ta.style.maxHeight || Infinity, state.maxHeight);

      if (maxHeight < state.height) {
        css += 'overflow: hidden';
      }

      ta.style.cssText = css;
      updating = false;
      callback();
    });
  }

  function update(callback = noop) {
    if (!ta) {
      callback();
      return;
    }

    const nodeHeight = calculateNodeHeight(ta, uid, useCacheForDOMMeasurements, minRows, maxRows);

    if (nodeHeight === null) {
      callback();
      return;
    }

    const {
      height,
      minHeight,
      maxHeight
      // rowCount: newRowCount,
      // valueRowCount
    } = nodeHeight;

    if (state.height !== height || state.minHeight !== minHeight || state.maxHeight !== maxHeight) {
      state = { height, minHeight, maxHeight };
      render(callback);
      return;
    }

    callback();
  }

  const pageResize = () => {
    if (resizeLock) {
      return;
    }
    resizeLock = true;

    update(() => {
      resizeLock = false;
    });
  };

  function updateHandle() {
    update();
  }

  const oldStyles = ta.style.cssText;
  const destroy = () => {
    window.removeEventListener('resize', pageResize, false);
    ta.removeEventListener('input', updateHandle, false);
    ta.removeEventListener('autosize:destroy', destroy, false);
    ta.removeEventListener('autosize:update', updateHandle, false);
    purgeCache(uid);
    ta.style.cssText = oldStyles;

    map.delete(ta);
  };

  ta.addEventListener('autosize:destroy', destroy, false);
  window.addEventListener('resize', pageResize, {
    passive: true
  });
  ta.addEventListener('input', updateHandle, {
    passive: true
  });
  ta.addEventListener('autosize:update', updateHandle, false);

  map.set(ta, {
    destroy,
    update
  });

  update();
}

function destroy(ta) {
  const methods = map.get(ta);
  if (methods) {
    methods.destroy();
  }
}

function update(ta) {
  const methods = map.get(ta);
  if (methods) {
    methods.update();
  }
}

// eslint-disable-next-line import/no-mutable-exports
let autosize = null;

const callOnAll
  = (fn) =>
    (el, ...other) => {
      if (el) {
        Array.prototype.forEach.call(el.length ? el : [el], (x) => fn(x, ...other));
      }
      return el;
    };

// Do nothing in Node.js environment and IE8 (or lower)
if (typeof window === 'undefined' || typeof window.getComputedStyle !== 'function') {
  autosize = identity;
  autosize.destroy = identity;
  autosize.update = identity;
} else {
  autosize = callOnAll(assign);
  autosize.destroy = callOnAll(destroy);
  autosize.update = callOnAll(update);
}

export default autosize;
