import { configureCompat } from 'vue';

import registerNpsElement from '@/modules/nps/register-nps-element';

import '@/shared/lib/feature-toggle/feature-toggle';

import { RegisterGlobalComponents } from './register-global-components';

configureCompat({
  MODE: 3
});

registerNpsElement();
RegisterGlobalComponents.register();
