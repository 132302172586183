<template>
  <popup-layout :title="title">
    <template #main>
      <div :class="$style.info">
        <span :class="$style.message">{{ message }}</span>
        <span v-if="vacancy">
          {{ vacancy.position }}
          <template v-if="vacancy.parent">/ <vacancy-name :vacancy="vacancy" full /></template>
        </span>
        <span>{{ date }}</span>
      </div>
    </template>
    <template #footer>
      <base-button @click="emit('cancel')">
        {{ $i18n('button.close') }}
      </base-button>
    </template>
  </popup-layout>
</template>

<script setup lang="ts">
import { defineComponent, defineProps, defineEmits, computed } from 'vue';

import BaseButton from '@/components/button/button.vue';
import VacancyName from '@/components/hf/vacancy-name/vacancy-name.vue';
import PopupLayout from '@/components/popup-layout/popup-layout.vue';

import { appConfig } from '@/shared/lib/config/app-config';
import { useI18N } from '@/shared/lib/use-i18n';
import { DateTimeHelper } from '@/shared/lib/util/date-time-helper';

import { ExportType } from './export-type';

const { type } = defineProps<IProps>();

const emit = defineEmits(['cancel']);

const i18n = useI18N();

type ValueOf<T> = T[keyof T];

type IProps = {
  type: ValueOf<typeof ExportType>;
  vacancy?: {
    position: string;
    parent?: number;
  };
};

defineComponent({
  name: 'ExportSuccess'
});

const date = DateTimeHelper.now().toShortFormat();
const title = computed(() => i18n('title', { email: appConfig.get('account').email }));
const message = computed(() => {
  if (type === ExportType.LIST) {
    return i18n('message.list');
  }
  if (type === ExportType.SURVEY_TYPE_A) {
    return i18n('message.survey_type_a');
  }
  if (type === ExportType.SURVEY_TYPE_Q) {
    return i18n('message.survey_type_q');
  }
  return undefined;
});
</script>

<style module lang="less">
@import '~@less/common/variables';

.info {
  color: #050505;
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 15px;
  line-height: 24px;
}
.message {
  font-weight: 500;
}
</style>

<i18n lang="json">
{
  "title": {
    "ru_RU": "Отчет сформирован и отправлен: {email}",
    "en_US": "Report sent to {email}"
  },
  "message.list": {
    "ru_RU": "Выгрузка по вакансии",
    "en_US": "Uploading by vacancy"
  },
  "message.survey_type_a": {
    "ru_RU": "Обратная связь по кандидатам на вакансии",
    "en_US": "Feedback on candidates for vacancy"
  },
  "message.survey_type_q": {
    "ru_RU": "Анкеты кандидатов на вакансии",
    "en_US": "Candidate questionnaires for vacancy"
  }
}
</i18n>
