<template>
  <request-form
    :open="showCreateModal"
    :vacancy-request-schema="vacancyRequestSchema"
    :vacancy-request="vacancyRequest"
    data-qa="vacancy_request_creation_form"
    @updated="handleUpdated"
    @change="showCreateModal = $event"
  />
</template>

<script>
import RequestForm from './index.vue';

export default {
  name: 'RequestCreate',
  components: { RequestForm },
  props: {
    open: Boolean,
    vacancyRequest: {
      type: Object,
      required: true
    },
    vacancyRequestSchema: {
      type: Object,
      required: true
    }
  },
  emits: ['change'],
  computed: {
    showCreateModal: {
      get() {
        return this.open;
      },
      set(flag) {
        this.$emit('change', flag);
      }
    }
  },
  methods: {
    handleUpdated() {
      this.$emit('change', false);
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
