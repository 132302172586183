import type { ComputedRef, Ref } from 'vue';

import type {
  ValueVariant,
  Value
} from '@/components/base-list/composables/use-normalized-value';
import type { HierarchyMap } from '@/components/hierarchy/hierarchy-map';

import { computed, ref, unref } from 'vue';

import {
  useNormalizedValue
} from '@/components/base-list/composables/use-normalized-value';

type Options = {
  multiple: Ref<boolean>;
  onlyLeaf: Ref<boolean>;
  hierarchyMap: Ref<HierarchyMap>;
};

type Result = {
  value: Ref<ValueVariant>;
  deepValue: ComputedRef<ValueVariant>;
};

export function useListValue(
  value: Ref<ValueVariant>,
  { multiple = ref(false), onlyLeaf = ref(false), hierarchyMap }: Options
): Result {
  const { normalizedValue } = useNormalizedValue(value, { multiple });

  const allSelectedValue = computed(() => {
    if (!unref(multiple) || unref(onlyLeaf)) {
      return normalizedValue.value;
    }
    const hash = hierarchyMap.value.deepChildrenMap || {};
    const selected: Array<Value> = [];
    (normalizedValue.value as Array<Value>).forEach((id) => {
      if (selected.includes(id)) {
        return;
      }
      selected.push(id, ...(hash[id] || []));
    });
    return Array.from(new Set(selected));
  });

  return {
    value: normalizedValue,
    deepValue: allSelectedValue
  };
}
