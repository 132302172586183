<template>
  <base-template
    :id="id"
    :ordered-properties="orderedProperties"
    :schema="schema"
    :ui-schema="uiSchema"
    :label="label"
  >
    <div :class="$style.wrapper">
      <div :class="$style.auto">
        <slot name="income" />
      </div>
      <div :class="$style.fixed">
        <slot name="type" />
      </div>
    </div>
  </base-template>
</template>

<script>
import BaseTemplate from './base-template.vue';

export default {
  name: 'IncomeTemplate',
  components: { BaseTemplate },
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      default: () => ({})
    },
    schema: {
      type: Object,
      required: true
    },
    uiSchema: {
      type: Object,
      required: true
    },
    orderedProperties: {
      type: Array,
      required: true
    }
  },
  computed: {
    isDelimiter() {
      return this.uiSchema['ui:options']?.breakAfter;
    }
  }
};
</script>

<style module lang="less">
.wrapper {
  align-items: flex-end;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  gap: 0 10px;
}
.auto {
  flex: 1 0 0;
}
.fixed {
  flex: 0 0 120px;
  width: 120px;
}
</style>

<i18n lang="json">{}</i18n>
