<template>
  <div :class="$style.container">
    <transition
      name="fade"
      :enter-from-class="$style.fadeEnter"
      :enter-active-class="$style.fadeEnterActive"
      :leave-to-class="$style.fadeLeaveTo"
      :leave-active-class="$style.fadeLeaveActive"
      @before-enter="beforeEnter"
      @after-enter="afterEnter"
      @after-leave="afterLeave"
    >
      <div
        v-if="showCurrentNotification"
        data-ignore-outside-click
        :class="{
          [$style.root]: true,
          [$style.error]: currentNotification.isError
        }"
        @mouseenter="handleMouseenter"
        @mouseleave="handleMouseleave"
      >
        <div
          :class="$style.content"
          :title="currentNotification.content"
          v-html="currentNotification.content"
        />
        <div :class="$style.close" data-qa="close-popup-notification" @click="handleClose">
          <svg width="10" height="10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="m8.973.326.718.812L5.771 5l3.92 3.862-.718.812L5 5.76 1.027 9.674l-.718-.812L4.229 5 .308 1.138l.718-.812L5 4.24 8.973.326Z"
              fill="#fff"
            />
          </svg>
        </div>
      </div>
    </transition>
    <slot name="poller">
      <notifier-message @notify="pollerNotifiers" />
    </slot>
  </div>
</template>

<script>
import NotifierMessage from '@/components/hf/notifier-message/notifier-message.vue';

import { notification } from '@/shared/lib/notification/notification.ts';

import { trlMessage } from '../../shared/lib/i18n';

export default {
  name: 'SystemNotifications',
  components: { NotifierMessage },
  props: {
    duration: {
      type: Number,
      default: 5000
    },
    delay: {
      type: Number,
      default: 1000
    }
  },
  data() {
    return {
      notificationQueue: [],
      showCurrentNotification: false,
      canShowNextNotification: true
    };
  },
  computed: {
    currentNotification() {
      return this.notificationQueue[this.notificationQueue.length - 1];
    }
  },
  mounted() {
    this.waitForNext = true;
    this.unsubscribe = notification.subscribe(this.addItem);
  },
  beforeUnmount() {
    this.unsubscribe();
  },
  methods: {
    pollerNotifiers(data) {
      if (!data.message) {
        return;
      }
      // у trlMessage payload не может быть вложенными значениями, только плоский объект, поэтому тут небольшой хак для мета информации
      const meta = Object.fromEntries(
        Object.entries(data.meta || {}).map(([key, value]) => [`meta_${key}`, value])
      );
      notification.notify({
        // eslint-disable-next-line no-restricted-syntax
        content: trlMessage(data.message, {
          ...data,
          ...meta
        }),
        isError: !data.success
      });
    },
    beforeEnter() {
      // "лочим" компонент для следующего сообщения
      // на время показа + задержку
      this.canHideCurrent = false;
      this.canShowNextNotification = false;
      this.hideTimeout = setTimeout(() => {
        this.showCurrentNotification = false;
      }, this.duration);
    },
    handleMouseenter() {
      if (this.canHideCurrent) {
        clearTimeout(this.hideTimeout);
      }
    },
    handleMouseleave() {
      this.hideTimeout = setTimeout(() => {
        this.showCurrentNotification = false;
      }, this.duration);
    },
    afterEnter() {
      this.canHideCurrent = true;
      if (this.preClose) {
        this.preClose = false;
        this.handleClose();
      }
    },
    afterLeave() {
      clearTimeout(this.hideTimeout);

      setTimeout(
        () => {
          this.waitForNext = true;
          // удаляем из очереди показанное сообщение
          this.notificationQueue.pop();

          // снимаем блокировку с тем чтобы показать следующее сообщение...
          this.canShowNextNotification = true;
          // ...если оно есть
          if (this.currentNotification) {
            this.showCurrentNotification = true;
          }
        },
        this.waitForNext ? this.delay : 0
      );
    },
    handleClose() {
      if (this.canHideCurrent) {
        this.showCurrentNotification = false;
      } else {
        this.preClose = true;
      }
    },
    addItem(notification) {
      if (notification.id) {
        this.notificationQueue = this.notificationQueue.filter(
          (oldNotification) =>
            notification.id !== oldNotification.id || oldNotification === this.currentNotification
        );

        if (this.currentNotification && this.currentNotification.id === notification.id) {
          this.waitForNext = false;
          this.handleClose(true);
        }
      }
      this.notificationQueue.unshift(notification);
      if (this.canShowNextNotification) {
        // немедля показать пришедшее сообщение
        this.showCurrentNotification = true;
      }
    }
  }
};
</script>

<style module>
.fadeEnterActive,
.fadeLeaveActive {
  transition: all 0.4s;
}

.fadeEnter,
.fadeLeaveTo {
  opacity: 0;
  transform: translateY(10px);
}

.container {
  position: fixed;
  bottom: 16px;
  left: 50%;
  margin-left: -275px;
  width: max-content;
  z-index: 999999;
}

.root {
  width: 550px;
  margin: 0 auto;
  display: flex;
  background: $btnGreen;
  border-radius: 6px;
}

.error {
  background: $red-400;
}

.content {
  flex-grow: 1;
  padding: 6px 30px;
  overflow: hidden;
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  font-weight: 500;
  @mixin ellipsis;

  a {
    color: $white;
  }
}

.close {
  display: flex;
  width: 30px;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  color: $white;
  font-size: 18px;
  cursor: default;
  user-select: none;
  border-left: 1px solid rgba(255, 255, 255, 0.25);
  font-weight: 500;

  &:hover {
    background-color: $black-6;
  }
}
</style>

<i18n lang="json">{}</i18n>
