import { reactive } from 'vue';

import { NotifierMessageType } from '@/components/hf/notifier-message/notifier-message-type';
import { getNotifierEventName } from '@/components/hf/notifier-message/notifier-message.vue';

import { EntityType } from '@/shared/api/photo';
import { appConfig } from '@/shared/lib/config/app-config';
import { trlMessage } from '@/shared/lib/i18n';
import { notifier } from '@/shared/lib/poller-instance';
import { StringHelper } from '@/shared/lib/util/string-helper';

const allowedImageTypes = ['image/jpeg', 'image/png', 'image/webp'];
const DELETED_PHOTO_VERSION = -1;

const makeFileExtStr = (mimes) => {
  const fileTypes = mimes.map((type) => {
    const [, extension] = type.split('/');
    return `.${extension ?? type}`;
  });
  return StringHelper.concat(fileTypes, ', ', ` ${trlMessage('or')} `);
};

// Для переполучения фото пользователя
// триггерим реактивную переменную
const seeds = reactive({});
const refreshPhoto = ({ data }) => {
  const id = data.entity_id;
  if (!(id in seeds)) {
    // Если такого пользователя нет на странице
    return;
  }
  const photoVersion = data.photo_version;
  if (photoVersion) {
    seeds[id] = photoVersion;
    return;
  }
  seeds[id]++;
};

notifier.onMessage((message) => {
  switch (getNotifierEventName(message)) {
    case NotifierMessageType.PHOTO_PHOTO_CHANGED_PHOTO_EDITED:
    case NotifierMessageType.PHOTO_PHOTO_CHANGED_PHOTO_DELETED:
      // Эти события могут прилетать не только для основного фото,
      // в таких случаях обновлять аватарку не надо
      if (!message.data.is_main) {
        return;
      }
      refreshPhoto(message);
      break;

    case NotifierMessageType.PHOTO_PHOTO_CHANGED_PHOTO_MAIN_PHOTO_SET:
      refreshPhoto(message);
      break;
  }
});

function getPhotoUrl({ entity, type = EntityType.APPLICANT, photoSeed = '' }) {
  let id;
  switch (type) {
    case EntityType.USER:
      id = entity.user_id ?? entity.member ?? entity.id;
      break;
    case EntityType.APPLICANT_RESPONSE:
      id = entity.external?.[0]?.id;
      break;
    default:
      id = entity.id;
      break;
  }

  if (!entity.has_photo && !entity.thumbnail_url) {
    return;
  }
  // Когда кандидат ещё не добавлен в базу, но его фото уже загружено
  // ожидается thumbnail_url
  if (
    [EntityType.APPLICANT, EntityType.APPLICANT_RESPONSE].includes(type)
    && entity.thumbnail_url
  ) {
    return entity.thumbnail_url;
  }

  if (!seeds[id]) {
    seeds[id] = entity.photo_version || 0;
  }
  // Все фото были удалены
  if (seeds[id] === DELETED_PHOTO_VERSION) {
    return '';
  }
  // Формируем url если у фото есть флаг 'has_photo',
  // либо если для этого пользователя пришло обновление из notifier'а
  if (!entity.has_photo && !seeds[id]) {
    return;
  }
  const params = new URLSearchParams();
  if (typeof entity.photo_version !== 'undefined') {
    params.append('version', seeds[id]);
  } else {
    // В поиске пользователей в организации используется старая логика с seed
    photoSeed = `${photoSeed || id}_${seeds[id]}`;
    params.append('seed', photoSeed);
  }
  return `${appConfig.get(
    'photoUrl'
  )}/photo_service/${type}/${id}/photos/main/thumbnail/redirect?${params.toString()}`;
}

export const PhotoHelper = {
  allowedImageTypes,
  makeFileExtStr,
  getPhotoUrl
};
