import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

import { defineComponent, computed } from 'vue';

import BaseButton from '@/components/button/button.vue';
import VacancyName from '@/components/hf/vacancy-name/vacancy-name.vue';
import PopupLayout from '@/components/popup-layout/popup-layout.vue';

import { appConfig } from '@/shared/lib/config/app-config';
import { useI18N } from '@/shared/lib/use-i18n';
import { DateTimeHelper } from '@/shared/lib/util/date-time-helper';

import { ExportType } from './export-type';

type ValueOf<T> = T[keyof T];

type IProps = {
  type: ValueOf<typeof ExportType>;
  vacancy?: {
    position: string;
    parent?: number;
  };
};


export default /*@__PURE__*/_defineComponent({
  __name: 'export-success',
  props: {
    type: {},
    vacancy: {}
  },
  emits: ['cancel'],
  setup(__props: any, { emit: __emit }) {



const emit = __emit;

const i18n = useI18N();

defineComponent({
  name: 'ExportSuccess'
});

const date = DateTimeHelper.now().toShortFormat();
const title = computed(() => i18n('title', { email: appConfig.get('account').email }));
const message = computed(() => {
  if (__props.type === ExportType.LIST) {
    return i18n('message.list');
  }
  if (__props.type === ExportType.SURVEY_TYPE_A) {
    return i18n('message.survey_type_a');
  }
  if (__props.type === ExportType.SURVEY_TYPE_Q) {
    return i18n('message.survey_type_q');
  }
  return undefined;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(PopupLayout, { title: title.value }, {
    main: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.info)
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.$style.message)
        }, _toDisplayString(message.value), 3),
        _cache[2] || (_cache[2] = _createTextVNode()),
        (_ctx.vacancy)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
              _createTextVNode(_toDisplayString(_ctx.vacancy.position) + " ", 1),
              (_ctx.vacancy.parent)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _cache[1] || (_cache[1] = _createTextVNode("/ ")),
                    _createVNode(VacancyName, {
                      vacancy: _ctx.vacancy,
                      full: ""
                    }, null, 8, ["vacancy"])
                  ], 64))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        _cache[3] || (_cache[3] = _createTextVNode()),
        _createElementVNode("span", null, _toDisplayString(_unref(date)), 1)
      ], 2)
    ]),
    footer: _withCtx(() => [
      _createVNode(BaseButton, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('cancel')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$i18n('button.close')), 1)
        ], undefined, true),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["title"]))
}
}

})