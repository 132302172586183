
    import { makeI18n } from '@/shared/lib/i18n';
    const baseTranslation = undefined
    
    
    if(module.hot) {
      const bucket = require("/app/build/i18n/browser-bucket.js");
      bucket.add('src/modules/vacancy/applicants-export/export-success.vue', {"title":{"ru_RU":"Отчет сформирован и отправлен: {email}","en_US":"Report sent to {email}"},"message.list":{"ru_RU":"Выгрузка по вакансии","en_US":"Uploading by vacancy"},"message.survey_type_a":{"ru_RU":"Обратная связь по кандидатам на вакансии","en_US":"Feedback on candidates for vacancy"},"message.survey_type_q":{"ru_RU":"Анкеты кандидатов на вакансии","en_US":"Candidate questionnaires for vacancy"}}) 
    }
   
    
    export default function (Component) {
      function beforeCreateI18n() {
        this.$i18n = this.$options._i18n;
      }

      function addHook(current) {
        if (Array.isArray(current)) {
          return [beforeCreateI18n].concat(current);
        } 
        if (current) {
          return [beforeCreateI18n, current];
        }
        return [beforeCreateI18n];
      }
    
      Component._i18n = makeI18n("src/modules/vacancy/applicants-export/export-success.vue", baseTranslation);
      Component.beforeCreate = addHook(Component.beforeCreate);
      delete Component._Ctor;
    }
  