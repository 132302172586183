<template>
  <div :class="classes" data-qa="popup-layout-heading">
    <slot />
    <base-icon v-if="removeLayer" :class="$style.icon" type="x" @click="removeLayer" />
  </div>
</template>

<script setup lang="ts">
import { computed, inject, useCssModule } from 'vue';

import BaseIcon from '@/components/icon.vue';

import { removeLayerSymbol } from '@/shared/ui/base-layer/constants';

defineOptions({
  name: 'PopupHeading'
});

const css = useCssModule();

const removeLayer = inject(removeLayerSymbol);

const classes = computed(() => {
  return {
    [css.heading]: true
  };
});
</script>

<style module>
.heading {
  padding: 20px 60px 20px 24px;
  background-color: $white;
  border-radius: var(--Radius-x4, 16px) var(--Radius-x4, 16px) 0 0;
  border-bottom: 1px solid var(--border-neutral-S, rgba(0, 0, 0, 0.08));
  color: #050505;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  min-width: 0;
  word-wrap: break-word;
  overflow: hidden;
}

.icon {
  cursor: pointer;
  color: #a5a5a5;
  border-radius: 8px;
  position: absolute;
  top: 20px;
  right: 24px;

  &:hover {
    background-color: $black-7;
  }

  &:active {
    background-color: #ebebeb;
  }
}
</style>

<i18n lang="json">{}</i18n>
