<template>
  <div :class="$style.root">
    <base-input
      size="micro"
      :focus="focus"
      :value="valueValue"
      :invalid="invalid"
      :class="$style.input"
      @input-value="handleInputValue"
    />
    <base-select :value="valueUnit" :class="$style.select" @change-value="handleChangeMeasureUnit">
      <base-option v-for="unit in units" :key="unit.id" :value="unit.id">
        {{ unit.label }}
      </base-option>
    </base-select>
    <button type="button" :class="$style.removeBtn" @click="handleRemove">
      <icon type="x-icon" :class="$style.removeBtnIcon" />
    </button>
  </div>
</template>

<script>
import { computed } from 'vue';

import BaseOption from '@/components/select/base-option.vue';

import { useGlobal } from '@/shared/lib/use-global';

import Icon from '../icon.vue';
import BaseInput from '../input/input.vue';
import BaseSelect from '../select/select.vue';
import { Unit, DEFAULT_UNIT } from './units';

export default {
  name: 'ReminderItem',
  components: {
    BaseOption,
    BaseInput,
    BaseSelect,
    Icon
  },
  props: {
    invalid: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      required: true
    },
    focus: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change', 'remove'],
  setup(props, { emit }) {
    const { $trlMessage } = useGlobal();

    const valueValue = computed(() => props.value?.value);
    const valueUnit = computed(() => props.value?.unit ?? DEFAULT_UNIT);
    const getUnitLabel = (unit, value) =>
      // eslint-disable-next-line no-restricted-syntax
      $trlMessage(`reminder-icu.${unit}`, { value: value ?? 0 });
    const units = computed(() => {
      const value = valueValue.value; // "...и засмеялся так неприятно"
      return [
        { id: Unit.MINUTE, label: getUnitLabel(Unit.MINUTE, value) },
        { id: Unit.HOUR, label: getUnitLabel(Unit.HOUR, value) },
        { id: Unit.DAY, label: getUnitLabel(Unit.DAY, value) },
        { id: Unit.WEEK, label: getUnitLabel(Unit.WEEK, value) }
      ];
    });

    return {
      units,
      valueValue,
      valueUnit,
      handleInputValue: (value) => emit('change', { value, unit: valueUnit.value }),
      handleChangeMeasureUnit: (unit) => emit('change', { value: valueValue.value, unit }),
      handleRemove: () => emit('remove')
    };
  }
};
</script>

<style module>
$removeBtnWidth: 30px;
$removeBtnHeight: 30px;

.root {
  display: flex;
  gap: 8px;
  align-items: center;
}

.input {
  composes: micro from '../../shared/ui/css/widths.module.css';
  flex-grow: 0;
  width: 56px;
}

.select {
  flex-grow: 1;
}

.removeBtn {
  @mixin reset-button-styles;
  cursor: pointer;
  color: #cccccc;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}

.removeBtn,
.removeBtnIcon {
  color: #d2d2d2;
  width: 14px;
  height: 14px;
  flex-grow: 0;
  display: inline-flex;
}
</style>

<i18n lang="json">{}</i18n>
