import type { Ref } from 'vue';

import type { Value, ValueVariant } from '@/components/base-list/composables/use-normalized-value';
import type { ParentChildMap } from '@/components/hierarchy/hierarchy-map';

import isEqual from 'lodash/isEqual';
import { computed, ref, watch } from 'vue';

type Options = {
  loading: Ref<boolean>;
  parentChildMap: Ref<ParentChildMap>;
  onChange: (value: ValueVariant) => void;
};

// TODO: не поддерживает onlyLeaf,
// Если вдруг поменяют настройку и будет предсохранено значение, которое не является корневым, то оно останется выбранным
export function useRemoveNonexistent(
  valueRef: Ref<ValueVariant>,
  { loading, parentChildMap, onChange }: Options
) {
  const removeNonexistent = ref(true);

  const needRemove = computed(() => {
    return (
      !loading.value && removeNonexistent.value && Object.keys(parentChildMap.value).length > 1
    );
  });

  watch(
    needRemove,
    (flag) => {
      if (!flag) {
        return;
      }
      const map = parentChildMap.value;
      const value = valueRef.value;
      if (Array.isArray(value)) {
        const result = value.reduce<Value[]>((res, value) => {
          if (value in map) {
            res.push(value);
          }
          return res;
        }, []);

        if (!isEqual(result, value)) {
          onChange(result);
        }
      } else if (value !== null && value !== undefined && !(value in map)) {
        onChange(null);
      }

      removeNonexistent.value = false;
    },
    {
      immediate: true
    }
  );
}
