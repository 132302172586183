<template>
  <div>
    <slot name="button" :open="open" />
    <quotas-modal
      :open="showModal"
      :vacancy-id="vacancyId"
      :frame-begin="frameBegin"
      :status="status"
      :include-all-frames="includeAllFrames"
      @change="showModal = $event"
    >
      <template #caption>
        <slot name="caption" />
      </template>
      <template #sub-caption>
        <slot name="sub-caption" />
      </template>
      <template #quota="scopedProps">
        <slot name="quota" v-bind="scopedProps" />
      </template>
      <template #footer>
        <slot name="footer" :close="close" />
      </template>
      <template #close-text>
        <slot name="close-text" />
      </template>
    </quotas-modal>
  </div>
</template>

<script>
import QuotasModal from './quotas-modal.vue';

export default {
  name: 'QuotasModalToggle',
  components: { QuotasModal },
  props: {
    vacancyId: {
      type: Number,
      required: true
    },
    frameBegin: {
      type: String,
      required: true
    },
    includeAllFrames: QuotasModal.props.includeAllFrames,
    status: QuotasModal.props.status
  },
  emits: ['opened', 'closed'],
  data() {
    return {
      showModal: false
    };
  },
  watch: {
    showModal(flag) {
      flag ? this.$emit('opened') : this.$emit('closed');
    }
  },
  methods: {
    open() {
      this.showModal = true;
    },
    close() {
      this.showModal = false;
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
