<template>
  <div>
    <modal-wrapper :open="isModalOpened($options.Modals.NEW_OR_COPY)" @change="handleModalChange">
      <template #default="{ hide }">
        <request-new-or-copy @cancel="hide" @submit="handleNewOrCopySubmit" />
      </template>
    </modal-wrapper>

    <modal-wrapper
      :open="isModalOpened($options.Modals.RECENT_REQUEST_LIST)"
      @change="handleModalChange"
    >
      <template #default="{ hide }">
        <recent-requests-list
          :vacancy-request-schema="vacancyRequestSchema"
          @cancel="hide"
          @submit="handleRecentRequestListSubmit"
        />
      </template>
    </modal-wrapper>

    <request-form-modal
      :open="isModalOpened($options.Modals.REQUEST_FORM)"
      :vacancy-request-schema="vacancyRequestSchema"
      :vacancy-request="initialVacancyRequest"
      data-qa="vacancy_request_creation_form"
      @change="handleModalChange"
      @created="goForward"
    />

    <modal-wrapper :open="isModalOpened($options.Modals.SUCCESS)" @change="handleModalChange">
      <template #default="{ hide }">
        <request-success data-qa="vacancy-request-sent-modal" @close="hide" />
      </template>
    </modal-wrapper>
  </div>
</template>

<script>
import omit from 'lodash/omit';

import ModalWrapper from '@/components/modal-next/modal-wrapper.vue';

import VacancyRequestAPI from '@/shared/api/vacancy-request';

import RequestNewOrCopy from '../new-or-copy/new-or-copy.vue';
import RecentRequestsList from '../recent-requests-list/recent-requests-list.vue';
import RequestFormModal from './index.vue';
import RequestSuccess from './request-success.vue';

const Modals = {
  NEW_OR_COPY: 'NewOrCopy',
  RECENT_REQUEST_LIST: 'RecentRequestList',
  REQUEST_FORM: 'RequestForm',
  SUCCESS: 'Success'
};

export default {
  name: 'RequestCreate',
  components: {
    ModalWrapper,
    RequestSuccess,
    RequestNewOrCopy,
    RecentRequestsList,
    RequestFormModal
  },
  Modals,
  props: {
    open: Boolean,
    vacancyRequest: {
      type: Object,
      default: null
    },
    vacancyRequestSchema: {
      type: Object,
      required: true
    }
  },
  emits: ['change'],
  data() {
    return {
      currentModal: undefined,
      hasRecentRequests: undefined,
      useRecentRequests: undefined,
      recentRequest: undefined
    };
  },
  computed: {
    initialVacancyRequest() {
      return this.hasRecentRequests && this.useRecentRequests && this.recentRequest
        ? this.recentRequest
        : (this.vacancyRequest ?? {});
    },
    modalsOrder() {
      return getModalsOrder({
        hasRecentRequests: this.hasRecentRequests,
        useRecentRequests: this.useRecentRequests
      });
    },
    nextModal() {
      return getNextModal(this.currentModal, this.modalsOrder);
    },
    prevModal() {
      return getPrevModal(this.currentModal, this.modalsOrder);
    }
  },
  watch: {
    open: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal && !oldVal) {
          // вот это нужно, чтобы не пойти по флоу дублирования заявки в случае её редактирования
          // или "отправить повторно" для заявке с отказом
          const hasRecentRequestsPromise = this.vacancyRequest
            ? Promise.resolve(false)
            : VacancyRequestAPI.hasRecentRequests(this.vacancyRequestSchema.id);

          hasRecentRequestsPromise
            .then((hasRecentRequests) => {
              this.hasRecentRequests = hasRecentRequests;
            })
            .catch((err) => {
              console.error(err);
              this.hasRecentRequests = false;
            })
            .finally(() => this.goForward());
        }
      }
    }
  },
  methods: {
    handleNewOrCopySubmit({ fromExisting }) {
      this.useRecentRequests = fromExisting;
      this.goForward();
    },
    handleRecentRequestListSubmit(request) {
      // дропаем id, иначе дальше по флоу будет засчитано за редактирование заявки
      this.recentRequest = omit(request, 'id');
      this.goForward();
    },
    // считаем модалку открытой, если это текущая модалка
    // или находится в списке до текущей
    // (т.е. открываем модалки при прохождении вперёд, kinda wizard-like shit)
    isModalOpened(modal) {
      const modalIndex = this.modalsOrder.indexOf(modal);
      if (modalIndex === -1) {
        return false;
      }
      const currentModalIndex = this.modalsOrder.indexOf(this.currentModal);
      return modalIndex <= currentModalIndex;
    },
    handleModalChange(val) {
      if (val === false) {
        this.goBack();
      }
    },
    closeEverything() {
      this.currentModal = undefined;
      this.hasRecentRequests = undefined;
      this.useRecentRequests = undefined;
      this.recentRequest = undefined;
      this.$emit('change', false);
    },
    goForward() {
      // nextTick здесь и ниже на всякий, чтобы компутеды уже были актуальны
      this.$nextTick(() => {
        const newModal = this.nextModal;
        if (!newModal) {
          this.closeEverything();
        } else {
          this.currentModal = newModal;
        }
      });
    },
    goBack() {
      this.$nextTick(() => {
        const newModal = this.prevModal;
        if (!newModal) {
          this.closeEverything();
        } else {
          this.currentModal = newModal;
        }
      });
    }
  }
};

function getModalsOrder({ hasRecentRequests, useRecentRequests } = {}) {
  // проверяем только на false,
  // т.к. undefined резервируется для ситуации "ещё не получено"
  return hasRecentRequests !== false
    ? [
        Modals.NEW_OR_COPY,
        useRecentRequests && Modals.RECENT_REQUEST_LIST,
        Modals.REQUEST_FORM,
        Modals.SUCCESS
      ].filter(Boolean)
    : [Modals.REQUEST_FORM, Modals.SUCCESS];
}

function getPrevModal(modal, order) {
  if (modal === Modals.SUCCESS) {
    // no way back
    return undefined;
  }
  const currIndex = order.findIndex((testModal) => testModal === modal);
  return currIndex ? order[currIndex - 1] : undefined;
}

function getNextModal(modal, order) {
  if (!modal) {
    return order[0];
  }
  const currIndex = order.findIndex((testModal) => testModal === modal);
  return order[currIndex + 1] ?? undefined;
}
</script>

<i18n lang="json">{}</i18n>
