import { WIDGET_TYPE } from '@/components/vjsf/vjsf';
import VueVoid from '@/components/vue-void.vue';

import CheckboxesWidget from './view-field-checkboxes.vue';
import DateWidget from './view-field-date.vue';
import HtmlWidget from './view-field-html.vue';
import PartialDictionaryWidget from './view-field-partial-dictionary.vue';
import RadioWidget from './view-field-radio.vue';
import SelectWidget from './view-field-select.vue';
import TextWidget from './view-field-text.vue';
import TextareaWidget from './view-field-textarea.vue';

export const widgets = {
  [WIDGET_TYPE.STRING]: TextWidget,
  [WIDGET_TYPE.INTEGER]: TextWidget,
  [WIDGET_TYPE.PARTIAL_DICTIONARY]: PartialDictionaryWidget,
  [WIDGET_TYPE.HTML]: HtmlWidget,
  [WIDGET_TYPE.TEXT]: TextareaWidget,
  [WIDGET_TYPE.SELECT]: SelectWidget,
  [WIDGET_TYPE.DATE]: DateWidget,
  [WIDGET_TYPE.HIDDEN]: VueVoid,
  [WIDGET_TYPE.SUBMIT]: VueVoid,
  [WIDGET_TYPE.CHECKBOX]: CheckboxesWidget,
  [WIDGET_TYPE.RADIO]: RadioWidget
};
