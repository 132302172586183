import runWithFps from 'run-with-fps';

export function initSnow(canvas, width, height, { numFlakes = 50 } = {}) {
  canvas.width = width * 2;
  canvas.height = height * 2;
  const gl = canvas.getContext('webgl');

  if (!gl) {
    return;
  }

  // Вершинный шейдер
  const vertexShaderSource = `
  precision highp float;
  attribute vec2 a_position;
  uniform vec2 u_resolution;
  uniform float u_time;

  void main() {
    // Преобразуем координаты в диапазон 0.0 - 1.0
    vec2 zeroToOne = a_position / u_resolution;

    // Преобразуем в диапазон -1.0 - 1.0
    vec2 clipSpace = zeroToOne * 2.0 - 1.0;

    gl_PointSize = 5.0;

    // Учитываем инверсию оси Y
    gl_Position = vec4(clipSpace * vec2(1, -1), 0, 1);
  }`;

  // Фрагментный шейдер
  const fragmentShaderSource = `
  precision highp float;

  float circle(in vec2 _st, in float _radius){
    vec2 dist = _st-vec2(0.5);
    return 1.-smoothstep(_radius-(_radius*0.1),
                         _radius+(_radius*0.1),
                         dot(dist,dist)*4.0);
  }

  void main() {
    float color = circle(gl_PointCoord, 0.9);
    gl_FragColor = vec4(vec3(color), color * 0.35);
  }`;

  // Функция для создания шейдера
  function createShader(gl, type, source) {
    const shader = gl.createShader(type);
    gl.shaderSource(shader, source);
    gl.compileShader(shader);
    if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
      console.error(gl.getShaderInfoLog(shader));
      gl.deleteShader(shader);
      return null;
    }
    return shader;
  }

  // Создаем шейдеры
  const vertexShader = createShader(gl, gl.VERTEX_SHADER, vertexShaderSource);
  const fragmentShader = createShader(gl, gl.FRAGMENT_SHADER, fragmentShaderSource);

  // Создаем программу
  const program = gl.createProgram();
  gl.attachShader(program, vertexShader);
  gl.attachShader(program, fragmentShader);
  gl.linkProgram(program);
  if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
    console.error(gl.getProgramInfoLog(program));
    gl.deleteProgram(program);
  }

  gl.useProgram(program);

  // Устанавливаем буфер вершин
  const positionBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
  gl.enable(gl.BLEND);
  gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

  // Создаем массив снежинок
  const positions = new Float32Array(numFlakes * 2);
  const weights = [];
  for (let i = 0; i < numFlakes; i++) {
    positions[i * 2] = Math.random() * canvas.width; // x
    positions[i * 2 + 1] = Math.random() * canvas.height; // y

    weights.push((0.5 - Math.random()) / 0.5);
  }
  gl.bufferData(gl.ARRAY_BUFFER, positions, gl.DYNAMIC_DRAW);

  // Привязываем атрибут позиции
  const positionLocation = gl.getAttribLocation(program, 'a_position');
  gl.enableVertexAttribArray(positionLocation);
  gl.vertexAttribPointer(positionLocation, 2, gl.FLOAT, false, 0, 0);

  // Устанавливаем uniform-ы
  const resolutionLocation = gl.getUniformLocation(program, 'u_resolution');
  gl.uniform2f(resolutionLocation, canvas.width, canvas.height);

  const timeLocation = gl.getUniformLocation(program, 'u_time');

  const wind = {
    value: 0.1,
    max: 0.5,
    min: -0.5,
    step: 0.03,
    dir: 1
  };

  let targetX = 0;
  let mouseX = 0;

  function startWindInteraction(e) {
    mouseX = e.pageX;
    targetX = e.pageX;
  }

  function windInteraction(e) {
    targetX = e.pageX;
  }

  canvas.addEventListener('mouseenter', startWindInteraction);
  canvas.addEventListener('touchstart', startWindInteraction);
  canvas.addEventListener('mousemove', windInteraction);
  canvas.addEventListener('touchmove', windInteraction);

  let time = 0;
  const SPEED = 0.3;
  function renderFrame() {
    let mouseWind = 0;

    const diff = targetX - mouseX;
    mouseWind = diff * 0.17;

    mouseX += diff * 0.1;

    time += 0.01;

    // Очищаем экран
    gl.clearColor(0, 0, 0, 0);
    gl.clear(gl.COLOR_BUFFER_BIT);

    // Обновляем позиции снежинок
    for (let i = 0; i < numFlakes; i++) {
      const direction = i % 2 ? -1 : 1;
      const rnd = Math.random();
      const w = weights[i];
      positions[i * 2 + 1]
        += (Math.cos(time * w * 0.1 + 0.1 + rnd * 0.4) + 1) * SPEED + w * 0.3 * direction;
      positions[i * 2]
        += Math.sin((time + rnd * 20 + positions[i * 2 + 1]) / 30) * 0.8 * rnd * direction * SPEED
          + wind.value * w
          + mouseWind * Math.abs(w);
      if (positions[i * 2 + 1] > canvas.height * 1.1) {
        positions[i * 2 + 1] = -canvas.height * 0.1;
      }

      if (positions[i * 2] > canvas.width * 1.1) {
        positions[i * 2] = -canvas.width * 0.1;
      } else if (positions[i * 2] < -canvas.width * 0.1) {
        positions[i * 2] = canvas.width * 1.1;
      }
    }
    gl.bufferSubData(gl.ARRAY_BUFFER, 0, positions);

    wind.value += wind.step * wind.dir;
    if (wind.value > wind.max || wind.value < wind.min || Math.random() > 0.95) {
      wind.dir *= -1;
    }
    // Устанавливаем время
    gl.uniform1f(timeLocation, time);

    // Рисуем снежинки
    gl.drawArrays(gl.POINTS, 0, numFlakes);
  }

  let rendering = false;
  renderFrame();
  runWithFps(() => {
    if (rendering) {
      renderFrame();
    }
  }, 30);

  return {
    start() {
      rendering = true;
    },
    stop() {
      rendering = false;
    },
    release() {
      rendering = false;
    }
  };
}
