import { appConfig } from '@/shared/lib/config/app-config';
import { userPermissions } from '@/shared/lib/config/user-permissions';
import { trlMessage } from '@/shared/lib/i18n';
import tariffs from '@/shared/lib/tariffs';
import { DateTimeHelper } from '@/shared/lib/util/date-time-helper';
import { StorageHelper } from '@/shared/lib/util/storage-helper';

// через typeof, так как в chrome есть встроенный конструктор Permissions
const accountName = appConfig.get('account')?.name ?? '';
const ElixirChatWidget = window.ElixirChatWidget;

const elixir = appConfig.get('elixir');
const ELIXIR_DEFAULT_CONFIG = {
  apiUrl: elixir?.apiUrl,
  socketUrl: elixir?.socketUrl,
  backendStaticUrl: elixir?.backendStaticUrl,
  companyId: elixir?.company_id
};

let instance = null;

const region = (appConfig.get('instanceRegion') || '').toLocaleUpperCase();
const defaultRegion = (appConfig.get('defaultRegion') || '').toLocaleUpperCase();
const shouldShowRegion = region && region !== defaultRegion;
const title = shouldShowRegion
  ? `[${region}] ${userPermissions.get('name')}`
  : userPermissions.get('name');

export default function getOrCreateElixirInstance() {
  const access = tariffs.licenseInfo?.current_license ? 'ACTIVE' : 'BLOCKED';
  const isElixirEnabled = Boolean(elixir);
  if (isElixirEnabled === false) {
    return undefined;
  }
  if (instance) {
    return instance;
  }
  const room = {
    id: elixir?.room_id,
    title,
    data: {
      version: appConfig.get('version'),
      // wtf ?. не работает и отъебывает :(
      plan: tariffs.licenseInfo?.current_license
        ? tariffs.licenseInfo?.current_license?.tariff_group?.name
        : 'NONE',
      planInfo: getDateString() || '',
      access
    }
  };
  const client = {
    id: elixir?.client_id,
    fullName: accountName,
    locale: appConfig.get('lang').split('_')[0]
    // firstName: accountName.split(/\s+/)[0],
    // lastName: accountName.split(/\s+/)[1] || ''
  };
  StorageHelper.setJSON('elixirchat-config', elixir);
  instance = new ElixirChatWidget({
    ...ELIXIR_DEFAULT_CONFIG,
    room,
    client
  });

  instance.appendWidget({
    title: trlMessage('elixir_title'),
    container: createElixirMountPoint(),
    hideDefaultButton: true,
    supportEmail: appConfig.get('supportEmail'),
    chatSubtitle: 'Ответим в течение нескольких минут',
    enabledChannels: ['whatsapp', 'telegram', 'facebook', 'vkontakte', 'viber'],
    fonts: [
      {
        fontFamily: 'Raleway',
        fontWeight: 'normal'
      },
      {
        fontFamily: 'Raleway',
        fontWeight: 'bold'
      }
    ],

    iframeCSS: `
      @font-face {
        font-family: 'Raleway';
        src: url('${window.STATIC_URI}fonts/raleway-webfont.woff2') format('woff2');
        font-weight: 100 900;
        font-style: normal;
      }
      body, input, button, textarea {
        font-family: Raleway, "Helvetica Neue", sans-serif !important;
        font-weight: 430;
        font-variation-settings: 'wght' 430;
        font-variant-numeric: lining-nums;
      }
      .elixirchat-chat-messages__item--by-operator .elixirchat-chat-messages__sender,
      .elixirchat-chat-messages__item--by-another-client .elixirchat-chat-messages__sender {
        color: #22BBD2 !important; /* turquoise */
      }
      .elixirchat-welcome-screen__chat-button {
        background: #22BBD2 !important; /* turquoise */
      }
      .elixirchat-chat-messages__item--by-me .elixirchat-chat-messages__balloon {
        background: #5951BE !important; /* purple */
      }
    `

    /* iframeStyles: `
            @font-face {
                font-family: 'Raleway';
                src: url('${window.STATIC_URI}/fonts/raleway-regular-text-figures-webfont.woff2') format('woff2'),
                    url('${window.STATIC_URI}/fonts/raleway-regular-text-figures-webfont.woff') format('woff');
                font-weight: normal;
                font-style: normal;
            }

            @font-face {
                font-family: 'Raleway';
                src: url('${window.STATIC_URI}/fonts/raleway-bold-webfont.woff2') format('woff2'),
                    url('${window.STATIC_URI}/fonts/raleway-bold-webfont.woff') format('woff');
                font-weight: 500;
                font-style: normal;
            }

            body,
            input,
            button,
            textarea {
                font: 14px/18px "Raleway" !important;
            }
            .elixirchat-chat-header,
            .elixirchat-chat-messages__date-title,
            .elixirchat-chat-messages__sender,
            .elixirchat-chat-typing {
                font-family: "Raleway" !important;
                font-weight: 500 !important;
            }
            .elixirchat-chat-messages__sender {
                color: #5951BD !important;
            }
            .elixirchat-chat-messages__item--by-me .elixirchat-chat-messages__balloon {
                background-color: #5951BD !important;
            }
            .elixirchat-chat-messages__reply-button:hover,
            .elixirchat-chat-messages__item--by-operator .elixirchat-chat-messages__sender {
                color: #22BBD2 !important;
            }
            .elixirchat-chat-messages__take-screenshot {
                background-color: #22BBD2 !important;
            }
            .elixirchat-chat-messages__take-screenshot:hover {
                background-color: #1ea6ba !important;
            }

            .elixirchat-chat-messages__sender * {
                font-weight: normal;
            }

            .elixirchat-chat-messages__text b {
                font-family: "Raleway" !important;
                font-weight: 500 !important;
            }
        ` */
  });
  return instance;
}

function createElixirMountPoint() {
  const mountPoint = document.createElement('div');
  mountPoint.style.cssText = `
        height: 0px !important;
        width: 0px !important;
        position: fixed !important;
        top: 0px !important;
        left: 0px !important;
        z-index: 999999 !important;
    `;
  document.body.appendChild(mountPoint);
  return mountPoint;
}

function getTariffsDate(currentLicense) {
  if (currentLicense) {
    const tariffName = currentLicense.tariff_group?.name;
    const tariffEnd = DateTimeHelper.toShortFormat({
      date: currentLicense.scheduled_end_at
    });
    const tariffStart = DateTimeHelper.toShortFormat({
      date: currentLicense.scheduled_begin_at
    });
    const currentTariff = `${tariffName}\n${tariffStart} — ${tariffEnd}`;
    return currentTariff;
  }
  return '';
}
function getDateString() {
  if (tariffs.licenseInfo) {
    return `${
      tariffs.licenseInfo?.current_license
        ? ''
        : `${getTariffsDate(tariffs.licenseInfo?.previous_license)}`
    }${getTariffsDate(tariffs.licenseInfo?.current_license)}${
      tariffs.licenseInfo?.next_license
        ? `\n\n${getTariffsDate(tariffs.licenseInfo?.next_license)}`
        : ''
    }`;
  }
}
