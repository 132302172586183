import type { ObjectDirective } from 'vue';

export const transitionTrigger: ObjectDirective<HTMLElement> = {
  beforeMount(el, { value }, { transition }) {
    if (transition && value) {
      transition.beforeEnter(el);
    }
  },
  mounted(el, { value }, { transition }) {
    if (transition && value) {
      transition.enter(el);
    }
  },
  updated(el, { value, oldValue }, { transition }) {
    if (!value === !oldValue) {
      return;
    }
    if (transition && value) {
      transition.beforeEnter(el);
      transition.enter(el);
    }
  },
  beforeUpdate(el, { value, oldValue }, { transition }) {
    if (!value === !oldValue) {
      return;
    }
    if (transition && !value) {
      transition.leave(el, () => {});
    }
  }
};
