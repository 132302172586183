import type { Stop } from 'tweeen';

import tween from 'tweeen';

function lerp(a: number, b: number, t: number): number {
  return a + (b - a) * t;
}

type ScrollToCoords = {
  left?: number;
  top?: number;
};

type ScrollToOptions = {
  duration?: number;
  easing?: (t: number) => number;
  end?: () => void;
};

type StoppableElement = HTMLElement & { stop?: Stop };

function scrollTo(
  el: StoppableElement,
  { left = 0, top = 0 }: ScrollToCoords = {},
  options: ScrollToOptions = { easing: linear, duration: 300 }
): Stop {
  const startLeft = el.scrollLeft;
  const startTop = el.scrollTop;

  if (el.stop) {
    el.stop();
  }

  function handleUpdate(t: number) {
    el.scrollTo(lerp(startLeft, left, t), lerp(startTop, top, t));
  }

  const stop = tween(0, 1, handleUpdate, {
    duration: options.duration,
    easing: options.easing,
    end: () => {
      handleUpdate(1);
      delete el.stop;
      options.end?.();
    }
  });
  el.stop = stop;

  return stop;
}

// нагло спёр https://github.com/tweenjs/tween.js/blob/main/src/Easing.ts#L32
function easeOutCubic(amount: number): number {
  return --amount * amount * amount + 1;
}

function easeInOutCubic(x: number): number {
  return x < 0.5 ? 4 * x * x * x : 1 - (-2 * x + 2) ** 3 / 2;
}

function linear(amount: number): number {
  return amount;
}

function swingEasing(x: number): number {
  return 0.5 - Math.cos(x * Math.PI) / 2;
}

export const AnimateHelper = {
  linear,
  easeOutCubic,
  easeInOutCubic,
  swingEasing,
  scrollTo
};
