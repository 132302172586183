import type { AxiosClient } from './utils/client/axios';
import type { RejectionList } from '@/shared/types/rejection';

import { ApiLayer } from './utils/api-layer';
import { axiosApp } from './utils/client/axios';

class RejectionReasonsLayer extends ApiLayer<AxiosClient> {
  /** Обновление списка причин отказа (/settings/orgs/{orgId}/rejection-reasons) **/
  update({ rejections }: { rejections: RejectionList }) {
    return this.methods.post<RejectionList>('/rejection', rejections);
  }
}

export const RejectionReasonsApi = new RejectionReasonsLayer(axiosApp);
