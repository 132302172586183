import type { AxiosRequestConfig } from 'axios';

import type { AxiosClient } from '@/shared/api/utils/client/axios';

import { ApiLayer } from '@/shared/api/utils/api-layer';
import axios from '@/shared/api/utils/client/axios';
import { appConfig } from '@/shared/lib/config/app-config';
import { userPermissions } from '@/shared/lib/config/user-permissions';

const config: AxiosRequestConfig = {
  baseURL: appConfig.get('photoUrl'),
  withCredentials: true
};

type EntityId = number | string;

export enum EntityType {
  APPLICANT = 'applicant',
  APPLICANT_RESPONSE = 'applicant_response',
  USER = 'user'
}

type CropZone = {
  x1: number;
  x2: number;
  y1: number;
  y2: number;
};

type IPhotoFields = {
  is_main: boolean;
  cropzone: CropZone;
  /* некоторое допущение, глобально могут быть сущности другого типа */
  entity_id: EntityId;
};

type IUploadOptions = {
  thumbnailCreation?: 'none' | 'instant' | 'background';
  entityType?: EntityType;
};

class PhotoLayer extends ApiLayer<AxiosClient> {
  fetchEntityPhotos(entityId: EntityId, entityType: EntityType = EntityType.APPLICANT) {
    return this.methods.get(`/photo_service/${entityType}/${entityId}/photos`, config);
  }

  fetchPhoto(photoId: number) {
    return this.methods.get(`/photo_service/photos/${photoId}`, config);
  }

  uploadPhoto(
    entityId: EntityId,
    file: File,
    { thumbnailCreation = 'none', entityType = EntityType.APPLICANT }: IUploadOptions = {}
  ) {
    const form = new FormData();
    form.append('entity_type', entityType);
    if (entityId) {
      form.append('entity_id', String(entityId));
    }
    form.append('photo_file', file);
    if (entityType === EntityType.APPLICANT) {
      form.append('organization_id', String(userPermissions.get('id')));
    }
    form.append('thumbnail_creation', thumbnailCreation);
    return this.methods.post('/photo_service/photos/upload', form, config);
  }

  updatePhoto(photoId: number, data: Partial<IPhotoFields>) {
    return this.methods.patch(`/photo_service/photos/${photoId}`, data, config);
  }

  deletePhoto(photoId: number) {
    return this.methods.delete(`/photo_service/photos/${photoId}`, config);
  }

  setPhotoCrop(entityId: EntityId, photoId: number, cropZone: CropZone) {
    const payload = {
      ...(entityId ? { entity_id: entityId } : {}),
      crop_zone: cropZone
    };
    return this.updatePhoto(photoId, payload);
  }
}

export const PhotoAPI = new PhotoLayer(axios);
