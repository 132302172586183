import type { CancelablePromise } from './utils/cancelable-promise';
import type { AxiosClient } from './utils/client/axios';
import type { ItemList } from './utils/types';

import { ApiLayer } from './utils/api-layer';
import axios from './utils/client/axios';

type NewTag = {
  color: string;
  name: string;
};

type Tag = NewTag & {
  account: number;
  id: number;
};

class TagsLayer extends ApiLayer<AxiosClient> {
  create(tags: (Tag | NewTag)[]): CancelablePromise<ItemList<Tag>> {
    return this.methods.post<ItemList<Tag>>('/tag', tags);
  }
}

export const TagsAPI = new TagsLayer(axios);
