export enum EmailType {
  LOGIN = 'LOGIN_EMAIL',
  NOTIFICATION = 'NOTIFICATION_EMAIL'
}

// Интерфейс для отдельного email
export type Email = {
  email: string;
  verified: boolean;
  type: EmailType;
  sso?: boolean; // Необязательное поле для единого входа (SSO)
};
