<template>
  <popper
    ref="popper"
    trigger=""
    class="dropdown dropdown_next"
    tag-name="div"
    :container="container"
    :force-show="show"
    :class="{
      dropdown_open: show,
      dropdown_block: block
    }"
    :options="allOptions"
    :reference-node="() => $refs.reference"
    :content-node="() => $refs.dropdownMenu"
    :content-class="$style.content"
    @created="handleCreate"
    @destroyed="handleDestroy"
    @hide="hide"
  >
    <div
      ref="dropdownMenu"
      role="listbox"
      class="dropdown-menu dropdown-menu_next"
      :class="classNamesMenu"
      @click="handleClickInDropdown"
    >
      <slot v-if="needShow" :on-toggle="handleToggle" :show="show" name="menu" />
      <slot :need-show="needShow" :on-toggle="handleToggle" :show="show" name="persistentMenu" />
    </div>
    <template #reference>
      <div ref="reference">
        <div v-if="lazy" ref="buttonWrapper" @click="handleToggle">
          <slot />
        </div>
        <slot v-else :show="show" :need-show="needShow" :on-toggle="handleToggle" />
      </div>
    </template>
  </popper>
</template>

<script>
import groupBy from 'lodash/groupBy';
import { nanoid } from 'nanoid';

import Popper from './popper.vue';

// TODO: не корректно работает при использовании событий focus/focusin тоглинга выпадашки
export default {
  name: 'DropdownNext',
  components: {
    Popper
  },
  props: {
    id: {
      type: String,
      default() {
        return nanoid();
      }
    },
    container: {
      type: HTMLElement,
      default: undefined
    },
    withPadding: {
      type: [Boolean, String],
      default: false,
      validation(value) {
        return typeof value === 'boolean' || value === 'bigger';
      }
    },
    size: {
      default: 'large',
      validator(value) {
        return [
          'status',
          'big',
          'giant',
          'bigger',
          'medium',
          'large',
          'auto',
          'larger',
          'period',
          'titan'
        ].includes(value);
      }
    },
    // Флаг для использования в Backbone вьюхах
    lazy: {
      type: Boolean,
      default: false
    },
    preventOverflowOnMainAxis: {
      type: Boolean,
      default: undefined
    },
    preventOverflowOnAltAxis: {
      type: Boolean,
      default: undefined
    },
    block: Boolean,
    disabled: Boolean,
    shown: Boolean,
    placement: {
      type: String,
      default: 'bottom-start'
    },
    modifiers: {
      type: Array,
      default: () => []
    },
    skidding: {
      type: Number,
      default: 0
    },
    distance: {
      type: Number,
      default: 10
    },
    menuClass: {
      type: String,
      default: ''
    }
  },
  emits: ['show', 'state', 'hide'],
  data() {
    return {
      readyDropdown: false,
      show: false,
      unsubscribe: null
    };
  },
  computed: {
    needShow() {
      return this.show && (this.lazy ? this.readyDropdown : true);
    },
    classNamesMenu() {
      const result = {
        [this.$style.menu]: true,
        [`dropdown-menu_size_${this.size}`]: this.size,
        [this.menuClass]: true
      };
      // Только в KA
      if (typeof this.withPadding === 'string') {
        result[`dropdown-menu_padding_${this.withPadding}`] = true;
      } else if (this.withPadding) {
        result['dropdown-menu_padding'] = true;
      }

      return result;
    },
    allOptions() {
      const modifiers = [
        {
          name: 'offset',
          options: {
            offset: [this.skidding, this.distance]
          }
        },
        {
          name: 'flip'
        },
        {
          name: 'eventListeners',
          options: {
            scroll: false
          }
        }
      ];

      if (
        typeof this.preventOverflowOnMainAxis === 'boolean'
        || typeof this.preventOverflowOnAltAxis === 'boolean'
      ) {
        const mainAxis = this.preventOverflowOnMainAxis ?? true; // фолбек на дефолтные для popper.js значения
        const altAxis = this.preventOverflowOnAltAxis ?? false;

        modifiers.push({
          name: 'preventOverflow',
          options: {
            mainAxis,
            altAxis
          }
        });
      }

      modifiers.push(...this.modifiers);
      return {
        modifiers: Object.entries(groupBy(modifiers, 'name')).map((entry) => entry[1].pop()),
        placement: this.placement
      };
    }
  },
  watch: {
    shown(val) {
      val ? this.open() : this.hide();
    }
  },
  created() {
    // TODO: в каких-то кейсах стор теряется,
    // нужно более плотное изучение. Как хотфикс пойдёт
    this.$store = this.$store || window.STORE;
  },
  beforeUnmount() {
    this.removerObserver();
  },
  mounted() {
    this.addObserver();

    if (this.shown) {
      this.open();
    }
  },
  methods: {
    /**
     * Для старых дропдаунов
     * В старых дропдаунах для закрытия вкладывают кнопки с [data-toggle="dropdown"]
     * При нажатии они «тогглят» дропдаун, в который вложены, т.е. закрывают,
     * т.к. если ты можешь нажать на эту кнопку, дропдаун открыт.
     *
     * В следующем методе предпринята попытка повторить это поведение,
     * т.к. этот компонент может использоваться в качестве custom element
     * и при этом нужно иметь возможность закрывать дропдауны по кнопке внутри него
     *
     * Но. Нужно уметь отличать кейс, когда внутри dropdown-next вложен «старый» дропдаун
     * и по [data-toggle="dropdown"] он должен открыться, а не закрыться dropdown-next.
     * Для этого добавлено второе условие, которое проверяет, что следующий за кнопкой элемент —
     * dropdown-menu (они обычно так устроены, в элемент dropdown вложена кнопка и после нее меню).
     * Если так, нам не надо ничего не делать.
     */
    handleClickInDropdown(e) {
      if (
        e.target.dataset.toggle === 'dropdown'
        && this.$refs.dropdownMenu === e.target.closest('.dropdown-menu')
      ) {
        if (
          e.target.nextElementSibling
          && e.target.nextElementSibling.classList.contains('dropdown-menu')
        ) {
          return;
        }
        e.stopPropagation();
        this.handleToggle(e);
      }
    },
    addObserver() {
      if (!this.$refs.dropdownMenu || this.added) {
        return;
      }
      this.added = true;

      this.previous = {
        width: undefined,
        height: undefined
      };

      this.resizeObserver = new ResizeObserver((entries) => {
        if (!Array.isArray(entries)) {
          return;
        }

        // Since we only observe the one element, we don't need to loop over the
        // array
        if (!entries.length) {
          return;
        }

        const entry = entries[0];

        // `Math.round` is in line with how CSS resolves sub-pixel values
        const newWidth = Math.round(entry.contentRect.width);
        const newHeight = Math.round(entry.contentRect.height);
        if (this.previous.width !== newWidth || this.previous.height !== newHeight) {
          const newSize = { width: newWidth, height: newHeight };
          this.previous = newSize;
          if (this.previous.width !== 0 && this.previous.height !== 0) {
            this.$refs.popper.updatePopper();
          }
        }
      });

      this.resizeObserver.observe(this.$refs.dropdownMenu);
    },
    removerObserver() {
      if (!this.$refs.dropdownMenu || !this.added) {
        return;
      }
      this.added = false;
      this.resizeObserver.unobserve(this.$refs.dropdownMenu);
      this.previous = {
        width: undefined,
        height: undefined
      };
    },
    handleToggle() {
      if (this.disabled) {
        return;
      }
      this.toggle();
    },
    // Для bridge со старыми дропдаунами
    emitBootstrapDropdownEvent(open) {
      if (!this.lazy) {
        return;
      }

      const eventType = open ? 'show' : 'hide';
      const button = this.$refs.buttonWrapper.querySelector('button');

      let event;
      if (typeof Event === 'function') {
        event = new Event(eventType, {
          bubbles: true
        });
      } else {
        event = document.createEvent('Event');
        event.initEvent(eventType, true, true);
      }
      button.dispatchEvent(event);
    },
    handleCreate() {
      this.readyDropdown = true;

      // Потому что после отрисовки в DOM
      // в backbone мы можем найти div и в него вставить нужное
      this.$nextTick(() => {
        this.emitBootstrapDropdownEvent(true);
      });
    },
    handleDestroy() {
      this.readyDropdown = false;
    },
    toggle() {
      this.show ? this.hide() : this.open();
    },
    open() {
      this.show = true;
      this.$emit('show');
      this.$emit('state', this.show);
    },
    hide() {
      this.show = false;
      this.emitBootstrapDropdownEvent(false);
      this.$emit('hide');
      this.$emit('state', this.show);
    }
  }
};
</script>

<style module>
div.menu {
  position: initial;
}
.content {
  will-change: transform;
}
</style>

<i18n lang="json">{}</i18n>
