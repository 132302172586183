<template>
  <select-item
    :bordered="bordered"
    :active="active"
    :icon="selectIcon"
    :highlight="highlight"
    :selected="selected"
    :title="title"
    :text-gap-y="textGapY"
    :icon-gap-y="iconGapY"
    :min-height="minHeight"
    :deep="item.deep || 0"
    :use-gap="useGap"
    @click="$emit('click')"
  >
    <template v-if="item.id" #before-content>
      <div :class="$style.iconWrapper">
        <base-icon v-if="useCrown" type="crown" :class="$style.crown" />
        <base-userpic v-if="photo" :src="photo" :class="[$style.avatar, $style.defaultAvatar]" />
        <base-icon v-else type="circle-profile" :class="$style.avatar" />
      </div>
    </template>
    <template #default>
      <template v-if="isMe">
        {{ $i18n('Me') }},
      </template>
      <slot>
        <span v-html="caption" />
      </slot>
    </template>
    <template #hint>
      <span v-html="hint" />
    </template>
    <template #subtitle>
      <slot name="subtitle">
        <span :class="$style.email" v-html="email" />
      </slot>
    </template>
  </select-item>
</template>

<script>
import BaseIcon from '@/components/icon.vue';
import BaseUserpic from '@/components/userpic/userpic.vue';
import { PhotoHelper } from '@/modules/applicant-photo-editor/helper';

import { EntityType } from '@/shared/api/photo';
import { appConfig } from '@/shared/lib/config/app-config';

import SelectItem from './select-item.vue';

export default {
  name: 'ColleagueItem',
  components: { SelectItem, BaseUserpic, BaseIcon },
  props: {
    disabled: SelectItem.props.disabled,
    selectIcon: SelectItem.props.icon,
    iconWidth: {
      type: String,
      default: '22px'
    },
    iconHeight: {
      type: String,
      default: '22px'
    },
    active: SelectItem.props.active,
    bordered: SelectItem.props.bordered,
    highlight: SelectItem.props.highlight,
    selected: SelectItem.props.selected,
    textGapY: SelectItem.props.textGapY,
    minHeight: SelectItem.props.minHeight,
    iconGapY: SelectItem.props.iconGapY,
    useGap: SelectItem.props.useGap,
    item: {
      type: Object,
      required: true
    },
    hintField: {
      type: String,
      default: undefined
    },
    userIdField: {
      type: String,
      default: 'id'
    },
    useCrown: Boolean
  },
  emits: ['click'],
  computed: {
    caption() {
      return this.item.name_highlight || this.item.name || this.item[this.hintField];
    },
    hint() {
      if (this.item.name && this.item[this.hintField]) {
        return this.item[this.hintField];
      }
      return '';
    },
    title() {
      const { name, email } = this.item;
      return `${name} (${email})`;
    },
    email() {
      return this.item.email_highlight || this.item.email;
    },
    isMe() {
      return appConfig.get('account')?.id === this.item[this.userIdField];
    },
    photo() {
      if (this.item.has_photo) {
        return PhotoHelper.getPhotoUrl({
          entity: this.item,
          type: EntityType.USER
        });
      }
      return null;
    }
  }
};
</script>

<style module>
.crown {
  position: relative;
  top: 1px;
}

.email {
  overflow: hidden;
  text-overflow: ellipsis;
}

.avatar {
  width: v-bind(iconWidth);
  height: v-bind(iconHeight);
}

.defaultAvatar {
  border: 1px solid transparent;
}

.iconWrapper {
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  align-items: center;
  margin-right: 7px;
  margin-top: 2px;
}
</style>

<i18n lang="json">{}</i18n>
