import type { AxiosClient } from '@/shared/api/utils/client/axios';
import type { Delete } from '@/shared/api/utils/default-responses';
import type { PaginatedList, CursorList, CursorPaginationParams } from '@/shared/api/utils/types';
import type { Attendee } from '@/shared/types/attendee';
import type { VacancyRequest } from '@/shared/types/vacancy-request';
import type { VacancyRequestStatus } from '@/shared/types/vacancy-request-status';

import { ApiLayer } from '@/shared/api/utils/api-layer';
import { axiosApp } from '@/shared/api/utils/client/axios';

type VacancyRequestPayload = {
  account_vacancy_request: number;
  attendees: Attendee[];
  files: number[];
};

type VacancyRequestsFilter = {
  paginate_by_cursor: boolean;
  next_page_cursor?: string | null | undefined;
  page?: number;
  count?: number;
  values?: boolean;
};

type RequestPaginatedList = {
  total_items: number;
} & PaginatedList<VacancyRequest>;

type RequestState = {
  changed: string | null;
  email: string;
  id: number;
  order: number;
  reason: string | null;
  status: Uppercase<VacancyRequestStatus> | null;
  vacancy_request_id: number;
};

export type RecentRequest = {
  id: number;
  account_vacancy_request: number;
  account: number;
  vacancy: number | null;
  position: string;
  created: string;
  updated: string;
  changed: string | null;
  removed: string | null;
  states: RequestState[];
  status: VacancyRequestStatus;
  account_info: {
    id: number;
    name: string;
    email: string;
  };
  values: Record<string, unknown>;
  applicants_to_hire: number | null;
  already_hired: number | null;
  work_days_in_work: number | null;
  work_days_after_deadline: number | null;
  work_days_before_deadline: number | null;
  quota_created: number | null /* что это такое? */;
  deadline: string;
  deadline_field: string;
};

type RecentRequestCursorList = {
  count: number;
} & CursorList<RecentRequest>;

type PendingRequestCount = {
  total: number;
};

type PendingRequestCursorList = {
  next_decision_link: string;
} & RecentRequestCursorList;

function prepareVacancyRequest(request: VacancyRequest) {
  return {
    ...request,
    files: request.files || [],
    attendees: request.states || []
  };
}

class VacancyRequestLayer extends ApiLayer<AxiosClient> {
  getList(params: VacancyRequestsFilter = { paginate_by_cursor: true }) {
    return this.methods
      .get<RequestPaginatedList>('/vacancy_request', { params })
      .then(({ items, ...other }) => ({
        ...other,
        items: items.map(prepareVacancyRequest)
      }));
  }

  getById(id: number) {
    return this.methods.get<VacancyRequest>(`/vacancy_request/${id}`).then(prepareVacancyRequest);
  }

  update(id: number, request: VacancyRequestPayload) {
    return this.methods
      .put<VacancyRequest>(`/vacancy_request/${id}`, request)
      .then(prepareVacancyRequest);
  }

  create(request: VacancyRequestPayload) {
    return this.methods
      .post<VacancyRequest>('/vacancy_request', request)
      .then(prepareVacancyRequest);
  }

  decline(id: number, reason: string) {
    return this.methods
      .patch<VacancyRequest>(`/vacancy_request/${id}`, {
        reason,
        removed: true
      })
      .then(prepareVacancyRequest);
  }

  remove(id: number) {
    return this.methods.delete<Delete>(`/vacancy_request/${id}`);
  }

  hasRecentRequests(schemaId: number) {
    const url = `/account/vacancy_request/${schemaId}/requests/exist`;
    return this.methods
      .get<{ entries_exist: boolean }>(url)
      .then((response) => response.entries_exist);
  }

  fetchRecentRequests(
    schemaId: number,
    paging: { next_page_cursor: CursorPaginationParams['next_page_cursor'] }
  ) {
    const paginationParams: CursorPaginationParams = {
      paginate: true,
      paginate_by_cursor: true,
      next_page_cursor: paging.next_page_cursor,
      count: 10
    };

    const url = `/account/vacancy_request/${schemaId}/requests`;
    return this.methods.get<RecentRequestCursorList>(url, {
      params: {
        ...paginationParams
      }
    });
  }

  getPendingRequestsCount() {
    return this.methods.get<PendingRequestCount>('/vacancy_request/pending/count');
  }

  getPendingRequests({ cursor }: { cursor: CursorPaginationParams['next_page_cursor'] }) {
    return this.methods.get<PendingRequestCursorList>('/vacancy_request/pending', {
      params: {
        next_page_cursor: cursor
      }
    });
  }

  sendRecruitmentPlan() {
    return this.methods.post<{ total_items_count: number }>('/vacancy_request/xlsx');
  }
}
// TODO: переделать на именованный экспорт, в соответствии с README
export default new VacancyRequestLayer(axiosApp);
