export const States = {
  OPEN: 'OPEN',
  HOLD: 'HOLD',
  CLOSED: 'CLOSED'
};

// convert string to searchable form
export const stringToIndex = (str) => {
  const s = str || '';
  return s
    .toLowerCase()
    .replace(/"|'|«|»|’|‘/g, '')
    .split(/[\\\-\s~()/_]+/)
    .filter(Boolean);
};

export const listToIndex = (...args) => {
  let result = [];
  args.filter(Boolean).forEach((item) => {
    result = result.concat(stringToIndex(item));
  });

  return result;
};
