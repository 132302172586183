<template>
  <div>
    <base-dropdown>
      <template #default="{ toggle, shown }">
        <slot name="button" :on-toggle="toggle" :show="shown" />
      </template>

      <template #content="{ hide }">
        <dropdown-menu-layout :class="$style.dropdown">
          <template #main>
            <div>
              <span
                v-for="color in $options.colors"
                :key="color.value"
                :class="$style.color"
                :style="{ backgroundColor: `#${color.value}` }"
                @click="handleSelectColor(color, hide)"
              /><span :class="$style.clearColor" @click="handleClearColor(hide)" />
            </div>
          </template>
        </dropdown-menu-layout>
      </template>
    </base-dropdown>
  </div>
</template>

<script>
import DropdownMenuLayout from '@/components/dropdown-menu-layout/dropdown-menu-layout.vue';

import BaseDropdown from '@/shared/ui/base-dropdown/base-dropdown.vue';

const COLORS = [
  { value: 'ea0019' },
  { value: 'eb3700' },
  { value: 'e37b00' },
  { value: 'd7b100' },
  { value: '80a000' },
  { value: '00ad3b' },
  { value: '00cba2' },
  { value: '00a6c8' },
  { value: '002ea3' },
  { value: '36517b' },
  { value: '814aa8' },
  { value: '5c2a55' },
  { value: '666666' },
  { value: '000000' },
  { value: '999999' }
];

export default {
  name: 'TextColor',
  colors: COLORS,
  components: {
    BaseDropdown,
    DropdownMenuLayout
  },
  emits: ['change'],
  methods: {
    handleSelectColor(color, hideColorDropdown) {
      const { value } = color;
      this.$emit('change', `#${value}`);
      hideColorDropdown();
    },
    handleClearColor(hideColorDropdown) {
      this.$emit('change', '');
      hideColorDropdown();
    }
  }
};
</script>

<style module>
.dropdown {
  width: 180px;
}

.color,
.clearColor {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 6px;
  cursor: pointer;
  transition-property: transform;
  transition-duration: 200ms;

  &:hover {
    transform: scale(1.2);
  }
}

.clearColor {
  box-shadow: 0px 0px 0px 1px $borderColor;
}

.clearColor::before {
  background-color: #f56c6c;
  bottom: 0;
  content: '';
  left: 50%;
  position: absolute;
  top: 0;
  width: 2px;
  transform: rotate(45deg);
}
</style>

<i18n lang="json">{}</i18n>
