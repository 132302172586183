import { JSON_SCHEMA_TYPE } from '../../vjsf.ts';
import * as LegacyShemaUtil from '../legacy-schema-utils';
import { always, cond } from '../utils';

const mapping = [
  [LegacyShemaUtil.isInteger, always(JSON_SCHEMA_TYPE.NUMBER)],
  [LegacyShemaUtil.isDictionaryLike, always(JSON_SCHEMA_TYPE.NUMBER)],
  [LegacyShemaUtil.isRoot, always(JSON_SCHEMA_TYPE.OBJECT)],
  [LegacyShemaUtil.isComplexLike, always(JSON_SCHEMA_TYPE.OBJECT)],
  [always(true), always(JSON_SCHEMA_TYPE.STRING)]
];

export function getSchemaType(field) {
  const ctor = cond(mapping)(field);
  return ctor();
}
