<template>
  <div :class="editorClass">
    <slot name="menubar" :editor="editor" :focused="focused" />
    <slot name="extension" :editor="editor" :focused="focused" />
    <div :class="$style.contentWrapper">
      <editor-content :editor="editor" :class="$style.editorContent" />
      <slot name="after-content" />
    </div>
    <slot name="after" />
  </div>
</template>

<script>
import { Dropcursor } from '@tiptap/extension-dropcursor';
import { Gapcursor } from '@tiptap/extension-gapcursor';
import { Placeholder } from '@tiptap/extension-placeholder';
import { TextSelection } from '@tiptap/pm/state';
import { Editor, EditorContent } from '@tiptap/vue-3';

import { Submit } from './extensions/submit';

export default {
  name: 'BaseWysiwyg',
  components: {
    EditorContent
  },
  props: {
    shouldFocusOnMount: {
      type: Boolean,
      default: false
    },
    content: {
      type: String,
      default: ''
    },
    invalid: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    extensions: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      default: ''
    },
    fixed: Boolean,
    transparent: Boolean
  },
  emits: ['update', 'focus', 'blur', 'submit'],
  data() {
    return {
      editor: null,
      focused: false
    };
  },
  computed: {
    editorClass() {
      return {
        [this.$style.editor]: true,
        [this.$style.fixed]: this.fixed,
        [this.$style.transparent]: this.transparent,
        [this.$style.focused]: this.focused,
        [this.$style.disabled]: this.disabled,
        [this.$style.invalid]: this.invalid
      };
    },
    editorAttributes() {
      return {
        name: this.name,
        'aria-invalid': this.invalid ? 'true' : 'false'
      };
    },
    editorProps() {
      return {
        editable: !this.disabled,
        editorProps: {
          attributes: this.editorAttributes
        }
      };
    }
  },
  watch: {
    disabled(isDisabled) {
      this.editor.options.editable = !isDisabled;
    },
    editorProps(props) {
      this.editor.setOptions(props);
    },
    content(value) {
      const isSame = this.getHtml() === value;

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(fixContent(value), false);
    },
    focused(flag) {
      flag ? this.$emit('focus') : this.$emit('blur');
    },
    extensions: {
      immediate: true,
      handler() {
        this.createEditor();
      }
    }
  },
  beforeUnmount() {
    this.editor.destroy();
  },
  methods: {
    getEditor() {
      return this.editor;
    },
    getHtml() {
      return fixGetHtml(this.editor.getHTML());
    },
    createEditor() {
      this.editor?.destroy();
      this.editor = new Editor({
        ...this.editorProps,
        autofocus: this.shouldFocusOnMount,
        extensions: [
          ...this.extensions,
          Dropcursor,
          Gapcursor,
          Placeholder.configure({
            placeholder: this.placeholder
          }),
          Submit.configure({
            onSubmit: () => {
              this.$emit('submit');
            }
          })
        ],
        content: fixContent(this.content),
        onUpdate: () => {
          const html = this.getHtml();
          this.$emit('update', html);
        },
        onFocus: () => {
          this.focused = true;
        },
        onBlur: () => {
          this.focused = false;
        }
      });
    },
    focus() {
      this.editor.commands.focus();
    },
    insertText(text) {
      // вставляет текст в текущую позицию, для использования снаружи
      // (нужно для функционала "вставить переменную")
      const { selection } = this.editor.state;
      let shouldAddSpaceBefore = false;
      let shouldAddSpaceAfter = false;
      if (selection instanceof TextSelection && selection.empty) {
        const isSpace = (char) => /\s/.test(char);
        const { nodeBefore, nodeAfter } = selection.$head;
        shouldAddSpaceBefore
          = nodeBefore?.isText && !isSpace(nodeBefore.text[nodeBefore.nodeSize - 1]);
        shouldAddSpaceAfter = nodeAfter?.isText && !isSpace(nodeAfter.text[0]);
      }
      const trimmedText = (text ?? '').trim();
      const textBefore = shouldAddSpaceBefore ? ' ' : '';
      const textAfter = shouldAddSpaceAfter ? ' ' : '';
      this.editor.chain().focus().insertContent(`${textBefore}${trimmedText}${textAfter}`).run();
    }
  }
};

/*
 * сейчас есть проблема с ресайзом таблиц
 * https://github.com/ueberdosis/tiptap/issues/721
 * https://discuss.prosemirror.net/t/when-using-the-domserializer-table-does-not-include-colgroup-cols-for-pixel-widths/2167
 */

function fixContent(content) {
  const tempNode = document.createElement('div');
  tempNode.innerHTML = content;

  // удаляем лишние переносы - их добавит сам редактор
  tempNode.querySelectorAll('p').forEach((node) => {
    if (node.childElementCount === 1 && node.firstChild instanceof HTMLBRElement) {
      node.removeChild(node.firstChild);
    }
  });

  // удалить как появится поддержка на бэке (? хотя может это лучше дропнуть с бэка..)
  tempNode.querySelectorAll('blockquote').forEach((node) => {
    node.removeAttribute('style');
  });

  return tempNode.innerHTML;
}

function fixGetHtml(html) {
  const tempNode = document.createElement('div');
  tempNode.innerHTML = html;
  tempNode.querySelectorAll('td').forEach((node) => {
    if (!node.hasAttribute('colwidth')) {
      return;
    }
    const width = node.getAttribute('colwidth');
    const colwidth = Number.parseInt(width);
    node.setAttribute('width', colwidth);
  });

  const paragraphs = tempNode.querySelectorAll('p');
  if (paragraphs.length === 1 && !paragraphs[0].firstChild) {
    // пустой визивиг, нужно для того,
    // чтобы при выводе результата не отображался пустой параграф с отступами
    return '';
  }

  // для одинкавого отображения результата и содержимого редактора
  // (в редакторе в пустые параграфы вставляется перенос)
  paragraphs.forEach((paragraphNode) => {
    if (paragraphNode.firstChild || paragraphNode.parentNode.localName === 'li') {
      return;
    }
    paragraphNode.appendChild(document.createElement('br'));
  });

  // для отображения бордера таблиц в письме (ибо там доступны только inline-стили)
  tempNode.querySelectorAll('blockquote').forEach((blockquoteNode) => {
    const color = blockquoteNode.style.color;
    if (!color) {
      return;
    }
    blockquoteNode.querySelectorAll('td').forEach((tdNode) => {
      tdNode.style.borderColor = color;
    });
  });

  // убираем параграфы из элементов списка (они создают проблему в верстке офферов)
  tempNode.querySelectorAll('li > p:first-child').forEach((paragraphNode) => {
    if (paragraphNode.nextSibling) {
      return;
    }
    const liNode = paragraphNode.parentNode;
    while (paragraphNode.firstChild) {
      liNode.appendChild(paragraphNode.firstChild);
    }
    liNode.removeChild(paragraphNode);
  });

  return tempNode.childNodes.length > 1 ? tempNode.outerHTML : tempNode.innerHTML;
}
</script>

<style module>
.editor {
  background-color: $white;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 208px;
}

.fixed {
  min-height: 100%;
}
.transparent {
  background-color: transparent;
}

.contentWrapper {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
}
.editorContent {
  display: flex;
  flex-direction: column;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  flex: 1 0 0;
}
.fixed .contentWrapper {
  overflow: auto;
}

.disabled {
  opacity: 0.3;
  pointer-events: none;
}

.editor :global(.ProseMirror-gapcursor)::after {
  border: none;
}

.editor :global(.ProseMirror-hideselection *) {
  caret-color: initial;
}

.editor :global(.ProseMirror) {
  flex-grow: 1;
  overflow: auto;
  padding: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  height: 100%;
}

:global(.form-group_invalid) .editor, /* простите пожалуйста (уйдет после выброса старых форм) */
.editor.invalid {
  border-color: $errorColor;
}

.focused {
  border-color: var(--wysiwyg-border-focus-color, var(--content-brand-blue, #25cfe8));
}

.editorContent * {
  caret-color: currentColor;
  outline: none;
}

.editorContent p {
  margin: 4px 0 0 0;
}

.editorContent ol,
.editorContent ul {
  margin-top: 0;
  padding-left: 1.5rem;
}

.editorContent li > ol,
.editorContent li > p,
.editorContent li > ul {
  margin: 0;
}

.editorContent :global(.ProseMirror) > p:first-child {
  margin-top: 0;
}

.editorContent :global(.ProseMirror) > p:last-child {
  margin-bottom: 0;
}

.editorContent blockquote {
  margin: 10px 0;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 0.8rem;
  color: #677478;
}

.editorContent blockquote td {
  border-color: #677478 !important;
}

.editorContent a {
  color: $blueColor;
}

.editorContent img {
  max-width: unset;
  border-radius: 3px;
}

.editorContent table {
  border-collapse: collapse;
  table-layout: fixed;
  margin: 0;
  overflow: hidden;
}

.editorContent table td,
.editorContent table th {
  min-width: 1em;
  border: 1px dotted #d0d0d0;
  box-sizing: border-box;
  position: relative;
}

.editorContent table th {
  font-weight: 500;
  text-align: left;
}

.editorContent table :global(.selectedCell):after {
  z-index: 2;
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(200, 200, 255, 0.4);
  pointer-events: none;
}

.editorContent table :global(.column-resize-handle) {
  position: absolute;
  right: -2px;
  top: 0;
  bottom: 0;
  width: 4px;
  z-index: 20;
  background-color: #adf;
  pointer-events: none;
}

.editorContent :global(.tableWrapper) {
  overflow-x: auto;
}

.editorContent :global(.resize-cursor) {
  cursor: ew-resize;
  cursor: col-resize;
}

.editorContent :global(.is-editor-empty:first-child::before) {
  opacity: 0.5;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.fadeActive {
  transition: opacity 100ms ease-out;
}
.fadeFrom {
  opacity: 0;
}
</style>

<i18n lang="json">{}</i18n>
