<template>
  <div :class="$style.root">
    <a :href="href" :class="$style.link" data-qa="back_to_work">
      <base-icon v-if="useIcon" type="arrow-left" />
      <span :class="$style.text"><slot /></span>
    </a>
  </div>
</template>

<script>
import BaseIcon from '@/components/icon.vue';

export default {
  name: 'BackLink',
  components: {
    BaseIcon
  },
  props: {
    href: {
      type: String,
      required: true
    },
    useIcon: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style module>
.root {
  height: var(--header-height);
  display: flex;
  align-items: center;
}

.link {
  font-size: 16px;
  letter-spacing: 0.001em;
  font-weight: 500;
  font-style: normal;
  position: relative;
  display: flex;
  gap: 4px;

  color: #fff;

  text-decoration: none;
  width: 100%;
  cursor: pointer;
}

.link:visited {
  color: #fff;
}

.text {
  position: relative;
  display: block;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    border-bottom: solid 1px $white-30;
    transition: border 0.1s linear;
  }
}

.link:hover .text::before {
  border-bottom-color: $white-50;
}
</style>

<i18n lang="json">{}</i18n>
