import cloneDeep from 'lodash/cloneDeep';

import { WIDGET_TYPE } from '../vjsf';

// Возможные сценарии:
//
// 1. Aрхивированное поле пустое
//  - при создании заявки скрываем
//  - при редактировании заявки скрываем
//
// 2. Aрхивированное поле НЕ пустое
//  - при создании заявки скрываем (поле заполненно каким-то начальным значением), флаг force
//  - при редактировании заявки отображаем
//
// Важно:
// поле скрывается, но не удаляется, чтобы не потерять значение
//
// Ограничения:
// Для упрощения обрабатываются поля только на верхнем уровне,
// т.к. архивирование - тема из конструктора, а там только поля верхнего уровня
// (в противном случае нужно будет обходить структуры вглубину)

export function hideArchivedFields({ schema, uiSchema, formData }, { force = false } = {}) {
  const resultUiSchema = cloneDeep(uiSchema);

  // все архивные поля
  const archivedFieldNames = Object.entries(schema.properties).reduce((acc, [name, field]) => {
    if (field.metadata?.is_archived) {
      acc.push(name);
    }
    return acc;
  }, []);

  const isEmpty = (name) => formData[name] === undefined || formData[name] === null;
  const fieldNamesToHide = force ? archivedFieldNames : archivedFieldNames.filter(isEmpty);

  fieldNamesToHide.forEach((fieldName) => {
    if (resultUiSchema[fieldName]) {
      resultUiSchema[fieldName]['ui:widget'] = WIDGET_TYPE.HIDDEN;
    }
  });

  return resultUiSchema;
}
