import type { AxiosClient } from '@/shared/api/utils/client/axios';
import type { Job, PaginatedList } from '@/shared/api/utils/types';
import type { MemberType } from '@/shared/types/member-type';
import type { EditableUserFields, FullUser, UserLegacy, FullUserLegacy } from '@/shared/types/user';

import { enrichedColleague } from '@/shared/api/colleague';
import { ApiLayer } from '@/shared/api/utils/api-layer';
import axios from '@/shared/api/utils/client/axios';
import { waitForJob } from '@/shared/lib/wait-for-job/wait-for-job';

type CoworkersFilter<FetchPermissions extends boolean> = {
  page?: number;
  count?: number;
  account_division?: number[];
  head_id?: number;
  query?: string;
  member_type: MemberType | MemberType[];
  include_rejected?: boolean;
  include_invites?: boolean;
  show_watchers?: boolean;
  fetch_permissions?: FetchPermissions; // false by default
  vacancy?: number;
};

class CoworkerLayer extends ApiLayer<AxiosClient> {
  constructor(client: AxiosClient) {
    super(client);

    this.getList = this.memoizeMethod(this.getList);
  }

  getList<FetchPermissions extends boolean = false>(params: CoworkersFilter<FetchPermissions>) {
    return this.methods.get<
      PaginatedList<FetchPermissions extends true ? FullUserLegacy : UserLegacy> & {
        total_items: number;
      }
    >('/settings/coworker', {
      params
    });
  }

  // коворкеры страный предмет, не передаёшь member_type получаешь всё и без пагинации
  getAllList<FetchPermissions extends boolean = false>({
    member_type,
    ...params
  }: Omit<CoworkersFilter<FetchPermissions>, 'page' | 'count'>) {
    const memberTypes = Array.isArray(member_type) ? member_type : [member_type];
    return this.getList({
      ...params,
      member_type: [],
      show_watchers: true
    }).then(({ items }) => {
      return items.filter((coworker) => {
        return memberTypes.includes(coworker.member_type);
      });
    });
  }

  getById(id: number) {
    return this.methods.get<FullUser>(`/settings/coworker/${id}`);
  }

  update(id: number, user: EditableUserFields) {
    return this.methods
      .put<Job>(`/settings/coworker/${id}`, user)
      .then(({ job }): Promise<FullUser> => waitForJob(job))
      .then(enrichedColleague);
  }

  remove(id: number, params: { new_head_id?: number } = {}) {
    return this.methods.delete(`/settings/coworker/${id}`, { params });
  }

  fetchByVacancyId(vacancyId: number) {
    return this.methods
      .get<{ items: Pick<UserLegacy, 'id' | 'name' | 'email'> }>(`/vacancy/${vacancyId}/access`)
      .then(({ items }) => items);
  }
}
export const CoworkerAPI = new CoworkerLayer(axios);
