import type { IAppConfig } from '@/shared/types/app-config';

class AppConfig {
  #config: IAppConfig;

  constructor(config: IAppConfig) {
    this.#config = config;
  }

  get<T extends keyof IAppConfig>(configKey: T) {
    return this.#config[configKey];
  }

  set<T extends keyof IAppConfig>(configKey: T, value: IAppConfig[T]): void {
    this.#config[configKey] = value;
  }

  get isAuthorized(): boolean {
    return !!this.#config?.account;
  }

  get isAutoPhoneformatEnabled() {
    return !!this.get('phoneCountryCode');
  }

  get cloudRegionHost() {
    const region = this.get('instanceRegion');
    return `https://huntflow.${region.toLowerCase()}`;
  }

  get blogAvailable() {
    return this.get('instanceRegion') !== 'AI';
  }
}

export const appConfig = new AppConfig({
  ...window.Config,
  defaultCurrencyCode: window.DefaultCurrencyCode || window.Config?.defaultCurrencyCode
});
