import type { CallCallback } from './call/base-call';
import type { CallSettings } from '@/shared/types/call-settings';

import tariffs from '@/shared/lib/tariffs';

import { createIntegration } from './integration/integration-factory';

export { CallState } from './call/base-call';

const integrationData = window.TelephonyIntegrations?.[0];

class Telephony {
  #integrationInstance: ReturnType<typeof createIntegration> | Promise<undefined>;

  constructor() {
    this.#integrationInstance = integrationData
      ? createIntegration(integrationData)
      : Promise.resolve(undefined);
  }

  async call(settings: CallSettings, onStateUpdate: CallCallback) {
    const integration = await this.#integrationInstance;
    if (!integration) {
      throw new Error('telephony not available');
    }
    return integration.call(settings, onStateUpdate);
  }

  get integrationType() {
    return integrationData?.integration;
  }

  get isIntegrationExists() {
    return !!integrationData;
  }

  get isAvailable() {
    return this.isIntegrationExists || tariffs.isDemo;
  }
}

export const telephony = new Telephony();
