import type { AxiosClient } from './utils/client/axios';

import { appConfig } from '@/shared/lib/config/app-config';
import { config } from '@/shared/lib/config/config';
import tariffs from '@/shared/lib/tariffs';

import { ApiLayer } from './utils/api-layer';
import { CancelablePromise } from './utils/cancelable-promise';
import axios from './utils/client/axios';

type BaseEventPayload = {
  duration?: number;
  feature_type?: string;
};

type EventsPayload = {
  sidebar_change_member: BaseEventPayload;
  doubles_comparison_count: {
    count: number;
  } & BaseEventPayload;
  doubles_merge_from_non_duplicates: BaseEventPayload;
  doubles_usage_time: {
    duration: number;
    mode: 'merge' | 'diff';
  } & BaseEventPayload;
  doubles_personal_notes_compare_again: BaseEventPayload;
  doubles_personal_notes_cancel_merge: BaseEventPayload;
  tariffbased_feature_click: BaseEventPayload & {
    feature_type:
      | 'zhb_icons_sms'
      | 'zhb_icons_form'
      | 'zhb_icons_feedback'
      | 'letter_delayed'
      | 'checkbox_salary'
      | 'letter_track'
      | 'action_videomeeting'
      | 'action_busy'
      | 'vacancy_deadline'
      | 'vacancy_visible'
      | 'vacancy_priority'
      | 'vacancy_steps'
      | 'vacancy_offer_template'
      | 'recruiters_team_merge'
      | 'unit_access'
      | 'settings_nav_access'
      | 'mail_template_followup'
      | 'career_site_qr'
      | 'recruiters_filter'
      | 'analytics_report';
  };
  photo_add: {
    entry_point: 'create_applicant_form' | 'edit_applicant_form' | 'applicant_card';
  };
  photo_open: {
    entry_point: 'edit_applicant_form' | 'applicant_card';
  };
};

class AnalyticsLayer extends ApiLayer<AxiosClient> {
  send<Key extends keyof EventsPayload>(eventName: Key, payload: EventsPayload[Key]) {
    if (!appConfig.get('enable_analytics')) {
      return CancelablePromise.resolve(undefined);
    }
    const properties = payload ?? {};
    return this.methods.post(
      `/analytics/event/${eventName}`,
      {
        distinct_id: config.userPermissions.accountMemberId,
        properties: {
          org_id: config.userPermissions.id,
          org_name: config.userPermissions.get('name'),
          org_tariff: tariffs.licenseInfo.current_license?.tariff_group.name,
          user_role: config.userPermissions.get('member_type'),
          user_id: config.appConfig.get('account')?.id,
          ...properties
        }
      },
      {
        baseURL: ''
      }
    );
  }

  timer() {
    const start = performance.now();
    return function end(): number {
      return Math.round(performance.now() - start) / 1000;
    };
  }
}

export const AnalyticsAPI = new AnalyticsLayer(axios);
