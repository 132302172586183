import type { AxiosClient } from '@/shared/api/utils/client/axios';

import { ApiLayer } from '@/shared/api/utils/api-layer';
import axios from '@/shared/api/utils/client/axios';

type Tag = 'followups' | 'email_tracking' | 'scheduled_emails' | 'email_stats';

class ActiveCompaign extends ApiLayer<AxiosClient> {
  send(tag: Tag) {
    return this.methods.post(`/promo/tracking/${tag}`);
  }
}
// TODO: переделать на именованный экспорт, в соответствии с README
export default new ActiveCompaign(axios);
