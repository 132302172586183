import cloneDeep from 'lodash/cloneDeep';

import { userPermissions } from '@/shared/lib/config/user-permissions';
import { JsonHelper } from '@/shared/lib/util/json-helper';
import WebsocketChannel from '@/shared/lib/websocket-channel/websocket-channel';

type Unsubscribe = () => void;
type Callback = (message: any) => unknown;

export class WSNotifier {
  #wsProtocol = 'wss';
  #wsUrl?: URL = undefined;
  #wsChannel?: WebsocketChannel = undefined;
  #callbacks: Callback[] = [];

  constructor() {
    const { host, protocol } = window.location;
    if (!protocol.startsWith('https')) {
      this.#wsProtocol = 'ws';
    }
    if (userPermissions.accountMemberId) {
      this.#wsUrl = new URL(
        `notifier_sse/${userPermissions.accountMemberId}/listen`,
        `${this.#wsProtocol}://${host}`
      );
    }
    this.#connect();
  }

  #connect() {
    if (!this.#wsUrl) {
      return false;
    }
    if (this.#wsChannel) {
      return true;
    }
    const wsChannel = new WebsocketChannel({
      url: this.#wsUrl.toString()
    });
    wsChannel.emitter.on('open', (evt) => {
      console.log('ws open', evt);
    });
    wsChannel.emitter.on('close', (evt) => {
      console.log('ws close', evt);
    });
    wsChannel.emitter.on('error', (evt) => {
      console.log('ws error', evt);
    });
    wsChannel.emitter.on('message', (evt) => {
      const data = JsonHelper.safeJsonParse(evt.data, {});
      console.log('ws message', evt, data);
      this.#callbacks.forEach((cb) => {
        try {
          cb(cloneDeep(data));
        } catch (e) {
          console.error(e);
        }
      });
    });
    this.#wsChannel = wsChannel;
    return true;
  }

  public onMessage(cb: Callback): Unsubscribe {
    this.#callbacks.push(cb);
    return () => {
      const index = this.#callbacks.indexOf(cb);
      this.#callbacks.splice(index, 1);
    };
  }
}
