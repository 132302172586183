import { DictionaryAPI } from '@/shared/api/dictionaries';
import { listToIndex } from '@/shared/lib/util/search';

import { DictionaryHelpers } from './dictionary-helpers';
import { Store } from './store';

const ancestorsInfoProp = Symbol('ancestorsInfo');

function getHashForDictionary(name) {
  const hashes = Store.getHashesMap();
  return hashes.get(name);
}

function setHashForDictionary(name, hash) {
  const oldHash = getHashForDictionary(name);
  if (oldHash === hash) {
    return false;
  }
  invalidateDictionary(name);
  cancelDictionaryLoading(name);

  const hashes = Store.getHashesMap();
  hashes.set(name, hash);
  return true;
}

function loadDictionary(name, hash = getHashForDictionary(name)) {
  const loadings = Store.getLoadingsMap();
  const loadingPromise = loadings.get(name);
  if (loadingPromise) {
    return loadingPromise;
  }

  const dictionary = getDictionary(name);
  if (dictionary) {
    return Promise.resolve(dictionary);
  }

  const newLoadingPromise = DictionaryAPI.getDictionary(name, hash);
  loadings.set(name, newLoadingPromise);
  newLoadingPromise.finally(() => loadings.delete(name));
  return newLoadingPromise.then((dictionary) => {
    const ancestorsInfo = DictionaryHelpers.buildAncestorsInfo(dictionary.fields, name);
    dictionary.fields.forEach((field, fieldIndex, arr) => {
      const ancestors = ancestorsInfo[fieldIndex];
      const searchIndex = listToIndex(
        field.name,
        [...ancestors, fieldIndex]
          .reverse()
          .map((index) => arr[index].name)
          .join(' '),
        field.meta?.subTitle
      );
      field[ancestorsInfoProp] = ancestors;
      field._index = searchIndex;
    });

    cacheDictionary(dictionary, name);
    return dictionary;
  });
}

function getDictionary(name) {
  const dictionaries = Store.getDictionariesMap();
  return dictionaries.get(name);
}

function cacheDictionary(dictionary, name) {
  const dictionaries = Store.getDictionariesMap();
  if (dictionaries.has(name)) {
    return false;
  }

  invalidateDictionary(name);
  dictionaries.set(name, dictionary);
  return true;
}

function invalidateDictionary(name) {
  const dictionaries = Store.getDictionariesMap();
  if (dictionaries.has(name)) {
    dictionaries.delete(name);
    return true;
  }
  return false;
}

function cancelDictionaryLoading(name) {
  const loadings = Store.getLoadingsMap();
  if (loadings.has(name)) {
    loadings.get(name).cancel?.();
    loadings.delete(name);
    return true;
  }
  return false;
}

export const DictionaryStore = {
  setHashForDictionary,
  cacheDictionary,
  loadDictionary,
  getDictionary,
  ancestorsInfoProp
};
