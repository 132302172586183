<template>
  <resource-action
    :tag="tag"
    :promise="promise"
    @success="handleSuccess"
    @error="$emit('error', $event)"
  >
    <template #default="{ loading }">
      <slot
        :items="items"
        :cursor="cursor"
        :loading="loading"
        :all-loaded="allLoaded"
        :can-load-more="!(loading || allLoaded)"
        :next="loading ? loop : next"
        :reset="reset"
        :prepend="prepend"
        :remove="remove"
        :insert="insert"
      />
    </template>
  </resource-action>
</template>

<script>
import ResourceAction from '@/components/resource-action/resource-action.vue';

const getFirstPageCursor = ({ paginateByCursor }) =>
  paginateByCursor ? { next_page_cursor: undefined } : { page: 1 };

export default {
  name: 'ResourcePaginator',
  components: { ResourceAction },
  props: {
    action: {
      type: Function,
      required: true
    },
    tag: ResourceAction.props.tag,
    paginateByCursor: {
      type: Boolean,
      default: false
    }
  },
  emits: ['success', 'error', 'next-page', 'reset'],
  data() {
    const firstPageCursor = getFirstPageCursor({ paginateByCursor: this.paginateByCursor });
    return {
      allLoaded: false,
      items: [],
      cursor: firstPageCursor,
      nextCursor: firstPageCursor
    };
  },
  computed: {
    promise() {
      return this.paginateByCursor
        ? this.action(this.cursor.next_page_cursor)
        : this.action(this.cursor.page);
    }
  },
  methods: {
    handleSuccess({ page, total, next_page_cursor, items }) {
      this.allLoaded = this.paginateByCursor ? next_page_cursor === null : page >= total;
      this.nextCursor = this.paginateByCursor ? { next_page_cursor } : { page: page + 1 };
      this.items.push(...items);
      this.$emit('success');
    },
    prepend(item) {
      this.items.unshift(item);
    },
    remove(index) {
      this.items.splice(index, 1);
      if (!this.items.length) {
        this.next();
      }
    },
    insert(index, item) {
      this.items[index] = item;
    },
    next() {
      if (!this.allLoaded) {
        this.cursor = this.nextCursor;
        this.$emit('next-page', this.cursor);
      }
    },
    loop() {},
    reset() {
      this.items = [];
      this.allLoaded = false;
      const firstPageCursor = getFirstPageCursor({ paginateByCursor: this.paginateByCursor });
      this.cursor = firstPageCursor;
      this.nextCursor = firstPageCursor;
      this.$emit('reset');
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
