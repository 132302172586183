import { computed, watch, ref } from 'vue';

import {
  getResultLength,
  toFlatList,
  getRoundRobinNextIndex,
  getRoundRobinPrevIndex
} from './helpers';

/*
 * - преобразование результата поиска в плоский список (который примает virtual list )
 * - управление выделением элемента в этом списке
 */
export function useListHook(groups, { includeGroupItem, includeEmptyGroup, activeFirstItem } = {}) {
  const activeItemIndex = ref(undefined);
  const items = computed(() =>
    toFlatList(groups.value, {
      includeGroupItem,
      includeEmptyGroup
    })
  );
  const length = computed(() => getResultLength(groups.value));
  const activeItem = computed(() => {
    if (activeItemIndex.value === undefined || groups.value === undefined) {
      return undefined;
    }
    if (activeItemIndex.value >= length.value) {
      return undefined;
    }
    let itemIndex = activeItemIndex.value;
    let groupIndex = 0;
    while (itemIndex >= groups.value[groupIndex].items.length) {
      itemIndex = itemIndex - groups.value[groupIndex].items.length;
      groupIndex++;
    }
    return groups.value[groupIndex].items[itemIndex];
  });

  watch(groups, () => {
    // Делаем setActiveItem после того как список обновился,
    // иначе без этого может быть ситуация, что lastActiveItem есть,
    // а activeItem - нет, что ведёт к невозможности
    // сделать элемент активным при повторном наведении на него
    setActiveItem(activeFirstItem ? items.value[0] : undefined);
  });

  const setNextActiveItem = () => {
    activeItemIndex.value = getRoundRobinNextIndex(activeItemIndex.value, groups.value);
  };

  const setPrevActiveItem = () => {
    activeItemIndex.value = getRoundRobinPrevIndex(activeItemIndex.value, groups.value);
  };

  let lastActiveItem;

  const setActiveItem = (item) => {
    if (lastActiveItem === item) {
      return true;
    }
    lastActiveItem = item;
    if (!item) {
      activeItemIndex.value = undefined;
      return true;
    }
    if (groups.value === undefined) {
      return false;
    }
    let groupIndex = 0;
    let itemIndex = 0;
    while (groups.value[groupIndex]) {
      const indexInGroup = groups.value[groupIndex].items.indexOf(item);
      if (indexInGroup !== -1) {
        activeItemIndex.value = itemIndex + indexInGroup;
        return true;
      }
      itemIndex = itemIndex + groups.value[groupIndex].items.length;
      groupIndex++;
    }
    return false;
  };

  return {
    items,
    activeItem,
    setNextActiveItem,
    setPrevActiveItem,
    setActiveItem
  };
}
