<template>
  <div :class="$style.wrapper">
    <slot />
    <base-icon
      v-if="locked"
      :class="$style.lock"
      :style="{
        width,
        height
      }"
      :height="height"
      :width="width"
      :color="color"
      type="unavailable"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import BaseIcon from '@/components/icon.vue';

export default defineComponent({
  name: 'LockIconWrapper',
  components: { BaseIcon },
  props: {
    width: {
      type: [String, Number],
      default: undefined
    },
    height: {
      type: [String, Number],
      default: 12
    },
    color: BaseIcon.props.color,
    left: {
      type: [String, Number],
      default: '6px'
    },
    top: {
      type: [String, Number],
      default: '6px'
    },
    locked: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    computedLeft() {
      const tryNumber = Number(this.left);
      return Number.isNaN(tryNumber) ? this.left : `${this.left}px`;
    },
    computedTop() {
      const tryNumber = Number(this.top);
      return Number.isNaN(tryNumber) ? this.top : `${this.top}px`;
    }
  }
});
</script>

<style module lang="less">
.wrapper {
  position: relative;
  display: inline-flex;
  .lock {
    position: absolute;
    width: 12px;
    height: 12px;
    left: calc(50% - v-bind(computedLeft));
    transform: translate(-50%, -50%);
    top: calc(50% - v-bind(computedTop));
    color: var(--lock-icon-color, inherit);
  }
}
</style>

<i18n lang="json">{}</i18n>
