<template>
  <modal-wrapper :open="showModal" size="maxcontent" @change="showConfirm">
    <div :class="$style.modal">
      <verification-complete
        v-if="verificationComplete"
        :type="emailInfo.type"
        :is-change="isChange"
        @close="handleClose"
      />
      <loader v-else-if="!isInit || isEmailVerify" :class="$style.loader" />
      <template v-else>
        <h4 :class="$style.title">
          {{ $i18n('verification.title') }}
        </h4>
        <div :class="$style.text" v-html="verificationText" />
        <pin-code
          :class="$style.pinCode"
          :disabled="pinCodeDisabled"
          :invalidate="invalidatePin"
          :timeout="formatSecondsToTime(nextSendPinSeconds)"
          :initial-pin-code="initialPinCode"
          :pin-error-message="pinErrorMessage"
          @complete="handleCompletePin"
          @not-complete="handleNotComplete"
        />
        <base-button
          color="black"
          :disabled="loading || isTimeout"
          :class="$style.button"
          data-qa="send-button"
          @click="handleSendPin"
        >
          <img v-if="loading" :src="require('../assets/loader.svg')" :class="$style.loaderRound">
          <span v-else :class="loading ? $style.hide : ''">
            {{ $i18n('verification.accept') }}
          </span>
        </base-button>
        <template v-if="!isTimeout">
          <div
            v-if="nextSendVerifySeconds"
            :class="$style.nextSendVerifyTimer"
            data-qa="next-send-timer"
            v-html="retryVerifyText"
          />
          <button
            v-else
            :class="$style.resend"
            :disabled="loadingResend"
            data-qa="resend-button"
            @click="handleResendVerify"
          >
            {{ $i18n('verification.resend') }}
          </button>
        </template>
      </template>
    </div>
  </modal-wrapper>
</template>

<script>
import { ModalWrapper, BaseButton } from '@/components';
import Loader from '@/components/loader/loader.vue';

import { EmailUserAPI } from '@/shared/api/email-user';
import { userEmails } from '@/shared/lib/user-emails/user-emails';

import PinCode from '../parts/pin-code.vue';
import VerificationComplete from '../parts/verification-complete.vue';

const ALREADY_VERIFIED_ERROR = 'email_already_verified';
const CODE_EXPIRED = 'verification_code_expired';
const TOO_MANY_REQ = 'too_many_requests';

export default {
  name: 'EmailVerificationModal',
  components: {
    ModalWrapper,
    BaseButton,
    Loader,
    PinCode,
    VerificationComplete
  },
  props: {
    emailInfo: {
      type: Object,
      required: true
    },
    initialVerifyId: {
      type: String,
      default: ''
    },
    initialPinCode: PinCode.props.initialPinCode,
    isChange: VerificationComplete.props.isChange,
    verificationParams: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['close'],
  data() {
    return {
      showModal: true,
      resend: false,
      // через сколько можно запросить повторную верификацию
      nextSendVerifySeconds: 0,
      // через сколько можно повторно проверить пин-код
      nextSendPinSeconds: 0,
      isWrongPin: false,
      pinCode: '',
      loading: false,
      isInit: false,
      invalidatePin: false,
      verifyId: '',
      verificationComplete: false,
      loadingResend: true,
      versionKey: 0,
      pinErrorMessage: '',
      isEmailVerify: false,
      // Флаг о том, что пин-код валиден по времени
      isPinValidUntil: true,
      userEmail: ''
    };
  },
  computed: {
    retryVerifyText() {
      return this.nextSendVerifySeconds > 0
        ? this.$i18n('verification.retry', {
            time: this.formatSecondsToTime(this.nextSendVerifySeconds)
          })
        : '';
    },
    verificationText() {
      return this.$i18n('verification.text', {
        email: `<strong>${this.userEmail}</strong>`
      });
    },
    isTimeout() {
      return Boolean(this.nextSendPinSeconds);
    },
    pinCodeDisabled() {
      return this.loading || !this.isInit || this.isTimeout;
    }
  },
  watch: {
    showModal(val) {
      if (!val) {
        this.$emit('close');
      }
    }
  },
  mounted() {
    this.userEmail = this.emailInfo.email;
    if (this.initialVerifyId || this.verificationParams?.id) {
      this.nextSendVerifySeconds = this.verificationParams?.next_try_after || 60;
      this.startRetrySendVerifyCountdown();

      this.verifyId = this.verificationParams?.id || this.initialVerifyId;
      this.isInit = true;
    } else {
      this.sendVerifyRequest();
    }
    if (this.initialPinCode) {
      this.isEmailVerify = true;
      this.pinCode = this.initialPinCode;
      this.handleSendPin();
    }
  },
  methods: {
    sendVerifyRequest() {
      const payload = {
        type: this.emailInfo.type,
        email: this.userEmail
      };

      this.loadingResend = true;

      EmailUserAPI.fetchVerify(payload)
        .then((response) => {
          this.userEmail = response?.email || this.userEmail;
          if (!this.nextSendVerifySeconds) {
            this.startRetrySendVerifyCountdown();
          }
          this.nextSendVerifySeconds = response.next_send_after;
          this.verifyId = response.id;
        })
        .catch((e) => {
          if (e.response.data.errors?.common[0] === ALREADY_VERIFIED_ERROR) {
            this.verificationComplete = true;
          }
          this.pinCode = '';
        })
        .finally(() => {
          this.isInit = true;
          this.isEmailVerify = false;
          this.loadingResend = false;
          this.resend = false;
        });
    },
    handleCompletePin(code) {
      this.pinCode = code;
      this.invalidatePin = false;
      this.handleSendPin();
    },
    handleNotComplete(code) {
      this.invalidatePin = false;
      this.pinCode = code;
    },
    formatSecondsToTime(seconds) {
      if (!seconds) {
        return '';
      }
      const h = Math.floor(seconds / 3600);
      const m = Math.floor((seconds % 3600) / 60);
      const s = seconds % 60;

      const hours = h > 0 ? `${h}:` : '';
      const minutes = (hours && m < 10 ? '0' : '') + m;
      const secondsString = (s < 10 ? '0' : '') + s;

      return `${hours}${minutes}:${secondsString}`;
    },
    startRetrySendVerifyCountdown() {
      const intervalId = setInterval(() => {
        this.nextSendVerifySeconds -= 1;
        if (this.nextSendVerifySeconds <= 0) {
          this.nextSendVerifySeconds = 0;
          clearInterval(intervalId);
          this.loadingResend = false;
        }
      }, 1000);
    },
    startRetrySendPinCountdown() {
      const intervalId = setInterval(() => {
        this.nextSendPinSeconds -= 1;
        if (this.nextSendPinSeconds === 0) {
          clearInterval(intervalId);
          this.invalidatePin = false;
        }
      }, 1000);
    },
    handleSendPin() {
      if (!this.pinCode) {
        this.invalidatePin = true;
        return;
      }
      this.pinErrorMessage = '';
      this.loading = true;
      EmailUserAPI.sendVerify({ id: this.verifyId, code: this.pinCode })
        .then((response) => {
          if (response.verified) {
            this.verificationComplete = true;
            this.versionKey = +new Date();
            EmailUserAPI.fetchList(this.versionKey).then((items) => userEmails.set(items));
          }
        })
        .catch((error) => {
          if (error.response.data.error === CODE_EXPIRED && this.initialPinCode) {
            this.sendVerifyRequest();
            return;
          }
          if (error.response.data.error === CODE_EXPIRED) {
            this.isPinValidUntil = false;
          }
          if (error.response.data.error === TOO_MANY_REQ && this.initialPinCode) {
            this.isEmailVerify = false;
          }
          if (error.response.data.next_try_after) {
            const prevTime = this.nextSendPinSeconds;
            this.nextSendPinSeconds = error.response.data.next_try_after;
            if (!prevTime) {
              this.startRetrySendPinCountdown();
            }
          } else {
            this.pinErrorMessage = error.response.data.error;
          }
          this.pinCode = '';
          this.invalidatePin = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleResendVerify() {
      this.resend = true;
      if (!this.isPinValidUntil) {
        this.isPinValidUntil = true;
        this.sendVerifyRequest();
        return;
      }
      EmailUserAPI.resendVerify(this.verifyId)
        .then((response) => {
          if (!this.nextSendVerifySeconds) {
            this.startRetrySendVerifyCountdown();
          }
          if (response.verified) {
            this.verificationComplete = true;
            EmailUserAPI.fetchList(this.versionKey).then((items) => userEmails.set(items));
          } else {
            this.nextSendVerifySeconds = response.next_send_after;
          }
        })
        .finally(() => {
          this.resend = false;
        });
    },
    showConfirm() {
      if (this.verificationComplete || confirm(this.$i18n('close_confirmation'))) {
        this.handleClose();
      }
    },
    handleClose() {
      this.showModal = false;
    }
  }
};
</script>

<style module>
.modal {
  font-family: $fontFamilyGraphik;
  background-color: $whiteColor;
  color: #373546;
  border-radius: 20px;
  font-size: 16px;
  line-height: 18px;

  margin: 115px auto;
  position: relative;

  width: 512px;
  padding: 56px 56px 40px;
}

.loader {
  margin: 0 auto 16px;
}

.title {
  margin: 0 0 16px;
  font-size: 30px;
  line-height: 32px;
  letter-spacing: -0.009em;
  font-weight: 600;
}

.text {
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0;

  strong {
    font-weight: 600;
  }
}

.pinCode {
  margin: 32px auto 40px;
}

.button {
  display: flex;
  height: 48px;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  justify-content: center;
  border: none;
  align-items: center;
  background-color: #1d1d1f;
  border-radius: 6px;
  font-family: $fontFamilyGraphik;
  cursor: pointer;
  text-transform: capitalize;

  &:disabled,
  &:disabled:hover,
  &:disabled:active {
    background-color: #1d1d1f;
    color: #666666;
  }

  &:hover {
    background-color: #1d1d1f;
  }

  &:active {
    background-color: #1d1d1f;
    box-shadow: 0 0 0 4px #d8d8e5;
  }
}

.nextSendVerifyTimer {
  text-align: center;
  color: #9c9c9c;
  font-weight: 400;
  margin-top: 24px;
  span {
    min-width: 50px;
    text-align: left;
    display: inline-block;
  }
}

.resend {
  padding: 0;
  margin: 24px auto 0;
  background-color: transparent;
  border: none;
  color: #1da1b5;
  display: flex;
  cursor: pointer;
  letter-spacing: 0.01em;
  font-weight: 400;

  &:hover {
    color: $red-400;
  }
}

.loaderRound {
  animation-name: loader;
  animation-iteration-count: infinite;
  animation-duration: 1s;
  animation-timing-function: linear;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
    stroke-dashoffset: 200px;
  }
  60% {
    stroke-dashoffset: 300px;
  }
  100% {
    transform: rotate(360deg);
    stroke-dashoffset: 200px;
  }
}
</style>

<i18n lang="json">
{
  "verification.title": {
    "ru_RU": "Подтверждение эл. почты",
    "en_US": "Email Confirmation"
  },
  "verification.accept": {
    "ru_RU": "Подтвердить",
    "en_US": "Confirm"
  },
  "verification.resend": {
    "ru_RU": "Отправить повторно",
    "en_US": "Resend"
  },
  "verification.retry": {
    "ru_RU": "Отправить повторно через '<span>'{time}'</span>'",
    "en_US": "Resend in '<span>'{time}'</span>'"
  },
  "verification.expired": {
    "ru_RU": "Срок действия кода истек",
    "en_US": "The code has expired"
  },
  "verification.text": {
    "ru_RU": "Введите код из письма, которое мы отправили на&nbsp;{email}",
    "en_US": "Enter the code from the mail we sent to&nbsp;{email}"
  },
  "close_confirmation": {
    "ru_RU": "Ожидается ввод кода подтверждения. Все равно закрыть?",
    "en_US": "The confirmation code has not been entered. Close it anyway?"
  }
}
</i18n>
