<template>
  <div>
    <validation-form-input
      name="taxpayer_id"
      :label="$trlMessage('document_request.form.taxpayer_id.label')"
      :class="commonStyles.field"
    />
    <validation-form-input
      name="contract_number"
      :label="$trlMessage('document_request.form.contract_number.label')"
      :class="commonStyles.field"
    />
    <base-delimiter />
    <validation-form-textarea
      name="comment"
      :label="$trlMessage('document_request.form.comment.label')"
      :placeholder="$trlMessage('document_request.form.comment.placeholder')"
      :class="[commonStyles.textarea, commonStyles.field]"
    />
    <file-upload name="file" :label="$trlMessage('file.attach')" :class="commonStyles.file" />
  </div>
</template>

<script>
import { string } from 'yup';

import BaseDelimiter from '@/components/delimiter/delimiter.vue';

import commonStyles from './common-styles.module.css';
import FileUpload from './partials/file-upload.vue';
import ValidationFormInput from './partials/input.vue';
import ValidationFormTextarea from './partials/textarea.vue';

export default {
  name: 'WorkContractForm',
  components: {
    BaseDelimiter,
    ValidationFormInput,
    ValidationFormTextarea,
    FileUpload
  },
  schema: {
    taxpayer_id: string().required(),
    contract_number: string().required(),
    comment: string()
  },
  computed: {
    commonStyles: () => commonStyles
  }
};
</script>

<i18n lang="json">{}</i18n>
