import type { AxiosClient } from '@/shared/api/utils/client/axios';
import type { ItemList } from '@/shared/api/utils/types';

import { ApiLayer } from '@/shared/api/utils/api-layer';
import axios from '@/shared/api/utils/client/axios';

type NewsItem = {
  id: number;
  content: string;
  subject: string;
  theme?: {
    background: string;
    textColor: string;
    iconColor: string;
  };
};

class NewsLayer extends ApiLayer<AxiosClient> {
  private getItemByNewsKey(key: string) {
    return this.methods.get<NewsItem>(`/news_items/${key}`, { baseURL: '/' });
  }

  private fakeNews() {
    const fakeNewsItems = ['0057_palantir', '0050_join'];
    return Promise.all(fakeNewsItems.map((key) => this.getItemByNewsKey(key)));
  }

  getList() {
    return this.methods
      .get<ItemList<NewsItem>>('/news_items/', { baseURL: '/' })
      .then(({ items }) => items);
  }

  markViewed(newsId: number) {
    return this.methods.put(
      `/news_items/${newsId}`,
      {
        viewed: true
      },
      { baseURL: '/' }
    );
  }
}

export const NewsAPI = new NewsLayer(axios);
