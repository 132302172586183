<template>
  <div>
    <transition name="news" type="transition" appear @after-enter="calcModalStyles">
      <news-thumbnail
        v-if="showThumbnail"
        ref="thumbnail"
        :theme="theme"
        :total-count="totalCount"
        :current-news="currentNews"
        @open="showThumbnail = false"
        @close="handleClose"
      />
    </transition>

    <transition name="fullscreen">
      <div
        v-if="!showThumbnail"
        class="fullscreen-container"
        :style="{
          background: theme.background,
          ...modalStyles
        }"
      >
        <transition mode="in-out" name="fade" appear appear-active-class="fadeIn">
          <news-text
            :key="currentNews.id"
            :current-news="currentNews"
            :has-next-news="hasNextNews"
            :theme="theme"
            @next="$emit('next')"
            @close="handleClose"
          />
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import NewsThumbnail from '@/modules/news/components/news-thumbnail.vue';

import NewsText from './components/news-text.vue';

export default {
  name: 'PureNews',
  components: { NewsThumbnail, NewsText },
  props: {
    hasNextNews: NewsText.props.hasNextNews,
    currentNews: NewsText.props.currentNews,
    totalCount: NewsThumbnail.props.totalCount
  },
  emits: ['next'],
  data() {
    return {
      showThumbnail: false,
      modalStyles: {}
    };
  },
  computed: {
    theme() {
      const defaultTheme = {
        background: '#5951be',
        textColor: '#FFFFFF',
        iconColor: '#FFFFFF'
      };
      return Object.assign(defaultTheme, this.currentNews.theme);
    }
  },
  watch: {
    $route() {
      if (this.showThumbnail) {
        return;
      }
      this.handleClose();
    }
  },
  created() {
    if (this.currentNews) {
      this.showThumbnail = true;
    }
  },
  methods: {
    calcModalStyles() {
      const thumbRect = this.$refs.thumbnail.$el.getBoundingClientRect();

      const initScaleX = thumbRect.height / window.innerHeight;
      const initScaleY = thumbRect.width / window.innerWidth;

      document.documentElement.style.setProperty('--news-thumbnail-scale-x', String(initScaleX));
      document.documentElement.style.setProperty('--news-thumbnail-scale-y', String(initScaleY));

      this.modalStyles = {
        transformOrigin: `
          ${thumbRect.x + thumbRect.width}px
          ${thumbRect.y + thumbRect.height}px
        `
      };
    },
    handleClose() {
      this.showThumbnail = true;
      this.$emit('next');
    }
  }
};
</script>

<style lang="less">
@import '~@less/common/variables';

.fullscreen-container {
  position: fixed;
  top: @layoutHeaderHeight + 8px;
  right: 8px;
  bottom: 8px;
  left: 8px;
  z-index: 3;
  animation: zoomIn 0.4s cubic-bezier(0.39, 0.69, 0.48, 0.95) forwards;
  transform: scale(var(--news-thumbnail-scale-y), var(--news-thumbnail-scale-x));
  border-radius: 16px;
}

// Анимации

// Анимация заполнения экрана
@keyframes zoomIn {
  to {
    transform: scale(1);
  }
}

// Анимация появления thumbnail новости
.news-enter-active,
.news-leave-active {
  transition: all 400ms;
}

.news-enter-from,
.news-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

// Анимации смены\появления новостного блока
.fade-out-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

// Анимация на закрытие блока с новостью
.fullscreen-leave-active {
  transition: opacity 0.5s;
}
.fullscreen-leave-to {
  opacity: 0;
}

// Отложенная анимация после zoomIn блока с новостью
@keyframes fadeInDelay {
  from,
  50% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  animation: fadeInDelay 1s;
}
</style>

<i18n lang="json">{}</i18n>
