const mediaSizes = {
  screenXs: 480, // Extra small screen / phone
  screenSm: 768, // Small screen / tablet
  screenMd: 992, // Medium screen / desktop
  screenLg: 1200 // Large screen / wide desktop
};

const widths = {
  nano: '55px',
  micro: '70px',
  smaller: '170px',
  small: '182px',
  normal: '200px',
  medium: '250px',
  large: '300px',
  larger: '400px',
  big: '450px',
  bigger: '500px',
  giant: '550px',
  titan: '600px',
  full: '100%'
};

const variables = {
  /* Размеры */
  ...Object.fromEntries(Object.entries(widths).map(([key, value]) => [`${key}Width`, value])),

  // Наборный текст
  baseLineHeight: '22px',
  baseFontSize: '16px',
  // Второстепенный текст
  secondaryLineHeight: '18px',
  secondaryFontSize: '14px',
  // Layout
  layoutHeaderHeight: '56px',
  layoutFilterHeight: '52px',
  // Header
  headerLineHeight: '30px',
  headerFontSize: '22px',
  fontFamilyGraphik: 'Graphik, Arial, Tahoma, Verdana, sans-serif',

  //
  secondLineHeight: '20px',

  islandGap: '8px',
  /* Цвета */
  black: '#050505',
  'black-6': 'rgba(0, 0, 0, 0.06)',
  'black-7': 'rgba(0, 0, 0, 0.07)',
  'black-8': 'rgba(0, 0, 0, 0.08)',
  'black-10': 'rgba(0, 0, 0, 0.10)',
  'black-12': 'rgba(0, 0, 0, 0.12)',
  'black-16': 'rgba(0, 0, 0, 0.16)',
  'black-30': 'rgba(0, 0, 0, 0.30)',
  'black-36': 'rgba(0, 0, 0, 0.36)',
  'black-40': 'rgba(0, 0, 0, 0.4)',
  'black-50': 'rgba(0, 0, 0, 0.5)',
  'black-60': 'rgba(0, 0, 0, 0.6)',
  'black-90': 'rgba(0, 0, 0, 0.9)',
  white: '#FFFFFF',
  'gray-100': '#F5F5F5',
  'gray-150': '#F8F8F8',
  'gray-200': '#E5E5E5',
  'gray-300': '#DCDCDC',
  'gray-400': '#BDBDBD',
  'gray-500': '#989898',
  'gray-600': '#888888',
  'gray-700': '#7C7C7C',
  'gray-800': '#3D3D3D',
  'gray-900': '#202020',
  'blue-500': '#25CFE8',
  'blue-600': '#0093AC',
  'blue-650': '#0AB3CE',
  'blue-700': '#50808A',
  'white-16': 'rgba(255, 255, 255, 0.16)',
  'white-20': 'rgba(255, 255, 255, 0.2)',
  'white-30': 'rgba(255, 255, 255, 0.3)',
  'white-50': 'rgba(255, 255, 255, 0.5)',
  'green-500': '#7AC015',
  'green-600': '#70B60B',
  'green-700': '#5CA200',
  'yellow-200': '#F6F3DE',
  'yellow-300': '#E6E2C5',
  'yellow-400': '#D5CFA3',
  'yellow-600': '#A5A392',
  'black-hover': 'rgba(255, 255, 255, 0.09)',
  'black-active': 'rgba(255, 255, 255, 0.14)',
  green: '#7AC015',
  red: '#FF533B',
  yellow: '#F69344',
  'red-50': '#FFF5F5',
  'red-100': '#FFD8D8',
  'red-400': '#FF533B',
  'red-500': '#FF0000',
  'red-600': '#DF0000',
  'red-700': '#B10303',
  violet: '#5E3C99',
  'green-success': '#13AA48',
  // пока так назвал, надо придумать нормальное название
  subTextColor: 'rgba(21, 19, 25, 0.5)',

  /* старые цвета */
  darkViolet: '#38343f',
  textColor: '#050505',
  // есть еще серый в стилях #ccc
  lightTextColor: '#8f999c', // @TODO: заменить например на #6C767A, у #8f999c недостаточный контраст
  errorColor: '#ff0000',
  headerColor: '#050505',
  grayColor: '#333',
  grayMid: '#ccc',
  grayLight: '#ebebeb',
  whiteColor: '#ffffff',
  vantaBlackColor: '#050505',
  blueColor: '#22bbd2',
  greenColor: '#61c497',
  yellowColor: '#ec7e00',
  lightGreenColor: '#63af00',
  focusColor: '#00a2c4',
  violetColor: '#410097',
  linkColor: '#0093AC',
  linkHoverColor: '#fa633e',
  hoverColor: '#FF533B',
  nyRedColor: '#e80027',
  march8Color: '#ff0061',
  aiColor: '#FF3963',
  borderColor: '#ebebeb',
  btnGreen: '#7ac016',
  delimiterColor: '#E0E0E0',
  headerDelimiterColor: '#373737',
  /* Media Queries */

  // [breakpoint]: [size] → [breakpoint]: [size]px
  ...Object.fromEntries(Object.entries(mediaSizes).map(([key, value]) => [key, `${value}px`])),
  // So media queries don't overlap when required, provide a maximum
  screenXsMax: `${mediaSizes.screenSm - 1}px`,
  screenSmMax: `${mediaSizes.screenMd - 1}px`,
  screenMdMax: `${mediaSizes.screenLg - 1}px`
};

const sidebarTextColor = `var(--sidebar-text-color, ${variables.whiteColor})`;
const sidebarTextColorHover = `var(--sidebar-text-color-hover, ${variables.whiteColor})`;

module.exports = {
  ...variables,
  // Theme colors
  sidebarBackground: `var(--sidebar-background, ${variables.black})`,
  sidebarTextColor,
  sidebarTextColorHover,
  sidebarTextColorActive: `var(--sidebar-text-color-active, ${sidebarTextColorHover})`,
  sidebarTextBackgroundActive: 'var(--sidebar-text-background-active, transparent)',
  sidebarTextBackgroundHover: 'var(--sidebar-text-background-hover, transparent)',
  sidebarHeaderTextColor: `var(--sidebar-header-text-color, ${sidebarTextColor})`,
  sidebarDividerColor: 'var(--sidebar-divider-color, #47434e)',
  sidebarVerticalDividerColor: `var(--sidebar-vertical-divider-color, ${variables.darkViolet})`,
  plusActionBackground: `var(--plus-button-background, ${variables['blue-500']})`,
  plusActionColor: `var(--plus-button-color, ${variables.whiteColor})`
};
