import { nanoid } from 'nanoid';
import { ref, watch, computed } from 'vue';

import { useLocalAutocomplete } from '../autocomplete-next/search-engine/use-local-autocomplete';
import { useServerSideAutocomplete } from '../autocomplete-next/search-engine/use-server-side-autocomplete';

export function useEmailAutocomplete({ coworkers, options: { includeResources } }) {
  const { search: searchCoworkers, result: resultCoworkers } = useLocalAutocomplete({
    sources: [{ items: coworkers }],
    options: {
      matchFn: (query) => (item) => {
        const lowercasedQuery = typeof query === 'string' ? query.toLocaleLowerCase() : query;
        const { email, name } = item;
        return (
          email?.toLocaleLowerCase().includes(lowercasedQuery)
          || name?.toLocaleLowerCase().includes(lowercasedQuery)
        );
      }
    }
  });
  const { search: searchContacts, result: resultContacts } = useServerSideAutocomplete({
    sources: [
      {
        getRequestParams: (query) => ({
          url: '/search/contacts',
          query: {
            q: query,
            resource: false
          }
        })
      }
    ],
    options: {
      includeBasePath: true
    }
  });
  const { search: searchResouces, result: resultResources } = useServerSideAutocomplete({
    sources: [
      {
        getRequestParams: (query) => ({
          url: '/search/contacts',
          query: {
            q: query,
            resource: true
          }
        })
      }
    ],
    options: {
      includeBasePath: true
    }
  });

  // для того, чтобы не выводить в списке уже выбранные email
  const excludedEmails = ref([]);
  // что-то вроде точки синхронизации,
  // чтоб не оч глитчило (ибо локальный поиск и серверный параллельно друг другу работают)
  // (всё равно немног глитчит, кажется из-за leading в debounce, но имхо терпимо)
  const syncId = ref(undefined);

  const result = ref([]);

  watch(syncId, () => {
    result.value = [
      ...(resultCoworkers.value ?? []),
      ...(resultContacts.value ?? []),
      ...(includeResources && resultResources.value ? resultResources.value : [])
    ];
  });

  const search = (query) => {
    const promises = [
      searchCoworkers(query),
      searchContacts(query),
      includeResources ? searchResouces(query) : Promise.resolve()
    ];
    Promise.all(promises).then(() => {
      syncId.value = `${nanoid()}_${+new Date()}`;
    });
  };

  return {
    result: computed(() => {
      const isExcludedItem = (item) => excludedEmails.value.includes(item.email);
      const filterItems = (items) => items.filter((item) => isExcludedItem(item) === false);
      return result.value.reduce((acc, group) => {
        const items = filterItems(group.items);
        acc.push({ ...group, items });
        return acc;
      }, []);
    }),
    search,
    setExcludeEmailsList: (list) => {
      excludedEmails.value = list;
    }
  };
}
