<template>
  <popup-layout
    :loading="!vacancyRequestSchema"
    data-qa="vacancy-request-view"
    :class="$style.vacancyRequestView"
    :title="vacancyRequest.position"
  >
    <template #title>
      <div :class="$style.header">
        <span data-qa="title" :class="$style.title">
          {{ vacancyRequest.position }}
        </span>
        <link-button size="xs" :to="settingsHref" icon="options-2-18" target="_blank">
          {{ $i18n('go_to_constructor') }}
        </link-button>
      </div>
    </template>
    <template #body>
      <vjsf-form
        :schema="processedSchema.schema"
        :ui-schema="processedSchema.ui_schema"
        :form-data="vacancyRequest.values"
        mode="inline"
        :class="$style.form"
      />
      <portal v-if="files" :to="mainPortal">
        <file-attachment-list :files="files" view-mode :class="$style.attachments" />
      </portal>
      <portal :to="sidePortal">
        <base-delimiter :top="20" :bottom="20" />
        <div data-qa="side-info" :class="$style.info">
          <watcher-info :vacancy-request="vacancyRequest" />
          <state
            v-for="(state, index) in states"
            :key="index"
            :first="index === 0"
            :state="state"
          />
          <more-button
            v-if="isShowMoreButtonVisible"
            :class="$style.moreButton"
            size="full"
            data-qa="more-button"
            @click="showAllStates"
          >
            {{ $trlMessage('button.show_more') }}
          </more-button>
          <approval-summary v-if="isApproved" :vacancy-request="vacancyRequest" />
        </div>
      </portal>
    </template>

    <template #footer>
      <buttons-line>
        <template #default="slotProps">
          <slot name="footer" v-bind="slotProps">
            <base-button data-qa="close" @click="handleCancel">
              {{ $trlMessage('button.close') }}
            </base-button>
          </slot>
        </template>
      </buttons-line>
    </template>
  </popup-layout>
</template>

<script>
import { Portal } from 'portal-vue';
import { computed, ref } from 'vue';

import BaseButton from '@/components/button/button.vue';
import LinkButton from '@/components/button/link-button.vue';
import BaseDelimiter from '@/components/delimiter/delimiter.vue';
import CompensationTemplate from '@/components/hf/request-list-item/widgets/compensation-template.vue';
import ButtonsLine from '@/components/layout/buttons-line.vue';
import MoreButton from '@/components/more-button/more-button.vue';
import PopupLayout from '@/components/popup-layout/popup-layout.vue';
import { setObjectTemplateComponents } from '@/components/vjsf/helpers/set-object-template-components';
import { VacancyRequestHelpers } from '@/components/vjsf/helpers/vacancy-request-helpers';
import VjsfForm from '@/components/vjsf/vjsf-view.vue';

import { userPermissions } from '@/shared/lib/config/user-permissions';
import { VacancyRequestStatus } from '@/shared/types/vacancy-request-status';
import FileAttachmentList from '@/shared/ui/file-attachment-list/file-attachment-list.vue';

import VacancyRequestFormLayout, {
  VACANCY_REQUEST_MAIN_PORTAL,
  VACANCY_REQUEST_SIDE_PORTAL
} from '../../components/vacancy-request-form-layout.vue';
import ApprovalSummary from './approval-summary.vue';
import State from './state.vue';
import WatcherInfo from './watcher-info.vue';

const STATES_PAGE_LIMIT = 5;

export default {
  name: 'VacancyRequestView',
  components: {
    PopupLayout,
    ApprovalSummary,
    BaseButton,
    LinkButton,
    BaseDelimiter,
    ButtonsLine,
    FileAttachmentList,
    MoreButton,
    Portal,
    State,
    VjsfForm,
    WatcherInfo
  },
  props: {
    vacancyRequest: {
      type: Object,
      default: null
    },
    vacancyRequestSchema: {
      type: Object,
      default: null
    },
    loading: Boolean
  },
  emits: ['cancel'],
  setup(props, { emit }) {
    const isAllStatesShown = ref(false);
    const showAllStates = () => {
      isAllStatesShown.value = true;
    };

    const isRejected = computed(
      () => props.vacancyRequest.status === VacancyRequestStatus.REJECTED
    );
    const isApproved = computed(
      () => props.vacancyRequest.status === VacancyRequestStatus.APPROVED
    );
    const states = computed(() => {
      const result = (props.vacancyRequest?.states || []).filter(
        (state) => !isRejected.value || state.status
      );
      if (isAllStatesShown.value) {
        return result;
      }
      return result.slice(0, STATES_PAGE_LIMIT);
    });
    const isShowMoreButtonVisible = computed(
      () => !isAllStatesShown.value && props.vacancyRequest?.states?.length > STATES_PAGE_LIMIT
    );

    const vacancyRequestIdRef = computed(() => {
      if (!isRejected.value) {
        return props.vacancyRequest.id;
      }
      // если заявка отклонена, то в этом компоненте нет её id-шника
      // (он оммитится для логики "отправить ещё раз"
      // но его можно получить из массива states
      const id = (props.vacancyRequest.states ?? []).find(
        (state) => state.vacancy_request
      )?.vacancy_request;
      return id;
    });

    const files = computed(() => props.vacancyRequest?.files || []);

    return {
      vacancyRequestId: vacancyRequestIdRef,
      canReceiveVacancyRequests: userPermissions.get('can_receive_vacancy_requests'),
      isWatcher: userPermissions.isWatcher,
      isRejected,
      isApproved,
      states,
      files,
      isShowMoreButtonVisible,
      showAllStates,
      handleCancel: () => emit('cancel'),
      settingsHref: `/settings/orgs/${userPermissions.get('nick')}/vacancy-request`
    };
  },
  computed: {
    mainPortal: () => VACANCY_REQUEST_MAIN_PORTAL,
    sidePortal: () => VACANCY_REQUEST_SIDE_PORTAL,
    processedSchema() {
      if (!this.vacancyRequestSchema) {
        return this.vacancyRequestSchema;
      }

      // Выкидываем поле position, т.к. оно показывается в заголовке модалки
      const schema = VacancyRequestHelpers.removePosition(this.vacancyRequestSchema);
      const uiSchema = setObjectTemplateComponents(this.vacancyRequestSchema.ui_schema, {
        [CompensationTemplate.name]: CompensationTemplate
      });
      uiSchema['ui:ObjectFieldTemplate'] = VacancyRequestFormLayout;
      if (uiSchema.applicants_to_hire) {
        uiSchema.applicants_to_hire['ui:options'].breakAfter = false;
      }

      return {
        ...this.vacancyRequestSchema,
        schema,
        ui_schema: uiSchema
      };
    }
  }
};
</script>

<i18n lang="json">
{
  "go_to_constructor": {
    "ru_RU": "Настроить поля формы",
    "en_US": "Customise form fields"
  }
}
</i18n>

<style module>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  gap: 8px;
  min-width: 0;

  .title {
    min-width: 0;
    @mixin ellipsis;
  }
}

.attachments {
  margin-left: -9px;
}

.info {
  overflow-wrap: break-word;
  word-break: normal;
}

.moreButton {
  padding-top: 10px;
}

.form form > :global(.form-group) {
  margin-bottom: 0;
}

.footer {
  padding: 20px 0;
}
</style>
