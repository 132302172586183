<template>
  <base-wysiwyg ref="wysiwyg" v-bind="$props" :extensions="$options.extensions">
    <template #menubar="{ editor }">
      <menubar :editor="editor" :invalid="invalid" :disabled="disabled" />
    </template>
  </base-wysiwyg>
</template>

<script>
import { Bold } from '@tiptap/extension-bold';
import { BulletList } from '@tiptap/extension-bullet-list';
import { Document } from '@tiptap/extension-document';
import { HardBreak } from '@tiptap/extension-hard-break';
import { History } from '@tiptap/extension-history';
import { Italic } from '@tiptap/extension-italic';
import { ListItem } from '@tiptap/extension-list-item';
import { OrderedList } from '@tiptap/extension-ordered-list';
import { Paragraph } from '@tiptap/extension-paragraph';
import { Text } from '@tiptap/extension-text';

import BaseWysiwyg from '../base-wysiwyg.vue';
import { Link } from '../extensions/link';
import Menubar from './menubar.vue';

const extensions = Object.freeze([
  Document,
  Text,
  Paragraph,
  BulletList,
  HardBreak,
  ListItem,
  OrderedList,
  Link.configure({
    openOnClick: false,
    validate: (href) => /^https?:\/\//.test(href)
  }),
  Bold,
  Italic,
  History
]);

export default {
  name: 'DefaultWysiwyg',
  components: {
    BaseWysiwyg,
    Menubar
  },
  extensions,
  props: {
    shouldFocusOnMount: {
      type: Boolean,
      default: false
    },
    content: {
      type: String,
      default: ''
    },
    invalid: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    }
  },
  methods: {
    insertText(...args) {
      return this.$refs.wysiwyg.insertText(...args);
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
