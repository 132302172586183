<template>
  <div v-if="canAddApplicants || isWatcher" :class="classes">
    <ul :class="$style.list">
      <li :class="$options.style.item">
        <menu-chapter
          :url="applicantsHref"
          icon="home-20"
          :active="isApplicantLinkActive"
          data-qa="all-applicants"
        >
          {{ linkText }}
        </menu-chapter>
      </li>
      <li v-if="canGetStats" :class="$options.style.item">
        <menu-chapter :url="url('/reports')" icon="graph-20" data-qa="report-link">
          {{ $i18n('analytics') }}
        </menu-chapter>
      </li>
      <li :class="$options.style.item">
        <menu-chapter
          :url="url('#calendar')"
          icon="calendar-20"
          data-qa="calendar-link"
          :active="isCalendarLinkActive"
        >
          {{ $i18n('calendar') }}
        </menu-chapter>
      </li>
    </ul>
    <menu-divider />
  </div>
</template>

<script>
import MenuChapter from '@/modules/sidebar/shares/menu-chapter.vue';
import MenuDivider from '@/modules/sidebar/shares/menu-divider.vue';

import { userPermissions } from '@/shared/lib/config/user-permissions';
import { VirtualStatusId } from '@/shared/lib/status/status';

import style from './sidebar.module.less';

export default {
  name: 'SidebarMenu',
  components: { MenuChapter, MenuDivider },
  style,
  props: {
    linkPresets: {
      type: Object,
      required: true
    }
  },
  computed: {
    isApplicantLinkActive() {
      return this.$route.name?.startsWith('applicants');
    },
    isCalendarLinkActive() {
      return this.$route.name?.startsWith('calendar');
    },
    isWatcher: () => userPermissions.isWatcher,
    orgNick: () => userPermissions.get('nick'),
    canGetStats: () => userPermissions.get('can_get_stats'),
    canAddApplicants: () => userPermissions.get('can_add_applicants'),
    classes() {
      return {
        [style.watcherSection]: this.isWatcher
      };
    },
    applicantsHref() {
      const linkPreset = this.linkPresets.all || {};
      const { status, applicant } = linkPreset;
      const baseHref = '#/applicants';
      if (!status || Object.values(VirtualStatusId).includes(status)) {
        return baseHref;
      }
      if (applicant) {
        return `${baseHref}/filter/${status}/id/${applicant}`;
      }
      return `${baseHref}/filter/${status}`;
    },
    linkText() {
      return this.canAddApplicants ? this.$i18n('all_applicants') : this.$i18n('dashboard');
    }
  },
  methods: {
    url(url) {
      return `/my/${this.orgNick}${url}`;
    }
  }
};
</script>

<style module>
.list {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
</style>

<i18n lang="json">
{
  "dashboard": {
    "ru_RU": "Картина дня",
    "en_US": "Dashboard"
  },
  "all_applicants": {
    "ru_RU": "Все кандидаты",
    "en_US": "All Applicants"
  },
  "calendar": {
    "ru_RU": "Календарь",
    "en_US": "Calendar"
  },
  "analytics": {
    "ru_RU": "Аналитика",
    "en_US": "Analytics"
  }
}
</i18n>
