<template>
  <form @submit.prevent.stop="handleSubmit">
    <popup-layout :title="$trlMessage('vacancy_request')" single-column>
      <template #main>
        <radio-field
          :checked="value"
          focus
          :label="$trlMessage('vacancy_request.from_scratch')"
          :value="$options.choice.NEW"
          :name="name"
          :class="$style.choice"
          ellipsis
          @change="value = $event"
        />
        <radio-field
          :checked="value"
          :label="$trlMessage('vacancy_request.from_existing')"
          :value="$options.choice.COPY"
          :name="name"
          :class="$style.choice"
          ellipsis
          @change="value = $event"
        />
      </template>
      <template #footer>
        <base-button type="submit" color="black" data-qa="save">
          {{ $trlMessage('button.continue') }}
        </base-button>
        <base-button data-qa="cancel" @click="handleCancel">
          {{ $trlMessage('button.cancel') }}
        </base-button>
      </template>
    </popup-layout>
  </form>
</template>

<script>
import { nanoid } from 'nanoid';

import BaseButton from '@/components/button/button.vue';
import RadioField from '@/components/form-fields/radio/radio.vue';
import PopupLayout from '@/components/popup-layout/popup-layout.vue';

const NEW = 'new';
const COPY = 'copy';

export default {
  name: 'NewOrCopy',
  choice: {
    NEW,
    COPY
  },
  components: {
    BaseButton,
    PopupLayout,
    RadioField
  },
  emits: ['submit', 'cancel'],
  data() {
    return {
      name: nanoid(),
      value: NEW
    };
  },
  methods: {
    handleSubmit() {
      this.$emit('submit', { fromExisting: this.value === COPY });
    },
    handleCancel() {
      this.$emit('cancel');
    }
  }
};
</script>

<style lang="less" module>
@import '~@less/common/mixins/font.less';

.choice {
  display: flex;
  align-items: baseline;
  .font_regular();
}

.choice + .choice {
  margin-top: 10px;
}

.choice:last-child {
  margin-bottom: 5px;
}
</style>

<i18n lang="json">{}</i18n>
