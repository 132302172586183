<template>
  <link-button :to="link" :color="color" :size="size" data-qa="apply-tariff-upgrade">
    <slot>{{ $i18n('upgrade') }}</slot>
  </link-button>
</template>

<script>
import LinkButton from '@/components/button/link-button.vue';

import { appConfig } from '@/shared/lib/config/app-config';
import mailto from '@/shared/lib/util/mailto-helper';

export default {
  name: 'ApplyButton',
  components: {
    LinkButton
  },
  props: {
    color: {
      type: String,
      default: 'green'
    },
    size: {
      type: String,
      default: 's'
    },
    mailText: {
      type: String,
      default: ''
    },
    reason: {
      type: String,
      default: ''
    },
    reasonText: {
      type: String,
      default: ''
    }
  },
  computed: {
    link() {
      const accountingEmail = appConfig.get('accountingEmail');
      const orgName = window.Organization?.account.name;
      if (this.mailText) {
        return mailto(this.mailText, accountingEmail, {
          orgName
        });
      }
      const tariffName = window.LicenseInfo?.current_license.tariff_group.name;
      // eslint-disable-next-line no-restricted-syntax
      const reason = this.reasonText || this.$trlMessage(this.reason);

      const subjectAppend = reason
        ? `: ${reason} (${orgName} ${this.$i18n('now')} ${tariffName})`
        : ` (${orgName})`;
      return mailto('promo.tariff_upgrade', accountingEmail, {
        subject: subjectAppend
      });
    }
  }
};
</script>

<i18n lang="json">
{
  "upgrade": {
    "ru_RU": "Заявка на смену тарифа",
    "en_US": "Apply to change your plan"
  },
  "now": {
    "ru_RU": "сейчас",
    "en_US": "now"
  }
}
</i18n>
