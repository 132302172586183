<template>
  <component :is="calculatedTag" v-bind="$attrs" type="button" :href="to" :class="className">
    <base-icon v-if="icon" :type="icon" :width="iconSize" :height="iconSize" :class="$style.icon" />
    <span :class="$style.content">
      <slot />
    </span>
    <span v-if="loading" :class="$style.loader">
      <base-loader :size="loaderSize" :color="loaderColor" />
    </span>
  </component>
</template>

<script>
import BaseIcon from '@/components/icon.vue';
import BaseLoader from '@/components/loader/loader.vue';

import { COLORS, ICON_SIZE, LOADER_COLOR, LOADER_SIZE, SIZES } from './constants';

export default {
  name: 'LinkButton',
  components: {
    BaseLoader,
    BaseIcon
  },
  props: {
    color: {
      type: String,
      default: 'gray',
      validator(value) {
        return COLORS.hasOwnProperty(value);
      }
    },
    size: {
      type: String,
      default: 's',
      validator(value) {
        return SIZES.hasOwnProperty(value);
      }
    },
    to: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    }
  },
  computed: {
    className() {
      return {
        [this.$style.button]: true,
        [this.$style[this.color]]: true,
        [this.$style[this.size]]: true,
        [this.$style.loading]: this.loading
      };
    },
    calculatedTag() {
      if (this.disabled) {
        return 'button';
      }
      return 'a';
    },
    iconSize() {
      return ICON_SIZE[this.size];
    },
    loaderSize() {
      return LOADER_SIZE[this.size];
    },
    loaderColor() {
      return LOADER_COLOR[this.color];
    }
  }
};
</script>

<style module src="./button.module.css" />

<i18n lang="json">{}</i18n>
