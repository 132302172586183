import { PipelinesAPI } from '@/shared/api/pipelines.ts';

import config from './config';
import createStore from './create-store';

window.STORE = createStore({
  ...config,
  state: {
    __main__: true
  }
});

export default window.STORE;

// TODO: Перенести запросы в компоненты, когда запросы будут возвращать значения с бэкенда.
//  Сейчас если будем запрашивать в компонентах, то при изменении значений они не будут обновляться на других страницах/компонентах
PipelinesAPI.getStatuses().then((statuses) => {
  window.STORE.commit('pipelines/updateStatuses', statuses);
});
PipelinesAPI.getPipelines().then((pipelines) => {
  window.STORE.commit('pipelines/updatePipelines', pipelines);
});
