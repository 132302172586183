<template>
  <aside :class="$style.aside">
    <template v-if="show">
      <div :class="[$style.sidebar, { [$style.loaded]: isLoaded }]" data-qa="sidebar">
        <div id="sidebar-content" class="g-scrollable" :class="$style.sidebarContent">
          <sidebar-default-content />
        </div>
      </div>
    </template>
    <div id="search-counter" />
    <div
      v-show="isLoaded"
      ref="resizeControl"
      :class="{ [$style.resizeControl]: true, [$style.hiddenResizeControl]: !show }"
    />
    <div ref="resizeContainer" :class="$style.resizeContainer" />
  </aside>
</template>

<script>
import { initMenuResize } from '@/components/page-layout/sidebar-resize';
import SidebarDefaultContent from '@/modules/sidebar/default-content.vue';

export default {
  name: 'SidebarLayout',
  components: { SidebarDefaultContent },
  props: {
    isLoaded: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {
    setTimeout(() => {
      this.initResize();
    }, 100);
  },
  beforeUnmount() {
    this.resize?.destroy();
  },
  methods: {
    initResize() {
      if (!this.$refs.resizeContainer) {
        return;
      }
      this.resize = initMenuResize({
        container: this.$refs.resizeContainer,
        control: this.$refs.resizeControl,
        placeholder: this.$refs.resizePlaceholder
      });
    }
  }
};
</script>

<style lang="less" module>
@import '~@less/common/variables';
@import '~@less/common/mixins/font';
@import '~@less/common/mixins/flex';

.menuCommon() {
  min-width: 250px;
  align-self: auto;
  flex: 0 0 auto;
  max-width: 400px;
}

.aside {
  .menuCommon();
  width: calc(1px * min(var(--sidebar-width, 250), var(--sidebar-max-width)));
  display: flex;
  flex-direction: column;
  position: relative;
  transition: opacity 0.2s ease-in-out;
}

.sidebar {
  .menuCommon();
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  flex: 1;
  display: block;
  min-width: auto;
  background: var(--sidebar-background, @black);
  background-image: none;
  position: relative;
  z-index: 1;
  border-radius: 16px;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: var(--sidebar-background, @black);
    opacity: 0;
    pointer-events: none;
  }

  &.loaded::before {
    opacity: 1;
    transition: opacity 400ms;
  }
}

.sidebarContent {
  overflow: auto;
  padding: 0 14px 90px 14px;
  -webkit-overflow-scrolling: touch;
  user-select: none;
  height: 100%;
}

.resizeContainer {
  position: absolute;
  left: 242px;
  width: 160px;
  top: 0;
  z-index: 9;
}

.resizeControl {
  position: absolute;
  right: 0;
  top: 16px;
  bottom: 16px;
  width: 8px;
  box-sizing: border-box;
  margin-left: 0px;
  z-index: 10;
  cursor: col-resize;
  left: 100% !important; // не очень приятный хак чтобы dragabilly ручку не таскал
  transform: none !important; // не очень приятный хак чтобы dragabilly ручку не таскал

  &::before {
    opacity: 0.2;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;

    content: '';
    background-image: url(./dots.svg);
    background-position: center;
    background-repeat: no-repeat;
  }

  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: 0;
    width: 3px;
    background-color: transparent;
    transition: background-color 0.2s;
    border-radius: 3px;
    transform: translateX(-50%);
  }

  &:hover,
  &:global(.is-pointer-down),
  &:global(.is-dragging) {
    &::after {
      background-color: #25cfe8;
    }
  }
}

@media screen and (max-width: 1150px) {
  .resizeControl {
    visibility: hidden;
    pointer-events: none;
  }
}

.hiddenResizeControl {
  visibility: hidden;
  pointer-events: none;
}
</style>

<i18n lang="json">{}</i18n>
