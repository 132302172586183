<template>
  <div :class="$style.root">
    <div :class="$style.content">
      <base-icon type="attention-thin" :class="$style.icon" />
      <div>
        <h3 :class="$style.caption">
          <slot name="caption" />
        </h3>
        <div :class="$style.description">
          <slot name="description" />
        </div>
      </div>
    </div>
    <div :class="$style.action">
      <slot name="action" />
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/components/icon.vue';

export default {
  name: 'AnnoyingBanner',
  components: { BaseIcon }
};
</script>

<style module lang="less">
.root {
  background-color: #ff8e7d;
  padding: 33px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content {
  display: flex;
  align-items: center;
  gap: 25px;
}

.caption {
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  margin: 0 0 4px;
}

.description {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.caption,
.description {
  a {
    color: inherit;
    text-decoration: underline;
    white-space: nowrap;
  }
}

.action {
  margin-left: 20px;
  a {
    white-space: nowrap;
  }
}

.icon {
  min-width: 46px;
}
</style>

<i18n lang="json">{}</i18n>
