<template>
  <base-dropdown
    :id="id"
    :shown="dropdownShown"
    :flip="dropdownFlip"
    :reference="dropdownReference"
    :add-layer="addLayer"
    :data-qa="dataQa"
    :max-height="maxHeight"
    :placement="dropdownPlacement"
    @shown="$emit('show')"
    @hidden="hide"
    @change="$emit('visibility-state', $event)"
  >
    <template #default="{ toggle, show, shown }">
      <hierarchy-value
        :hierarchy-map="fullHierarchyMap"
        :multiple="multiple"
        :only-leaf="onlyLeaf"
        :value="value"
        :selected-items="selectedItems"
      >
        <template #default="{ count, allValue }">
          <toggle-text
            :value="allValue"
            :required="required"
            :placeholder="placeholder"
            :multiple="multiple"
            :loading="loading"
            :count="count"
          >
            <template #default="{ text, showText }">
              <slot
                :id="id"
                name="toggle"
                :loading="loading"
                :disabled="disabled"
                :invalid="invalid"
                :count="count"
                :value="allValue"
                :multiple="multiple"
                :placeholder="placeholder"
                :required="required"
                :toggle="toggle"
                :show="show"
                :shown="shown"
                :text="text"
                :show-text="showText"
                :selected-items="selectedItems"
                :next="next"
                :prev="prev"
                :enter="enter"
                :reset="reset"
              >
                <toggle-button
                  :id="id"
                  :data-qa="name"
                  :text="text"
                  :name="name"
                  :invalid="invalid"
                  :disabled="disabled"
                  @click="toggle"
                >
                  <template #default>
                    <slot
                      name="toggle-text"
                      :count="count"
                      :value="allValue"
                      :multiple="multiple"
                      :selected-items="selectedItems"
                    >
                      <template v-if="!multiple && selectedItems">
                        {{ selectedItems[valueField] }}
                      </template>
                    </slot>
                  </template>
                  <template #icon>
                    <slot name="toggle-icon" />
                  </template>
                </toggle-button>
              </slot>
            </template>
          </toggle-text>
        </template>
      </hierarchy-value>
    </template>
    <template #content="{ hide: dropdownHide, maxHeight: dropdownMaxHeight }">
      <dropdown-menu-layout :size="dropdownSize" data-qa="dropdown-autocomplete-content">
        <template #content>
          <slot name="content" :toggle="dropdownHide">
            <base-combobox
              ref="combobox"
              :items="items"
              :loading="loading"
              :page-mode="pageMode"
              :multiple="multiple"
              :value="value"
              :max-height="dropdownMaxHeight"
              :item-size="itemSize"
              :hierarchy-map="currentHierarchyMap"
              :show-inactive="showInactive"
              :only-leaf="onlyLeaf"
              :max-values="maxValues"
              :activity-toggle="activityToggle"
              :clearable="clearable"
              :query="query"
              :loop-pointer="loopPointer"
              :placeholder="searchPlaceholder"
              :caption-field="captionField"
              :disable-auto-compress="disableAutoCompress"
              focus
              @change="handleChange($event, dropdownHide)"
              @search="$emit('search', $event)"
              @reset="$emit('reset')"
              @toggle-activity-toggle="$emit('toggle-activity-toggle', $event)"
            >
              <template #before="props">
                <slot name="before" v-bind="props" />
              </template>
              <template #before-search>
                <slot name="before-search" />
              </template>
              <template #search="props">
                <slot name="search" v-bind="props" />
              </template>
              <template #after-search>
                <slot name="after-search" />
              </template>
              <template #reset>
                <slot name="reset" />
              </template>
              <template #not-found>
                <slot name="not-found" />
              </template>
              <template #loading>
                <slot name="loading" />
              </template>
              <template #item-header="props">
                <slot name="item-header" v-bind="props" />
              </template>
              <template #item-header-title="props">
                <slot name="item-header-title" v-bind="props" />
              </template>
              <template #item="props">
                <slot name="item" v-bind="props" :toggle="dropdownHide" />
              </template>
              <template #item-icon="props">
                <slot name="item-icon" v-bind="props" />
              </template>
              <template #item-before-content="props">
                <slot name="item-before-content" v-bind="props" />
              </template>
              <template #item-after-content="props">
                <slot name="item-after-content" v-bind="props" :toggle="dropdownHide" />
              </template>
              <template #item-title="props">
                <slot name="item-title" v-bind="props" />
              </template>
              <template #item-hint="props">
                <slot name="item-hint" v-bind="props" />
              </template>
              <template #item-subtitle="props">
                <slot name="item-subtitle" v-bind="props" />
              </template>
              <template #after="props">
                <slot name="after" v-bind="props" />
              </template>
              <template #after-list="props">
                <slot name="after-list" v-bind="props" />
              </template>
            </base-combobox>
          </slot>
        </template>
      </dropdown-menu-layout>
    </template>
  </base-dropdown>
</template>

<script>
import { computed, toRefs } from 'vue';

import { BaseCombobox } from '@/components/base-combobox';
import DropdownMenuLayout from '@/components/dropdown-menu-layout/dropdown-menu-layout.vue';
import HierarchyValue from '@/components/hierarchy/hierarchy-value.vue';

import BaseDropdown from '@/shared/ui/base-dropdown/base-dropdown.vue';

import { useRemoveNonexistent } from './composables/use-remove-nonexistent';
import { useSelectedItems } from './composables/use-selected-items';
import { DEFAULT_MAX_HEIGHT } from './constants';
import ToggleButton from './toggle-button.vue';
import ToggleText from './toggle-text.vue';

export default {
  name: 'DropdownAutocomplete',
  components: {
    DropdownMenuLayout,
    HierarchyValue,
    ToggleText,
    ToggleButton,
    BaseDropdown,
    BaseCombobox
  },
  props: {
    loading: BaseCombobox.props.loading,
    pageMode: BaseCombobox.props.pageMode,
    items: BaseCombobox.props.items,
    itemSize: BaseCombobox.props.itemSize,
    showInactive: BaseCombobox.props.showInactive,
    hierarchyMap: BaseCombobox.props.hierarchyMap,
    hierarchyMapWithInactive: {
      type: Object,
      default: null
    },
    loopPointer: BaseCombobox.props.loopPointer,
    multiple: BaseCombobox.props.multiple,
    value: BaseCombobox.props.value,
    maxValues: BaseCombobox.props.maxValues,
    onlyLeaf: BaseCombobox.props.onlyLeaf,
    searchPlaceholder: BaseCombobox.props.placeholder,
    clearable: BaseCombobox.props.clearable,
    query: BaseCombobox.props.query,
    maxHeight: {
      type: BaseCombobox.props.maxHeight.type,
      default: DEFAULT_MAX_HEIGHT
    },
    activityToggle: BaseCombobox.props.activityToggle,
    captionField: BaseCombobox.props.captionField,
    valueField: BaseCombobox.props.captionField,
    disableAutoCompress: BaseCombobox.props.disableAutoCompress,
    id: BaseDropdown.props.id,
    dataQa: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    dropdownSize: {
      type: [String, Number],
      default: 'larger',
      validator: DropdownMenuLayout.props.size.validator
    },
    dropdownFlip: BaseDropdown.props.flip,
    dropdownShown: BaseDropdown.props.shown,
    dropdownReference: BaseDropdown.props.reference,
    addLayer: BaseDropdown.props.addLayer,
    dropdownPlacement: BaseDropdown.props.placement,
    closeAfterSelect: {
      type: Boolean,
      default: true
    },
    name: {
      type: String,
      default: undefined
    },
    disabled: Boolean,
    required: Boolean,
    invalid: Boolean,
    ignoreSameValueCheck: Boolean
  },
  emits: [
    'show',
    'hide',
    'visibility-state',
    'search',
    'reset',
    'toggle-activity-toggle',
    'change'
  ],
  setup(props, { emit }) {
    const {
      query,
      items,
      hierarchyMap,
      hierarchyMapWithInactive,
      value,
      multiple,
      loading,
      showInactive
    } = toRefs(props);
    const fullHierarchyMap = computed(() => hierarchyMapWithInactive.value ?? hierarchyMap.value);
    const currentHierarchyMap = computed(() => {
      if (showInactive.value && hierarchyMapWithInactive.value) {
        return hierarchyMapWithInactive.value;
      }
      return hierarchyMap.value;
    });
    const parentChildMap = computed(() => fullHierarchyMap.value.parentChildMap);

    const { selectedItems, normalizedValue } = useSelectedItems(items, {
      showInactive: true,
      parentChildMap,
      multiple,
      query,
      value
    });

    useRemoveNonexistent(normalizedValue, {
      loading,
      parentChildMap,
      onChange(newValue) {
        emit('change', newValue);
      }
    });

    return {
      selectedItems,
      currentHierarchyMap,
      fullHierarchyMap
    };
  },
  methods: {
    handleChange(value, onToggle) {
      this.$emit('change', value);
      if (
        !this.multiple
        && (this.value !== value || this.ignoreSameValueCheck)
        && this.closeAfterSelect
      ) {
        onToggle();
      }
    },
    hide() {
      this.$emit('hide');
      this.$emit('search', '');
    },
    next() {
      this.$refs.combobox?.next();
    },
    prev() {
      this.$refs.combobox?.prev();
    },
    enter() {
      this.$refs.combobox?.enter();
    },
    reset() {
      this.$refs.combobox?.reset();
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
