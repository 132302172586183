<template>
  <modal-wrapper :open="showModal" @change="showModal = $event">
    <template #default="{ hide }">
      <popup-layout :title="title">
        <template #main>
          <span
            :class="$style.message"
            v-html="$trlMessage('documents_request.confirmation_modal.text')"
          />
        </template>

        <template #footer>
          <base-button data-qa="close" @click="hide">
            {{ $trlMessage('button.close') }}
          </base-button>
        </template>
      </popup-layout>
    </template>
  </modal-wrapper>
</template>

<script>
import BaseButton from '@/components/button/button.vue';
import ModalWrapper from '@/components/modal-next/modal-wrapper.vue';
import PopupLayout from '@/components/popup-layout/popup-layout.vue';

export default {
  name: 'DocumentsModal',
  components: {
    ModalWrapper,
    PopupLayout,
    BaseButton
  },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    }
  },
  emits: ['change'],
  computed: {
    showModal: {
      get() {
        return this.open;
      },
      set(flag) {
        this.$emit('change', flag);
      }
    }
  }
};
</script>

<style lang="less" module>
.message {
  display: block;
  margin-bottom: 10px;
}
</style>

<i18n lang="json">{}</i18n>
