<template>
  <div :class="$style.menu" :style="{ maxHeight }">
    <virtual-scroller
      ref="scroller"
      :class="$style.scroller"
      :key-field="dataKey"
      :min-item-size="42"
      :items="items"
    >
      <template #default="{ item }">
        <autocomplete-menu-item
          :data-key="dataKey"
          :source="item"
          :active-item="activeItem"
          :checked-items="checkedItems"
        >
          <template #item="scopedProps">
            <slot name="item" v-bind="scopedProps" />
          </template>
          <template #emptyItem="scopedProps">
            <slot name="emptyItem" v-bind="scopedProps" />
          </template>
          <template #groupItem="scopedProps">
            <slot name="groupItem" v-bind="scopedProps" />
          </template>
        </autocomplete-menu-item>
      </template>
    </virtual-scroller>
  </div>
</template>

<script>
import { ref } from 'vue';

import VirtualScroller from '@/shared/ui/virtual-scroller/virtual-scroller.vue';

import AutocompleteMenuItem from './autocomplete-menu-item.vue';

// TODO: временная обёртка, используйте `@/components/base-list/base-list.vue` или его производные
export default {
  name: 'AutocompleteMenu',
  components: {
    VirtualScroller,
    AutocompleteMenuItem
  },
  props: {
    dataKey: {
      type: String,
      default: 'id'
    },
    items: {
      type: Array,
      default: undefined
    },
    maxHeight: {
      type: String,
      default: '360px'
    },
    checkedItems: {
      type: Array,
      default: () => []
    },
    activeItem: {
      type: Object,
      default: undefined
    }
  },
  setup(props) {
    const scroller = ref(null);
    const scrollToIndex = (index) => {
      return scroller.value.scrollToIndex(index);
    };

    const scrollToItem = (item) => {
      const index = props.items?.indexOf(item);
      if (index >= 0) {
        scrollToIndex(index);
      }
    };

    return {
      scroller,
      scrollToIndex,
      scrollToItem
    };
  }
};
</script>

<style lang="less" module>
.menu {
  height: 100%;
  display: flex;
  flex-direction: column;

  &,
  &:focus {
    outline: none;
  }
}
.scroller {
  outline: none;
  flex-grow: 1;
  text-align: left;
}
</style>

<i18n lang="json">{}</i18n>
