import type { CancelablePromise } from '@/shared/api/utils/cancelable-promise';
import type { AxiosClient } from '@/shared/api/utils/client/axios';
import type { LegacySchema } from '@/shared/types/legacy-schema';

import { ApiLayer } from '@/shared/api/utils/api-layer';
import axios from '@/shared/api/utils/client/axios';

import { mapSchemaFields } from './map-schema-fields';

class VacancyLegacySchemaLayer extends ApiLayer<AxiosClient> {
  constructor(client: AxiosClient) {
    super(client);
    this.get = this.memoizeMethod(this.get);
  }

  get(): CancelablePromise<LegacySchema> {
    return this.methods
      .get<LegacySchema<{ name: string }>>('/vacancy/fields')
      .then(mapSchemaFields);
  }
}

export const VacancyLegacySchemaApi = new VacancyLegacySchemaLayer(axios);
