import type { Delete } from './utils/default-responses';
import type { AxiosClient } from '@/shared/api/utils/client/axios';
import type { ItemList } from '@/shared/api/utils/types';

import { ApiLayer } from '@/shared/api/utils/api-layer';
import { axiosApp } from '@/shared/api/utils/client/axios';

export const enum SocialTypes {
  TELEGRAM = 'TELEGRAM'
}

type TemplateType = 'personal' | 'organization';

type ITemplatePayload = {
  body: string;
  name: string;
  // возможно надо подрихтовать в типах и по коду:
  // сейчас если отправить непустой divisions и type "personal",
  // то с бэка ошибки не будет, но divisions будут проигнорированы —
  // в ответе будет пустой массив
  divisions: number[];
  type: TemplateType;
};

type ITemplateResponse = {
  id: number;
  body: string;
  name: string;
  member: number;
  removed: null;
  type: TemplateType;
  divisions: Array<{ id: number }>;
};

class ImLayer extends ApiLayer<AxiosClient> {
  check(imId: number, applicantId: number) {
    const url = `/im/${imId}/applicant/${applicantId}/check`;
    return this.methods.get<unknown>(url);
  }

  fetchTemplateList(options?: { editableOnly: boolean }) {
    const url = '/im/template';
    const params = options?.editableOnly ? { edit: true } : {};
    return this.methods
      .get<ItemList<ITemplateResponse>>(url, {
        params
      })
      .then(({ items }) => items);
  }

  createTemplate(template: ITemplatePayload) {
    const url = '/im/template';
    return this.methods.post<ITemplateResponse>(url, template);
  }

  updateTemplate(id: number, template: ITemplatePayload) {
    const url = `/im/template/${id}`;
    return this.methods.put<ITemplateResponse>(url, template);
  }

  deleteTemplate(id: number) {
    const url = `/im/template/${id}`;
    return this.methods.delete<Delete>(url);
  }
}

export const ImAPI = new ImLayer(axiosApp);
