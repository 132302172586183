import type { Ref } from 'vue';

import type {
  DictionaryUpdateInfo,
  PartialDictionaryLayer
} from '@/shared/api/dictionary-partial/layer';
import type {
  TreeId,
  DictionaryItem,
  DictionaryStructure,
  SearchDictionaryItem
} from '@/shared/api/dictionary-partial/types';
import type { CancelablePromise } from '@/shared/api/utils/cancelable-promise';
import type { CursorList } from '@/shared/api/utils/types';

import { ref, computed, watch } from 'vue';

import { normalizeValue } from '@/components/base-list/normalize-value';
import { makeHierarchyMap } from '@/components/hierarchy/hierarchy-map';

import {
  TREE_ROOT_ID
} from '@/shared/api/dictionary-partial/types';
import { MessageEvent } from '@/shared/types/poller-message';

type DictionaryResourceOptions = {
  dictionaryId: Ref<TreeId>;
  apiMethods: PartialDictionaryLayer;
  multiple?: Ref<boolean>;
  currentValue?: Ref<any>;
  autoUpdateOutdated?: Ref<boolean>;
  inactiveSelectable?: boolean;
  showInactive?: Ref<boolean>;
  onlyAvailable?: Ref<boolean>;
  query: Ref<string>;
  showRoot: boolean;
};

type GetDictionary = {
  parentId?: TreeId;
  cursor?: string;
};

type DictionaryMeta = {
  levels: number;
  has_inactive: boolean;
  [key: number]: any;
};

export function useDictionaryResource({
  dictionaryId,
  apiMethods,
  currentValue = ref(undefined),
  autoUpdateOutdated = ref(true),
  inactiveSelectable,
  showInactive = ref(false),
  onlyAvailable = ref(true),
  multiple = ref(false),
  query,
  showRoot = false
}: DictionaryResourceOptions) {
  const parentChildMapWithInactive = ref<Record<TreeId, TreeId>>({});
  const parentChildMap = ref<Record<TreeId, TreeId>>({});

  const meta = ref<Partial<DictionaryMeta>>({});
  const structureChangeInfo = ref<DictionaryUpdateInfo | null>(null);

  const structureLoading = ref(false);

  const isInactiveId = (id: number) => {
    return id in parentChildMapWithInactive.value && !(id in parentChildMap.value);
  };

  const forceShowInactive = ref(false);

  const handleStructure = ([
    { meta: newMeta, items },
    { items: structureWithoutInactive }
  ]: DictionaryStructure[]) => {
    const rootId = TREE_ROOT_ID;
    const structWithInactive = { ...items };
    const structWithoutInactive = { ...structureWithoutInactive };

    for (const key in structWithInactive) {
      structWithInactive[key] = structWithInactive[key] === null ? rootId : structWithInactive[key];
    }

    for (const key in structWithoutInactive) {
      structWithoutInactive[key]
        = structWithoutInactive[key] === null ? rootId : structWithoutInactive[key];
    }

    parentChildMap.value = Object.freeze(structWithoutInactive);
    parentChildMapWithInactive.value = Object.freeze(structWithInactive);

    meta.value = newMeta || {};

    const normalizedValue = normalizeValue(currentValue?.value, {
      multiple: multiple?.value
    });

    let forceInactive;

    if (multiple?.value && Array.isArray(normalizedValue)) {
      forceInactive = !normalizedValue.some((id: any) => !isInactiveId(id));
    } else {
      forceInactive = isInactiveId(normalizedValue as number);
    }

    if (forceInactive) {
      forceShowInactive.value = forceInactive;
    }

    structureChangeInfo.value = null;
    return { meta: meta.value, items };
  };

  const searchMode = ref<boolean>(false);

  // Request

  const prepareResult = <T extends DictionaryItem>(result: CursorList<T>): CursorList<T> => {
    result.items.forEach((item) => {
      if (inactiveSelectable) {
        item.available = true;
      } else if (!item.active) {
        item.available = false;
      }
    });
    return result;
  };

  // - Structure
  const updateStructure = () => {
    structureLoading.value = true;
    return Promise.all([
      apiMethods.getStructure(),
      apiMethods.getStructure({ include_inactive: false })
    ])
      .then(handleStructure)
      .finally(() => {
        structureLoading.value = false;
      });
  };

  const handleDictionaryUpdated = (_: any, message: any) => {
    const messageInfo = apiMethods.checkMessage(message);
    if (!messageInfo.matched) {
      return;
    }
    if (messageInfo.type === MessageEvent.dictionaryUpdated) {
      structureChangeInfo.value = messageInfo.data;
      autoUpdateOutdated?.value && updateStructure();
    }
  };

  // - Data
  const getTree = ({
    parentId,
    cursor
  }: GetDictionary): CancelablePromise<CursorList<DictionaryItem>> => {
    return apiMethods
      .getTree({
        parent_id: parentId === TREE_ROOT_ID ? undefined : (parentId as number),
        include_inactive: forceShowInactive.value || showInactive.value,
        next_page_cursor: cursor,
        only_available: onlyAvailable.value
      })
      .then(prepareResult);
  };

  const getSearch = ({
    cursor
  }: GetDictionary): CancelablePromise<CursorList<SearchDictionaryItem>> => {
    return apiMethods
      .getSearch({
        query: query.value,
        include_inactive: forceShowInactive.value || showInactive.value,
        next_page_cursor: cursor,
        only_available: onlyAvailable.value
      })
      .then(prepareResult)
      .then((result: any) => {
        result.items.forEach((item: any) => {
          item.disabled = !item.name_highlight;
        });
        return result;
      });
  };

  const getList = ({ cursor }: GetDictionary): CancelablePromise<CursorList<DictionaryItem>> => {
    return apiMethods
      .getList({
        include_inactive: forceShowInactive.value || showInactive.value,
        next_page_cursor: cursor,
        only_available: onlyAvailable.value
      })
      .then(prepareResult);
  };

  // Initialize
  watch(
    dictionaryId,
    (newDictionaryId, oldDictionaryId) => {
      if (newDictionaryId !== oldDictionaryId) {
        updateStructure();
      }
    },
    { immediate: true }
  );

  // Hierarchy maps
  const hierarchyMap = computed(() => {
    if (showRoot) {
      return makeHierarchyMap({ ...parentChildMap.value, [TREE_ROOT_ID]: '_' });
    }
    return makeHierarchyMap(parentChildMap.value);
  });
  const hierarchyMapWithInactive = computed(() => {
    if (showRoot) {
      return makeHierarchyMap({ ...parentChildMapWithInactive.value, [TREE_ROOT_ID]: '_' });
    }
    return makeHierarchyMap(parentChildMapWithInactive.value);
  });

  return {
    // State
    parentChildMapWithInactive,
    parentChildMap,
    meta,
    structureChangeInfo,
    structureLoading,
    hierarchyMap,
    hierarchyMapWithInactive,

    // Computed
    searchMode,

    // Methods
    getList,
    getTree,
    getSearch,
    updateStructure,
    handleDictionaryUpdated
  };
}
