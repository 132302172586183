import type { AxiosRequestHeaders } from 'axios';

import type { AxiosClient } from './utils/client/axios';

import pickBy from 'lodash/pickBy';

import { ApiLayer } from './utils/api-layer';
import axios from './utils/client/axios';

export type UploadOptions = {
  preset?: string;
  parse?: boolean;
  baseURL?: string;
};

// на странице заполнения анкеты неавторизованным пользователем (кандидатом)
// в ответе на /upload приходит сокращённая информация (без url)
// для предотвращения использования ХФ в качестве файлохранилища
export type IFileInfoShort = {
  id: number;
  size: number;
  name: string;
  content_type: string;
};

type IFileInfo = IFileInfoShort & {
  bucket: 'uploads';
  original: string;
  host: string;
  path: string;
  account: number;
  url: string;
  flag: null;
};

class FileLayer extends ApiLayer<AxiosClient> {
  upload(file: File, { preset = '', parse = false, baseURL }: UploadOptions = {}) {
    const headers: AxiosRequestHeaders = {};
    if (parse) {
      headers['X-File-Parse'] = true;
    }
    const formData = new FormData();
    formData.set('file', file);
    formData.set('_file', file.name);
    formData.set('preset', preset);
    const options = pickBy(
      {
        headers,
        baseURL
      },
      (p) => p
    );
    return this.methods.post<IFileInfo | IFileInfoShort>('/upload', formData, options);
  }
}

export const FileAPI = new FileLayer(axios);
