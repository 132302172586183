import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, mergeProps as _mergeProps, resolveDynamicComponent as _resolveDynamicComponent, toHandlers as _toHandlers } from "vue"

const _hoisted_1 = ["title", "innerHTML"]
const _hoisted_2 = ["title", "innerHTML"]

import { computed, ref, toRefs } from 'vue';

import { DropdownAutocomplete, ModalAutocomplete } from '@/components/base-autocomplete';
import ToggleButton from '@/components/base-autocomplete/toggle-button.vue';
import LinkButton from '@/components/button/link-button.vue';
import SelectButtonText from '@/components/dictionary/select-button-text.vue';
import NotifierMessage from '@/components/hf/notifier-message/notifier-message.vue';
import IntersectionObserver from '@/components/intersection-observer/intersection-observer.vue';
import SpinnerItem from '@/components/list-item/spinner-item.vue';
import useDictionary from '@/components/partial-dictionary-autocomplete/lib/useDictionary';

import { DIVISION_DICTIONARY } from '@/shared/api/dictionary-partial';
import { userPermissions } from '@/shared/lib/config/user-permissions';
import { useI18N } from '@/shared/lib/use-i18n';

import StructureUpdated from './structure-updated.vue';
import ToggleText from './toggle-text.vue';

type PartialDictionaryProps = {
  showInDropdown?: boolean;
  dictionaryId: string;
  multiple?: boolean;
  value: any;
  autoUpdateOutdated?: boolean;
  multipleLabel: string;
  multipleLabelDefaultUnselected?: boolean;
  pageMode?: boolean;
  maxHeight?: number;
  onlyLeaf?: boolean;
  maxValues?: number;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  invalid?: boolean;
  dropdownSize?: string;
  name?: string;
  disableAutoCompress?: boolean;
  id?: string;
  modalTitle?: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'partial-dictionary-autocomplete',
  props: {
    showInDropdown: { type: Boolean, default: false },
    dictionaryId: {},
    multiple: { type: Boolean, default: false },
    value: {},
    autoUpdateOutdated: { type: Boolean, default: false },
    multipleLabel: {},
    multipleLabelDefaultUnselected: { type: Boolean },
    pageMode: { type: Boolean },
    maxHeight: {},
    onlyLeaf: { type: Boolean },
    maxValues: {},
    placeholder: {},
    required: { type: Boolean },
    disabled: { type: Boolean },
    invalid: { type: Boolean },
    dropdownSize: {},
    name: {},
    disableAutoCompress: { type: Boolean },
    id: {},
    modalTitle: {}
  },
  emits: ['reset', 'update:value'],
  setup(__props: any, { emit: __emit }) {



const emit = __emit;

const i18n = useI18N();

const visisbilityState = ref(false);

const props = toRefs({
  dictionaryId: __props.dictionaryId,
  multiple: __props.multiple,
  value: __props.value,
  autoUpdateOutdated: __props.autoUpdateOutdated
});

const {
  structureChangeInfo,
  items,
  itemsLoading,
  structureLoading,
  disabled: resourceDisabled,
  query,
  setQuery,
  handleShowInactive,
  hasInactive,
  showInactive,
  hierarchyMap,
  hierarchyMapWithInactive,
  searchMode,
  canLoadMoreByLevel,
  cursorByLevel,
  pollerListeners,
  next,
  showInModal,
  isEmpty,
  updateStructure
} = useDictionary({
  dictionaryId: props.dictionaryId,
  multiple: props.multiple,
  currentValue: props.value,
  inactiveSelectable: true,
  autoUpdateOutdated: props.autoUpdateOutdated
});

const component = computed(() => {
  if (__props.showInDropdown) {
    return DropdownAutocomplete;
  }
  return showInModal.value ? ModalAutocomplete : DropdownAutocomplete;
});

const dictionarySettingsLink = computed(() => {
  const type
    = __props.dictionaryId === DIVISION_DICTIONARY ? 'dictionary-list' : 'additional-dictionary-list';
  return `/settings/orgs/${userPermissions.get('nick')}/${type}/${__props.dictionaryId}`;
});

const forceUpdateStructure = () => {
  updateStructure();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(component.value), {
      id: _ctx.id,
      items: _unref(items),
      loading: _unref(itemsLoading),
      "page-mode": _ctx.pageMode,
      multiple: _ctx.multiple,
      value: _ctx.value,
      "max-height": _ctx.maxHeight,
      "hierarchy-map": _unref(hierarchyMap),
      "hierarchy-map-with-inactive": _unref(hierarchyMapWithInactive),
      "show-inactive": _unref(showInactive),
      "only-leaf": _ctx.onlyLeaf,
      "max-values": _ctx.maxValues,
      "activity-toggle": _unref(hasInactive),
      query: _unref(query),
      name: _ctx.name,
      placeholder: _ctx.placeholder,
      required: _ctx.required,
      clearable: !_ctx.required,
      disabled: _ctx.disabled || _unref(resourceDisabled),
      invalid: _ctx.invalid,
      collapsible: !_unref(searchMode),
      title: _ctx.modalTitle,
      "dropdown-size": _ctx.dropdownSize,
      show: visisbilityState.value,
      "disable-auto-compress": _ctx.disableAutoCompress,
      onSearch: _unref(setQuery),
      onReset: _cache[0] || (_cache[0] = ($event: any) => (emit('reset'))),
      onToggleActivityToggle: _unref(handleShowInactive),
      onChange: _cache[1] || (_cache[1] = ($event: any) => (emit('update:value', $event))),
      onVisibleChange: _cache[2] || (_cache[2] = ($event: any) => (visisbilityState.value = $event))
    }, {
      content: _withCtx(() => [
        _renderSlot(_ctx.$slots, "content", {}, () => [
          (_unref(structureChangeInfo))
            ? (_openBlock(), _createBlock(StructureUpdated, {
                key: 0,
                info: _unref(structureChangeInfo),
                onUpdate: forceUpdateStructure
              }, null, 8, ["info"]))
            : (_unref(isEmpty))
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass(_ctx.$style.noItems)
                }, [
                  _createElementVNode("div", null, _toDisplayString(_unref(i18n)('no-items')), 1),
                  _cache[3] || (_cache[3] = _createTextVNode()),
                  _createVNode(LinkButton, {
                    to: dictionarySettingsLink.value,
                    target: "_blank"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(i18n)('setup-dictionary')), 1)
                    ], undefined, true),
                    _: 1
                  }, 8, ["to"])
                ], 2))
              : _createCommentVNode("", true)
        ])
      ]),
      toggle: _withCtx((scopedProps) => [
        _createVNode(ToggleText, {
          text: scopedProps.text,
          "show-text": scopedProps.showText,
          count: scopedProps.count,
          value: scopedProps.value,
          loading: _unref(structureLoading),
          multiple: scopedProps.multiple,
          "multiple-text": _ctx.multipleLabel,
          "dictionary-id": _ctx.dictionaryId,
          items: _unref(items),
          "hierarchy-map": _unref(hierarchyMapWithInactive)
        }, {
          default: _withCtx(({ text, count }) => [
            _renderSlot(_ctx.$slots, "toggle", _mergeProps(scopedProps, {
              text: text,
              count: count
            }), () => [
              _createVNode(ToggleButton, {
                id: _ctx.id || '',
                invalid: _ctx.invalid || false,
                disabled: _ctx.disabled || _unref(structureLoading),
                name: _ctx.name || '',
                text: text,
                onClick: scopedProps.toggle
              }, {
                icon: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "toggle-icon")
                ]),
                default: _withCtx(() => [
                  _cache[4] || (_cache[4] = _createTextVNode()),
                  _createVNode(SelectButtonText, {
                    multiple: !_unref(structureLoading) && scopedProps.multiple,
                    "default-unselected": _ctx.multipleLabelDefaultUnselected || false,
                    title: text,
                    count: count
                  }, null, 8, ["multiple", "default-unselected", "title", "count"])
                ], undefined, true),
                _: 2
              }, 1032, ["id", "invalid", "disabled", "name", "text", "onClick"])
            ])
          ]),
          _: 2
        }, 1032, ["text", "show-text", "count", "value", "loading", "multiple", "multiple-text", "dictionary-id", "items", "hierarchy-map"])
      ]),
      "item-title": _withCtx(({ item }) => [
        _createElementVNode("span", {
          title: item.name,
          innerHTML: item.name_highlight || item.name
        }, null, 8, _hoisted_1)
      ]),
      "item-subtitle": _withCtx(({ item }) => [
        (item.meta)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              title: item.meta.subTitle,
              innerHTML: item.meta.subTitle
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true)
      ]),
      "after-level": _withCtx(({ id }) => [
        (_unref(canLoadMoreByLevel)[id])
          ? (_openBlock(), _createBlock(IntersectionObserver, {
              key: _unref(cursorByLevel)[id],
              onAppear: ($event: any) => (_unref(next)(id))
            }, null, 8, ["onAppear"]))
          : _createCommentVNode("", true),
        _cache[5] || (_cache[5] = _createTextVNode()),
        ((_ctx.showInDropdown || !_unref(showInModal)) && _unref(itemsLoading))
          ? (_openBlock(), _createBlock(SpinnerItem, {
              key: 1,
              deep: 0
            }))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 40, ["id", "items", "loading", "page-mode", "multiple", "value", "max-height", "hierarchy-map", "hierarchy-map-with-inactive", "show-inactive", "only-leaf", "max-values", "activity-toggle", "query", "name", "placeholder", "required", "clearable", "disabled", "invalid", "collapsible", "title", "dropdown-size", "show", "disable-auto-compress", "onSearch", "onToggleActivityToggle"])),
    _cache[6] || (_cache[6] = _createTextVNode()),
    _createVNode(NotifierMessage, _toHandlers(_unref(pollerListeners)), null, 16)
  ]))
}
}

})