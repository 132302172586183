import type { Message } from './poller/poller';

import { appConfig } from './config/app-config';
import { WSNotifier } from './notifier/ws-notifier';
import Poller from './poller/poller';
import TabsBroadcastChannel from './tabsBroadcast/tabsBroadcast';

export { MessageEvent } from '@/shared/types/poller-message';

if (!window.__TABS_BROADCAST_CHANNEL__) {
  window.__TABS_BROADCAST_CHANNEL__ = new TabsBroadcastChannel();
}

const tabsBroadCast = window.__TABS_BROADCAST_CHANNEL__;

if (!window.__POLLER_INSTANCE__) {
  window.__POLLER_INSTANCE__ = new Poller(
    appConfig.get('notifierUrl'),
    appConfig.get('notifierUrl').replace('https', 'wss').replace('http', 'ws'),
    tabsBroadCast
  );

  if (appConfig.get('account')) {
    tabsBroadCast.onBecomeMaster(() => {
      window.__POLLER_INSTANCE__?.start();
    });
  }
}

if (!window.__WS_NOTIFIER_INSTANCE__) {
  window.__WS_NOTIFIER_INSTANCE__ = new WSNotifier();
}
export const notifier = window.__WS_NOTIFIER_INSTANCE__;
export const poller = window.__POLLER_INSTANCE__;

export const waitForMessage = (fn: (message: Message) => boolean) =>
  new Promise<Message>((resolve) => {
    const releasePoller = poller.onMessage((message) => {
      if (fn(message)) {
        releasePoller();
        resolve(message);
      }
    });
  });
