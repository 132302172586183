import { getCurrentInstance } from 'vue';

export function useI18N() {
  const instance = getCurrentInstance();
  // @ts-expect-error - должно быть
  const i18n = instance?.type._i18n;
  if (!i18n) {
    throw new Error('i18n не найден');
  }
  return i18n;
}
