<template>
  <component :is="component">
    <vjsf
      view-mode
      disabled
      :omit-missing-fields="omitMissingFields"
      :schema="schema"
      :ui-schema="uiSchema"
      :form-data="formData"
      :custom-formats="customFormats"
      :widgets="widgets"
    />
  </component>
</template>

<script>
import { defineComponent } from 'vue';

import InlineContainer from './inline-container.vue';
import NormalContainer from './normal-container.vue';
import Vjsf from './vjsf-form.vue';

export default defineComponent({
  name: 'VjsfView',
  components: { Vjsf },
  props: {
    schema: {
      type: Object,
      default: () => ({})
    },
    uiSchema: {
      type: Object,
      default: () => ({})
    },
    formData: {
      type: Object,
      default: () => ({})
    },
    customFormats: {
      type: Object,
      default: () => ({})
    },
    widgets: {
      type: Object,
      default: () => ({})
    },
    mode: {
      type: String,
      default: 'normal',
      validator(value) {
        return ['normal', 'inline'].includes(value);
      }
    },
    omitMissingFields: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    component() {
      if (this.mode === 'inline') {
        return InlineContainer;
      }
      if (this.mode === 'normal') {
        return NormalContainer;
      }
      return 'div';
    }
  }
});
</script>

<i18n lang="json">{}</i18n>
