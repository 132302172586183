import type { Ref } from 'vue';

import type { ValueVariant } from '../normalize-value';

import { computed, ref } from 'vue';

import { normalizeValue } from '../normalize-value';

export type { ValueVariant, Value } from '../normalize-value';

type Options = {
  multiple: Ref<boolean>;
};

type Result = {
  normalizedValue: Ref<ValueVariant>;
};

export function useNormalizedValue(
  value: Ref<ValueVariant>,
  { multiple = ref(false) }: Options
): Result {
  const normalizedValue = computed(() => {
    return normalizeValue(value.value, { multiple: multiple.value });
  });

  return {
    normalizedValue
  };
}
