<template>
  <view-field
    :schema="schema"
    :is-visible="options.isVisible"
    :label="label"
    :delimiter="options.breakAfter"
    :data-qa="options.name || id"
  >
    <widget-value :value="value" />
  </view-field>
</template>

<script>
import WidgetValue from './base-widget/widget-value.vue';
import ViewField from './view-field.vue';

export default {
  name: 'ViewFieldText',
  components: {
    ViewField,
    WidgetValue
  },
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: undefined
    },
    label: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default: () => ({})
    },
    schema: ViewField.props.schema
  }
};
</script>

<i18n lang="json">{}</i18n>
