<template>
  <div>
    <p :class="$style.select">
      <span v-if="(defaultUnselected && multiple && !count) || !title" :class="$style.name">{{
        $trlMessage('not-selected')
      }}</span>
      <template v-else>
        <span :class="$style.name">{{ title }}</span>
        <span v-if="multiple">{{ `: ${count || $trlMessage('search.count.all')}` }}</span>
      </template>
    </p>
  </div>
</template>

<script>
export default {
  name: 'SelectButtonText',
  props: {
    count: {
      type: Number || String,
      default: 0
    },
    multiple: {
      type: Boolean,
      default: true
    },
    defaultUnselected: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="less" module>
.select {
  display: grid;
  grid-template-columns: minmax(auto, max-content) auto;
  margin: 0;
}

.name {
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>

<i18n lang="json">{}</i18n>
