<template>
  <request-list-container>
    <template #default>
      <template v-for="(currentQuotas, caption) in quotasByFrame" :key="caption">
        <div v-if="showCaption" :class="$style.caption">
          {{ caption }}
        </div>
        <slot
          v-for="quota in currentQuotas"
          :key="quota.id"
          name="quota"
          :quota="quota"
          :schema-map="schemaMap"
          :schemas-fields="schemasFields"
        >
          <quota-item
            :quota="quota"
            :schema-map="schemaMap"
            :schemas-fields="schemasFields"
            @show-quota="$emit('show-quota', $event)"
          />
        </slot>
      </template>
      <slot v-if="!quotas.length" name="empty" />
      <slot name="after" />
    </template>
  </request-list-container>
</template>

<script>
import groupBy from 'lodash/groupBy';

import RequestListContainer from '@/components/vjsf/inline-container.vue';

import QuotaItem from './quota-item.vue';

export default {
  name: 'QuotaList',
  components: { RequestListContainer, QuotaItem },
  props: {
    quotas: {
      type: Array,
      required: true
    },
    schemas: {
      type: Array,
      required: true
    },
    schemasFields: {
      type: Object,
      required: true
    },
    frameBegin: {
      type: String,
      required: true
    },
    showCaption: Boolean
  },
  emits: ['show-quota'],
  computed: {
    quotasByFrame() {
      return groupBy(this.quotas, ({ created }) => {
        return created >= this.frameBegin
          ? this.$trlMessage('vacancy.quota.current')
          : this.$trlMessage('vacancy.quota.previous');
      });
    },
    schemaMap() {
      return this.schemas.reduce((acc, schema) => {
        acc[schema.id] = schema;
        return acc;
      }, {});
    }
  }
};
</script>

<style module>
.caption {
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  padding-top: 5px;
}
.caption {
  padding-top: 25px;
}
</style>

<i18n lang="json">{}</i18n>
