<template>
  <div data-qa="popup-layout-body" :class="$style.wrapper">
    <slot name="prepend" />
    <div v-if="hasBody" :class="$style.body">
      <div
        data-qa="body-info"
        :class="[
          $style.main,
          { [$style.fullWidth]: singleColumn },
          { [$style.clearPadding]: paddingDisabled }
        ]"
      >
        <render-slot :slot-content="mainSlot" />
      </div>
      <div v-if="!singleColumn" :class="$style.side">
        <render-slot :slot-content="sideSlot" />
      </div>
    </div>
    <slot name="append" />
  </div>
</template>

<script>
import { computed } from 'vue';

import RenderSlot from '@/components/render-slot/render-slot.vue';

import { SlotChecker } from '@/shared/lib/util/slot-checker';

export default {
  name: 'PopupBody',
  components: { RenderSlot },
  props: {
    singleColumn: {
      type: Boolean,
      default: false
    },
    paddingDisabled: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const mainSlot = SlotChecker.useSlotContent('main');
    const sideSlot = SlotChecker.useSlotContent('side');

    const hasBody = computed(() => Boolean(mainSlot.value) || Boolean(sideSlot.value));
    return {
      mainSlot,
      sideSlot,
      hasBody
    };
  }
};
</script>

<style lang="less" module>
@import '~@less/common/variables.less';

@sideWidth: 238px;

.wrapper {
  background: @white;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
}

.body {
  display: flex;
  flex: 1 0 0;
}

.main {
  flex-grow: 1;
  padding: 24px 24px 24px 0;
  position: relative;
  box-sizing: border-box;
  max-width: calc(100% - @sideWidth);
}

.clearPadding {
  padding: 0;
}

.fullWidth {
  padding-right: 0;
  max-width: 100%;
}

.side {
  flex: none;
  padding: 24px 0 24px 24px;
  width: @sideWidth;
  position: relative;
  box-sizing: border-box;
  border-left: 1px solid var(--border-neutral-S, rgba(0, 0, 0, 0.08));
  &:empty {
    border: none;
  }
}
</style>

<i18n lang="json">{}</i18n>
