<template>
  <div :class="className" @mouseleave="handleMouseLeave">
    <span v-if="!hideText" :class="$style.ratingValue">{{ tempValue }}/{{ maxValue }}</span>
    <label
      v-for="starNum in maxValue"
      :key="starNum"
      :class="getStarItemClassName(getStarValue(starNum, maxValue))"
      :data-value="getStarValue(starNum, maxValue)"
      @mouseenter="handleStarMouseEnter(getStarValue(starNum, maxValue))"
    >
      <input
        :name="$attrs.id"
        v-bind="$attrs"
        :value="getStarValue(starNum, maxValue)"
        :checked="getStarValue(starNum, maxValue) === value"
        type="radio"
        @change="handleChange"
        @click="handleStarClick(getStarValue(starNum, maxValue))"
      >
      <base-icon :class="$style.starIcon" type="star" />
    </label>
  </div>
</template>

<script>
import BaseIcon from '@/components/icon.vue';

export default {
  name: 'VRating',
  components: {
    BaseIcon
  },
  props: {
    value: {
      type: Number,
      default: undefined
    },
    starSize: {
      type: String,
      default: 'medium',
      validator(value) {
        return ['small', 'medium', 'large'].includes(value);
      }
    },
    maxValue: {
      type: Number,
      default: 10
    },
    hideText: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change'],

  data() {
    return {
      animateSelectedValue: false,
      currValue: this.value ?? 0,
      tempValue: this.value ?? 0
    };
  },
  computed: {
    className() {
      return {
        [this.$style.rating]: true,
        [this.$style.ratingHoverable]: this.animateSelectedValue
      };
    }
  },
  methods: {
    handleChange(event) {
      const value = Number.parseInt(event.target.value, 10);
      this.currValue = value;
      this.$emit('change', value);
    },
    getStarItemClassName(value) {
      return {
        [this.$style.starItem]: true,
        [this.$style.activeStarItem]: value <= this.value,
        [this.$style.selectedStarItem]: this.value === value,
        [this.$style[this.starSize]]: true
      };
    },
    getStarValue(num, maxNum) {
      return maxNum + 1 - num;
    },
    // Вот эта машинерия нужна для того,
    // чтобы не срабатывала анимация ховера на звёздочку
    // если звёздочка была только что выбрана
    // т.е. уже проигрались анимации наведения и клика
    handleStarMouseEnter(value) {
      this.tempValue = value;
      if (this.animateSelectedValue === false && this.value !== value) {
        this.animateSelectedValue = value;
      }
    },
    handleStarClick(value) {
      this.animateSelectedValue = false;
      this.tempValue = value;
    },
    handleMouseLeave() {
      this.animateSelectedValue = true;
      this.tempValue = this.currValue;
    }
  }
};
</script>

<style module>
.rating {
  direction: rtl;
  unicode-bidi: bidi-override;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  user-select: none;
  vertical-align: top;
  max-width: fit-content;
}

.starItem {
  position: relative;
  input:disabled ~ .starIcon {
    pointer-events: none;
  }
}

.starItem,
.starItem input,
.starItem .starIcon {
  box-sizing: content-box;
  width: 30px;
  height: 29px;
  padding-right: 4px;
  padding-left: 3px;
  @media screen and (max-width: $screenSmMax) {
    box-sizing: content-box;
    width: 22px;
    height: 21px;
    padding-right: 3px;
    padding-left: 3px;
  }
}

.small {
  width: 24px;
  height: 23px;
  input {
    width: 24px;
    height: 23px;
  }
  .starIcon {
    width: 24px;
    height: 23px;
  }
}

.large {
  width: 32px;
  height: 31px;
  input {
    width: 32px;
    height: 31px;
  }
  .starIcon {
    width: 32px;
    height: 31px;
  }
}

.ratingValue {
  display: block;
  text-align: right;
  direction: ltr;
  color: $textColor;
  font-size: 16px;
  line-height: 29px;
  width: 56px;
  @media screen and (max-width: $screenSmMax) {
    font-size: 12px;
    line-height: 21px;
    width: 42px;
  }
  @media screen and (max-width: $screenSmMax) {
    display: none;
  }
}

.starItem input,
.starItem .starIcon {
  position: absolute;
  top: 0;
}

.starItem .starIcon {
  left: 0;
}

.starItem input {
  left: 3px;
}

.starItem[data-value='1'] {
  & input {
    left: 0;
  }
  & .starIcon {
    padding-left: 0;
  }
}

.starItem[data-value='10'] {
  & .starIcon {
    padding-right: 0;
  }
}

.starItem input {
  opacity: 0;
}

.starIcon {
  cursor: pointer;
}

/* colors */
.starItem {
  color: #ebebeb;
}

.activeStarItem {
  color: #ffea32;
}
.starItem:hover,
.starItem:hover ~ .starItem {
  color: #ffea32;
}

/* animations */
.rating.ratingHoverable .starItem .starIcon,
.rating .starItem:not(.selectedStarItem) .starIcon {
  &:hover {
    animation: ratingHoverAnimation 0.2s ease-out;
    animation-fill-mode: forwards;
  }
}

.rating:not(.ratingHoverable) .starItem.selectedStarItem .starIcon {
  animation: ratingClickAnimation 0.3s ease-out;
  animation-fill-mode: forwards;
}

@keyframes ratingHoverAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
@keyframes ratingClickAnimation {
  0% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1.47);
  }
  100% {
    transform: scale(1);
  }
}
</style>

<i18n lang="json">{}</i18n>
