<template>
  <vacancy-resource
    :value="value"
    :query="query"
    :show-group-label="showGroupLabel"
    :ignore-vacancies-id="ignoreVacanciesId"
    :additional-items="additionalItems"
    :additional-filter="additionalFilter"
    :show-closed="showClosed"
    :show-other="showOther"
    :parent="parent"
    :squash-to-parent="squashToParent"
    :sort="sort"
    :class="$props.class"
    :style="style"
    @change:vacancies="$emit('change:vacancies', $event)"
  >
    <template #default="{ vacancies, loading }">
      <dropdown-autocomplete
        :id="id"
        :query="query"
        :items="vacancies"
        :value="value"
        :loading="loading"
        :multiple="multiple"
        :dropdown-size="dropdownSize"
        :dropdown-flip="dropdownFlip"
        :disabled="disabled"
        caption-field="position"
        value-field="position"
        v-bind="$attrs"
        @change="$emit('change', $event)"
        @search="query = $event"
      >
        <template #toggle="props">
          <slot name="toggle" v-bind="props" />
        </template>
        <template #item="props">
          <vacancy-item
            :highlight="props.highlight"
            :selected="props.selected"
            :disabled="props.disabled"
            :active="props.active"
            :item="props.item"
            :subtitle-field="subtitleField"
            :icon-gap-y="props.item[subtitleField] ? iconGapY : '0px'"
            bordered
          />
        </template>
      </dropdown-autocomplete>
    </template>
  </vacancy-resource>
</template>

<script>
import { DropdownAutocomplete } from '@/components/base-autocomplete';
import VacancyResource from '@/components/hf/resource/vacancy-resource.vue';
import VacancyItem from '@/components/list-item/vacancy-item.vue';

export default {
  name: 'VacancyDropdownAutocomplete',
  components: { VacancyItem, VacancyResource, DropdownAutocomplete },
  inheritAttrs: false,
  props: {
    id: DropdownAutocomplete.props.id,
    multiple: DropdownAutocomplete.props.multiple,
    dropdownSize: DropdownAutocomplete.props.dropdownSize,
    dropdownFlip: DropdownAutocomplete.props.dropdownFlip,
    value: DropdownAutocomplete.props.value,
    disabled: DropdownAutocomplete.props.disabled,
    showGroupLabel: VacancyResource.props.showGroupLabel,
    showClosed: VacancyResource.props.showClosed,
    showOther: VacancyResource.props.showOther,
    parent: VacancyResource.props.parent,
    squashToParent: VacancyResource.props.squashToParent,
    ignoreVacanciesId: VacancyResource.props.ignoreVacanciesId,
    additionalFilter: VacancyResource.props.additionalFilter,
    additionalItems: VacancyResource.props.additionalItems,
    subtitleField: VacancyItem.props.subtitleField,
    sort: VacancyResource.props.sort,
    iconGapY: VacancyItem.props.iconGapY,

    class: {
      type: [String, Array, Object],
      default: undefined
    },
    style: {
      type: [String, Array, Object],
      default: undefined
    }
  },
  emits: ['change', 'change:vacancies'],
  data() {
    return {
      query: ''
    };
  }
};
</script>

<i18n lang="json">{}</i18n>
