import type { Component } from 'vue';

import { defineAsyncComponent, h, defineComponent } from 'vue';

type Options = {
  hideLoader?: boolean;
};

function lazyLoadView<T extends Component>(
  AsyncView: Promise<T>,
  options: Options = {}
): Promise<Component> {
  const AsyncHandler = defineAsyncComponent({
    loader: () => AsyncView,
    loadingComponent: options.hideLoader
      ? require('@/components/vue-void.vue').default
      : require('@/components/loader/content-loader.vue').default
  });

  return Promise.resolve(
    defineComponent({
      inheritAttrs: false,
      render() {
        return h(AsyncHandler, this.$attrs);
      }
    })
  );
}

export const PageHelper = {
  lazyLoadView
};
