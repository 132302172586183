import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import type { AxiosError } from 'axios';

import type { Pipeline } from '@/shared/types/pipeline';
import type { Status } from '@/shared/types/status';

import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import { HttpErrorStatus } from '@/shared/api/http-error';
import { VacancyAPI } from '@/shared/api/vacancy';
import { useI18N } from '@/shared/lib/use-i18n';

import ApplicantsExport from '../../applicants-export/applicants-export.vue';
import { ExportType } from './export-type';

type ValueOf<T> = T[keyof T];

type IProps = {
  type: ValueOf<typeof ExportType>;
  applicantId?: number;
  vacancyId: number;
  accountVacancyStatusGroup?: number;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'applicants-export',
  props: {
    type: {},
    applicantId: {},
    vacancyId: {},
    accountVacancyStatusGroup: {}
  },
  emits: ['submit', 'cancel', 'empty'],
  setup(__props: any, { emit: __emit }) {



const emit = __emit;

const i18n = useI18N();

const isLoading = ref(false);
const store = useStore();
const pipelineById = computed(() => store.getters['pipelines/pipelineById']);
const statuses = computed(() => store.getters['vacancyStats/statuses']);
const pipelineStatusIds = computed(() =>
  __props.accountVacancyStatusGroup
    ? getPipelineStatusIds(pipelineById.value(__props.accountVacancyStatusGroup))
    : null
);

const realStatuses = computed(() => {
  if (__props.applicantId) {
    return [];
  }

  return filterStatuses(
    statuses.value.filter(({ virtual }) => !virtual),
    pipelineStatusIds.value
  );
});

const title = computed(() => {
  if (__props.type === ExportType.LIST) {
    return i18n('title.list');
  } else if (__props.type === ExportType.SURVEY_TYPE_A) {
    return i18n('title.survey_type_a');
  } else if (__props.type === ExportType.SURVEY_TYPE_Q) {
    return i18n('title.survey_type_q');
  }
  return null;
});

const initialValue = {
  field: [],
  status: realStatuses.value.map(({ id }) => id)
};

const handleSubmit = (values: { field: Array<string>; status: Array<number> }) => {
  isLoading.value = true;

  if (__props.type === ExportType.LIST) {
    return VacancyAPI.fetchStopList(__props.vacancyId, values)
      .then(() => {
        emit('submit');
      })
      .finally(() => {
        isLoading.value = false;
      });
  }

  let payload;
  if (__props.applicantId) {
    payload = {
      vacancy_id: __props.vacancyId,
      field: values.field,
      applicant_id: __props.applicantId
    };
  } else {
    payload = {
      vacancy_id: __props.vacancyId,
      field: values.field,
      status: values.status
    };
  }

  const handleError = (err: AxiosError) => {
    type ErrorResponseData = {
      errors?: {
        common?: Array<string>;
      };
    };
    const data = err.response?.data as ErrorResponseData;
    const reason = data?.errors?.common?.[0];
    const statusCode = err.response?.status;

    const isEmptyReport
      = statusCode === HttpErrorStatus.BAD_REQUEST
        && (reason === 'error.reports.survey_type_a.empty_report'
          || reason === 'error.reports.survey_type_q.empty_report');

    if (isEmptyReport) {
      emit('empty');
    } else {
      throw err;
    }
  };

  if (__props.type === ExportType.SURVEY_TYPE_A) {
    return VacancyAPI.generateSurveyTypeAReport(payload)
      .then(() => {
        emit('submit');
      })
      .catch(handleError)
      .finally(() => {
        isLoading.value = false;
      });
  }

  if (__props.type === ExportType.SURVEY_TYPE_Q) {
    return VacancyAPI.generateSurveyTypeQReport(payload)
      .then(() => {
        emit('submit');
      })
      .catch(handleError)
      .finally(() => {
        isLoading.value = false;
      });
  }
};

const handleCancel = () => emit('cancel');

defineComponent({
  name: 'VApplicantsExport'
});

function getPipelineStatusIds(pipeline: Pipeline) {
  return pipeline?.statuses.map((status) => status.account_vacancy_status);
}

function filterStatuses(statuses: Array<Status>, pipelineStatusIds: Array<Status>) {
  return pipelineStatusIds ? statuses.filter((s) => pipelineStatusIds.includes(s.id)) : statuses;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ApplicantsExport, {
    title: title.value,
    statuses: realStatuses.value,
    value: initialValue,
    loading: isLoading.value,
    onSubmit: handleSubmit,
    onCancel: handleCancel
  }, null, 8, ["title", "statuses", "loading"]))
}
}

})