import type { NotificationValue } from './notifications';
import type { AxiosClient } from './utils/client/axios';
import type { PaginatedList, ItemList } from './utils/types';
import type { AccountInfo } from '@/shared/types/account-info';
import type { VacancyQuota } from '@/shared/types/vacancy-quota';
import type { VacancyState } from '@/shared/types/vacancy-state';

import chunk from 'lodash/chunk';

import { VacancyNamingHelper } from '@/shared/lib/util/vacancy-naming';
import { MessageEvent } from '@/shared/types/poller-message';

import { ApiLayer } from './utils/api-layer';
import axios, { axiosApp } from './utils/client/axios';

type Reason = {
  id: number;
  name: string;
};

type VacancyCloseReason = Reason;
type VacancyHoldReason = Reason;

type VacancyNotificationsSettings = {
  status: number[];
  value: NotificationValue | null;
};

type LogParams = {
  page: number;
  count: number;
};

type LogItem = {
  id: number;
  account_data: AccountInfo;
  created: string;
  state: VacancyState;
  account_vacancy_close_reason: number | null;
  account_vacancy_hold_reason: number | null;
  vacancy_frame_fill_quota?: VacancyQuota;
};

type VacancyStat = {
  items: { total: number } & Record<string, number>;
  hired_applicants: number;
};

type Vacancy = {
  id: number;
  custom_name_data: Record<string, string[]>;
  company: null | string;
  position: null | string;
} & {
  [key: string]: unknown;
};

type VacancyAssignOptions = {
  permissions?: Array<{
    permission: 'status' | 'vacancy' | 'children-inherit-multivacancy-statuses';
    value?: number;
  }>;
};

declare global {
  // eslint-disable-next-line ts/consistent-type-definitions
  interface Window {
    Vacancy: Vacancy;
  }
}

type SearchParams = {
  q?: string;
  parent?: boolean | number;
  mine?: boolean;
  state?: VacancyState[];
  ignore?: number[];
  member?: number[] | number;
  count?: number;
  page?: number;
  id?: number[];
  extended?: boolean;

  assigned_divisions_only?: boolean;
  account_division?: number[];
  account_region?: number[];
};

type ListParams = {
  state?: VacancyState[];
  member?: number[] | number;
};

const CHUNK_LENGTH = 100;

class VacancyLayer extends ApiLayer<AxiosClient> {
  constructor(client: AxiosClient) {
    super(client);

    this.fetchById = this.memoizeMethod(this.fetchById, ({ invalidate, message }, id) => {
      if (
        ![MessageEvent.vacancyAdd, MessageEvent.vacancyEdit, MessageEvent.vacancyRemove].includes(
          message.event
        )
      ) {
        return;
      }

      const data = message.data as Vacancy;
      if (data.id === id) {
        invalidate();
      }
    });
    this.fetchCloseReasonList = this.memoizeMethod(this.fetchCloseReasonList);
    this.fetchHoldReasonList = this.memoizeMethod(this.fetchHoldReasonList);
  }

  fetchStopList(vacancyId: number, data: { field: string[]; status: number[] }) {
    return this.methods.post(`/vacancy/${vacancyId}/stoplist`, data);
  }

  generateSurveyTypeAReport(
    data: { vacancy_id: number; field: string[] } & (
      | { status: number[] }
      | { applicant_id: number }
    )
  ) {
    return this.methods.post('/report/generate/applicant_survey_type_a', data, {
      baseURL: axiosApp.baseURL
    });
  }

  generateSurveyTypeQReport(
    data: { vacancy_id: number; field: string[] } & (
      | { status: number[] }
      | { applicant_id: number }
    )
  ) {
    return this.methods.post('/report/generate/applicant_survey_type_q', data, {
      baseURL: axiosApp.baseURL
    });
  }

  fetchFullById(vacancyId: number) {
    return this.methods.get(`/full_vacancy/${vacancyId}`, {
      headers: {
        accept: 'application/json'
      }
    });
  }

  fetchChildrenList(vacancyId: number) {
    return new Promise((resolve, reject) => {
      let result: Vacancy[] = [];

      const step = (page: number) => {
        this.searchListByFilter({ parent: vacancyId, count: 100, page })
          .then(({ items, page, total }) => {
            result = result.concat(items);
            if (page <= total) {
              step(page + 1);
              return;
            }
            resolve(result);
          })
          .catch(reject);
      };
      step(1);
    });
  }

  // независимо от прав, нужно для построения названий в мультике 2.0
  // с переездом названий на бэк нужно будет удалить
  fetchAllChildrenList(vacancyId: number) {
    return this.methods.get(`/vacancy/${vacancyId}/children`);
  }

  fetchApplicantsOnClosedVacancy(vacancyId: number, { page }: { page: number }) {
    const url = '/report/preview/closed_vacancy/hired_applicants';
    return this.methods.post(url, {
      page,
      period_type: 'current',
      vacancies_ids: [vacancyId]
    });
  }

  fetchById(vacancyId: number) {
    return this.methods.get<Vacancy>(`/vacancies/${vacancyId}`, {
      headers: {
        Accept: 'application/json'
      }
    });
  }

  searchListByFilter(params: SearchParams = {}) {
    if (params.id?.length) {
      const chunks = chunk(params.id, CHUNK_LENGTH);
      return Promise.all(
        chunks.map((ids) =>
          this.methods.get<PaginatedList<Vacancy>>('/search/vacancies', {
            params: {
              ...params,
              id: ids,
              count: CHUNK_LENGTH
            }
          })
        )
      ).then((data) => {
        const itemsList = data.map((d) => d.items);
        const firstChunk = itemsList.shift() || [];
        const items = firstChunk.concat(...itemsList);
        return {
          items,
          page: 1,
          total: chunks.length
        };
      });
    }
    return this.methods.get<PaginatedList<Vacancy>>('/search/vacancies', {
      params
    });
  }

  fetchListByFilter(params: ListParams = {}) {
    return this.methods.get<ItemList<Vacancy>>('/vacancies', {
      params: {
        ...params,
        json: true
      }
    });
  }

  fetchCloseReasonList() {
    return this.methods
      .get<ItemList<VacancyCloseReason>>('/vacancy_close_reason')
      .then(({ items }) => items);
  }

  fetchHoldReasonList() {
    return this.methods
      .get<ItemList<VacancyHoldReason>>('/vacancy_hold_reason')
      .then(({ items }) => items);
  }

  fetchStat(vacancyId: number) {
    return this.methods.get<VacancyStat>(`/vacancy/${vacancyId}/stats`);
  }

  fetchHiredStat(vacancyId: number) {
    return this.fetchStat(vacancyId).then((stat) => stat.hired_applicants);
  }

  fetchLogListById(vacancyId: number, params: LogParams) {
    return this.methods.get<PaginatedList<LogItem>>(`/vacancy/${vacancyId}/logs`, { params });
  }

  fetchNotificationsSettings(vacancyId: number) {
    return this.methods.get<VacancyNotificationsSettings>(`/vacancy/${vacancyId}/notify`);
  }

  updateNotificationsSettings(vacancyId: number, data: any) {
    return this.methods.post<VacancyNotificationsSettings>(`/vacancy/${vacancyId}/notify`, data);
  }

  delete(vacancyId: number) {
    return this.methods.delete<Vacancy>(`/vacancies/${vacancyId}`);
  }

  patch(vacancyId: number, data: Partial<Vacancy>) {
    return this.methods.patch<Vacancy>(`/vacancies/${vacancyId}`, data);
  }

  /**
   * Приостановка вакансии
   * @param vacancyId
   * @param data
   */
  hold(vacancyId: number, data: Partial<Vacancy>) {
    return this.methods.post<Vacancy>(`/vacancy/${vacancyId}/state/hold`, data);
  }

  /**
   * Возобновление вакансии
   * @param vacancyId
   */
  resume(vacancyId: number) {
    return this.methods.post<Vacancy>(`/vacancy/${vacancyId}/state/resume`);
  }

  /**
   * Закрытие вакансии
   * @param vacancyId
   * @param data
   */
  close(vacancyId: number, data: Partial<Vacancy>) {
    return this.methods.post<Vacancy>(`/vacancy/${vacancyId}/state/close`, data);
  }

  update(vacancyId: number, data: Vacancy) {
    return this.methods.put<Vacancy>(`/vacancies/${vacancyId}`, data);
  }

  create(data: Vacancy) {
    return this.methods.post<Vacancy>('/vacancies', data);
  }

  assignCoworker(coworkerId: number, vacancyId: number, options: VacancyAssignOptions = {}) {
    return this.methods.put<{ status: boolean }>(
      `/vacancy/${vacancyId}/coworker/${coworkerId}/assign`,
      options
    );
  }

  unassignCoworker(coworkerId: number, vacancyId: number) {
    return this.methods.delete<{ status: boolean; member: number }>(
      `/vacancy/${vacancyId}/coworker/${coworkerId}/assign`
    );
  }

  prepare<V extends Vacancy>(vacancy: V) {
    const company = VacancyNamingHelper.computeCompany(vacancy);

    return {
      ...vacancy,
      company
    };
  }

  exists(state: VacancyState) {
    return this.methods.get<{ result: boolean }>('/vacancies/exists', {
      baseURL: axiosApp.baseURL,
      params: {
        state
      }
    });
  }
}

export const VacancyAPI = new VacancyLayer(axios);
