<template>
  <base-list
    ref="list"
    :items="items"
    :loading="loading"
    :page-mode="pageMode"
    :max-height="maxHeight"
    :item-size="itemSize"
    :hierarchy-map="hierarchyMap"
    :show-inactive="showInactive"
    :loop-pointer="loopPointer"
    :items-postprocessing="itemsPostprocessing"
    :caption-field="captionField"
    @enter-item="toggle"
    @left-key="$emit('left-key', $event)"
    @right-key="$emit('right-key', $event)"
  >
    <template #before="props">
      <slot name="before" v-bind="props" :reset="reset" />
    </template>
    <template #loading>
      <slot name="loading" />
    </template>
    <template #not-found>
      <slot name="not-found" />
    </template>
    <template #item-header="props">
      <slot name="item-header" v-bind="props" />
    </template>
    <template #item-header-title="props">
      <slot name="item-header-title" v-bind="props" />
    </template>
    <template #item="props">
      <select-item-wrapper
        :deep-children="hierarchyMap.deepChildrenMap[props.item.id]"
        :selected="selectionMap[props.item.id]"
        :multiple="multiple"
        :value="selection"
        @click="toggle(props.item)"
      >
        <template #default="{ select, selected, hasSelectedChildren, hasChildren }">
          <slot
            name="item"
            v-bind="props"
            :selected="selected"
            :toggle="select"
            :has-selected-children="hasSelectedChildren"
          >
            <select-item
              :highlight="props.highlight"
              :selected="selected"
              :disabled="props.disabled"
              :deep="props.deep"
              :active="props.active"
              :available="hasChildren && onlyLeaf ? false : props.available"
              :bordered="Boolean(props.nextItem && props.nextItem.id)"
            >
              <template #default>
                <slot name="item-title" v-bind="props">
                  <span
                    :title="props.item[captionField]"
                    v-html="props.item[`${captionField}_highlight`] || props.item[captionField]"
                  />
                </slot>
              </template>
              <template #icon>
                <slot name="item-icon" v-bind="props" :selected="selected" />
              </template>
              <template #before-content>
                <slot name="item-before-content" v-bind="props" />
              </template>
              <template #after-content>
                <slot name="item-after-content" v-bind="props" />
              </template>
              <template #hint>
                <slot name="item-hint" v-bind="props" />
              </template>
              <template #subtitle>
                <slot name="item-subtitle" v-bind="props" />
              </template>
            </select-item>
          </slot>
        </template>
      </select-item-wrapper>
    </template>
    <template #after="props">
      <slot name="after" v-bind="props" :reset="reset" />
    </template>
    <template #after-list="props">
      <slot name="after-list" v-bind="props" />
    </template>
  </base-list>
</template>

<script>
import { toRefs, ref } from 'vue';

import BaseList from '@/components/base-list/base-list.vue';
import SelectItem from '@/components/list-item/select-item.vue';

import { useListSelection } from './composables/use-list-selection';
import SelectItemWrapper from './select-item-wrapper.vue';

export default {
  name: 'SelectList',
  components: { SelectItemWrapper, BaseList, SelectItem },
  props: {
    loading: BaseList.props.loading,
    pageMode: BaseList.props.pageMode,
    items: BaseList.props.items,
    itemSize: BaseList.props.itemSize,
    showInactive: BaseList.props.showInactive,
    maxHeight: BaseList.props.maxHeight,
    hierarchyMap: BaseList.props.hierarchyMap,
    itemsPostprocessing: BaseList.props.itemsPostprocessing,
    loopPointer: BaseList.props.loopPointer,
    captionField: BaseList.props.captionField,
    multiple: Boolean,
    value: {
      type: [Array, String, Number, Boolean],
      default: undefined
    },
    maxValues: {
      type: Number,
      default: Infinity
    },
    onlyLeaf: Boolean, // доступны для выбора только элементы без детей, P.S. названия совсем не говорящее...
    disableAutoCompress: Boolean
  },
  emits: ['change', 'reset', 'left-key', 'right-key'],
  setup(props, { emit }) {
    const { value, multiple, hierarchyMap, onlyLeaf, disableAutoCompress, maxValues }
      = toRefs(props);
    const list = ref(null);
    const { toggle, reset, selectionMap, selection } = useListSelection(value, {
      multiple,
      maxValues,
      hierarchyMap,
      onlyLeaf,
      disableAutoCompress,
      onChange(newValue) {
        emit('change', newValue);
      }
    });
    return {
      list,
      next() {
        list.value.handleNext();
      },
      prev() {
        list.value.handlePrev();
      },
      enter() {
        list.value.handleEnter();
      },
      reset: () => {
        reset();
        emit('reset');
      },
      toggle,
      selectionMap,
      selection
    };
  }
};
</script>

<i18n lang="json">{}</i18n>
