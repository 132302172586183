import account from './modules/account';
import applicantsStats from './modules/applicants-stats';
import config from './modules/config';
import im from './modules/im';
import interviewTypes from './modules/interview-types';
import mail from './modules/mail';
import members from './modules/members';
import pipelines from './modules/pipelines';
import rejections from './modules/rejection-reasons';
import sidebar from './modules/sidebar';
import sms from './modules/sms';
import sources from './modules/sources/sources';
import templateVariables from './modules/template-variables';
import vacancies from './modules/vacancies';
import vacancyStats from './modules/vacancy-stats';

export const modulesNS = {
  applicantsStats: 'applicantsStats',
  vacancyStats: 'vacancyStats'
};

export default {
  modules: {
    config,
    account,
    vacancies,
    members,
    sidebar,
    pipelines,
    mail,
    sms,
    im,
    interviewTypes,
    rejections,
    sources,
    templateVariables,
    [modulesNS.applicantsStats]: applicantsStats,
    [modulesNS.vacancyStats]: vacancyStats
  },
  strict: false
};
