<template>
  <div :class="$style.wrapper">
    <base-list
      ref="list"
      :max-height="maxHeight"
      :items="items"
      :item-size="39"
      show-inactive
      @enter-item="select"
    >
      <template #item="{ item, highlight }">
        <div :class="$style.item">
          <select-list-item
            :item="item"
            :item-component="itemComponent"
            :display-field="displayField"
            :sub-display-field="subDisplayField"
            :hovered="highlight"
            :picked="isGroupPicked(item) || isPicked(item)"
            @click="select"
          />
        </div>
      </template>
    </base-list>
  </div>
</template>

<script>
import BaseList from '@/components/base-list/base-list.vue';

import {
  deselectHierarchy,
  selectHierarchy,
  createParentLookupTable
} from '@/shared/lib/util/check-hierarchy';

import SelectListItem from './select-list-item.vue';

// TODO возможно стоит в findFirstPredicate добавить проверку на disabled
/**
 * Predicate to find first available item (but not header)
 * @param model
 * @returns {boolean}
 */
const findFirstPredicate = (model) => model.type !== 'header';

// TODO: временная обёртка, используйте `@/components/base-list/base-list.vue` или его производные
export default {
  name: 'SelectList',
  components: {
    BaseList,
    SelectListItem
  },
  SelectListItem,
  props: {
    value: {
      validator() {
        return true;
      },
      default: null
    },
    items: {
      type: Array,
      required: true
    },
    displayField: {
      type: String,
      default: 'name'
    },
    subDisplayField: {
      type: String,
      default: ''
    },
    idField: {
      type: String,
      default: 'id'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    // когда список позволяет делать множетственный выбор, то учитывать иерархию айтемов
    considerHierarchy: {
      type: Boolean,
      default: false
    },
    itemComponent: {
      type: String,
      default: 'item'
    },
    isSelectable: {
      type: Function,
      default: null
    },
    maxHeight: {
      type: String,
      default: ''
    }
  },
  emits: ['change'],
  computed: {
    parentLookupTable() {
      return createParentLookupTable(this.items);
    },
    valueParentLookupTable() {
      return createParentLookupTable(this.value);
    }
  },
  methods: {
    isGroupPicked(item) {
      if (!item.isGroup) {
        return false;
      }
      const isPicked = (item) => {
        return (this.parentLookupTable[item.id] || []).every((child) => {
          if (child.isGroup) {
            return isPicked(child);
          } else {
            return (this.valueParentLookupTable[item.id] || []).find(
              (valueItem) => valueItem.id === child.id
            );
          }
        });
      };

      return isPicked(item);
    },
    isPicked(item) {
      if (this.multiple) {
        return !!this.value.find((m) => m[this.idField] === item[this.idField]);
      } else {
        return this.value?.[this.idField] === item[this.idField];
      }
    },
    handleNext() {
      this.$refs.list.handleNext();
    },
    handlePrev() {
      this.$refs.list.handlePrev();
    },
    handleEnter() {
      this.$refs.list.handleEnter();
    },
    select(item) {
      // isSelectable по идее, это добавление для findFirstPredicate
      // уже есть todo на добавление учета _disabled в findFirstPredicate
      // кажется, что надо еще учитывать isSelectable
      if (!item || !findFirstPredicate(item)) {
        return;
      }

      if (item._disabled) {
        return;
      }

      const index = this.multiple
        ? this.value.findIndex((m) => m[this.idField] === item[this.idField])
        : -1;
      if (index < 0 && this.isSelectable && !this.isSelectable(item, this.value)) {
        return;
      }

      const nextValue = item;

      if (!this.multiple) {
        this.$emit('change', nextValue);
        return;
      }

      const newValue = [...this.value];

      if (this.isGroupPicked(item) && this.considerHierarchy) {
        const temp = deselectHierarchy(nextValue, this.value, this.items);
        newValue.splice(0, newValue.length, ...temp);
        this.$emit('change', newValue);
        return;
      }

      if (index >= 0) {
        if (this.considerHierarchy) {
          const temp = deselectHierarchy(nextValue, this.value, this.items);
          newValue.splice(0, newValue.length, ...temp);
        } else {
          newValue.splice(index, 1);
        }
      } else if (!this.value.find((m) => m[this.idField] === item[this.idField])) {
        if (this.considerHierarchy) {
          const temp = selectHierarchy(nextValue, this.value, this.items);
          newValue.splice(0, newValue.length, ...temp);
        } else {
          newValue.push(nextValue);
        }
      }

      this.$emit('change', newValue);
    }
  }
};
</script>

<style module lang="less">
@import '~@less/common/variables.less';

.wrapper {
  height: 100%;
}

.item {
  width: 100%;
}
</style>

<i18n lang="json">{}</i18n>
