import type { AxiosClient } from '@/shared/api/utils/client/axios';
import type { ItemList } from '@/shared/api/utils/types';
import type { Email, EmailType } from '@/shared/types/email-user';

import { ApiLayer } from '@/shared/api/utils/api-layer';
import { axiosApp } from '@/shared/api/utils/client/axios';

// Интерфейс для проверки email
type EmailVerificationRequest = {
  type: EmailType;
  email: string;
};

// Интерфейс для ответа на запрос проверки email
type EmailVerificationResponse = {
  id: string; // Уникальный идентификатор запроса
  type: EmailType;
  next_send_after: number; // Время, через которое можно повторно отправить код, в секундах
  ttl: number; // Время оставшейся жизни запроса, в секундах
  email: string;
};

// Интерфейс для отправки пинкода
type PinVerificationRequest = {
  id: string;
  code: string;
};

class EmailUserLayer extends ApiLayer<AxiosClient> {
  constructor(client: AxiosClient) {
    super(client);
    this.fetchList = this.memoizeMethod(this.fetchList);
  }

  fetchList(versionKey = 0) {
    return this.methods
      .get<ItemList<Email>>('/user/emails', {
        params: { versionKey } // Чтобы была возможность запрашивать не-мемоизированный список
      })
      .then(({ items }) => items);
  }

  update(payload: EmailVerificationRequest) {
    return this.methods.put('/user/email', payload);
  }

  /**
   * Запрос для подтверждения почты, в результате которого на указанную почту отправляют пин-код
   * @param payload
   */
  fetchVerify(payload: EmailVerificationRequest) {
    return this.methods.post<EmailVerificationResponse>(
      '/user/email_verification_request',
      payload
    );
  }

  /**
   * Запрос для повторного запроса кода верификации
   * @param id
   */
  resendVerify(id: string) {
    return this.methods.patch<EmailVerificationResponse>(`/user/email_verification_request/${id}`);
  }

  /**
   * Верификация почты с помощью пин-код
   * @param payload
   */
  sendVerify(payload: PinVerificationRequest) {
    const params = {
      code: payload.code
    };
    return this.methods.post<EmailVerificationResponse>(
      `/user/email_verification_request/${payload.id}/verify`,
      params
    );
  }
}

export const EmailUserAPI = new EmailUserLayer(axiosApp);
